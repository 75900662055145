import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';

import { getSessionById, updateActiveSession } from "redux/ducks/sessions";
import { selectContactBySessionId } from "redux/selectors/selectors";
import { timerStart } from "redux/ducks/timers";

import CallTimer from "components/CallItem/CallTimer";
import Spinner from "components/UI/Spinner/Spinner";

const AttachmentSession = props => {
  const {
    dateClosed,
    dateCreated,
  } = props;

  const [state, setState] = useState({
    isPending: true,
    isSessionDeleted: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!props.sessionExist) {
      setState({
        isPending: true,
        isSessionDeleted: false,
      });

      props.getSessionById(props.id)
        .then(res => {
          if (res && res.isFailed) {
            if (res.message.includes('No sales session with id:')) {
              setState({
                isPending: false,
                isSessionDeleted: true,
              });
            }
          }
          else {
            setState({
              isPending: false,
              isSessionDeleted: false,
            });
          }
        })
    }
    else {
      setState({
        isPending: false,
        isSessionDeleted: false,
      });
    }
    if (dateCreated && !dateClosed) {
      props.timerStart('session_' + props.id, dateCreated);
    }
  }, [props.sessionExist, dateCreated]);

  const goToSession = e => {
    if (!dateClosed) {
      props.updateActiveSession(props.id);

      navigate('/client/sales');
    }
  }

  const renderTopLine = () => {
    if (state.isPending) {
      return null;
    }
    else if (props.contact) {
      return <>Session with <b>{props.contact.fn}</b></>;
    }
  }

  const renderBottomLine = () => {
    if (state.isPending) {
      return null;
    }
    else if (dateClosed) {
      return 'session closed';
    }
    else if (state.isSessionDeleted) {
      return <span className='attachment-session__deleted-session'>Session was deleted</span>
    }
    else if (props.timer && !dateClosed) {
      return <CallTimer timer={props.timer} />
    }
  }

  return (
    <div className='attachments__session' onClick={goToSession}>
      {props.showTitle &&
        <h4 className="attachments__title">Attached session</h4>
      }

      <div className="attachment-session__body">
        {state.isPending &&
          <div className='attachment-session__spinner-wrap'>
            <Spinner spinnerSize='30px' />
          </div>
        }

        <p className="attachment-session__meta">
          {renderTopLine()}
        </p>

        <time className="attachment-session__time">
          {renderBottomLine()}
        </time>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  sessionExist: !!state.sessions.entities[ownProps.id],
  dateCreated: state.sessions.entities[ownProps.id] && state.sessions.entities[ownProps.id].dateCreated,
  dateClosed: state.sessions.entities[ownProps.id] && state.sessions.entities[ownProps.id].dateClosed,
  contact: state.sessions.entities[ownProps.id] && selectContactBySessionId(state, ownProps.id),
  timer: state.timers[`session_${ownProps.id}`]
});

const mapDispatchToProps = {
  getSessionById,
  timerStart,
  updateActiveSession
}

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentSession);