import React from "react";

import { MODAL_TYPES } from "redux/ducks/activeWindows";
import { classModifier } from "utils";

import './ProfileCardPhotos.scss';
import Carousel from "components/Carousel/Carousel";

const ProfileCardPhotos = ({ images, ...props }) => {
  const {
    id,
    agentName
  } = props;

  const onShowSlider = e => {
    if (props.isBufferZone || !images.length) return;

    if (typeof (e.target.className) !== 'string') { // if click on svg
      return onShowSlider({
        target: e.target.parentNode
      });
    };

    return e.target.dataset.image
      ? props.openModal(MODAL_TYPES.profileCarousel, { id })
      : null;
  }

  return (
    <div className="profile-card-photos">
      <div
        className={classModifier('profile-card-photos__image-wrap', [
          props.isAvailable && 'available',
          props.isArchived && 'archived',
        ])}
        onClick={onShowSlider}
      >
        <Carousel
          draggable={false}
          images={images}
          imgClass="profile-card-photos__image"
          imgWrapClass='profile-card-photos__image-inner'
          prevBtnClass="profile-card-photos__prev-slide"
          nextBtnClass="profile-card-photos__next-slide"
          iconWidth="32"
          iconHeight="32"
        />

        <div className="profiles__tags-agent-wrap">
          {agentName &&
            <div className="profiles__agent">
              {agentName}
            </div>
          }
        </div>

      </div>

      <div className="profile-card-photos__desc">
        <span className="profile-card-photos__count">Photos {images.length}</span>

        {/* <button
          className="profile-card-photos__private-gallery"
          onClick={() => props.openModal(MODAL_TYPES.privateGallery, { profileId: id })}
        >
          Private Gallery
        </button> */}
      </div>
    </div>
  );
};

export default React.memo(ProfileCardPhotos);