import React, { useState, memo } from 'react';

import './ToggleSwitch.scss';
import { useDidUpdate } from 'hooks';

const ToggleSwitch = (props) => {
  const {
    classNamePrefix = 'toggle-switch',
    isChecked = false,
    disabled = false,
    name,
  } = props;

  const [isToggleChecked, setToggleChecked] = useState(!!isChecked);
  const [toggleId, setToggleId] = useState(props.id || Math.round(Math.random() * 1000));

  useDidUpdate(() => {
    setToggleChecked(!!isChecked);
  }, [isChecked])

  const handleChange = (e) => {
    console.log('handle toggle Change', e.target.checked)
    setToggleChecked(e.target.checked);

    if (props.onChange && typeof props.onChange === "function") {
      props.onChange(e.target.checked);
    }
  }

  console.log('isTOO', isToggleChecked, isChecked);


  return (
    <label
      className={classNamePrefix}
      htmlFor={toggleId}
    >
      <input
        type="checkbox"
        name={name}
        id={toggleId}
        className={classNamePrefix + "__checkbox"}
        checked={isToggleChecked}
        onChange={handleChange}
        disabled={disabled}
      />

      <div
        className={
          `${classNamePrefix}__slider
            ${disabled ? classNamePrefix + "__slider" + "--disabled" : ""}`
        }
      >
        <span
          className={
            `${classNamePrefix}__slider-inner
            ${disabled ? classNamePrefix + "__slider-inner" + "--disabled" : ""}`
          }
        />
      </div>

    </label>
  )
}

export default memo(ToggleSwitch);