export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const REMOVE_MODAL = 'REMOVE_MODAL';

export const PLAY_MEDIA = 'PLAY_MEDIA';
export const STOP_MEDIA = 'STOP_MEDIA';

export const UPDATE_MODAL_PROPS = 'UPDATE_MODAL_PROPS';

export const MODAL_TYPES = {
  editContactForm: 'edit-contact-form',
  addContactForm: 'add-contact-form',
  userSettings: 'user-settings',
  profileRingtones: 'profile-ringtones',
  timePicker: 'time-picker',
  contactCard: 'contact-card',
  blockForm: 'block-form',
  transferedCall: 'transfered-call',
  voicemails: 'voicemails',
  incomingQueueCall: 'incoming-queue-call',
  conferenceFullMode: 'conference-full-mode',
  webrtcConferenceFullMode: 'webrtc-conference-full-mode',
  salesFiltersEditor: 'sales-filters-editor',
  bookingsList: 'bookings-list',
  bookingsByDates: 'bookings-by-dates',
  chat: 'chat',
  profilesBuffer: 'profiles-buffer',
  profilesBooked: 'profiles-booked',
  profileReviews: 'profile-reviews',
  profileCarousel: 'profile-carousel',
  incomingCall: 'incoming-call',
  outgoingCall: 'outgoing-call',
  error: 'error',
  image: 'image',
  video: 'video',
  fullScreenCall: 'full-screen-call',
  awaySystem: 'away-system',
  mergeContacts: 'merge-contacts',
  mailingComponent: 'mailing-component',
  privateGallery: 'private-gallery',
  contactsMergeList: 'contacts-merge-list',
  bookingExistWarning: 'booking-exist-warning',
  nightBotReport: 'night-bot-report',
  profilePricesEditing: 'profile-prices-editing',
  profileNotesList: 'profile-notes-list',
  mailContactSearch: 'mail-contact-search',
  notForContact: 'not-for-contact',
  call: 'call',
  feedback: 'feedback',
  SearchList: 'SearchList',
}

export const openModal = (type, props) => dispatch => {
  dispatch({
    type: OPEN_MODAL,
    payload: {
      type,
      props,
    },
  })
}

export const closeModal = (modalType = null) => dispatch => { // delete last item by default
  dispatch({
    type: CLOSE_MODAL,
    payload: modalType,
  });

  setTimeout(() => dispatch({
    type: REMOVE_MODAL,
    payload: modalType,
  }), 500);
}

export const updateModalProps = (props, modalType) => dispatch => {
  dispatch({
    type: UPDATE_MODAL_PROPS,
    payload: {
      props,
      modalType,
    }
  })
}

export const openTransferedCallModal = (call, audio) => (dispatch, getState) => {
  if (!call.id) {
    const media = getState().activeWindows.media;

    if (media && !media.paused) {
      media.pause();
      media.currentTime = 0;
    }
  }

  dispatch({
    type: OPEN_MODAL,
    payload: {
      type: MODAL_TYPES.transferedCall,
      props: { call },
      audio,
    }
  });
};

export const playMedia = (media) => dispatch => {
  dispatch({
    type: PLAY_MEDIA,
    payload: media,
  });
};

export const stopMedia = (media) => dispatch => {
  dispatch({
    type: STOP_MEDIA,
    payload: media,
  });
};

const initialState = {
  activeModals: [],
  media: null,
  smsAudio: null,
  callAudio: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_MODAL: {
      const { type, props, audio } = payload;
      const activeModalWithCurrentType = state.activeModals.find(modal => modal.type === type);

      if (activeModalWithCurrentType && JSON.stringify(activeModalWithCurrentType.props) === JSON.stringify(props)) {
        return state;
      }
      const newModal = {
        type,
        props,
        isShow: true,
        isCloseOutside: true,
        isCloseOnEscape: true,
      };

      if (type === MODAL_TYPES.incomingCall) {
        newModal.isCloseOutside = false;
      }

      if (type === MODAL_TYPES.userSettings) {
        newModal.isCloseOutside = false;
        newModal.isCloseOnEscape = false;
      }

      return {
        ...state,
        activeModals: [...state.activeModals, newModal],
        media: audio || state.media,
      }
    }

    case CLOSE_MODAL: {
      const updatedActiveModals = [...state.activeModals];

      const lastIndex = updatedActiveModals.length - 1;
      const removedIndex = payload
        ? state.activeModals.findIndex(modal => modal.type === payload)
        : lastIndex;

      if (removedIndex === -1) {
        return state;
      }
      const removedModal = updatedActiveModals[removedIndex];

      updatedActiveModals[removedIndex] = {
        ...removedModal,
        isShow: false,
      }

      return {
        ...state,
        activeModals: updatedActiveModals,
      }
    }

    case REMOVE_MODAL: {
      const updatedActiveModals = [...state.activeModals];

      const lastIndex = updatedActiveModals.length - 1;
      const removedIndex = payload
        ? state.activeModals.findIndex(modal => modal.type === payload)
        : lastIndex;

      if (removedIndex === -1) {
        return state;
      }
      updatedActiveModals.splice(removedIndex, 1);

      return {
        ...state,
        activeModals: updatedActiveModals,
      }
    }

    case PLAY_MEDIA: {
      if (state.media && state.media === payload) {
        return state;
      }
      else if (state.media && state.media !== payload) {
        state.media.pause();
        state.media.currentTime = 0;
      }

      return {
        ...state,
        media: payload,
      };
    }
    case STOP_MEDIA: {
      if (state.media) {
        state.media.pause();
        state.media.currentTime = 0;
      }

      return state;
    }
    case UPDATE_MODAL_PROPS: {
      const updatedActiveModals = [...state.activeModals];

      const updatedIndex = updatedActiveModals.findIndex(modal => modal.type === payload.modalType);


      if (updatedIndex === -1) {
        return state;
      }

      updatedActiveModals[updatedIndex] = {
        ...updatedActiveModals[updatedIndex],
        props: payload.props,
      }

      return {
        ...state,
        activeModals: [...updatedActiveModals]
      };
    }
    default:
      return state;
  }
};