import { getContactAvatar } from 'utils';
import { CONTACT_TYPES } from 'config/constants';

import './SearchListContactItem.scss';

const SearchListContactItem = ({ item: contact, onClick }) => {
  const isOperator = contact.type === CONTACT_TYPES.OPERATOR;
  const mailOrPhone = !isOperator && (contact.emails[0]?.email || contact.tels[0]?.tel);

  return (
    <div className='search-list-client-item' onClick={() => onClick(contact)}>
      <div className='search-list-client-item__avatar-thumb'>
        <img src={getContactAvatar(contact)} alt="avatar" />
      </div>
      <div className='search-list-client-item__info'>
        <p className='search-list-client-item__name'>{!isOperator ? contact.fn : contact.username}</p>

        {!isOperator && mailOrPhone &&
          <p className='search-list-client-item__mail-or-phone'>
            {mailOrPhone}
          </p>
        }
      </div>
    </div>
  );
};

export default SearchListContactItem;
