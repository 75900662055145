import React, { useState, useRef, useEffect } from 'react';

import './AudioPlayer.scss';

const maxCountOfSteps = 100; // 100%

const Slider = props => {
  const {
    countOfSteps,
    onUpdate,
    thumbWasMoved, // flag for render the slider after change values in parent component
  } = props;

  const [currentStep, setCurrentStep] = useState(0);
  const [countInStep, setCountInStep] = useState(null); // how much steps in step (in proportion). Example: 2 step of 5 It's 20/100

  const sliderRef = useRef({});
  const thumbRef = useRef({});

  useEffect(() => { // Init
    setCurrentStep(props.currentStep);
    setCountInStep(maxCountOfSteps / countOfSteps);
  }, []);

  useEffect(() => { // Main slider
    if (thumbWasMoved !== undefined) {
      let currStep = currentStep;

      if (currStep / countInStep !== props.currentStep - 1) { // not normal. seconds in player not equal slider step
        setCurrentStep((props.currentStep - 1) * countInStep);
        currStep = (props.currentStep - 1) * countInStep;
      }

      if (currStep !== countInStep && currStep / countInStep === countOfSteps) {
        return setCurrentStep(0);
      }

      setCurrentStep((currStep / countInStep * countInStep) + countInStep);
    }
  }, [!!thumbWasMoved && thumbWasMoved, countInStep]);

  const onMouseDown = e => {
    updateThumbCoords(e.clientX);

    document.addEventListener("mouseup", onMouseUp);
    document.addEventListener("mousemove", onMouseMove);
  };

  const onMouseUp = e => {
    document.removeEventListener("mouseup", onMouseUp);
    document.removeEventListener("mousemove", onMouseMove);
  };

  const onMouseMove = e => {
    updateThumbCoords(e.clientX);
  };

  const updateThumbCoords = (clientX) => {
    const sliderWidth = sliderRef.current.getBoundingClientRect().width;
    const thumbWidth = thumbRef.current.getBoundingClientRect().width;
    const rightEdge = sliderWidth - thumbWidth;

    const currentLeft = sliderRef.current.getBoundingClientRect().left + (thumbWidth / 2);
    const shiftX = clientX - currentLeft;

    const sizeOfStep = rightEdge / maxCountOfSteps; // in pixels

    if (clientX < currentLeft) {
      setCurrentStep(0);
      onUpdate(0);

      return;
    }
    else if (shiftX > rightEdge) {
      setCurrentStep(maxCountOfSteps);
      onUpdate(countOfSteps);

      return;
    }

    const newCurrentStepInPercentage = shiftX / sizeOfStep; // For Slider
    const newCurrentStepInSeconds = newCurrentStepInPercentage / countInStep; // For Player

    setCurrentStep(newCurrentStepInPercentage);
    onUpdate(newCurrentStepInSeconds);
  };

  const getLeftOffsetFor = (forWhat) => {
    const leftInPercentage = currentStep;

    const thumbWidth = thumbRef.current.offsetWidth;
    const substractWidth = forWhat === 'left-path' // left-path It's line that moves behind thumb
      ? 0
      : thumbWidth * (currentStep / 100);

    return `calc(${leftInPercentage}% - ${substractWidth}px)`;
  }

  return (
    <div
      className="player__slider"
      onMouseDown={onMouseDown}
      ref={sliderRef}
    >
      <div
        className="player__slider-left-path"
        style={{
          width: getLeftOffsetFor("left-path")
        }}
      />

      <div
        className="player__thumb"
        ref={thumbRef}
        style={{
          left: getLeftOffsetFor("thumb")
        }}
      />

      <div className="player__slider-right-path" />
    </div>
  );
};

export default Slider;