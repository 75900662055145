import API from 'api/api';

import normalizeContacts from 'utils/normalizeContacts';
import { onRemoveTabUpdateActiveContact } from './clientChats';
import { removeAllSessionNotifications } from "./notifications";
import { addToEntitiesIfMissing, LS } from 'utils';
import { ADDITIONAL_SESSION_FILTERS } from 'config/constants';


export const BOOKING_STATUSES = {
  0: 'cancelled',
  1: 'pending',
  2: 'in progress',
  3: 'finished',
  4: 'feedback received',
  5: 'pre-pending'
}


export const CHECK_EXISTING_OF_ACTIVE_SESSION = 'CHECK_EXISTING_OF_ACTIVE_SESSION';

export const GET_ALL_SESSIONS = 'GET_ALL_SESSIONS';
export const GET_SESSION_BY_ID = 'GET_SESSION_BY_ID';
export const ADD_SESSION = 'ADD_SESSION';
export const ADD_SESSION_PENDING = 'ADD_SESSION_PENDING';
export const CLEAR_SESSION_PENDING = 'CLEAR_SESSION_PENDING';
export const REMOVE_SESSION = 'REMOVE_SESSION';

export const ADD_PARTNER_SESSION = 'ADD_PARTNER_SESSION';
export const REMOVE_PARTNER_SESSION = 'REMOVE_PARTNER_SESSION';

export const UPDATE_COMPARED_ID = 'UPDATE_COMPARED_ID';
export const UPDATE_COMPARED_ID_FROM_RECENTLY_VIEWED = 'UPDATE_COMPARED_ID_FROM_RECENTLY_VIEWED';
export const REMOVE_COMPARED_ID = 'REMOVE_COMPARED_ID';

export const UPDATE_BUFFERED_ID = 'UPDATE_BUFFERED_ID';
export const REMOVE_BUFFERED_ID = 'REMOVE_BUFFERED_ID';
export const ADD_PROFILES_TO_SESSION_BUFFER = 'sessions/ADD_PROFILES_TO_SESSION_BUFFER';
export const ADD_PROFILES_TO_GALLERY = 'sessions/ADD_PROFILES_TO_GALLERY';

export const UPDATE_BOOKING = 'UPDATE_BOOKING';
export const REMOVE_BOOKING = 'REMOVE_BOOKING';
export const UPDATE_SESSION_BUFFER = 'UPDATE_SESSION_BUFFER';
export const UPDATE_BOOKING_SCHEDULED_MSGS = 'UPDATE_BOOKING_SCHEDULED_MSGS';
export const DELETE_BOOKING_SCHEDULED_MSGS = 'DELETE_BOOKING_SCHEDULED_MSGS';
export const UPDATE_BOOKING_REQUEST = 'UPDATE_BOOKING_REQUEST';
export const DELETE_BOOKING_REQUEST = 'DELETE_BOOKING_REQUEST';

export const UPDATE_PROPOSED = 'UPDATE_PROPOSED';

export const UPDATE_ACTIVE_FILTERS = 'UPDATE_ACTIVE_FILTERS';
export const UPDATE_ADDITIONAL_FILTERS = 'UPDATE_ADDITIONAL_FILTERS';

export const RESET_DEFAULT_SESSION_FILTERS = 'RESET_DEFAULT_SESSION_FILTERS';

export const UPDATE_ACTIVE_SESSION = 'UPDATE_ACTIVE_SESSION';

export const ADD_PREV_BOOKED_PROFILES_TO_SESSION = 'ADD_PREV_BOOKED_PROFILES_TO_SESSION';


export const GET_SESSIONS_HISTORY = 'GET_SESSIONS_HISTORY';
export const SHOW_SESSION_FROM_HISTORY = 'SHOW_SESSION_FROM_HISTORY';
export const UPDATE_SESSIONS_HISTORY = 'UPDATE_SESSIONS_HISTORY';
export const TOGGLE_HISTORY_SESSION_SUCCESS = 'TOGGLE_HISTORY_SESSION_SUCCESS';
export const PARTNER_TOGGLE_HISTORY_SESSION_SUCCESS = 'PARTNER_TOGGLE_HISTORY_SESSION_SUCCESS';
export const CLEAR_SESSIONS_HISTORY = 'CLEAR_SESSIONS_HISTORY';
export const REMOVE_VISITED_SESSION = 'REMOVE_VISITED_SESSION';

export const UPDATE_SESSION = 'UPDATE_SESSION';

export const UPDATE_ASSISTANCE_SESSION = 'UPDATE_ASSISTANCE_SESSION';

export const UPDATE_GALLERY_URL = 'UPDATE_GALLERY_URL';

export const UPDATE_SESSION_VIEWED = 'UPDATE_SESSION_VIEWED';

let defaultSessionGalleryId;

// A C T I O N S

export const checkExistingOfActiveSession = () => dispatch => {
  dispatch({
    type: CHECK_EXISTING_OF_ACTIVE_SESSION
  });
};

export const addSession = (data) => dispatch => {
  dispatch({
    type: ADD_SESSION,
    payload: data
  });
}

export const getAllSessions = (currentUserId, activeSessionId, defaultSession) => (dispatch, getState) => {
  return API.getAllSessions()
    .then(res => {
      const { entities, result } = normalizeSessions(res.data);
      const ids = [];
      const partnersIds = [];
      const assistanceIds = [];

      let normalizedContacts = {};

      result.forEach(sessionId => {
        const usersIds = entities[sessionId].usersIds;
        const contacts = entities[sessionId].callers;
        const reqForAssistanceIds = entities[sessionId].reqForAssistanceIds;

        // if (!contacts) return; //FIXME 

        if (contacts.length) {
          normalizedContacts = {
            ...normalizedContacts,
            ...normalizeContacts(contacts, false)
          };

          // first contact is client
          if (contacts.length > 1) {
            const girlIds = [];

            contacts.forEach(contact => {
              if (contact.type === 2 || contact.type === 3) {
                girlIds.push(contact.id)
              }
            });

            entities[sessionId].girlIds = girlIds;
          }
        }

        if (usersIds.length === 1 && usersIds[0] === currentUserId) {
          return ids.push(sessionId);
        }
        else if (~usersIds.indexOf(currentUserId) || reqForAssistanceIds.includes(currentUserId)) {
          return assistanceIds.push(sessionId);
        }
        else {
          return partnersIds.push(sessionId);
        }
      });

      // if (defaultSession) { //TODO \\\\\\\\\\\\\\ 
        // if (!defaultSession.additionalFilters) {
        //   entities[0] = { ...defaultSession, additionalFilters: {} };
        // }
        // else {
        //   entities[0] = defaultSession;
        // }

        // defaultSessionGalleryId = defaultSession.galleryId;
      // }
      // else {

      // Set default session
      const galleryId = `operator_${currentUserId}_${+new Date()}`;
      const defaultSession = {
        id: 0,
        active: 1,
        galleryId,
        comparedIds: [],
        bufferedIds: [],
        bookedIds: [],
        activeFilters: [],
        additionalFilters: {},
        recentlyViewedIds: [],
        usersIds: [],
        viewed: [],
        proposed: {
          operatorsIds: [],
          byOperatorId: {}
        }
      };
      defaultSessionGalleryId = galleryId;

      entities[0] = defaultSession;
        // LS.setItem('defaultSession', defaultSession, currentUserId);
      // } //TODO ///////////////

      // history session may not exist when page is updated
      let updatedActiveSessionId = activeSessionId;

      if (!entities[activeSessionId]) {
        updatedActiveSessionId = ids[ids.length - 1] || assistanceIds[assistanceIds.length - 1] || 0;
      }

      return dispatch({
        type: GET_ALL_SESSIONS,
        payload: {
          entities,
          ids,
          partnersIds,
          assistanceIds,
          contacts: normalizedContacts,
          activeSession: updatedActiveSessionId
        }
      });
    })
    .catch(console.log);
};


export const getSessionById = (sessionId, viewingOfSession = 0) => (dispatch, getState) => {
  return API.getSessionById(sessionId, viewingOfSession)
    .then(res => {
      const myId = getState().user.id;

      const contacts = res.data.callers;
      const normalizedContacts = normalizeContacts(contacts, false);

      const girlIds = [];

      contacts.forEach(contact => {
        if (contact.type === 2 || contact.type === 3) {
          girlIds.push(contact.id)
        }
      })

      return dispatch({
        type: GET_SESSION_BY_ID,
        payload: {
          session: { ...normalizeSession(res.data), girlIds },
          myId,
          contacts: normalizedContacts
        }
      });
    })
    .catch(err => {
      if (err && err.response && err.response.status === 400) {
        return {
          isFailed: true,
          message: err.response.data.message,
        };
      }
    });
};

export const clearSessionPending = () => dispatch => {
  dispatch({
    type: CLEAR_SESSION_PENDING
  });
};

export const getClientPrevBookedProfiles = (clientId, sessionId) => dispatch => {
  return API.getClientPrevBookedProfiles(clientId)
    .then(res => {
      console.log('@@', res)

      // if (!isEmptyObj(res.data)) {
      dispatch({
        type: ADD_PREV_BOOKED_PROFILES_TO_SESSION,
        payload: {
          sessionId,
          data: res.data
        }
      })
      // }
    })
    .catch(console.error);
}

export const createSession = (
  contactId,
  bufferedIds,
  comparedIds,
  activeFilters,
  additionalFilters,
  recentlyViewedIds,
) => dispatch => {
  dispatch({
    type: ADD_SESSION_PENDING
  });

  return API.createSession(
    contactId,
    bufferedIds,
    comparedIds,
    activeFilters,
    additionalFilters,
    recentlyViewedIds,
    )
    .then(res => {

      const sessionId = res.data.id;
      const contacts = res.data.callers;
      const girlIds = [];

      contacts.forEach(contact => {
        if (contact.type === 2 || contact.type === 3) {
          girlIds.push(contact.id)
        }
      })

      dispatch({
        type: ADD_SESSION,
        payload: { ...res.data, girlIds }
      });

      dispatch(getClientPrevBookedProfiles(contactId, sessionId));

      if (bufferedIds && bufferedIds.length) {
        API.addProfilesToGallery(sessionId, bufferedIds)
          .then(res => {
            const gallery = res.data.result;
            const url = gallery && gallery.link_abs;

            dispatch(updateGalleryUrl(url, sessionId));
          })
          .catch(console.log);
      }
    })
    .catch(err => {
      return {
        errorMsg: err.response.data.message
      };
    });
};

export const showSessionFromHistory = session => dispatch => {
  const contacts = session.callers;
  const normalizedContacts = normalizeContacts(contacts, false);

  const girlIds = [];

  contacts.forEach(contact => {
    if (contact.type === 2 || contact.type === 3) {
      girlIds.push(contact.id)
    }
  })

  dispatch({
    type: SHOW_SESSION_FROM_HISTORY,
    payload: {
      session: { ...session, girlIds },
      contacts: normalizedContacts
    }
  });
};

export const closeSession = (id) => dispatch => {
  return API.closeSession(id)
    .then(res => {
      dispatch(removeAllSessionNotifications(id));
      dispatch({
        type: REMOVE_SESSION,
        payload: res.data.id
      });
    })
    .catch(console.log);
};

export const deleteSession = (id) => dispatch => {
  return API.deleteSession(id)
    .then(res => {
      dispatch(removeAllSessionNotifications(id));
      dispatch({
        type: REMOVE_SESSION,
        payload: id,
      });
    })
    .catch(console.log);
};

export const addPartnerSession = (session) => dispatch => {
  dispatch({
    type: ADD_PARTNER_SESSION,
    payload: session
  });
};

export const removePartnerSession = (id) => dispatch => {
  dispatch(removeAllSessionNotifications(id));
  dispatch({
    type: REMOVE_PARTNER_SESSION,
    payload: id
  });
};

export const updateActiveSession = (id) => dispatch => {
  dispatch({
    type: UPDATE_ACTIVE_SESSION,
    payload: id
  });
};

export const updateComparedId = (newId, sessionId, canEditSession, isHostProfileUpdate) => (dispatch, getState) => {
  const comparedIds = getState().sessions.entities[sessionId].comparedIds;

  if (comparedIds.includes(newId)) { return; }

  let newComparedIds = [...comparedIds];

  if (isHostProfileUpdate) {
    newComparedIds[0] = newId;
  }
  else {
    newComparedIds = comparedIds.length
      ? [comparedIds[0], newId]
      : [newId];
  }

  if (sessionId === 0 || !canEditSession) { // If default session or session from history / partner session
    return dispatch({
      type: UPDATE_COMPARED_ID,
      payload: {
        comparedIds: newComparedIds,
        profileId: newId
      }
    });
  }

  return API.updateComparedIds(sessionId, newComparedIds)
    .catch(console.log);
};

export const removeComparedId = (removeId, comparedIds, sessionId, canEditSession, activeFilters = []) => dispatch => {
  const newComparedIds = filterArr(comparedIds, removeId);

  const isCombinedFilterActive =
    activeFilters.includes("nearby") &&
    activeFilters.includes("bestmatch") &&
    activeFilters.includes("availableNow");

  if (!newComparedIds.length) {
    if (isCombinedFilterActive) {
      dispatch(updateActiveFilters(['nearby', 'availableNow', 'bestmatch'], sessionId, activeFilters, canEditSession));
    }
    else if (activeFilters.includes('bestmatch')) {
      dispatch(updateActiveFilters('bestmatch', sessionId, activeFilters, canEditSession));
    }
  }

  if (sessionId === 0 || !canEditSession) { // If default session or session from history / partner session
    return dispatch({
      type: REMOVE_COMPARED_ID,
      payload: newComparedIds
    });
  }

  return API.removeComparedId(sessionId, newComparedIds)
    .catch(console.log);
};

export const updateComparedIdFromRecentlyViewed = (newId) => dispatch => {
  dispatch({
    type: UPDATE_COMPARED_ID_FROM_RECENTLY_VIEWED,
    payload: newId
  });
};

export const updateBufferedId = (profileId, sessionId, canEditSession, profileIdNew, contact_uid) => dispatch => {
  if (!canEditSession) { // If session from history / partner session
    return dispatch({
      type: UPDATE_BUFFERED_ID,
      payload: profileId
    });
  }
  else if (sessionId === 0) {
    return API.addProfilesToGallery(defaultSessionGalleryId, profileId)
      .then(res => {
        const gallery = res.data.result;
        const url = gallery && gallery.link_abs;

        dispatch(updateGalleryUrl(url, sessionId));
        dispatch({
          type: UPDATE_BUFFERED_ID,
          payload: profileId
        });
      })
      .catch(console.log);
  }

  return API.addBufferId(profileId, sessionId, profileIdNew, contact_uid)
    .then((res) => {
      const sessionGalleryUrl = res.data.galleryUrl;

      API.addProfilesToGallery(sessionId, profileId)
        .then(res => {
          const gallery = res.data.result;
          const url = gallery && gallery.link_abs;

          !sessionGalleryUrl && url && dispatch(updateGalleryUrl(url, sessionId));
        })
        .catch(console.log);
    })
    .catch(console.log);
};

export const removeBufferedId = (profileId, sessionId, canEditSession, isActiveSession = true) => dispatch => {
  if (!canEditSession) { // If session from history / partner session
    return dispatch({
      type: REMOVE_BUFFERED_ID,
      payload: { profileId, sessionId, isActiveSession },
    });
  }
  else if (sessionId === 0) {
    return API.removeProfileFromGallery(defaultSessionGalleryId, profileId)
      .then(() => {
        dispatch({
          type: REMOVE_BUFFERED_ID,
          payload: { profileId, sessionId, isActiveSession },
        });
      })
      .catch(console.log);
  }

  return API.removeBufferedId(profileId, sessionId)
    .then(() => {
      API.removeProfileFromGallery(sessionId, profileId)
        .catch(console.log);
    })
    .catch(console.log);
};

// If sessionId not passed - add profiles to default session gallery
// profileIds may be a one (Number) or several ids (Array)
export const addProfilesToGallery = ({ sessionId, profileIds }) => (dispatch, getState) => {
  const defaultSession = selectDefaultSession(getState());

  return API.addProfilesToGallery(
    sessionId || defaultSession.galleryId,
    profileIds
  )
    .then(({ data }) => {
      dispatch({
        type: ADD_PROFILES_TO_GALLERY,
        payload: {
          sessionId: sessionId || defaultSession.id,
          galleryUrl: data.result.link_abs,
        },
      });
    });
};

// If sessionId not passed - add profiles to default session
// profileIds may be a one (Number) or several ids (Array)
export const addProfilesToBufferAndGallery = ({ sessionId, profileIds }) => (dispatch, getState) => {
  const defaultSession = selectDefaultSession(getState());
  
  if (!sessionId) {
    dispatch({
      type: ADD_PROFILES_TO_SESSION_BUFFER,
      payload: { sessionId: defaultSession.id, profileIds },
    });

    return dispatch(addProfilesToGallery({ profileIds }));
  }

  // BufferedIds replaced in session (in global state) on socket receive
  return API.addProfilesToSessionBuffer({ sessionId, profileIds })
    .then(() => dispatch(addProfilesToGallery({ sessionId, profileIds })));
}


export const updateActiveFiltersFromSocket = (sessionId, newActiveFilters) => dispatch => {
  return dispatch({
    type: UPDATE_ACTIVE_FILTERS,
    payload: {
      sessionId,
      newActiveFilters
    }
  });
};

export const resetDefaultSessionFilters = () => dispatch => {
  dispatch({
    type: RESET_DEFAULT_SESSION_FILTERS,
  })
}

export const updateActiveFilters = (newFilter, sessionId, activeFilters, canEditSession) => dispatch => {
  const newActiveFilters = getNewActiveFilters(newFilter, activeFilters);

  if (newActiveFilters === null) { return; }

  if (sessionId === 0 || !canEditSession) { // If default session or session from history / partner session
    return dispatch({
      type: UPDATE_ACTIVE_FILTERS,
      payload: {
        sessionId,
        newActiveFilters
      }
    });
  }

  return API.updateActiveFilters(sessionId, newActiveFilters);
};

export const updateAdditionalTitleFilters = (key, value, sessionId, additionalFilters, canEditSession, isRemove = false) => dispatch => {
  const newAdditionalFilters = { ...additionalFilters };

  if (isRemove) {
    delete newAdditionalFilters[key];
  }
  else {
    newAdditionalFilters[key] = value;
  }

  if (sessionId === 0 || !canEditSession) { // If default session or session from history / partner session
    return dispatch({
      type: UPDATE_ADDITIONAL_FILTERS,
      payload: {
        sessionId,
        newAdditionalFilters
      }
    });
  }

  return API.updateAdditionalFilters(sessionId, newAdditionalFilters);
};

export const updateProfileFilters = (profileFilters, sessionId, additionalFilters, canEditSession) => dispatch => {
  const newAdditionalFilters = { ...additionalFilters };

  Object.keys(profileFilters).forEach(key => {
    if (!profileFilters[key]) {
      delete newAdditionalFilters[ADDITIONAL_SESSION_FILTERS[key]]
    }
    else {
      newAdditionalFilters[ADDITIONAL_SESSION_FILTERS[key]] = profileFilters[key];
    }
  });

  if (sessionId === 0 || !canEditSession) { // If default session or session from history / partner session
    return dispatch({
      type: UPDATE_ADDITIONAL_FILTERS,
      payload: {
        sessionId,
        newAdditionalFilters
      }
    });
  }

  return API.updateAdditionalFilters(sessionId, newAdditionalFilters);
};

export const clearAdditionalFilters = (sessionId, canEditSession) => dispatch => {
  if (sessionId === 0 || !canEditSession) { // If default session or session from history / partner session
    return dispatch({
      type: UPDATE_ADDITIONAL_FILTERS,
      payload: {
        sessionId,
        newAdditionalFilters: {}
      }
    });
  }

  return API.updateAdditionalFilters(sessionId, {});
} 

export const updateAdditionalFilters = (sessionId, newAdditionalFilters) => dispatch => {
  return dispatch({
    type: UPDATE_ADDITIONAL_FILTERS,
    payload: {
      sessionId,
      newAdditionalFilters
    }
  });
};

export const updateBooking = (sessionId, booking) => dispatch => {
  dispatch({
    type: UPDATE_BOOKING,
    payload: {
      sessionId,
      booking,
    }
  })
}

export const updateBookingScheduledMessages = (sessionId, bookingId, scheduleMessage) => dispatch => {
  dispatch({
    type: UPDATE_BOOKING_SCHEDULED_MSGS,
    payload: {
      sessionId,
      bookingId,
      scheduleMessage,
    }
  })
}

export const deleteBookingScheduledMessages = (sessionId, bookingId, scheduleMessageId) => dispatch => {
  dispatch({
    type: DELETE_BOOKING_SCHEDULED_MSGS,
    payload: {
      sessionId,
      bookingId,
      scheduleMessageId,
    }
  })
}

export const updateBookingRequest = (sessionId, request) => ({
  type: UPDATE_BOOKING_REQUEST,
  payload: {
    sessionId,
    request
  }
})

export const deleteBookingRequest = (sessionId, request) => ({
  type: DELETE_BOOKING_REQUEST,
  payload: {
    sessionId,
    request
  }
})

export const removeBooking = (sessionId, booking) => dispatch => {
  dispatch({
    type: REMOVE_BOOKING,
    payload: {
      sessionId,
      booking,
    }
  });
}

export const addProposedId = (profileId, sessionId, operatorId) => dispatch => {
  return API.addProposedId(operatorId, sessionId, profileId)
    .then(res => {
      dispatch({
        type: UPDATE_PROPOSED,
        payload: {
          id: sessionId,
          proposed: res.data.proposed
        }
      });
    })
    .catch(console.log);
};

export const removeProposedId = (profileId, sessionId, operatorId) => dispatch => {
  return API.removeProposedId(operatorId, sessionId, profileId)
    .then(res => {
      dispatch({
        type: UPDATE_PROPOSED,
        payload: {
          id: sessionId,
          proposed: res.data.proposed
        }
      });
    })
    .catch(console.log);
};

export const getSessionsHistory = (type, id = '') => dispatch => {
  return API.getSessionsHistory(type, id)
    .then(res => {
      dispatch({
        type: GET_SESSIONS_HISTORY,
        payload: normalizeSessions(res.data)
      });
    })
    .catch(console.log);
};

export const updateSessionsHistoryAPI = (type, id = '', offset = 0) => dispatch => {
  const config = {
    params: {
      // connectToken: localStorage.connectToken,
      limit: 20,
      offset
    }
  };

  return API.getSessionsHistory(type, id, config)
    .then(res => {
      dispatch({
        type: UPDATE_SESSIONS_HISTORY,
        payload: normalizeSessions(res.data)
      });
    })
    .catch(console.log);
};

export const getSuccessfulSessions = (offset) => dispatch => {
  return API.getSuccessfulSessions()
    .then(res => {
      if (offset === undefined) {
        dispatch({
          type: GET_SESSIONS_HISTORY,
          payload: normalizeSessions(res.data)
        });
      }
      else {
        dispatch({
          type: UPDATE_SESSIONS_HISTORY,
          payload: normalizeSessions(res.data)
        });
      }
    })
    .catch(console.log);
};

export const toggleHistorySessionSuccess = (id) => dispatch => {
  return API.toggleHistorySessionSuccess(id)
    .then(res => {
      dispatch({
        type: TOGGLE_HISTORY_SESSION_SUCCESS,
        payload: res.data
      });
    })
    .catch(err => {
      return {
        errorMsg: err.response.data.message
      };
    });
};

export const partnerToggleHistorySessionSuccess = (newSession) => dispatch => {
  dispatch({
    type: PARTNER_TOGGLE_HISTORY_SESSION_SUCCESS,
    payload: newSession
  });
};

export const clearSessionsHistory = () => dispatch => {
  dispatch({
    type: CLEAR_SESSIONS_HISTORY
  });
};

export const removeVisitedSession = (id) => dispatch => {
  dispatch({
    type: REMOVE_VISITED_SESSION,
    payload: id
  });
};

export const continueClosedSession = (id) => dispatch => {
  dispatch({
    type: ADD_SESSION_PENDING
  });

  return API.continueClosedSession(id)
    .then(res => {
      return dispatch({
        type: ADD_SESSION,
        payload: res.data
      });
    })
    .catch(err => {
      return {
        errorMsg: err.response.data.message
      };
    });
};

export const duplicateClosedSession = (id, clientId) => dispatch => {
  dispatch({
    type: ADD_SESSION_PENDING
  });

  return API.duplicateClosedSession(id, clientId)
    .then(res => {

      return dispatch({
        type: ADD_SESSION,
        payload: res.data
      });
    })
    .catch(err => {
      return {
        errorMsg: err.response.data.message
      };
    });
};

export const updateSession = (session, changeActiveSession = false) => (dispatch, getState) => {
  const myId = getState().user.id;

  dispatch({
    type: UPDATE_SESSION,
    payload: {
      session,
      myId,
      changeActiveSession
    }
  });
};

export const transferTheSession = (sessionId, operatorId) => dispatch => {
  return API.transferTheSession(sessionId, operatorId)
    .catch(console.log);
};

export const addAssistantToSession = (sessionId, operatorId) => dispatch => {
  return API.addAssistantToSession(sessionId, operatorId)
    .then(res => {
      dispatch(updateSession(res.data));
    })
    .catch(console.log);
};

export const removeAssistantFromSession = (sessionId, operatorId) => dispatch => {
  return API.removeAssistantFromSession(sessionId, operatorId)
    .then(res => {
      // dispatch(updateSession(res.data));
    })
    .catch(console.log);
};

export const updateAssistanceSession = (updatedSession) => dispatch => {
  return dispatch({
    type: UPDATE_ASSISTANCE_SESSION,
    payload: updatedSession
  });
};

export const updateSessionBuffer = (updatedSession) => dispatch => {
  const contacts = updatedSession.callers;
  const normalizedContacts = normalizeContacts(contacts, false);

  const girlIds = [];

  contacts.forEach(contact => {
    if (contact.type === 2 || contact.type === 3) {
      girlIds.push(contact.id)
    }
  })

  return dispatch({
    type: UPDATE_SESSION_BUFFER,
    payload: {
      session: { ...updatedSession, girlIds },
      contacts: normalizedContacts
    }
  });
}

export const updateGalleryUrl = (newUrl, sessionId) => dispatch => {
  if (sessionId === 0) {
    return dispatch({
      type: UPDATE_GALLERY_URL,
      payload: {
        url: newUrl,
        id: sessionId,
      }
    });
  }

  return API.updateGalleryUrlInSession(newUrl, sessionId)
    .then(res => {
      dispatch({
        type: UPDATE_GALLERY_URL,
        payload: {
          url: newUrl,
          id: sessionId
        }
      });
    })
    .catch(console.log);
};

export const updateSessionViewed = (sessionId, newViewed) => dispatch => {
  return dispatch({
    type: UPDATE_SESSION_VIEWED,
    payload: {
      sessionId,
      newViewed,
    },
  });
};

export const addGirlsIdsThatLinksIsSendInChat = (sessionId, girlId) => dispatch => {
  API.addGirlsIdsThatLinksIsSendInChat(sessionId, girlId)
    .then((res) => { 
      dispatch({
        type: ADD_GIRL_ID_THAT_LINK_IS_SEND_TO_THE_ARRAY,
        payload: { sessionId, girlId}
      });
    })
    .catch(console.log);
};

// I N I T I A L    S T A T E

const initialState = {
  activeSession: null,
  ids: [],
  partnersIds: [],
  assistanceIds: [],
  entities: {},
  addSessionPending: true,
  historyIds: [],
  visitedHistoryIds: []
};

// R E D U C E R

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CHECK_EXISTING_OF_ACTIVE_SESSION: {
      const aSessId = state.activeSession;
      const isActiveSessExist = !!state.entities[aSessId];

      if (isActiveSessExist) { return state; }

      const nextActiveSession = state.ids[state.ids.length - 1] || state.assistanceIds[state.assistanceIds.length - 1];

      return {
        ...state,
        activeSession: nextActiveSession || 0
      };
    }

    case GET_ALL_SESSIONS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...payload.entities
        },
        ids: payload.ids,
        partnersIds: payload.partnersIds,
        assistanceIds: payload.assistanceIds,
        addSessionPending: false,
        activeSession: (payload.activeSession || payload.activeSession === 0)
          ? payload.activeSession
          : 0
      };
    }

    case GET_SESSION_BY_ID: {
      const usersIds = payload.session.usersIds;
      const sessionId = payload.session.id;

      let updatedIds = state.ids;
      let updatedAssistanceIds = state.assistanceIds;
      let updatedPartnersIds = state.partnersIds;

      const addIdIfItNotExist = (ids, id = sessionId) => ~ids.indexOf(id)
        ? ids
        : [...ids, id];

      if (!payload.session.dateClosed) { // if session is not closed
        if (usersIds.length === 1 && usersIds[0] === payload.myId) { // if it's only my session
          updatedIds = addIdIfItNotExist(updatedIds);
        }
        else if (~usersIds.indexOf(payload.myId)) { // if i take part in this session
          updatedAssistanceIds = addIdIfItNotExist(updatedAssistanceIds);
        }
        else { // i have nothing to do with this session
          updatedPartnersIds = addIdIfItNotExist(updatedPartnersIds);
        }
      }

      const updatedSession = state.entities[sessionId]
        ? {
          ...state.entities[sessionId],
          ...payload.session
        }
        : payload.session

      return {
        ...state,
        entities: {
          ...state.entities,
          [sessionId]: updatedSession
        },
        ids: updatedIds,
        partnersIds: updatedPartnersIds,
        assistanceIds: updatedAssistanceIds
      };
    }

    case ADD_SESSION_PENDING: {
      return {
        ...state,
        addSessionPending: true
      };
    }

    case ADD_SESSION: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.id]: payload
        },
        ids: [...state.ids, payload.id],
        activeSession: payload.id,
        addSessionPending: false,
        visitedHistoryIds: ~state.visitedHistoryIds.indexOf(payload.id)
          ? filterArr(state.visitedHistoryIds, payload.id)
          : state.visitedHistoryIds
      };
    }

    case CLEAR_SESSION_PENDING: {
      return {
        ...state,
        addSessionPending: false
      };
    }

    case SHOW_SESSION_FROM_HISTORY: {
      const activeSessions = [...state.ids, ...state.assistanceIds, ...state.visitedHistoryIds];

      const updatedVisitedHistoryIds = ~activeSessions.indexOf(payload.session.id)
        ? state.visitedHistoryIds
        : [...state.visitedHistoryIds, payload.session.id];

      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.session.id]: payload.session,
        },
        activeSession: payload.session.id,
        visitedHistoryIds: updatedVisitedHistoryIds
      };
    }

    case REMOVE_SESSION: {
      const updatedEntities = { ...state.entities };

      delete updatedEntities[payload];

      const newActiveSession = onRemoveTabUpdateActiveContact([...state.ids, ...state.assistanceIds], state.activeSession, payload) || 0;

      const updatedIds = ~state.ids.indexOf(payload)
        ? filterArr(state.ids, payload)
        : state.ids;

      const updatedAssistanceIds = ~state.assistanceIds.indexOf(payload)
        ? filterArr(state.assistanceIds, payload)
        : state.assistanceIds;

      return {
        ...state,
        ids: updatedIds,
        assistanceIds: updatedAssistanceIds,
        entities: updatedEntities,
        activeSession: newActiveSession
      };
    }

    case UPDATE_ACTIVE_SESSION: {
      if (!state.entities[payload]) {
        return {
          ...state,
          activeSession: 0,
        }
      }

      if (payload === state.activeSession) { return state; }

      return {
        ...state,
        activeSession: payload
      };
    }

    case UPDATE_COMPARED_ID: {
      const activeSessionId = state.activeSession;
      const activeSession = { ...state.entities[activeSessionId] };

      const pId = payload.profileId;

      let newRecentlyViewedIds = [];

      if (newRecentlyViewedIds[0] === pId) {
        newRecentlyViewedIds = activeSession.recentlyViewedIds;
      }
      else if (activeSession.recentlyViewedIds.includes(pId)) {
        newRecentlyViewedIds = [pId, ...filterArr(activeSession.recentlyViewedIds, pId)];
      }
      else {
        newRecentlyViewedIds = [pId, ...activeSession.recentlyViewedIds];

        newRecentlyViewedIds.length > 20 && newRecentlyViewedIds.splice(-1, 1);
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [activeSessionId]: {
            ...activeSession,
            comparedIds: payload.comparedIds,
            recentlyViewedIds: newRecentlyViewedIds
          }
        }
      };
    }

    case REMOVE_COMPARED_ID: {
      const activeSession = { ...state.entities[state.activeSession] };

      return {
        ...state,
        entities: {
          ...state.entities,
          [state.activeSession]: {
            ...activeSession,
            comparedIds: payload
          }
        }
      };
    }

    case UPDATE_COMPARED_ID_FROM_RECENTLY_VIEWED: {
      const activeSessionId = state.activeSession;
      const activeSession = { ...state.entities[activeSessionId] };
      const recentlyIds = activeSession.recentlyViewedIds;

      const getIndex = index => recentlyIds.indexOf(index);

      const checkOnHostProfile = action => {
        if (nextIndex === hostProfileIndex) { return action === 'next' ? nextIndex++ : nextIndex--; }
      };

      const currentIndex = getIndex(payload.id);
      const hostProfileIndex = getIndex(activeSession.comparedIds[0]);

      let nextIndex = currentIndex;

      if (payload.action === 'next') {
        nextIndex++;
        checkOnHostProfile('next');
        nextIndex = nextIndex > recentlyIds.length - 1 ? 0 : nextIndex;
        checkOnHostProfile('next');
      }
      else {
        nextIndex--;
        checkOnHostProfile('prev');
        nextIndex = nextIndex < 0 ? recentlyIds.length - 1 : nextIndex;
        checkOnHostProfile('prev');
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [activeSessionId]: {
            ...activeSession,
            comparedIds: [recentlyIds[hostProfileIndex], recentlyIds[nextIndex]]
          }
        }
      };
    }

    case DELETE_BOOKING_SCHEDULED_MSGS: {
      const session = state.entities[payload.sessionId];
      const updatedBooking = Object.values(session.bookings).find(booking => booking.id === payload.bookingId);

      if (!updatedBooking) return state;

      const { profile_id: profileId } = updatedBooking;

      const updatedScheduleMessages = updatedBooking.scheduleMessages
        .filter(scheduleMessage => scheduleMessage.id !== payload.scheduleMessageId);

      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.sessionId]: {
            ...session,
            bookings: {
              ...session.bookings,
              [profileId]: {
                ...updatedBooking,
                scheduleMessages: updatedScheduleMessages,
              },
            },
          },
        },
      };
    }

    case UPDATE_BOOKING_SCHEDULED_MSGS: {
      const session = state.entities[payload.sessionId];
      const updatedBooking = Object.values(session.bookings).find(booking => booking.id === payload.bookingId);
      const { profile_id: profileId } = updatedBooking;

      const existingScheduleMessageIndex = updatedBooking.scheduleMessages
        .findIndex(scheduleMessage => scheduleMessage.id === payload.scheduleMessage.id);

      let updatedScheduleMessages = [...updatedBooking.scheduleMessages];

      if (~existingScheduleMessageIndex) {
        updatedScheduleMessages[existingScheduleMessageIndex] = payload.scheduleMessage;
      }
      else {
        updatedScheduleMessages = [payload.scheduleMessage, ...updatedScheduleMessages,];
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.sessionId]: {
            ...session,
            bookings: {
              ...session.bookings,
              [profileId]: {
                ...updatedBooking,
                scheduleMessages: updatedScheduleMessages,
              },
            },
          },
        },
      };
    }

    case UPDATE_BOOKING_REQUEST: {
      const session = state.entities[payload.sessionId];
      if (!session) return state;

      const updatedBooking = Object.values(session.bookings).find(booking => booking.id === payload.request.booking_id);
      const { profile_id: profileId } = updatedBooking;

      const existingScheduleMessageIndex = updatedBooking.scheduleMessages
        .findIndex(scheduleMessage => scheduleMessage.id === payload.scheduleMessage.id);

      let updatedScheduleMessages = [...updatedBooking.scheduleMessages];

      if (~existingScheduleMessageIndex) {
        updatedScheduleMessages[existingScheduleMessageIndex] = payload.scheduleMessage;
      }
      else {
        updatedScheduleMessages = [payload.scheduleMessage, ...updatedScheduleMessages,];
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.sessionId]: {
            ...session,
            bookings: {
              ...session.bookings,
              [profileId]: {
                ...updatedBooking,
                requests: [payload.request],
              },
            },
          },
        },
      };
    }

    case DELETE_BOOKING_REQUEST: {
      const session = state.entities[payload.sessionId];
      if (!session) return state;

      const updatedBooking = Object.values(session.bookings).find(booking => booking.id === payload.request.booking_id);

      if (!updatedBooking) return state;

      const { profile_id: profileId } = updatedBooking;

      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.sessionId]: {
            ...session,
            bookings: {
              ...session.bookings,
              [profileId]: {
                ...updatedBooking,
                requests: [],
              },
            },
          },
        },
      };
    }


    case UPDATE_BOOKING: {
      const session = state.entities[payload.sessionId];
      const updatedBooking = payload.booking;
      const { profile_id: profileId } = updatedBooking;

      if (!session) return state;

      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.sessionId]: {
            ...session,
            bookings: {
              ...session.bookings,
              [profileId]: updatedBooking,
            },
            bookedIds: session.bookedIds.includes(profileId)
              ? session.bookedIds
              : [...session.bookedIds, profileId],
          },
        },
      }
    }

    case REMOVE_BOOKING: {
      const profileId = payload.booking.profile_id;
      const session = state.entities[payload.sessionId];
      const updatedBookings = { ...session.bookings };

      delete updatedBookings[profileId];

      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.sessionId]: {
            ...session,
            bookings: updatedBookings,
            bookedIds: session.bookedIds.filter(id => id !== profileId),
          },
        },
      }
    }

    case UPDATE_PROPOSED: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.id]: {
            ...state.entities[payload.id],
            proposed: payload.proposed
          }
        }
      };
    }

    case UPDATE_BUFFERED_ID: {
      const activeSessionId = state.activeSession;
      const entities = { ...state.entities };
      const activeSession = entities[activeSessionId];

      const isNewId = activeSession.bufferedIds.indexOf(payload) === -1;

      if (!isNewId) { return state; }

      return {
        ...state,
        entities: {
          ...entities,
          [activeSessionId]: {
            ...activeSession,
            bufferedIds: [payload, ...activeSession.bufferedIds]
          }
        }
      };
    }

    case REMOVE_BUFFERED_ID: {
      const { profileId, isActiveSession } = payload;
      const sessionId = isActiveSession ? state.activeSession : payload.sessionId;
      const session = state.entities[sessionId];

      return {
        ...state,
        entities: {
          ...state.entities,
          [sessionId]: {
            ...session,
            bufferedIds: Array.isArray(profileId)
              ? session.bufferedIds.filter(id => !profileId.includes(id))
              : filterArr(session.bufferedIds, profileId),
          }
        }
      };
    }

    case ADD_PROFILES_TO_SESSION_BUFFER: {
      const { sessionId, profileIds } = payload;
      const { entities } = state;

      const profileIdsArr = Array.isArray(profileIds)
        ? profileIds
        : [profileIds];

      const newProfileIds = profileIdsArr.filter(id => {
        return !entities[sessionId].bufferedIds.includes(id);
      });

      return {
        ...state,
        entities: {
          ...entities,
          [sessionId]: {
            ...entities[sessionId],
            bufferedIds: [
              ...entities[sessionId].bufferedIds,
              ...newProfileIds,
            ],
          }
        }
      }
    }

    case ADD_PROFILES_TO_GALLERY: {
      const { sessionId, galleryUrl } = payload;
      const { entities } = state;

      return {
        ...state,
        entities: {
          ...entities,
          [sessionId]: {
            ...entities[sessionId],
            galleryUrl,
          }
        }
      };
    }

    case RESET_DEFAULT_SESSION_FILTERS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          '0': {
            ...state.entities[0],
            activeFilters: [],
            additionalFilters: [],
          }
        }
      };
    }

    case UPDATE_ACTIVE_FILTERS: {
      const { sessionId, newActiveFilters } = payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          [sessionId]: {
            ...state.entities[sessionId],
            activeFilters: newActiveFilters
          }
        }
      };
    }

    case UPDATE_ADDITIONAL_FILTERS: {
      const { sessionId, newAdditionalFilters } = payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          [sessionId]: {
            ...state.entities[sessionId],
            additionalFilters: newAdditionalFilters
          }
        }
      };
    }

    case ADD_PARTNER_SESSION: {
      const updatedEntities = { ...state.entities };

      updatedEntities[payload.id] = payload;

      return {
        ...state,
        entities: updatedEntities,
        partnersIds: [...state.partnersIds, payload.id]
      };
    }

    case REMOVE_PARTNER_SESSION: {
      const updatedEntities = { ...state.entities };

      delete updatedEntities[payload];

      const newActiveSession = onRemoveTabUpdateActiveContact([...state.ids, ...state.assistanceIds], state.activeSession, payload) || 0;

      const updatedPartnersIds = ~state.partnersIds.indexOf(payload)
        ? filterArr(state.partnersIds, payload)
        : state.partnersIds;

      const updatedAssistanceIds = ~state.assistanceIds.indexOf(payload)
        ? filterArr(state.assistanceIds, payload)
        : state.assistanceIds;

      return {
        ...state,
        entities: updatedEntities,
        partnersIds: updatedPartnersIds,
        assistanceIds: updatedAssistanceIds,
        activeSession: newActiveSession
      };
    }

    case GET_SESSIONS_HISTORY: {
      return {
        ...state,
        entities: addToEntitiesIfMissing(state.entities, payload.entities),
        historyIds: payload.result,
      };
    }

    case UPDATE_SESSIONS_HISTORY: {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...payload.entities
        },
        historyIds: [...state.historyIds, ...payload.result]
      };
    }

    case TOGGLE_HISTORY_SESSION_SUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.id]: payload
        }
      };
    }

    case PARTNER_TOGGLE_HISTORY_SESSION_SUCCESS: {
      if (state.historyIds.indexOf(payload.id) === -1) { return state; }

      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.id]: payload
        }
      };
    }

    case CLEAR_SESSIONS_HISTORY: {
      return {
        ...state,
        historyIds: []
      };
    }

    case REMOVE_VISITED_SESSION: {
      const updatedEntities = { ...state.entities };

      delete updatedEntities[payload];

      return {
        ...state,
        entities: updatedEntities,
        visitedHistoryIds: filterArr(state.visitedHistoryIds, payload),
        activeSession: payload === state.activeSession
          ? 0
          : state.activeSession
      };
    }

    case UPDATE_SESSION: {
      const myId = payload.myId;
      const id = payload.session.id;
      const usersIds = payload.session.usersIds;
      const nextActiveSession = state.ids[state.ids.length - 1] || state.assistanceIds[state.assistanceIds.length - 1];
      const session = { ...payload.session };
      const contacts = session.callers;
      const updatedIds = {};

      const handleIds = (name) => {
        updatedIds[name] = state[name].includes(id)
          ? state[name]
          : [id, ...state[name]];

        ['ids', 'partnersIds', 'assistanceIds']
          .filter(n => n !== name)
          .forEach(name => {
            updatedIds[name] = state[name].includes(id)
              ? state[name].filter(sessId => sessId !== id)
              : state[name];
          });
      }

      if (contacts && contacts.length > 1) {
        const girlIds = [];

        contacts.forEach(contact => {
          if (contact.type === 2 || contact.type === 3) {
            girlIds.push(contact.id)
          }
        });

        session.girlIds = girlIds;
      }

      if (usersIds.includes(myId)) {
        handleIds(usersIds.length === 1
          ? 'ids'
          : 'assistanceIds'
        );
      }
      else if (session.reqForAssistanceIds.includes(myId)) {
        handleIds('assistanceIds');
      }
      else {
        handleIds('partnersIds');
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [id]: session,
        },
        ids: updatedIds.ids,
        assistanceIds: updatedIds.assistanceIds,
        partnersIds: updatedIds.partnersIds,
        activeSession: payload.changeActiveSession
          ? nextActiveSession || 0
          : state.activeSession
      };
    }

    case UPDATE_ASSISTANCE_SESSION: {
      const { id } = payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          [id]: {
            ...state.entities[id],
            ...payload
          }
        }
      };
    }

    case UPDATE_SESSION_BUFFER: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.session.id]: {
            ...state.entities[payload.session.id],
            ...payload.session
          }
        }
      };
    }

    case UPDATE_GALLERY_URL: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.id]: {
            ...state.entities[payload.id],
            galleryUrl: payload.url,
          }
        }
      };
    }

    case UPDATE_SESSION_VIEWED: {
      const session = state.entities[payload.sessionId];

      if (!session) return state;

      const inputViewedHasNewValue = JSON.stringify(session.viewed) !== JSON.stringify(payload.newViewed);

      if (!inputViewedHasNewValue) { return state; }

      return {
        ...state,
        entities: {
          ...state.entities,
          [session.id]: {
            ...session,
            viewed: payload.newViewed,
          }
        }
      };
    }

    case ADD_PREV_BOOKED_PROFILES_TO_SESSION: {
      const session = state.entities[payload.sessionId];

      return {
        ...state,
        entities: {
          ...state.entities,
          [session.id]: {
            ...session,
            prevBookedProfiles: payload.data
          }
        }
      }
    }

    default: {
      return state;
    }
  }
};

// R E D U X    H E L P E R S

export const filterArr = (arr, payload) => arr.filter(key => key !== payload);

export const getNewActiveFilters = (newFilter, activeFilters) => {
  const isPropose = newFilter.includes('Proposed');
  const firstFilter = activeFilters[0];
  const isFirstFilterIsAnProposed = firstFilter && firstFilter.includes('Proposed');
  const combinedFilter = ['nearby', 'bestmatch', 'availableNow'];
  const isCombinedFilterActive =
    activeFilters.includes("nearby") &&
    activeFilters.includes("bestmatch") &&
    activeFilters.includes("availableNow");

  let newActiveFilters;

  if (newFilter === 'All Profiles') {
    const updatedActiveFilters = activeFilters.filter(key => key === 'availableNow' || key === 'bestmatch');

    if (updatedActiveFilters.length === 0) {
      newActiveFilters = [];
    }
    else {
      newActiveFilters = isCombinedFilterActive
        ? []
        : updatedActiveFilters
    }
  }
  else if (isPropose) {
    newActiveFilters = firstFilter === newFilter
      ? []
      : [newFilter];
  }
  else if (isFirstFilterIsAnProposed) {
    newActiveFilters = [newFilter];
  }
  else if (~activeFilters.indexOf(newFilter) && (!isCombinedFilterActive || !combinedFilter.includes(newFilter))) {
    newActiveFilters = filterArr(activeFilters, newFilter);
  }
  else if (Array.isArray(newFilter)) { // For combine filter, [nearby,availableNow,bestmatch]
    if (isCombinedFilterActive) {
      newActiveFilters = activeFilters.filter(key => key !== 'nearby' && key !== 'availableNow' && key !== 'bestmatch');
    }
    else {
      newActiveFilters = Array.from(new Set([...activeFilters, ...newFilter]));
    }
  }
  else {
    if (isCombinedFilterActive) {
      if (newFilter === 'nearby') {
        newActiveFilters = activeFilters.filter(key => key !== 'availableNow' && key !== 'bestmatch');
      }
      else if (newFilter === 'availableNow') {
        newActiveFilters = activeFilters.filter(key => key !== 'nearby' && key !== 'bestmatch');
      }
      else if (newFilter === 'bestmatch') {
        newActiveFilters = activeFilters.filter(key => key !== 'nearby' && key !== 'availableNow');
      }
      else {
        newActiveFilters = [...activeFilters, newFilter];
      }
    }
    else {
      newActiveFilters = [...activeFilters, newFilter];
    }
  }

  return newActiveFilters;
};

const normalizeSessions = (sessions) => {
  const entities = {};

  const result = sessions.map(session => {
    const bookedIds = [];
    const bookings = {};

    session.bookings.forEach(booking => {
      bookedIds.push(booking.profile_id);
      bookings[booking.profile_id] = booking;
    });

    entities[session.id] = {
      ...session,
      bookedIds,
      bookings,
    };

    return session.id;
  });

  return { entities, result };
}
export const normalizeSession = (session) => {
  const bookedIds = [];
  const bookings = {};

  session.bookings.forEach(booking => {
    // bookedIds.push(booking.profile_id);
    !bookedIds.includes(booking.profile_id) && bookedIds.push(booking.profile_id);
    bookings[booking.profile_id] = booking;
  });

  return {
    ...session,
    bookedIds,
    bookings,
  }
}