import React from 'react';

import './Modal.scss';
import { useDidMount, useWillUnmount } from 'hooks';

const Modal = (props) => {
  useDidMount(() => document.addEventListener("keydown", handleKeyDown, false));

  useWillUnmount(() => document.removeEventListener("keydown", handleKeyDown, false));

  const handleKeyDown = (e) => {
    if (!props.closeModal) return;
    if (props.notCloseOnEscape) return;

    if (e.code === "Escape") {
      props.closeModal();
    }
  }

  const handleMouseDown = (e) => {
    if (!props.closeModal) return;
    if (props.notCloseOutside) return;

    if (e.target !== e.currentTarget) {
      return;
    }

    props.closeModal();
  }

  const activeClass = props.isOpen
    ? 'modal__container open'
    : 'modal__container open out';

  return (
    <div
      className={activeClass}
      style={props.zIndex ? { zIndex: props.zIndex } : null}
    >
      <div className="modal__background" onMouseDown={handleMouseDown}>
        <article className="modal__content">
          {/* <Scrollbars > */}
          {props.children}
          {/* </Scrollbars> */}
        </article>
      </div>
    </div>
  )
}

export default Modal;
