import { useCancelToken } from "hooks";

import "./Gallery.scss";
import GalleryList from "./components/GalleryList/GalleryList";
import GallerySidebar from "./components/GallerySidebar/GallerySidebar";
import GalleryHeader from './components/GalleryHeader/GalleryHeader'


const Gallery = () => {
  // To cancel axios http request
  const { newCancelToken, cancelPrevRequest } = useCancelToken();

  return (
    <section className="gallery">
      <GallerySidebar 
        newCancelToken={newCancelToken} 
        cancelPrevRequest={cancelPrevRequest}
      />

      <GalleryHeader />

      <GalleryList 
        newCancelToken={newCancelToken}
        cancelPrevRequest={cancelPrevRequest}
      />
    </section>
  );
};

export default Gallery;
