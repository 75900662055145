import React, { Fragment, useState, useEffect } from "react";

import { classModifier } from "utils";
import { DIVA_GIRLS_IMG_HOST } from "redux/ducks/divaGirls";

import './Carousel.scss'
import ICONS from "assets/icons";
import LazyLoadImage from "components/LazyLoadImage/LazyLoadImage";

const Carousel = (props) => {
  console.log("CAROUSEL RENDER");

  const [activeSlide, setActiveSlide] = useState(props.defaultActiveSlide || 0);

  const {
    images,
    imgClass,
    imgWrapClass,
    prevBtnClass,
    nextBtnClass,
    iconWidth,
    iconHeight,
    spinnerSize = 50,
    isKeyNavigation,
  } = props;

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === 'ArrowRight') {
        changeSlide('next');
      }
      else if (e.key === 'ArrowLeft') {
        changeSlide('prev');
      }
    }

    if (isKeyNavigation && images.length > 1) {
      document.addEventListener('keydown', onKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    }
  }, []);

  const changeSlide = (action) => {
    const lastSlide = images.length - 1;

    if (action === "prev") {
      setActiveSlide(prevActiveSlide =>
        prevActiveSlide === 0
          ? lastSlide
          : prevActiveSlide - 1
      );
    }
    else {
      setActiveSlide(prevActiveSlide =>
        prevActiveSlide === lastSlide
          ? 0
          : prevActiveSlide + 1
      );
    };
  };

  return (
    <Fragment>
      {images.map((image, i) => (
        <div
          key={i}
          className={classModifier(imgWrapClass, [i === activeSlide && 'active'])}
        >
          <LazyLoadImage
            src={DIVA_GIRLS_IMG_HOST + image}
            className={imgClass}
            style={{ opacity: i === activeSlide ? "1" : "0" }}
            spinnerSize={spinnerSize}
          />
        </div>
      ))}

      {images.length > 1 && <>
        <div
          className={prevBtnClass}
          onClick={() => changeSlide("prev")}
        >
          <ICONS.chevron className="carousel-arrow-icon carousel-arrow-icon--prev" />
        </div>

        <div
          className={nextBtnClass}
          onClick={() => changeSlide("next")}
        >
          <ICONS.chevron className="carousel-arrow-icon carousel-arrow-icon--next" />
        </div>
      </>}
    </Fragment>
  );
};

export default Carousel;