import React from 'react';
import Loadable from 'react-loadable';
import { usePopperTooltip } from 'react-popper-tooltip';

import ICONS from 'assets/icons';

import AsyncComponentLoader from 'components/AsyncComponentLoader/AsyncComponentLoader';

export const LoadEmojiPicker = Loadable({
  loader: () => import(/* webpackChunkName: "EmojiPickerChunk"*/ './EmojiPicker'),
  loading: AsyncComponentLoader,
  delay: 400,
  timeout: 10000, // 10 seconds
  modules: ['EmojiPickerChunk']
});

const EmojiPickerBtn = ({
  onSelect,
}) => {
  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    trigger: "click",
    placement: 'top',
    mutationObserverOptions: null,
  })

  return <>
    <button
      className="chat-input__btn"
      ref={setTriggerRef}
      onMouseOver={LoadEmojiPicker.preload}
    >
      <ICONS.smile className="chat-input__btn-icon" />
    </button>

    {visible &&
      <div ref={setTooltipRef} {...getTooltipProps()}>
        <LoadEmojiPicker
          style={{ position: 'initial'}}
          showPreview={false}
          onSelect={onSelect}
          native={true}
        />
      </div>
    }   
  </>
}


export default EmojiPickerBtn;