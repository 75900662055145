import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDidUpdate, usePrevious } from 'hooks';
import { classModifier, throttle } from 'utils';
import { GENERAL_ROOM_ID, updateActiveRoom } from 'redux/ducks/roomChats';
import { selectRoomList } from 'redux/selectors/selectors';

import './OperatorsChatRooms.scss';
import ICONS from 'assets/icons';

const OperatorsChatRooms = () => {
  const [isDisplayScrollButtons, setIsDisplayScrollButtons] = useState(false);
  const [disabledScrollButtons, setDisabledScrollButtons] = useState({
    left: true,
    right: false,
  });

  const listRef = useRef(null);
  const dispatch = useDispatch();

  const rooms = useSelector(selectRoomList);
  const roomIds = useSelector(state => state.rooms.ids);
  const activeRoomId = useSelector(state => state.roomChats.activeRoomId);

  const sortedRooms = useMemo(() => {
    return rooms.sort((room1, room2) => room1.id - room2.id);
  }, [rooms]);

  const prevSortedRooms = usePrevious(sortedRooms);
  const prevActiveRoomId = usePrevious(activeRoomId);

  useDidUpdate(() => {
    // Scroll to new chat created
    if (
      sortedRooms.length > prevSortedRooms.length &&
      sortedRooms.length - prevSortedRooms.length === 1
    ) {
      scrollTo(listRef.current.scrollWidth);
    }
    // Scroll to start if active chat has been deleted
    else if (
      sortedRooms.length < prevSortedRooms.length &&
      !roomIds.includes(prevActiveRoomId)
    ) {
      scrollTo(0);
    }
  }, [sortedRooms.length]);

  useEffect(() => {
    throttledCalculateScrollButtons();
  }, [rooms]);

  useEffect(() => {
    window.addEventListener('resize', throttledCalculateScrollButtons);

    return () => {
      window.removeEventListener('resize', throttledCalculateScrollButtons);
    }
  }, [disabledScrollButtons, isDisplayScrollButtons]);

  const calculateScrollButtons = () => {
    calcIsScrollButtonsDisabled();
    calcIsScrollButtonsDisplayed();
  };

  const throttledCalculateScrollButtons = useMemo(
    () => throttle(calculateScrollButtons, 200),
    [disabledScrollButtons, isDisplayScrollButtons]
  );

  const scrollTo = (left) => {
    listRef.current.scrollTo({ behavior: 'smooth', left });
  }

  // Setting value to state to re-render and recalculate new value
  const calcIsScrollButtonsDisabled = () => {
    const listElem = listRef?.current;
    if (!listElem) return;

    if (listElem.scrollLeft === 0 && !disabledScrollButtons.left) {
      setDisabledScrollButtons(prev => ({ ...prev, left: true }));
    }
    else if (listElem.scrollLeft !== 0 && disabledScrollButtons.left) {
      setDisabledScrollButtons(prev => ({ ...prev, left: false }));
    }
    
    if (listElem.scrollLeft + listElem.clientWidth === listElem.scrollWidth && !disabledScrollButtons.right) {
      setDisabledScrollButtons(prev => ({ ...prev, right: true }));
    }
    else if (listElem.scrollLeft + listElem.clientWidth !== listElem.scrollWidth && disabledScrollButtons.right) {
      setDisabledScrollButtons(prev => ({ ...prev, right: false }));
    }
  }

  const calcIsScrollButtonsDisplayed = () => {
    const listElem = listRef?.current;
    if (!listElem) return;

    const isScroll = listElem.scrollWidth !== listElem.clientWidth;
    if (!isDisplayScrollButtons && isScroll) {
      setIsDisplayScrollButtons(true);
    }
    else if (isDisplayScrollButtons && !isScroll) {
      setIsDisplayScrollButtons(false);
    }
  }

  const onClickTag = (e, roomId) => {
    dispatch(updateActiveRoom(roomId));

    const unreadCounterOffset = 8;
    const tagElem = e.currentTarget;
    const listElem = listRef.current;
    const viewOffsetStart = listRef.current.scrollLeft;
    const viewOffsetEnd = viewOffsetStart + listRef.current.clientWidth;
    const elemStart = tagElem.offsetLeft;
    const elemEnd = tagElem.offsetLeft + tagElem.clientWidth;
    const additionalOffset = tagElem?.hasAttribute('data-unread') ? unreadCounterOffset : 0; // Add a scroll offset if a tab has an unread indicator

    if (elemStart < viewOffsetStart && elemEnd < viewOffsetEnd) { // If element crosses left border
      scrollTo(elemStart - additionalOffset);
    }
    else if (elemStart > viewOffsetStart && elemEnd > viewOffsetEnd) { // If element crosses right border
      scrollTo(elemEnd - listElem.clientWidth);
    }
  }

  const onClickArrow = (e) => {
    const elements = Array.from(listRef.current.children);
    const direction = e.currentTarget.dataset.direction;
    const viewOffsetStart = listRef.current.scrollLeft;
    const viewOffsetEnd = viewOffsetStart + listRef.current.clientWidth;

    const getCrossingElem = (viewBorderOffset) => elements.find((elem) => {
      const elemStart = elem.offsetLeft;
      const elemEnd = elem.offsetLeft + elem.clientWidth;
      return elemStart < viewBorderOffset && elemEnd > viewBorderOffset;
    })

    if (direction === 'right') {
      const nextHiddenElement = elements
        .filter((elem) => elem.offsetLeft >= viewOffsetEnd)
        .shift();

      const resultElem = getCrossingElem(viewOffsetEnd) || nextHiddenElement;

      if (resultElem) {
        scrollTo(resultElem.offsetLeft + resultElem.clientWidth - listRef.current.clientWidth);
      }
    }
    else {
      const prevHiddenElement = elements
        .filter((elem) => elem.offsetLeft + elem.clientWidth <= viewOffsetStart)
        .pop();

      const resultElem = getCrossingElem(viewOffsetStart) || prevHiddenElement;
      const additionalOffset = resultElem?.hasAttribute('data-unread') ? 8 : 0;  // Add a scroll offset if a tab has an unread indicator

      if (resultElem) {
        scrollTo(resultElem.offsetLeft - additionalOffset);
      }
    }
  }
  
  return (
    <div className="operators-chat-rooms">
      <button
        className={classModifier('operators-chat-rooms__btn', !isDisplayScrollButtons && 'hidden')}
        data-direction="left"
        disabled={disabledScrollButtons.left || !isDisplayScrollButtons}
        onClick={onClickArrow}
      >
        <ICONS.chevron className={classModifier('operators-chat-rooms__icon', 'left')} />
      </button>

      <div 
        className="operators-chat-rooms__list" 
        ref={listRef} 
        onScroll={calcIsScrollButtonsDisabled}
      >
        {sortedRooms.map(room => (
          <button
            key={room.id}
            className={classModifier('operators-chat-rooms__tab', activeRoomId === room.id && 'active')} 
            onClick={(e) => onClickTag(e, room.id)}
            data-unread={room.unreadCount ? '' : undefined}
          >
            {Number(room.id) === GENERAL_ROOM_ID ? 'All operators' : room.chatTitle}

            {!!room.unreadCount && 
              <span className="operators-chat-rooms__unread-count">
                {room.unreadCount > 9 ? '9+' : room.unreadCount}
              </span>
            }
          </button>
        ))}
      </div>

      <button
        className={classModifier('operators-chat-rooms__btn', !isDisplayScrollButtons && 'hidden')}
        data-direction="right"
        disabled={disabledScrollButtons.right || !isDisplayScrollButtons}
        onClick={onClickArrow}
      >
        <ICONS.chevron className={classModifier('operators-chat-rooms__icon', 'right')} />
      </button>
    </div>
  )
}

export default React.memo(OperatorsChatRooms);
