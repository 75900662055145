import ICONS from 'assets/icons';
import React from 'react'
import { useSelector } from 'react-redux';

import { selectLockedOperator } from 'redux/selectors/selectors';
import { formatDateToTime, getDateByTimezoneOffset } from 'utils';

const BookingListItem = ({booking, userTimezone}) => {
  const operatorName = useSelector((state) => selectLockedOperator(state, booking.usersIds[0]));
  const userHour12 = useSelector((state) => state.user.hour12);

  const getBookingTime = () => {
    const bookingDateByTimezone = getDateByTimezoneOffset(userTimezone, booking.date);
    const dateInSeconds = new Date(bookingDateByTimezone).getTime() / 1000;
    const time = formatDateToTime(bookingDateByTimezone, userHour12, false);

    const getBookingFinalTime = (timeAgo) => formatDateToTime((new Date((dateInSeconds + timeAgo)*1000)), userHour12, false);

    switch (booking.duration) {
      case '30 minutes':
        return `${time} - ${getBookingFinalTime(1800)}`;
      case '45 minutes':
        return `${time} - ${getBookingFinalTime(2700)}`;
      case '1 hour':
        return `${time} - ${getBookingFinalTime(3600)}`;
      case 'Extra hour':
        return 'Extra hour';
      case '90 minutes':
        return `${time} - ${getBookingFinalTime(5400)}`;
      case '2 hours':
        return `${time} - ${getBookingFinalTime(7200)}`;
      case '3 hours':
        return `${time} - ${getBookingFinalTime(10800)}`;
      case 'Dinner date':
        return 'Dinner date';
      case '12 hours':
        return `${time} - ${getBookingFinalTime(43200)}`;
      case 'Overnight':
        return 'Overnight';
      default:
        'incorrect time';
    }
  }
  
  return (
    <div key={booking.id} className="bookings-list-item">
      <div className="bookings-list-item__header">
        <div className="bookings-list-item__time">
          {getBookingTime()}
        </div>

        <div className="bookings-list-item__header-info">
          <p className="bookings-list-item__operator-name">
            {operatorName}
          </p>
          {!!booking.is_prebooking && 
            <div className="bookings-list-item__icons">
              <ICONS.p className="bookings-list-item__icon-pre-booking"/>
            </div>
          }
        </div>
      </div>

      <div className="bookings-list-item__content">
        <p className="bookings-list-item__info">
          {booking.duration} / <span className="bookings-list-item__type">{booking.type}</span> / £{booking.rate} 
        </p>
        <p className="bookings-list-item__girl-name">
          {booking.profile_name}
        </p>
        <p className="bookings-list-item__client-name">
          {booking.caller.fn}
        </p>
      </div>
    </div>
  );
}

export default BookingListItem;