import React, { useState, useCallback } from "react";

import API from "api/api";
import { useDidMount } from "hooks";

import './ContactLogsTab.scss';
import List from "components/List/List";
import Spinner from "components/UI/Spinner/Spinner";
import ContactLogItem from "./components/ContactLogItem";
import CustomScrollbarTrack from "components/UI/CustomScrollbarTrack/CustomScrollbarTrack";

const ContactLogsTab = props => {
  const [log, setLog] = useState([]);
  const [pending, setPending] = useState(true);

  useDidMount(() => getContactLog());

  const getContactLog = (offset = '') => {
    API.getContactLog(props.id, offset)
      .then(res => setLog(prevLog => [...prevLog, ...res.data]))
      .catch(console.log)
      .finally(() => setPending(false))
  };

  const Component = useCallback((props) => <ContactLogItem {...props} />, []);
  const loadMore = useCallback((offset) => getContactLog(offset), []);
  
  return (
    <div className="contact-log-history">
      {pending
        ? <div className="contact-log-history__load-wrap">
            <Spinner spinnerSize={30} />
          </div>
        : <List
            list={log}
            limit={20}
            scrollInitialPosition='top'
            classPrefix='contact-log-history'
            listItem={Component}
            loadMore={loadMore}
            scrollbarProps={{ renderTrackVertical: CustomScrollbarTrack }}
          />
      }
    </div>
  );
};

export default ContactLogsTab;