import React, { Fragment, useState } from "react";
import { connect } from 'react-redux';

import { selectContactById } from "redux/selectors/selectors";

import ICONS from "assets/icons";
import ContactTags from "components/ContactTags/ContactTags";

const ProfileCardTitle = ( props ) => {
  const {
    // id,
    // contactId,
    prices = [],
    reviewsCount,
  } = props;

  const [noteListCount, setNoteListCount] = useState(props.notesCount);

  const renderProfileRate = () => {
    const halfHour = prices.find(rate => rate.name === '30 minutes') || {};
    const hour = prices.find(rate => rate.name === '1 hour') || {};
    const {
      incall = '-',
      outcall = '-',
    } = hour;

    return `${halfHour.incall ? halfHour.incall : '-'}/${incall}/${outcall}`;
  }

  // const showProfileReviews = () => {
  //   if (reviewsCount === 0) return;

  //   props.openModal(MODAL_TYPES.profileReviews, { id });
  // }

  // const showNotes = () => {
  //   props.openModal(MODAL_TYPES.profileNotesList, { 
  //     contactId,
  //     setNoteListCount,
  //   });
  // }

  return (
    <Fragment>
      <h2 className="profile-card__title">
        {props.name}

        <span className="profile-card__title-rate">
          {renderProfileRate()}
        </span>

        {!!props.prevBookedCount &&
          <span
            className="profile-card__prev-booked-count"
          >
            {props.prevBookedCount}
            <ICONS.eye />
          </span>
        } 
      </h2>

      <div className="profile-card__tags">
        <ContactTags tags={props.contact?.callerTags} />
      </div> 

      <div className="profile-card__rating-wrap">
        <div className="profile-card__rating">
          {props.rating.rating}&nbsp;
          {props.rating.votes && 
            <button
              // onClick={showProfileReviews} // TODO uncomment after adding modal
              className="profile-card__reviews-btn"
            >
              ({reviewsCount})
            </button>
          }
        </div> 
  
        {true && 
          <div className="profile-card__notelist-count">
            {noteListCount}
            <button
              // onClick={showNotes} // TODO uncomment after adding modal
              className="profile-card__notelist-btn"
            >
              <ICONS.penSquare className="profile-card__notelist-btn-icon" />
            </button>
          </div>
        }
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => ({
  contact: selectContactById(state, ownProps.contactId),
});

export default connect(mapStateToProps)(ProfileCardTitle);