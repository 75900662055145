import React, { Fragment, lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import {
  closeModal, MODAL_TYPES,
} from 'redux/ducks/activeWindows';

import './Dialogs.scss';
import Modal from 'components/Modal/Modal';
import DelayedComponent from 'components/DelayedComponent/DelayedComponent';
import OutgoingCallModal from './components/OutgoingCallModal';
import IncomingCallModal from './components/IncomingCallModal';
import ModalError from './components/ModalError/ModalError';
import ModalImage from 'components/ModalImage/ModalImage';
import ModalVideo from 'components/ModalVideo/ModalVideo';
import AwaySystemModal from './components/AwaySystemModal/AwaySystemModal';
import MailContactSearch from './components/MailContactSearch/MailContactSearch';
import NotForContactModal from './components/NotForContactModal/NotForContactModal';
import FeedbackForm from 'components/FeedbackForm/FeedbackForm';
import ProfileCarousel from '../ProfileCard/components/ProfileCarousel/ProfileCarousel';
import SearchList from 'components/SearchList/SearchList';

const LoadContactCard = lazy(() => import(/* webpackChunkName: "ContactCard"*/ './components/ContactCard/ContactCard'));
const LoadUserSettings = lazy(() => import(/* webpackChunkName: "UserSettings"*/ './components/UserSettings/UserSettings'));
const LoadProfilePricesEditing = lazy(() => import(/* webpackChunkName: "ProfilePricesEditing"*/ './components/ProfilePricesEditingModal/ProfilePricesEditingModal'));
const LoadCallModal = lazy(() => import(/* webpackChunkName: "CallModal"*/ './components/CallModal/CallModal'));
const LoadPrivateGallery = lazy(() => import(/* webpackChunkName: "LoadPrivateGalleryChunk"*/ './components/PrivateGallery/PrivateGallery'));

const components = {
  [MODAL_TYPES.userSettings]: LoadUserSettings,
  [MODAL_TYPES.mailContactSearch]: MailContactSearch,
  [MODAL_TYPES.outgoingCall]: OutgoingCallModal,
  [MODAL_TYPES.incomingCall]: IncomingCallModal,
  [MODAL_TYPES.contactCard]: LoadContactCard,
  [MODAL_TYPES.image]: ModalImage,
  [MODAL_TYPES.video]: ModalVideo,
  [MODAL_TYPES.error]: ModalError,
  [MODAL_TYPES.awaySystem]: AwaySystemModal,
  [MODAL_TYPES.profilePricesEditing]: LoadProfilePricesEditing,
  [MODAL_TYPES.notForContact]: NotForContactModal,
  [MODAL_TYPES.call]: LoadCallModal,
  [MODAL_TYPES.feedback]: FeedbackForm,
  [MODAL_TYPES.profileCarousel]: ProfileCarousel,
  [MODAL_TYPES.privateGallery]: LoadPrivateGallery,
  [MODAL_TYPES.SearchList]: SearchList,
};

const DelayedComponents = (props) => {
  const {
    activeModals = [],
  } = props;

  const renderComponent = (modal) => {
    const Component = components[modal.type];

    return <Component closeModal={props.closeModal} {...modal.props} />
  }

  return (
    <>
      {ReactDOM.createPortal(
        <Fragment>
          <Suspense fallback={<div className='suspense' />}>
            {activeModals.map(modal =>
              <DelayedComponent isMount={modal.isShow} key={modal.type}>
                <Modal
                  isOpen={modal.isShow}
                  closeModal={props.closeModal}
                  notCloseOutside={!modal.isCloseOutside}
                  notCloseOnEscape={!modal.isCloseOnEscape}
                >
                  {renderComponent(modal)}
                </Modal>
              </DelayedComponent>
            )}
          </Suspense>
        </Fragment>,
        document.getElementById('dialogs-root')
      )}
    </>
  );
};

const mapStateToProps = state => ({
  activeModals: state.activeWindows.activeModals,
});

const mapDispatchToProps = {
  closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(DelayedComponents);