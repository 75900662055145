import React from 'react';
import { connect } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';

import { classModifier } from 'utils';
import getFormattedDate from 'utils/getFormattedDate';
import { 
  selectOperatorIncludingCurrent, 
  selectUserTimezone 
} from 'redux/selectors/selectors';
  
  import ICONS from 'assets/icons';
import Portal from 'components/Portal';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';

const ContactLogItem = props => {
  const {
    item,
    username,
    userHour12,
    userTimezone
  } = props;
  
  const {
    visible,
    setTooltipRef,
    setTriggerRef,
    getTooltipProps
  } = usePopperTooltip({
    trigger: 'hover',
    placement: 'top',
    onVisibleChange,
    offset: [0, 2],
  });

  function onVisibleChange(isVisible) {
    if (!item.description && !item.avatar) {
      return false;
    }

    return isVisible;
  }

  const { time, date } = getFormattedDate({
    day: '2-digit',
    month: 'short',
		hour12: userHour12,
		userTimezone: userTimezone, 
		dateWithoutTimezoneOffset: item.created_at 
	});

  const renderDescription = () => {
    if (item.action === 'changed_avatar') {
      return <>
        Avatar was changed from
        <LazyLoadImage
          src={item.avatar?.oldValue}
          containerClassName="contact-log__description-avatar"
        />
        to
        <LazyLoadImage
          src={item.avatar?.newValue}
          containerClassName="contact-log__description-avatar"
        />
      </>
    }
    else if (item.action === 'added_avatar') {
      return <>
        Added new avatar
        <LazyLoadImage
          src={item.avatar?.newValue}
          containerClassName="contact-log__description-avatar"
        />
      </>
    }
    
    return item.description;
  };

  return (
    <li className="contact-log" ref={setTriggerRef}>
      <div className={classModifier("contact-log__row", "date")}>
        <div className="contact-log__date">
          {date.split(' ').reverse().join(' ')}, {time}
        </div>
      </div>

      <div className="contact-log__row">
        <div className="contact-log__operator">
          {username || 'System'}
        </div>

        <div className="contact-log__action">
          {item.title}
        </div>
      </div>

      {!!item.data &&
        <button type="button" className="contact-log__undo-btn">
          <ICONS.undo className="contact-log__undo-btn-icon"/>
        </button>
      }

      {visible &&
        <Portal>
          <div ref={setTooltipRef} {...getTooltipProps()} className="contact-log__description">
            {renderDescription()}
          </div>
        </Portal>
      }
    </li>
  )
};

const mapStateToProps = (state, ownProps) => ({
  username: selectOperatorIncludingCurrent(state, ownProps.item.user_id)?.username,
  userTimezone: selectUserTimezone(state),
  userHour12: state.user.hour12,
});

export default connect(mapStateToProps)(ContactLogItem);