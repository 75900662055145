import React from 'react';
import { connect } from 'react-redux';

import ICONS from 'assets/icons';
import getFormattedDate from 'utils/getFormattedDate';
import { selectUserTimezone } from 'redux/selectors/selectors';


const ContactRequestsTabItem = ({ item, className, userTimezone, userHour12 }) => {
	const {
		text,
		is_success,
	} = item;

	const { time, date } = getFormattedDate({ 
		hour12: userHour12, 
		userTimezone, 
		dateWithoutTimezoneOffset: item.date 
	});

	return (
		<li className={className}>
			<div>
				{is_success
					? <span className={className + '-status--success'}>success</span>
					: <span className={className + '-status--unsuccess'}>unsuccess</span>
				}
				<span className={className + '-date'}>
					{date}, <span>{time}</span>
				</span>

				<button 
					type="button" 
					className={className + '-session-btn'} 
				>
					<ICONS.puzzleLeft width="15"/>
				</button>
				<p>{text}</p>
			</div>
		</li>
	)
};

const mapStateToProps = (state) => ({
	userTimezone: selectUserTimezone(state),
	userHour12: state.user.hour12
});

export default connect(mapStateToProps)(ContactRequestsTabItem);