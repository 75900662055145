const protocol = 'wss://';
const port = ':8000';
const currentHostname = window && window.location && window.location.hostname;

let socketHostname;

if (currentHostname === 'localhost' || currentHostname === 'v2.comdiva.local') {
    socketHostname = protocol + 'v2.comdiva.local' + port;
}
else if (currentHostname && currentHostname !== 'chromewebdata') {
    socketHostname = (protocol + 'socket.' + currentHostname + port).replace('v2.', '');
}
else {
    socketHostname = 'ws://127.0.0.1:8000';
}

export const SOCKET_ROOT = `${socketHostname}`;