import { useMemo } from 'react';
import Select from 'react-select';
import { v4 as uuid } from 'uuid';

import { classModifier } from 'utils';

import './FeedbackSelectField.scss';

const FeedbackSelectField = ({
  input: { value, onChange, onBlur },
  meta: { touched, error },
  fieldType,                  // 'type', 'duration'
}) => {
  const inputId = useMemo(() => uuid(), []);

  const labelText = () => {
    switch (fieldType) {
      case 'type':
        return 'Type';
      case 'duration':
        return 'Booking Duration';
    }
  };

  const selectOptions = () => {
    switch (fieldType) {
      case 'type':
        return TYPE_FIELD_OPTIONS;
      case 'duration':
        return DURATION_FIELD_OPTIONS;
    }
  };

  const selectStyles = useMemo(() => {
    switch (fieldType) {
      case 'type':
        return {
          option: getTypeFieldOptionStyle,
          singleValue: getTypeFieldSingleValueStyle,
        };
    }
  }, [fieldType]);

  return (
    <div
      className={classModifier('feedback-select-field', [
        fieldType === 'type' && 'type',
      ])}
    >
      <label
        className='feedback-select-field__label'
        htmlFor={inputId}
      >
        {labelText()}
      </label>

      <Select
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        inputId={inputId}
        placeholder='Select'
        options={selectOptions()}
        styles={selectStyles}
        components={{
          IndicatorSeparator: null,
        }}
        className='feedback-field-select'
        classNamePrefix='feedback-field-select'
        noOptionsMessage={() => null}
      />

      {touched && error &&
        <p className='feedback-form__field-error'>{error}</p>
      }
    </div>
  );
};

export const TYPE_FIELD_OPTIONS = [
  { value: 2, label: 'Positive', color: '#01DF85' },
  { value: 1, label: 'Negative', color: '#F8737F' },
  { value: 0, label: 'Neutral', color: '#808080' },
];

export const DURATION_FIELD_OPTIONS = [
  { value: 30, label: '30 minutes' },
  { value: 45, label: '45 minutes' },
  { value: 60, label: '1 hour' },
  { value: 90, label: '90 minutes' },
  { value: 120, label: '2 hours' },
  { value: 180, label: '3 hours' },
  { value: 480, label: '8 hours' },
  { value: 720, label: '12 hours' },
];

const getTypeFieldOptionStyle = (provided, { data }) => ({
  ...provided,
  ':before': { backgroundColor: data.color },
});

const getTypeFieldSingleValueStyle = (provided, { data }) => ({
  ...provided,
  ':after': { backgroundColor: data.color },
});

export default FeedbackSelectField;
