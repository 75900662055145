import React, { useMemo } from 'react';
import Select from 'react-select';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';
import { CONTACT_TYPES } from 'config/constants';
import { selectAgentsList } from 'redux/selectors/selectors';

import ContactAvatar from '../ContactAvatar';
import FormTextInput from 'components/FormTextInput/FormTextInput';

const clientTypeOptions = [
  { label: 'Client', value: CONTACT_TYPES.CLIENT },
  { label: 'Girl', value: CONTACT_TYPES.GIRL },
  { label: 'Agent', value: CONTACT_TYPES.AGENT },
  { label: 'Services', value: CONTACT_TYPES.SERVICES },
]

const AdrContactFormHeader = props => {
  const { 
    photo,
    active,
    agents,
    values,
    setPhoto,
    setValues,
    avaCropper,
    saveContact,
    serverError,
    editedContact,
    toggleAvaCropper,
    isNewContactCreation,
   } = props;

  const regexpId = /\sID\s+?[0-9]+$/g;
  const idFromFn = regexpId.exec(editedContact?.fn);
  const isContactGirl = +values.type === +CONTACT_TYPES.GIRL;
  const isEditedContactType = !isNewContactCreation && (!!values.type && editedContact?.type !== +values.type);
  const isEditedAgent = isContactGirl && !isNewContactCreation && (editedContact.agentId !== values?.agentId);
  
  const agentsOptions = useMemo(() => [
    { label: '(no agent)', value: null, color: '#808080' }, 
    ...agents.map(agent => ({ label: agent.fn, value: agent.id }))
  ], [agents]);

  const selectAgentStyles = useMemo(() => ({
    singleValue: (provided, { data }) => ({
      ...provided,
      color: `${data.color} !important`,
    })
  }), []);

  const deleteContactAvatar = () =>
    window.confirm("Delete avatar?") && setPhoto({ url: null, file: null });

  const getDate = (dateTime) => {
    if (!dateTime) return;

    const [date] = dateTime.split(" ");
    const [yyyy, mm = "", dd = ""] = date.split("-");

    return `${dd}/${mm}/${yyyy}`;
  };

  const changeContactAvatar = (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      if (file.type.startsWith("image")) {
        setPhotoHandler(file);
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleBlur = () => (isEditedContactType || isEditedAgent) && saveContact(values);

  const setPhotoHandler = (file) => {
    setPhoto({ file, url: URL.createObjectURL(file)});
  };

  return (
    <header className="adr-book-contact-form__header">
      <div className="adr-book-contact-form__avatar-wrapper">
        <Field
          name='image'
          photo={photo}
          values={values}
          avaCropper={avaCropper}
          component={ContactAvatar}
          cropSubmit={setPhotoHandler}
          classPrefix="adr-contact-ava"
          editedContact={editedContact}
          toggleAvaCropper={toggleAvaCropper}
          cropperClassPrefix="adr-book-img-cropper"
          isNewContactCreation={isNewContactCreation}
          onChangeContactAvatar={changeContactAvatar}
          onDeleteContactAvatar={deleteContactAvatar}
        />
      </div>
      <div className="adr-book-contact-form__header-content">
        <div className="adr-book-contact-form__header-content-wrapper">

          <div 
            className={classModifier('adr-book-contact-form__field', 'contact-type')}
            onBlur={handleBlur}
          >
            Contact type:
            <Select 
              options={clientTypeOptions}
              isSearchable={false}
              className="react-select"
              classNamePrefix="react-select"
              defaultValue={clientTypeOptions[0]}
              value={clientTypeOptions.find(el => String(el.value) === values.type)}
              onChange={option => setValues('type', option.value)}
              getOptionValue={option => option.value}
            />
          </div>

          {isContactGirl &&
            <div 
              className={classModifier('adr-book-contact-form__field', 'agent')}
              onBlur={handleBlur}
            >
              Agent:
              <Select 
                options={agentsOptions}
                className="react-select"
                classNamePrefix="react-select"
                defaultValue={agentsOptions[0]}
                styles={selectAgentStyles}
                value={agentsOptions.find(el => el.value === values.agentId)}
                onChange={option => setValues('agentId', option.value)}
                getOptionValue={option => option.value}
              />
            </div>
          }

          {active && !isNewContactCreation && (
            <div className="adr-book-contact-form__date-status">
              <span className={classModifier("adr-book-contact-form__date", "created")}>
                {`Created: ${getDate(editedContact.date_created) || ''} ; `}
              </span>

              <span className={classModifier("adr-book-contact-form__date", "edited")}>
                {`Edited: ${getDate(editedContact.last_interaction) || ''}`}
              </span>
            </div>
          )}
        </div>

        <div className={classModifier("adr-book-contact-form__input-wrap", +values.type === CONTACT_TYPES.GIRL && 'is-contact-girl')}>
          {idFromFn && 
            <div className={classModifier('adr-book-contact-form__input', ['id', +values.type === CONTACT_TYPES.GIRL && 'is-contact-girl'])}>
              {idFromFn}
            </div>
          }

          <div className={classModifier("adr-book-contact-form__fn-wrapper")}>
            <Field
              name="fn"
              component={FormTextInput}
              serverErrors={serverError}
              autoComplete="off"
              placeholder="Full Name"
              className={classModifier('adr-book-contact-form__input', 'name')}
            />
            {values.fn && 
              <button 
                type="button" 
                className="adr-book-contact-form__btn-clear"
                onClick={() => setValues('fn', '')}
              >
                <ICONS.close className="adr-book-contact-form__btn-clear-icon"/>
              </button>
            }
          </div>
        </div>
      </div>
    </header>
  )
}

const mapStateToProps = (state) => ({
  agents: selectAgentsList(state),
});

export default connect(mapStateToProps)(React.memo(AdrContactFormHeader));