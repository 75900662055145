import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import ICONS from "assets/icons";
import { getContactAvatar } from "utils";

import ImgCropper from "components/ImgCropper";
import LazyLoadImage from "components/LazyLoadImage/LazyLoadImage";
import DelayedComponent from "components/DelayedComponent/DelayedComponent";


const ContactAvatar = (props) => {
  const {
    photo,
    values,
    avaCropper,
    cropSubmit,
    classPrefix,
    toggleAvaCropper,
    editedContact = {},
    cropperClassPrefix,
    isNewContactCreation,
    onChangeContactAvatar,
    onDeleteContactAvatar,
  } = props;

  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    noDrag: true,
    onDrop: acceptedFiles => {
      const files = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );

      setFiles(files);

      if (onChangeContactAvatar) {
        onChangeContactAvatar(acceptedFiles[0]);
        toggleAvaCropper();
      }
    }
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]
  );

  const handleAvatarClick = (e) => {
    if (e.target.closest("button[data-action='delete']")) {
      e.preventDefault();

      onDeleteContactAvatar();
    } 
    else if (e.target.closest("button[data-action='upload']")) {
      getRootProps().onClick(e);
    }
    else if (e.target.closest("button[data-action='edit']")) {
      toggleAvaCropper()
    }
  }

  const currAvatar = isNewContactCreation 
    ? photo.url || getContactAvatar({ type: +values.type })
    : editedContact.photo?.src || getContactAvatar({ type: +values.type });

  return (
    <div className={classPrefix}>
      <div className={`${classPrefix}__img-container`}>
        <div className={`${classPrefix}__img-wrap`}>
          <LazyLoadImage
            src={currAvatar}
            className={`${classPrefix}__img`}
            alt="contact_avatar"
            spinnerSize={50}
          />
        </div>

        <div className={`${classPrefix}__control-icons`}>
          <div
            {...getRootProps({ className: `${classPrefix}__image-input` })}
            onClick={handleAvatarClick}
          >
            <input {...getInputProps()} />

            {photo.file && (
              <button
                type="button"
                className={`${classPrefix}__icon-wrapper ${classPrefix}__icon-wrapper--delete`}
                data-action="delete"
              >
                <ICONS.close className={`${classPrefix}__icon ${classPrefix}__icon--delete`} />
              </button>
            )}

            <button
              type="button"
              className={`${classPrefix}__icon-wrapper ${classPrefix}__icon-wrapper--upload`}
              data-action="upload"
            >
              <ICONS.upload className={`${classPrefix}__icon ${classPrefix}__icon--upload`}/>
            </button>

            {(photo.file || (editedContact.photo && !isNewContactCreation) || props.isSelectedDivaProfile) && (
              <button
                type="button"
                className={`${classPrefix}__icon-wrapper ${classPrefix}__icon-wrapper--edit`}
                data-action="edit"
              >
                <ICONS.pencil className={`${classPrefix}__icon ${classPrefix}__icon--edit`}/>
              </button>
            )}
          </div>
        </div>
      </div>

      <DelayedComponent isMount={avaCropper}>
        <div className={avaCropper ? cropperClassPrefix : `${cropperClassPrefix} close`} onClick={toggleAvaCropper}>
          <ImgCropper
            cropSubmit={(croppedImage) => {
              cropSubmit(croppedImage, false);
              toggleAvaCropper();
            }}
            aspect={4 / 4}
            photo={photo.url || getContactAvatar(editedContact, 'contact', true)}
            setPhoto={cropSubmit}
            isDisabled={!editedContact.photo && !photo.url}
            toggleAvaCropper={toggleAvaCropper}
            classNamePrefix={cropperClassPrefix}
          />
        </div>
      </DelayedComponent>   
    </div>
  );
}

export default ContactAvatar;
