import './Checkbox.scss';

export default function Checkbox({ inputProps, input: finalFormInputProps }) {
  return (
    <span className="checkbox">
      <input
        {...finalFormInputProps}
        className="checkbox__input" 
        type="checkbox"
        {...inputProps}
      />

      <span className="checkbox__indicator"></span>
    </span>
  )
}
