import { useState } from 'react';
import { useSelector } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';

import { selectUserHour12, selectUserTimezone } from 'redux/selectors/selectors';
import { getDateByTimezoneOffset } from 'utils';
import { useDidUpdate } from 'hooks';
import popperViewportCenter from 'config/popperViewportCenter';
import './FeedbackCalendarField.scss';
import ICONS from 'assets/icons';
import DateTimePicker from 'components/DateTimePicker/DateTimePicker';

const FeedbackCalendarField = ({
  input: { value: date, onChange, onBlur },
  meta: { touched, error },
}) => {
  const userTimezone = useSelector(selectUserTimezone);
  const userHour12 = useSelector(selectUserHour12);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const {
    setTriggerRef,
    setTooltipRef,
    getTooltipProps,
  } = usePopperTooltip({
    trigger: 'click',
    visible: isCalendarOpen,
    onVisibleChange: setIsCalendarOpen,
  }, {
    modifiers: [popperViewportCenter]
  });

  useDidUpdate(() => {
    if (!isCalendarOpen) {
      onBlur();
    }
  }, [isCalendarOpen]);

  const handleSelectDate = timestamp => {
    onChange(timestamp);
    setIsCalendarOpen(false);
  }

  const formatDate = () => {
    if (!date) return null;

    const dateByTimezone = getDateByTimezoneOffset(userTimezone, date);

    return dateByTimezone.toLocaleString('en-GB', {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: 'numeric',
      minute: '2-digit',
      hour12: userHour12,
      hourCycle: 'h12',
    })
  }

  const tooltipProps = getTooltipProps({
    className: 'feedback-calendar-field__tooltip',
  });

  return (
    <div className='feedback-calendar-field'>
      <p className='feedback-calendar-field__label'>
        Booking Date / Time
      </p>

      <button
        className='feedback-calendar-field__button'
        type='button'
        ref={setTriggerRef}
      >
        {!date &&
          <span className='feedback-calendar-field__placeholder'>Select</span>
        }
        
        {date &&
          <span className='feedback-calendar-field__date-value'>{formatDate()}</span>
        }

        <ICONS.chevron className='feedback-calendar-field__chevron-icon' />
      </button>

      {touched && error &&
        <p className='feedback-form__field-error'>{error}</p>
      }

      {isCalendarOpen &&
        <div ref={setTooltipRef} {...tooltipProps}>
          <DateTimePicker
            onSelectDate={handleSelectDate}
            onCancel={() => setIsCalendarOpen(false)}
            initDate={date}
          />
        </div>
      }
    </div>
  )
}

export default FeedbackCalendarField;
