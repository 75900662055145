import { createSelector } from 'reselect';

import { 
  GIRLS,
  ALL_FILTER,
  UNREAD_FILTER,
  MARKED_FILTER,
  sortContactIdsByDate,
  AGENTS_FILTER,
  RELATED_FILTER
} from 'redux/ducks/contacts';

import {
  ONLINE_FILTER,
  ALL_OPERATORS_FILTER,
  ALL_ROOMS_FILTER,
  SHOW_UNREAD_ROOMS,
  DEFAULT_FILTER,
} from 'redux/ducks/rooms';
import { PARTICIPANT_USER } from 'redux/ducks/conferences';  
import { OPERATORS_CALL } from 'redux/ducks/missedCalls';
import { MAIL_LIST_FOLDER_TABS } from '../ducks/mail';
import { NOTIFICATION_TYPES } from 'redux/ducks/notifications';
import { CHAT_TYPES, CONTACTS_MODES_TYPES, CONTACT_TYPES } from 'config/constants';
import { isEmptyObj } from 'utils';
import { PUBLIC_CHAT_TABS } from 'redux/ducks/roomChats';


export const selectRoomByOperatorId = createSelector(
  (state) => state.rooms.entities,
  (state) => state.rooms.privateIds,
  (state, operatorId) => operatorId,

  (entities, privateIds, operatorId) => {
    let room;

    privateIds.forEach(privateId => {
      if (entities[privateId].usersIds[0] === operatorId) {
        room = entities[privateId];
      }
    });

    return room;
  }
)

export const selectRoomIdByOperatorId = createSelector(
  (state) => state.rooms.entities,
  (state) => state.rooms.privateIds,
  (state, operatorId_type) => operatorId_type,

  (rooms, privateIds, operatorId_type) => {
    const operatorId = typeof operatorId_type === 'string'
      ? +operatorId_type.split('_')[0]
      : +operatorId_type;

    let roomId;

    privateIds.forEach(privateId => {
      if (rooms[privateId].usersIds[0] === operatorId) {
        roomId = rooms[privateId].id;
      }
    });

    return roomId ? roomId : 1;
  },
);

export const selectRoomIdsByOperatorIds = createSelector(
  (state) => state.rooms.entities,
  (state) => state.rooms.privateIds,
  (state, operatorIds) => operatorIds,

  (rooms, privateIds, operatorIds) => {
    const result = {};

    operatorIds.forEach(operatorId => {
      let i = privateIds.length;

      while (i--) {
        const room = rooms[privateIds[i]];

        if (room.usersIds[0] === operatorId) {
          result[operatorId] = room.id;
          break;
        }
      }
    });

    return result;
  }
)

export const selectLockedOperator = (state, id) => {
  const operator = id ? state.operators.entities[id] : null;

  if (operator) {
    return operator.username;
  }
  if (!operator && id !== null) {
    return 'You';
  }
  if (!operator && id === null) {
    return 'None';
  }
};

export const selectMissedCallsByCallerId = createSelector(
  (state) => state.missedCalls.entities,
  (state, callerId_type) => callerId_type,

  (missedCalls, callerId_type) => missedCalls[callerId_type] || []
);

export const selectCallerFromMissedCall = createSelector(
  (state) => state.contacts.entities,
  (state) => state.operators.entities,
  (state) => state.user,
  (state, callerId_type) => callerId_type.split('_'),

  (contacts, operators, user, callerId_type) => {
    const callerId = +callerId_type[0];
    const callerType = +callerId_type[1];

    if (callerType === OPERATORS_CALL && callerId === user.id) {
      return user;
    }
    else if (callerType === OPERATORS_CALL) {
      return operators[callerId];
    }

    return contacts[callerId] || null;
  });

export const selectCallInfoById = createSelector(
  (state) => state.calls.connectedContacts,
  (state) => state.operators.entities,
  (state) => state.user,
  (state) => state.contacts.entities,
  (state, contactId) => contactId,
  (state, contactId, operatorId) => operatorId,

  (calls, operators, profile, contacts, contactId, operatorId) => {

    const contact = contactId && contacts[contactId];

    if (contact) {
      const call = calls[contactId];

      const operator = call?.userId === profile.id
        ? 'Me'
        : operators[call?.userId];

      return {
        contact,
        operator,
      };
    }
    else {
      let callerId;
      let isInEntities;

      const operatorByRoom = operators[operatorId];

      const callByOperatorId = Object.values(calls)
        .find(call => call.userId === operatorByRoom.id);

      if (callByOperatorId) {
        callerId = callByOperatorId.callerId;
        isInEntities = contacts[callerId];
      }

      const contactByOperatorId = callByOperatorId
        ? contacts[callerId]
        : {};

      return {
        callerId,
        isInEntities,
        operatorByRoom,
        contactByOperatorId,
      };
    }
  }
);

export const selectActiveCall = state => state.calls.activeCall;

export const selectIncomingCallById = (state, callId) => {
  return state.calls.incomingCalls[callId] || {};
};

export const selectIncomingCallByCallerId = createSelector(
  (state) => state.calls.incomingCalls,
  (state, callerId_type) => callerId_type,

  (incomingCalls, callerId_type) => {
    const callerId = +callerId_type.split('_')[0];
    return Object.values(incomingCalls).find(call => call.senderId === callerId);
  }
);

export const selectIsUnreadMessages = createSelector(
  (state) => state.contacts.entities,
  (state) => state.contacts.clients.ids[0],
  (state) => state.contacts.clients.pinIds,

  (contacts, firstUnpinnedId, pinIds) => {
    const allIds = [...pinIds];

    let i = 0;
    let haveUnread = false;

    if (firstUnpinnedId) {
      allIds.push(firstUnpinnedId);
    }

    while (i < allIds.length) {
      if (contacts[allIds[i]].unreadCount > 0) {
        haveUnread = true;
        break;
      }

      i++;
    }

    return haveUnread;
  }
);


export const selectContactById = (state, id) => {
  return state.contacts.entities[id];
};

export const selectContactsByIds = createSelector(
  (state) => state.contacts.entities,
  (state, contactIds) => contactIds,

  (contacts, contactIds) => {
    return contactIds.map(id => contacts[id]);
  }
)

export const selectRelatedOperator = (state, contactId) => {
  const contact = selectContactById(state, contactId);

  if (contact && contact.relatedUserId) {
    return state.operators.entities[contact.relatedUserId];
  }

  return null;
};

export const selectAgentContactById = (state, id) => {
  return id && state.contacts.entities[id] && state.contacts.entities[id].agentId
    ? state.contacts.entities[state.contacts.entities[id].agentId]
    : null;
};


// export const selectIsContactActive = (state, type, id) => {
//   const active = type === 1
//     ? state.clientChats.active
//     : state.girlChats.active;
//   return id && state.contacts.entities[id];
// };


export const selectIsContactActive = (state, type, id) => {
  const active = type === 2
    ? state.girlChats.active
    : state.clientChats.active;

  return id === active;
};

export const selectContactFromEntities = (state, id, type) => { // info for calls
  if (type === CONTACT_TYPES.OPERATOR) {
    return state.operators.entities[id];
  }
  else {               // contact
    return state.contacts.entities[id];
  }
};


export const selectChatFromEntities = (state, id, type) => { // info for chats
  if (type === CHAT_TYPES.ROOM) {                        // operator
    return state.rooms.entities[id];
  }
  else {               // contact
    return state.contacts.entities[id];
  }
};


export const selectTabsWithUnread = createSelector(
  (state, ids, type) => state.contacts.entities,
  (state, ids, type) => state.rooms.entities,
  (state, ids, type) => ids,
  (state, ids, type) => type,

  (contacts, rooms, ids, type) => {
    const entities = type === CHAT_TYPES.ROOM
      ? rooms
      : contacts;

    const unreadIds = [];

    ids.map(id => {
      if (id !== 'new_chat' && entities[id]?.unreadCount > 0) {
        unreadIds.push(id);
      }
    });

    return unreadIds;
  }
);

export const selectNotLoadedContacts = createSelector(
  (state) => state.contacts.entities,
  (state, ids) => ids,

  (contacts, ids) => ids && ids.filter(id => !contacts[id]),
)

export const selectRecentTab = createSelector(
  (state, chatType, recentTab) => recentTab,
  (state, chatType) => chatType,
  (state) => state.contacts.entities,

  (recentTab, chatType, contacts) => {
    if (!recentTab) {
      return null
    }

    return chatType === CHAT_TYPES.ROOM
      ? recentTab
      : contacts[recentTab];
  }
)

export const selectOperatorOrUserById = createSelector(
  (state, id) => state.operators.entities,
  (state, id) => state.user,
  (state, id) => id,

  (operators, user, id) => operators[id]
    ? operators[id]
    : user
)


export const selectClientsList = createSelector(
  (state) => state.contacts.clients.ids,
  (state) => state.contacts.clients.pinIds,
  (state) => state.contacts.clients.search,
  (state) => state.contacts.clients.searchedIds,
  (state) => state.contacts.clients.activeFilter,
  (state) => state.contacts.clients.filteredIds,

  (ids, pinIds, searchQuery, searchedIds, filter, filteredIds) => {
    if (searchQuery) {
      return searchedIds;
    }
    if (filter === ALL_FILTER) {
      return [...pinIds, ...ids];
    }
    else {
      return filteredIds
    }
  }
);

export const selectClientsToSearchForThem = createSelector(
  (state) => state.contacts.clients.ids,
  (state) => state.contacts.clients.pinIds,

  (ids, pinIds) => {
    return [...pinIds, ...ids];
  }
);


export const selectGirlsList = createSelector(
  (state) => state.contacts.girls.ids,
  (state) => state.contacts.girls.pinIds,
  (state) => state.contacts.girls.search,
  (state) => state.contacts.girls.searchedIds,
  (state) => state.contacts.girls.activeFilter,
  (state) => state.contacts.girls.filteredIds,

  (ids, pinIds, searchQuery, searchedIds, filter, filteredIds) => {
    if (searchQuery) {
      return searchedIds;
    }
    if (filter === ALL_FILTER) {
      return [...pinIds, ...ids];
    }
    else {
      return filteredIds
    }
  }
);


// export const selectFilteredContactIds = (entities, filterName, type) => {
//   let unsortedIds = [];


//   if ([MARKED_FILTER, UNREAD_FILTER].includes(filterName)) {
//     let filter;

//     if (filterName === MARKED_FILTER) {
//       filter = 'marked';
//     }
//     else if (filterName === UNREAD_FILTER) {
//       filter = 'unreadCount';
//     }

//     for (let key in entities) {
//       if (entities[key][filter] && entities[key].type === type) {
//         unsortedIds.push(key);
//       }
//     }
//   }
//   else {
//     const type = filterName === AGENTS_FILTER
//       ? 3
//       : 4;

//     unsortedIds = filterContactsByType(entities, type);
//   }


//   return sortContactIdsByDate(entities, unsortedIds);
// };

// const filterContactsByType = (entities, type) => {
//   const contactIds = [];

//   for (let key in entities) {
//     if (entities[key].type === type) {
//       contactIds.push(entities[key].id);
//     }
//   }

//   return contactIds;
// };


export const selectActiveClient = (state) => {
  const contactsList = state.contacts.entities;
  const activeClientId = state.clientChats.active;

  return contactsList[activeClientId] || {};
};


const statusOrder = ["online", "busy", "away", "offline"];

export const selectSortedByStatusRoomsIds = createSelector(
  state => state.operators.entities,
  state => state.rooms.entities,
  state => state.rooms.privateIds,

  (operators, rooms, ids) => {
    return ids
      .filter(id => operators[rooms[id].usersIds[0]].status !== "offline")
      .sort((id1, id2) => {
        const entity1 = operators[rooms[id1].usersIds[0]].status;
        const entity2 = operators[rooms[id2].usersIds[0]].status;

        return statusOrder.indexOf(entity1) - statusOrder.indexOf(entity2);
      });
  }
);

export const selectFreeOperators = createSelector(
  (state) => state.operators.entities,

  (operators) => {
    return Object.values(operators).filter(
      operator => operator.status === "online"
    );
  }
);

export const selectFreeOperatorsIds = createSelector(
  (state) => state.operators.entities,

  (operators) => {
    return Object.keys(operators).filter(
      operatorId => operators[operatorId].status === "online"
    );
  }
);

export const selectFreeCallModesOperators = (state) => {
  const activeCallContact = state.calls.activeCall.caller || state.calls.activeCall.sender || {};

  return selectFreeOperators(state).filter(operator =>
    operator.mode.call[CONTACTS_MODES_TYPES[activeCallContact.type]]);
};

export const selectOnlineOperatorsIds = createSelector(
  (state) => state.operators.entities,

  (operators) => {
    return Object.keys(operators).filter(
      operatorId => operators[operatorId].status !== "offline",
    );
  }
);

export const selectOnlineOperators = createSelector(
  (state) => state.operators.entities,

  (operators) => {
    return Object.values(operators).filter(
      operator => operator.status !== "offline",
    );
  }
);

export const selectOperatorByRoomId = createSelector(
  (state) => state.operators.entities,
  (state, id) => state.rooms.entities[id],

  (operators, activeChat) => {
    return operators[activeChat.usersIds[0]];
  }
);

export const selectDivaGirlsProfilesPending = state => state.divaGirls.loadProfilesPending;

export const selectDefaultSession = state => state.sessions.entities[0];

export const selectActiveSessionId = state => state.sessions.activeSession;

export const selectActiveSession = state => {
  const activeSessionId = selectActiveSessionId(state);

  if (typeof activeSessionId !== 'number' || activeSessionId < 0) {
    return null;
  }
  
  return state.sessions.entities[activeSessionId] || null;
}

export const selectOperatorActiveSessionsCountByRoomId = createSelector(
  (state) => state.sessions.entities,
  (state, roomId) => state.rooms.entities[roomId],

  (sessions, room) => {
    if (!room || room.chatType !== 'private') {
      return null;
    }
    const operatorId = room.usersIds[0];

    const activeOperatorSessions = Object.keys(sessions).filter(sessionId => {
      const session = sessions[+sessionId];

      if (!session || !session.usersIds) {
        return false;
      }
      return session.usersIds.includes(operatorId) && !!session.active;
    });

    return activeOperatorSessions.length;
  }
)

export const selectOperatorActiveSessionsCountByOperatorId = createSelector(
  (state) => state.sessions.entities,
  (state, operatorId) => operatorId,

  (sessions, operatorId) => {
    let count = 0;

    for (const sessionId in sessions) {
      const session = sessions[sessionId];

      if (!!session.active && session.usersIds.includes(operatorId)) {
        ++count;
      }
    }

    return count;
    const activeOperatorSessions = Object.keys(sessions).filter(sessionId => {
      const session = sessions[+sessionId];

      return session.usersIds.includes(operatorId) && !!session.active;
    });

    return activeOperatorSessions.length;
  }
)

export const selectOperatorIncludingCurrent = (state, id) => {
  if (state.operators.ids.indexOf(id) === -1) {
    return state.user;
  }
  else {
    return state.operators.entities[id];
  }
};

export const selectObjectOperators = (state, operatorsIds) => {
  if (!Array.isArray(operatorsIds)) {
    return [state.operators.entities[operatorsIds]];
  }

  return operatorsIds.map(id => state.operators.entities[id]);
};

export const selectRoomById = (state, id) => state.rooms.entities[id];

export const selectPrivateRoomIdsByQuery = createSelector(
  (state, query) => query.toLowerCase().trim(),
  (state) => state.operators.ids,
  (state) => state.operators.entities,
  (state, query, ignoreOperatorsIds) => ignoreOperatorsIds,

  (adaptedQuery, privateIds, operatorEntities, ignoreOperatorsIds = []) => {
    const filteredPrivateIds = privateIds.filter(id => {
      return !ignoreOperatorsIds.includes(id);
    });

    if (!adaptedQuery) {
      return filteredPrivateIds;
    }

    const searchedIds = [];

    filteredPrivateIds.map(id => {
      if (operatorEntities[id].username.toLowerCase().indexOf(adaptedQuery) !== -1) {
        searchedIds.push(id);
      }
    });

    return searchedIds;
  }
);


// export const selectRoomsList = createSelector( //DELETE Old select
//   (state) => state.rooms.ids,
//   (state) => state.rooms.entities,
//   (state) => state.rooms.search,
//   (state) => state.rooms.searchedIds,
//   (state) => state.rooms.activeFilter,
//   (state) => state.operators.entities,

//   (ids, entities, searchQuery, searchedIds, filter, operators) => {
//     if (searchQuery) {
//       return searchedIds;
//     }
//     if (filter === ALL_FILTER) {
//       return ids;
//     }
//     else {
//       return selectFilteredRoomsIds(entities, filter, operators);
//     }
//   }
// );

export const selectRoomList = createSelector(
  (state) => state.rooms.ids,
  (state) => state.rooms.entities,

  (roomIds, roomEntities) => roomIds
    .filter(id => (['general', 'room'].includes(roomEntities[id].chatType)))
    .map(id => roomEntities[id])
)

// const selectFilteredRoomsIds = (entities, filter, operatorsEntities) => { 
//   let unsortedIds = [];

//   if (filter === ONLINE_FILTER) {
//     const privateIds = selectOnlyPrivateRoomsIds(entities);

//     unsortedIds = getOnlyOnlinePrivateRoomsIds(entities, privateIds, operatorsEntities);
//   }
//   else if (filter === ALL_OPERATORS_FILTER) {
//     unsortedIds = selectOnlyPrivateRoomsIds(entities);
//   }
//   else if (filter === ALL_ROOMS_FILTER) {
//     unsortedIds = selectOnlyRoomsIds(entities);
//   }
//   else if (filter === SHOW_UNREAD_ROOMS) {
//     unsortedIds = selectUnreadCountRoomsIds(entities);
//   }
//   else if (filter === DEFAULT_FILTER) {
//     const privateIds = selectOnlyPrivateRoomsIds(entities);
//     const onlinePrivateRoomIds = getOnlyOnlinePrivateRoomsIds(entities, privateIds, operatorsEntities);
//     const allRooms = selectOnlyRoomsIds(entities);

//     return [
//       ...sortContactIdsByDate(entities, onlinePrivateRoomIds),
//       ...sortContactIdsByDate(entities, allRooms),
//     ];
//   }
//   return sortContactIdsByDate(entities, unsortedIds);
// };

// const selectOnlyPrivateRoomsIds = (entities) => {
//   const privateIds = [];

//   for (let key in entities) {
//     if (entities[key].chatType === "private") {
//       privateIds.push(+key);
//     }
//   }

//   return privateIds;
// };

// const selectUnreadCountRoomsIds = (entities) => {
//   const unreadRoomsIds = [];

//   for (let key in entities) {
//     if (entities[key].unreadCount !== 0) {
//       unreadRoomsIds.push(+key);
//     }
//   }

//   return unreadRoomsIds;
// };

// const selectOnlyRoomsIds = (entities) => {
//   const roomsIds = [];

//   for (let key in entities) {
//     if (entities[key].chatType === "room") {
//       roomsIds.push(+key);
//     }
//   }

//   return roomsIds;
// };

// const getOnlyOnlinePrivateRoomsIds = (entities, privateIds, operators) => {
//   const onlineIds = [];

//   privateIds.map(id => {
//     const operatorId = entities[id].usersIds[0];

//     if (operators[operatorId] && ["online", "away", "busy"].includes(operators[operatorId].status)) {
//       onlineIds.push(id);
//     }
//   });

//   return onlineIds;
// };

export const selectPropertyOfActiveSession = createSelector(
  (state, property) => state.sessions.entities[state.sessions.activeSession],
  (state, property) => property,

  (aSession, property) => {
    if (!aSession) {
      return [];
    }

    return aSession[property];
  }
);

export const selectIdsProposedByTheOperator = createSelector(
  (state, operatorId) => state.sessions.entities[state.sessions.activeSession],
  (state, operatorId) => operatorId,

  (aSession, id) => {
    if (!aSession) {
      return [];
    }

    return aSession.proposed.byOperatorId[id];
  }
);

export const selectCountOfProposedProfilesInActiveSession = createSelector(
  (state) => state.sessions.entities[state.sessions.activeSession],

  (session) => {
    if (!session) {
      return null;
    }

    const firstFilter = session.activeFilters[0];

    if (!firstFilter || !firstFilter.includes('Proposed')) {
      return null;
    }

    const operatorId = firstFilter.split(':')[2];
    const profilesIds = session.proposed.byOperatorId[operatorId];

    return profilesIds
      ? profilesIds.length
      : false;
  }
);

export const selectOperatorsIdsOfActiveSessionProposed = createSelector(
  (state) => state.sessions.entities[state.sessions.activeSession],

  (session) => {
    if (!session) {
      return [];
    }

    return session.proposed.operatorsIds;
  }
);

export const selectIsCurrentOperatorProposedProfile = createSelector(
  (state, id) => state.user.id,
  (state, id) => id,
  (state, id) => state.sessions.entities[state.sessions.activeSession].proposed,

  (myId, profileId, proposed) => {
    if (proposed.byOperatorId === null) {
      return false;
    }

    const ids = proposed.byOperatorId[myId] || [];

    return ids.includes(profileId);
  }
);

export const getCallsList = createSelector(
  (state) => state.calls.callsOnHold,
  (state) => state.calls.callsOnTransfer,
  (state) => state.missedCalls.ids,
  (state) => state.calls.incomingCalls,
  (state) => state.calls.conferences,
  (state, type) => type,

  (callsOnHold, callsOnTransfer, missedCalls, incomingCalls, conferences, type) => {
    switch (type) {
      case 'onHold': return Object.values(callsOnHold);
      case 'missed': return missedCalls;
      case 'conf': return Object.values(conferences);
      case 'inQueue': return Object.values(incomingCalls);
      case 'onTransfer': return Object.values(callsOnTransfer);

      default:
        break;
    }
  }
);

export const selectIsProfileInProposed = createSelector(
  (state) => state.sessions.entities[state.sessions.activeSession].proposed,
  (state, profileId) => profileId,

  (proposed, profileId) => {
    if (proposed.byOperatorId === null) {
      return false;
    }

    let i = proposed.operatorsIds.length;

    while (i--) {
      const currentOperatorId = proposed.operatorsIds[i];
      const currentIds = proposed.byOperatorId[currentOperatorId];

      if (currentIds.includes(profileId)) {
        return true;
      }
    }

    return false;
  }
);

export const selectSessionIdsForGallery = (state) => {
  const session = state.sessions.entities[state.sessions.activeSession];

  if (session === undefined) {
    return [];
  }

  const firstFilter = session.activeFilters[0];
  const isFiltered = session.activeFilters.length || !isEmptyObj(session.additionalFilters);

  if (firstFilter && firstFilter.includes('Proposed')) {
    const operatorId = firstFilter.split(':')[2]; // "Proposed:OperatorName:OperaotrId"

    return session.proposed.byOperatorId[operatorId] || [];
  }
  else if (!isFiltered || (firstFilter === 'availableNow' && session.activeFilters.length === 1)) {
    return state.divaGirls.ids;
  }

  return state.divaGirls.auxiliaryIds;
};

// This selector combined from both: count calculation in
// ProfilesTitle component & selectSessionIdsForGallery() above
export const selectGalleryProfilesCount = state => {
  const activeSession = selectActiveSession(state);

  if (!activeSession) {
    return 0;
  }

  const firstFilter = activeSession.activeFilters?.[0];

  if (firstFilter?.includes('Proposed')) {
    const operatorId = firstFilter.split(':')[2];

    return activeSession.proposed?.byOperatorId?.[operatorId]?.length || 0;
  };

  return state.divaGirls.count || 0;
}

export const selectIsAllGalleryProfilesInBuffer = createSelector(
  selectSessionIdsForGallery,
  state => selectActiveSession(state)?.bufferedIds,

  (galleryProfilesIds, activeSessionBufferIds) => {
    return galleryProfilesIds.every(id => activeSessionBufferIds.includes(id));
  }
);

export const selectIsProfileInBuffer = createSelector(
  (state, id) => state.sessions.entities[state.sessions.activeSession].bufferedIds,
  (state, id) => id,

  (bufferedIds, id) => ~bufferedIds.indexOf(id) ? true : false
);

export const selectIsProfileInBooked = createSelector(
  (state, id) => state.sessions.entities[state.sessions.activeSession].bookedIds,
  (state, id) => id,

  (bookedIds, id) => bookedIds && ~bookedIds.indexOf(id) ? true : false
);

export const selectProfilePrevBookedCount = createSelector(
  (state, id) => state.sessions.entities[state.sessions.activeSession].prevBookedProfiles,
  (state, id) => id,

  (prevBookedProfiles, id) => {
    if (!prevBookedProfiles) {
      return 0;
    }

    return prevBookedProfiles[id] || 0;
  }
);

export const selectProfilesSameServices = createSelector(
  (state) => state.sessions.entities[state.sessions.activeSession].comparedIds,
  (state) => state.divaGirls.entities,

  (comparedIds, girls) => {
    const services = comparedIds.length === 2
      ? [
        girls[comparedIds[0]].services.map(service => service.name),
        girls[comparedIds[1]].services.map(service => service.name)
      ]
      : null;

    return services !== null
      ? services[0].filter(service => ~services[1].indexOf(service))
      : [];
  }
);

export const selectProfilesSameParams = createSelector(
  (state) => state.sessions.entities[state.sessions.activeSession].comparedIds,
  (state) => state.divaGirls.entities,

  (comparedIds, girls) => {
    const firstGirl = girls[comparedIds[0]];
    const secondGirl = girls[comparedIds[1]] || {};

    const services = comparedIds.length === 2 && !!secondGirl.services
      ? firstGirl.services.filter(service => secondGirl.services.includes(service))
      : [];

    const location = comparedIds.length === 2 && !!secondGirl.location
      ? firstGirl.location.filter(location => secondGirl.location.includes(location))
      : [];

    const language = comparedIds.length === 2 && !!secondGirl.language
      ? firstGirl.language.filter(language => secondGirl.language.includes(language))
      : [];

    const dress = firstGirl.dress === secondGirl.dress;
    const breast_size = firstGirl.breast_size === secondGirl.breast_size;
    const height = firstGirl.height === secondGirl.height;
    const age = firstGirl.age === secondGirl.age;
    const hair = firstGirl.hair === secondGirl.hair;
    const nationality = firstGirl.nationality === secondGirl.nationality;

    return {
      services,
      location,
      language,
      dress,
      breast_size,
      height,
      age,
      hair,
      nationality,
    }
  }
);


export const selectSessionByContactId = createSelector(
  (state, id) => state.sessions.entities,
  (state, id) => state.sessions.ids,
  (state, id) => state.sessions.assistanceIds,
  (state, id) => state.operators.entities,
  (state, id) => id,

  (sessions, ids, assistanceIds, operators, contactId) => {
    let sessionId = null;
    let isMineSession = false;

    Object.keys(sessions).map(id => {
      if (sessions[id].callerId === contactId) {
        sessionId = sessions[id].id;

        if (~ids.indexOf(sessionId) || ~assistanceIds.indexOf(sessionId)) {
          isMineSession = true;
        }
      }
    });

    const sessionInfo = {
      sessionId,
      isMineSession
    };
    const userId = sessions[sessionId] && sessions[sessionId].usersIds[0];

    if (sessionId && !isMineSession && userId) {
      if (!operators[userId]) {
        return { // it's session from history. It doesn't being in sessions.ids
          sessionId: null,
          isMineSession: false
        };
      }

      sessionInfo['operatorName'] = operators[userId].username;
    }

    return sessionInfo;
  }
);

export const selectSessionsByContactIds = createSelector(
  (state) => state.sessions.entities,
  (state) => [...state.sessions.ids, ...state.sessions.assistanceIds],
  (state, contactIds) => contactIds,

  (sessions, userSessionIds, contactIds) => {
    return userSessionIds
      .filter(sessionId => contactIds.includes(sessions[sessionId].callerId))
      .map(filteredSessionId => sessions[filteredSessionId]);
  }
)


export const selectContactBySessionId = (state, id) => {
  const contacts = state.contacts.entities;
  const session = state.sessions.entities[id];

  if (session) {
    return contacts[session.callerId];
  }
};


export const selectOperatorBySessionId = (state, id) => {
  if (id === 0) {
    return state.user;
  }

  const operators = state.operators.entities;
  const session = state.sessions.entities[id];

  return operators[session.usersIds[0]];
};

export const selectCanIEditSession = state => {
  const aSessionId = state.sessions.activeSession;
  const activeSession = state.sessions.entities[aSessionId];

  const isSessionFromHistory = activeSession && !activeSession.active;
  const isPartnersSession = state.sessions.partnersIds.includes(aSessionId);

  return isSessionFromHistory || isPartnersSession
    ? false
    : true;
};

export const selectIsAmSessionOwner = (state, sessionId) => {
  const activeSession = state.sessions.entities[sessionId];

  return activeSession.ownerId === state.user.id;
}

export const selectIsPartnersSession = state => {
  const aSessionId = state.sessions.activeSession;
  const activeSession = state.sessions.entities[aSessionId];
  const myId = state.user.id;

  if (aSessionId === 0) {
    return false;
  }
  if (!activeSession.usersIds.includes(myId)) {
    return true;
  }

  return false;
};

export const selectActiveSessionIsPartners = state => state.sessions.partnersIds.includes(state.sessions.activeSession);

export const selectNameForSessionFromHistory = (state, isClient, session) => {
  if (!session) {
    return null;
  }

  if (isClient) {
    const isMySession = session.usersIds.includes(state.user.id);

    if (!isMySession) {
      const operator = state.operators.entities[session.usersIds[0]];

      return operator && operator.username;
    }

    return 'You';
  }

  const contact = state.contacts.entities[session.callerId];

  if (!contact) {
    return null;
  }

  return contact.fn;
};

export const selectIdsForSession = (state, type) => {
  if (!type) {
    return [];
  }

  else if (type.isDefault) {
    return [0];
  }

  return state.sessions[type.property];
};

export const selectFilterNameByKey = (state, key) => {
  if (key === 'All Profiles' || key === 'Previously Booked') {
    return key;
  }

  const filters = state.divaGirls.filters;

  return filters[key] && filters[key].name || 'Loading...';
};

export const selectDivaNotLoadedIds = createSelector(
  (state) => state.divaGirls.entities,
  (state, aSessionId) => state.sessions.entities[aSessionId].bookedIds,
  (state, aSessionId) => state.sessions.entities[aSessionId].bufferedIds,
  (state, aSessionId) => state.sessions.entities[aSessionId].comparedIds,
  (state, aSessionId) => state.sessions.entities[aSessionId].recentlyViewedIds,
  (state, aSessionId) => state.sessions.entities[aSessionId].proposed,

  (girls, booked, buffered, compared, recently, proposed) => {
    const notLoadedIds = [];

    const sessionIds = [
      ...booked,
      ...buffered,
      ...compared,
      ...recently
    ];

    proposed.operatorsIds.map(id => {
      const ids = proposed.byOperatorId[id];

      sessionIds.push(...ids);
    });

    const uniqueSessionIds = sessionIds.filter((id, i, self) => self.indexOf(id) === i);

    uniqueSessionIds.map(id => {
      if (!girls[id]) {
        notLoadedIds.push(id);
      }
    });

    return notLoadedIds;
  }
);

export const selectIsUnreadMsgsInSession = createSelector(
  (state) => state.contacts.entities,
  (state, sessionId) => state.sessions.entities[sessionId].callerId,
  (state, sessionId) => state.sessions.entities[sessionId].girlIds,

  (contacts, clientId, girlIds = []) => {
    let isAnyContactInSessionHasUnreadMsg = false;

    const contactIds = [clientId, ...girlIds];

    contactIds.forEach(id => {
      if (contacts[id] && contacts[id].unreadCount) {
        isAnyContactInSessionHasUnreadMsg = true;
      }
    })

    return isAnyContactInSessionHasUnreadMsg;
  }
);

export const selectToast = (state, toastId) => state.notifications.entities[toastId];

export const selectSessionByToast = (state, toastId) => {
  const sessions = state.sessions.entities;
  const toast = state.notifications.entities[toastId];

  if (!toast || !sessions[toast.sessionId]) {
    return null;
  }

  return sessions[toast.sessionId];
}

export const selectTransferOrAssistanceForNotification = (state, notificationId) => {
  const sessions = state.sessions.entities;
  const notification = state.notifications.entities[notificationId];
  const REQUEST_NOTIFICATIONS = [NOTIFICATION_TYPES.sessionTransferRequest, NOTIFICATION_TYPES.sessionAssistanceRequest];

  if (!notification || !REQUEST_NOTIFICATIONS.includes(notification.type) || !sessions[notification.data.session_id]) {
    return null;
  }

  return notification.type === NOTIFICATION_TYPES.sessionAssistanceRequest
    ? sessions[notification.data.session_id].reqForAssistanceIds
    : sessions[notification.data.session_id].reqForTransferId;
}

export const selectAgentsList = createSelector(
  (state) => state.contacts.entities,
  (state) => state.contacts.agentsIds,

  (contacts, agentsIds) => agentsIds.map(id => contacts[id])
);

export const selectAgentByGirlId = (state, girlId) => {
  const divaGirlAgentIdArray = state.divaGirls.entities[girlId].agent_id;

  if (!divaGirlAgentIdArray) return null;

  return state.contacts.entities[divaGirlAgentIdArray[0]];
}

export const selectActiveConference = (state, conferenceId) => {
  return state.conferences.activeConference.id === conferenceId
    ? state.conferences.activeConference
    : state.conferences.conferences[conferenceId];
};


export const selectParticipantById = (state, participantId) => {
  const [id, type] = participantId.split('_');

  if (+type === PARTICIPANT_USER) {
    return state.operators.entities[id]
      ? state.operators.entities[id]
      : state.user;
  }

  return state.contacts.entities[id];
};

export const selectOperatorsIdsByFilter = createSelector(
  (state) => state.operators.entities,
  (state) => state.operators.ids,
  (state, initialIds) => initialIds,
  (state, initialIds, ignoredIds) => ignoredIds,
  (state, initialIds, ignoredIds, query) => query.toLowerCase().trim(),

  (entities, ids, initialIds = [], ignoredIds = [], query = '') => {
    let filteredIds = ids;

    if (initialIds.length) {
      initialIds.forEach(id => {
        if (!filteredIds.includes(id)) {
          filteredIds.unshift(id);
        }
      });
    }

    if (ignoredIds.length) {
      filteredIds = ids.filter(id => {
        return !ignoredIds.includes(id);
      });
    }

    if (!query) {
      return filteredIds;
    }

    const searchedIds = [];

    filteredIds.forEach(id => {
      if (entities[id] && entities[id].username.toLowerCase().indexOf(query) !== -1) {
        searchedIds.push(id);
      }
    });

    return searchedIds;
  }
);

export const selectOperatorNames = createSelector(
  (state) => state.operators.entities,

  (entities) => {
    const names = [];

    for (let operator in entities) {
      names.push(entities[operator].username.toLowerCase());
    }
    return names;
  }
);

export const selectOperatorsShortInfo = createSelector(
  (state) => state.operators.entities,

  (entities) => {
    const operators = [];

    for (let operator in entities) {
      operators.push({
        photo: entities[operator].photo,
        status: entities[operator].status,
        username: entities[operator].username,
      });
    }
    return operators;
  }
);

export const selectIsContactMember = createSelector(
  (state) => state.sessions.entities[state.sessions.activeSession].callerId,
  (state) => state.contacts.entities,

  (contactId, entities) => {
    const contact = entities[contactId];

    if (!contactId || !contact) return false;

    for (let value of contact.callerTags) {
      if (value.title.toLowerCase() === "member") return true
    }

    return false
  }
);

export const selectRecentTabs = (state, type) => {
  switch (type) {
    case CHAT_TYPES.CLIENT:
      return state.clientChats.recentTabs;

    case CHAT_TYPES.GIRL:
      return state.girlChats.recentTabs;

    default:
      return state.roomChats.recentTabs;
  };
};

export const selectContactsRecentTab = (state, recentTabs) => {
  if (recentTabs && recentTabs.visible.length) {
    return selectContactById(state, recentTabs.visible[recentTabs.visible.length - 1]);
  };
};

export const selectWebrtcConferenceWithInvitation = createSelector(
  (state) => state.webrtc.conferences,

  (webrtcConferences) => {
    return Object.values(webrtcConferences).find(conference => conference.isInvited);
  }
);

export const selectActiveWebrtcConference = (state) => {
  const activeConferenceId = state.webrtc.activeConferenceId;

  if (activeConferenceId) {
    return state.webrtc.conferences[activeConferenceId];
  };
};

export const selectIsWebrtcAudioElementsMuted = createSelector(
  (state) => state.webrtc.activeConferenceId,
  (state) => state.webrtc.conferences,
  (state) => state.webrtc.isConferenceMute,
  (state) => state.user.id,

  (activeConferenceId, conferences, isConferenceMute, userId) => {
    if (activeConferenceId && isConferenceMute) {
      const currentParticipant = conferences[activeConferenceId].participants[userId]

      return currentParticipant && currentParticipant.status !== 'busy'
    }
    return false;
  }
);

export const selectAdrBookList = createSelector(
  (state) => state.addressBook.ids,
  (state) => state.addressBook.auxiliaryIds,
  (state) => state.addressBook.includeUnsaved,
  (state) => state.addressBook.search,
  (state) => state.addressBook.sortBy,
  (state) => state.addressBook.tags.active,
  (state) => state.addressBook.contactType,

  (ids, auxiliaryIds, includeUnsaved, search, sortBy, activeTags, contactType) => {
    const isActiveTags = !isEmptyObj(activeTags);
    const isAnyFilter = includeUnsaved || contactType || search || sortBy || isActiveTags;

    if (!isAnyFilter) {
      return ids;
    }
    return auxiliaryIds;
  }
);

export const selectAddrBookActiveContact = state => {
  const activeContactId = state.addressBook.active;

  if (!activeContactId) {
    return null;
  }

  return state.contacts.entities[activeContactId];
};

export const selectUserTimezone = (state) => {
  if (state.user.timezone && (state.user.timezone.offset_minutes || state.user.timezone.offset_minutes === 0)) {
    return state.user.timezone.offset_minutes;
  }
  return new Date().getTimezoneOffset() * -1;
}

export const selectActiveFolderTab = (state) => {
  const { activeFolder, activeFolderTabIndex } = state.mail.mailList;
  const folderTabs = MAIL_LIST_FOLDER_TABS[activeFolder];

  if (!activeFolder) return null;

  if (!folderTabs?.length) return null;

  return folderTabs[activeFolderTabIndex]?.mode;
};

export const selectMailListSearchQuery = state => state.mail.mailList.search;

export const selectUserHour12 = state => state.user.hour12;

export const selectAllRequirements = state => state.bookings.requirements;

export const selectMailDelayedMessage = state => state.mail.delayedMessage;

export const selectChatTabUnreadCount = createSelector(
  (state, id) => id,
  (state, id, type) => type,
  (state) => state.rooms.ids,
  (state) => state.rooms.entities,
  (state) => state.contacts.entities,

  (id, type, roomIds, roomEntities, contactEntities) => {
    if (id === PUBLIC_CHAT_TABS.rooms) { // if general operators tab - sum all rooms
      return roomIds
        .filter(id => (['general', 'room'].includes(roomEntities[id].chatType)))
        .reduce((count, id) => count += roomEntities[id].unreadCount, 0);
    }
    else if (type === CHAT_TYPES.ROOM) {
      return roomEntities[id].unreadCount;
    }
    else {
      return contactEntities[id].unreadCount;
    }
  }
)

export const selectHostProfile = (state) => {
  const hostProfileId = selectPropertyOfActiveSession(state, 'comparedIds')[0];

  return state.divaGirls.entities[hostProfileId];
};