import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import ReCAPTCHA from "react-google-recaptcha";

import API from 'api/api';
import { getCookie, capitalizeFirstLetter } from 'utils';
import { useDidMount, useToggle } from 'hooks';
import { ROUTES } from 'config/constants';

import './LoginPage.scss';
import Checkbox from 'components/Checkbox/Checkbox';
import Label from 'components/Label/Label';
import AsyncButton from 'components/AsyncButton/AsyncButton';
import FormTextField from 'components/FormTextField/FormTextField';

const currentHostname = window?.location?.hostname;
const isHideRecaptcha = process.env.NODE_ENV === 'development' ||
  currentHostname.includes('comdiva.local') ||
  currentHostname.includes('commdiva.abcwdl.com');

const LoginPage = (props) => {
  const [loginPending, setLoginPending] = useState(false);

  const [isRecaptchaValid, toggleRecaptchaValid] = useToggle(isHideRecaptcha);
  const [serverErrors, setServerErrors] = useState({});

  const inputRef = useRef();
  const recaptchaRef = useRef();

  const navigate = useNavigate();

  useDidMount(() => {
    const initApp = async () => {
      await API.getIsIAmLoggedIn()
        .then(() => {
          localStorage.isLoggedIn = 1;

          navigate(ROUTES.main, { replace: true });
        })
        .catch((err) => {
          console.log(err);
          inputRef.current && inputRef.current.focus();
        });
    };

    if (process.env.NODE_ENV === 'development') {
      if (localStorage.isLoggedIn) {
        navigate(ROUTES.main, { replace: true });
      }
      else {
        initApp();
      }
    }
    else if (!!getCookie('isAuthenticated')) {
      navigate(ROUTES.main, { replace: true });
    }
    else {
      inputRef.current && inputRef.current.focus();
    }
  });

  const loginUser = (values) => {
    setLoginPending(true);

    return API.loginUser(values)
      .then(res => {
        if (res.data.success === 1) {
          props.setIsLoggedIn(true)

          navigate(ROUTES.twoFactor, {
            replace: true,
            state: {
              email: values.email
            }
          })
        }
      })
      .catch(err => {
        setLoginPending(false);
        setServerErrors(JSON.parse(err.response?.data.message));
      })
  };

  const onSubmit = (values) => {
    return loginUser(values, props.history)
      .then(res => {
        if (res === 'wrong_credentials' && !isHideRecaptcha) {
          recaptchaRef.current.reset();
          toggleRecaptchaValid(false);
        }
      });
  };

  return (
    <div className="login">
      <h1 className="login__title">Good Morning!</h1>
      <h2 className="login__subtitle">Please Sign In to Your Dashboard</h2>

      <Form
        onSubmit={onSubmit}
        validate={validate}
      >
        {({ handleSubmit, submitting }) => (
          <form
            className="login__form"
            onSubmit={handleSubmit}
          >
            <Field
              name="email"
              label="Email"
              className="login__form-field"
              labelClassName="login__label"
              inputWrapClassName="login__input-wrap"
              inputClassName="login__input"
              component={FormTextField}
              serverErrors={serverErrors}
              inputProps={{
                tabIndex: 1,
                ref: inputRef,
                autoFocus: true,
              }}
            />

            <div className="login__form-field-wrap">
              <Field
                type="password"
                name="password"
                label="Password"
                className="login__form-field login__form-field--password"
                labelClassName="login__label"
                inputWrapClassName="login__input-wrap"
                inputClassName="login__input"
                component={FormTextField}
                serverErrors={serverErrors}
                inputProps={{ tabIndex: 2 }}
              />

              <button type="button" className="login__form-field-btn">
                Forgot Password
              </button>
            </div>

            <div className='login__form-field login__form-field--checkbox'>
              <Label className="login__checkbox-label" label="Keep me signed in">
                {id => (
                  <Field
                    name="keep-signed-in"
                    type="checkbox"
                    component={Checkbox}
                    inputProps={{ id, tabIndex: 3 }}
                  />
                )}
              </Label>
            </div>

            {!isHideRecaptcha &&
              <div className="login__recaptcha">
                <ReCAPTCHA
                  sitekey='6LcTJLUUAAAAAJ9lgSjRKy0ElVzqt_KxDvroGTdF'
                  onChange={() => toggleRecaptchaValid()}
                  ref={recaptchaRef}
                />
              </div>
            }

            {serverErrors.general &&
              <div className="login__general-error">{capitalizeFirstLetter(serverErrors.general)}</div>
            }

            <AsyncButton
              type="submit"
              className="btn btn--primary login__submit-btn"
              tabIndex={4}
              pending={loginPending}
              disabled={submitting || !isRecaptchaValid || loginPending}
            >
              Sign in
            </AsyncButton>
          </form>
        )}
      </Form>
    </div>
  );
};


const validate = ({ email, password }) => {
  const getIsFieldEmpty = (name) => !name || !name.trim().length;

  const errors = {};

  const emailReg = /[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]/igm;

  const isEmailEmpty = getIsFieldEmpty(email);
  const isPassEmpty = getIsFieldEmpty(password);

  const isEmailValid = emailReg.test(email) && !email.trim().includes(' ');

  if (isEmailEmpty) errors.email = 'Enter email';
  else if (!isEmailValid) errors.email = 'Incorrect Email';

  if (isPassEmpty) errors.password = 'Enter the password';

  return errors;
};

export default LoginPage;