import './HeaderModalButtons.scss';
import ICONS from 'assets/icons';

const HeaderModalButtons = () => {
  return (
    <div className="header-modal-btns">
      <button className="header-modal-btns__button">
        <ICONS.booking className="header-modal-btns__icon" width="20px" height="20px" />
      </button>

      <button className="header-modal-btns__button">
        <ICONS.map  className="header-modal-btns__icon" width="20px" height="20px" />
      </button>

      <button className="header-modal-btns__button">
        <ICONS.checkbox className="header-modal-btns__icon" width="20px" height="20px" />
      </button>

      <button className="header-modal-btns__button">
        <ICONS.msgArrows className="header-modal-btns__icon" width="20px" height="20px" />
      </button>

      <button className="header-modal-btns__button">
        <ICONS.scale className="header-modal-btns__icon" width="20px" height="20px" />
      </button>

      <button className="header-modal-btns__button">
        <ICONS.robot className="header-modal-btns__icon" width="20px" height="20px" />
      </button>
    </div>
  )
}

export default HeaderModalButtons;
