import React, { useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { isEmptyObj } from 'utils';
import { 
  ADR_BOOK_LIMIT, 
  changeContactSorting, 
  filterContactsForAdrBook, 
  getContactsForAdrBook } from 'redux/ducks/addressBook';
import { selectAdrBookList } from 'redux/selectors/selectors';

import './AdrBookListWrap.scss';
import AdrBookList from './components/AdrBookList/AdrBookList';
import AdrBookListSort from './components/AdrBookListSort/AdrBookListSort';
import AdrBookItem from 'containers/AdrBookListWrap/components/AdrBookItem/AdrBookItem';


const AdrBookListWrap = (props) => {
  const {
    includeUnsaved,
    contactType,
    search,
    sortBy,
    activeTags,
  } = props;

  const isContactsSyncedRef = useRef(false);

  useEffect(() => {
    const isActiveTags = !isEmptyObj(activeTags);
    const isAnyFilter = includeUnsaved || contactType || search || sortBy || isActiveTags;

    if (!isAnyFilter) {
      const config = {};

      if (!isContactsSyncedRef.current) {
        config.sync = 1
      }

      props.getContactsForAdrBook(config)
        .then(() => isContactsSyncedRef.current = true);
    }
    else {
      const config = {
        includeUnsaved,
        contactType,
        search,
        sortBy,
      };

      if (isActiveTags) {
        config.activeTags = activeTags;
      }
      if (!isContactsSyncedRef.current) {
        config.sync = 1
      }

      props.filterContactsForAdrBook(config)
        .then(() => isContactsSyncedRef.current = true);
    }
  }, [includeUnsaved, contactType, search, sortBy, activeTags]);

  const updateList = useCallback((offset) => {
    const isActiveTags = !isEmptyObj(activeTags);
    const isAnyFilter = includeUnsaved || contactType || search || sortBy || isActiveTags;

    if (!isAnyFilter) {
      props.getContactsForAdrBook({ offset: offset });
    }
    else {
      const config = {
        includeUnsaved,
        contactType,
        search,
        sortBy,
        offset
      };

      if (isActiveTags) {
        config.activeTags = activeTags;
      }

      props.filterContactsForAdrBook(config);
    }
  }, [includeUnsaved, contactType, search, sortBy, activeTags]);

  console.log('AdrBookListWrap Render');

  return (
    <div className="adr-book-list-wrapper">
      <header className="adr-book-list-wrapper__header">
        <AdrBookListSort 
          sortBy={sortBy}
          changeContactSorting={props.changeContactSorting}
          contactsPending={props.contactsPending}
        />
      </header>

      <AdrBookList
        list={props.ids}
        limit={ADR_BOOK_LIMIT}
        pending={props.contactsPending}
        loadMore={updateList}
        listItem={AdrBookItem}
        mode={includeUnsaved + '' + contactType + '' + search + '' + sortBy + '' + JSON.stringify(activeTags)}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  includeUnsaved: state.addressBook.includeUnsaved,
  contactType: state.addressBook.contactType,
  search: state.addressBook.search,
  sortBy: state.addressBook.sortBy,
  totalCount: state.addressBook.totalCount,
  activeTags: state.addressBook.tags.active,
  ids: selectAdrBookList(state),
  contactsPending: state.addressBook.contactsPending,
});

const mapDispatchToProps = {
  filterContactsForAdrBook,
  getContactsForAdrBook,
  changeContactSorting,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdrBookListWrap);