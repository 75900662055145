import React from 'react';
import { connect } from 'react-redux';

import { useDidMount } from 'hooks';
import { openModal, closeModal, MODAL_TYPES } from 'redux/ducks/activeWindows';
import { callToConference } from 'redux/ducks/twilio';

import './SidebarHeader.scss';
// import { EditContactFormNew } from 'containers/Dialogs/Dialogs';
import Title from 'components/UI/Title/Title';
import ICONS from 'assets/icons';
import Menu from 'components/Menu/Menu';

const SidebarHeader = (props) => {
  const menu = [];

  useDidMount(() => {
    // if (props.type === 'Rooms') {
    //   menu.push({
    //     content: 'Start new conference',
    //     // onMouseOver: console.log,
    //     // action: () => props.callToConference() //TODO v2
    //   })
    // }
    if (props.type === 'Clients' || props.type === 'Girls') {
      menu.push({
        content: 'Add new contact',
        // onMouseOver: () => EditContactFormNew, //TODO v2
        // action: () => props.openModal(MODAL_TYPES.addContactForm, {
        //   customSubmit: props.closeModal,
        //   isGirls: props.type === 'Girls',
        // }),
      })
    }
  });

  return (
    <div className="sidebar-header">
      <Title
        classPrefix="sidebar-header-title"
        icon={props.type === 'Clients' ? ICONS.addressCard1 : ICONS.venus}
        text={props.type}
      />

      <Menu
        iconSize={14}
        menuItems={menu}
        icon={ICONS.settings}
        dropWrapperProps={{
          placement: 'bottom-end'
        }}
      />
    </div>
  );
};


const mapDispatchToProps = {
  callToConference,
  openModal,
  closeModal,
};

export default connect(null, mapDispatchToProps)(SidebarHeader);