import React from 'react';
import './Title.scss';

const Title = ({ classPrefix, isShowIcon = true, icon: Icon, iconSize = 14, ...props }) => {
  const classNamePrefix = classPrefix || 'title';

  return (
    <h2 className={classNamePrefix}>
      {isShowIcon &&
        <div className={classNamePrefix + "__icon-wrap"}>
          {Icon && 
            <Icon color="#404040" width={iconSize} height={iconSize}/>
          }
        </div>
      }

      <span className={classNamePrefix + "__text"}>
        {props.text}
      </span>

      {!!props.count && !props.isShowCount &&
        <span className={classNamePrefix + "__count"}>
          {"(" + props.count + ")"}
        </span>
      }
    </h2>
  )
};

export default React.memo(Title);