import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import API from 'api/api';
import { classModifier, formatDateToTime, getDateByTimezoneOffset } from 'utils';
import { useDidMount, useWillUnmount } from 'hooks';
import { NOTIFICATION_TYPES } from 'redux/ducks/notifications';
import { updateActiveChat } from 'redux/ducks/roomChats';
import {
  showSessionFromHistory,
  updateActiveFilters,
  updateActiveSession
} from 'redux/ducks/sessions';
import { getContactsById } from 'redux/ducks/contacts';
import {
  selectRoomIdByOperatorId,
  selectTransferOrAssistanceForNotification,
  selectUserTimezone
} from 'redux/selectors/selectors';

import Spinner from '../../../components/UI/Spinner/Spinner';


const REQUEST_NOTIFICATIONS = [
  NOTIFICATION_TYPES.sessionAssistanceRequest,
  NOTIFICATION_TYPES.sessionTransferRequest,
];


const SessionNotification = props => {
  const {
    className = '',
    activeSession,
    operator,
    client,
    notification,
    session,
    roomId,
    transferOrAssistance,
  } = props;

  const {
    color,
    date_created,
    type,
  } = notification;
  const {
    session_id: sessionId,
    callerId,
    userId,
  } = notification.data;

  const navigate = useNavigate();
  const location = useLocation();

  const isSalesPage = location.pathname === '/sales';
  const date = getDateByTimezoneOffset(props.userTimezone, date_created);

  const [isReadPending, setReadPending] = useState(false);
  const [isRequestForContactSent, setRequestForContactSent] = useState(false);
  const [sessionState, setSessionState] = useState({ status: 'not-available', session: null });

  useDidMount(() => {
    if (!client) {
      props.getContactsById(callerId, 1)
        .then(() => setRequestForContactSent(true));
    }

    if (!session) {
      API.getSessionById(sessionId)
        .then(res => {
          setSessionState({ status: 'closed', session: res.data });
        });
    }
    else {
      setSessionState({ status: 'available' })
    }
  });

  useEffect(() => {
    if (notification.duration) {
      const interval = setInterval(() => {
        const diffInSeconds = (getDateByTimezoneOffset(props.userTimezone, new Date()) - date) / 1000;

        if (diffInSeconds > notification.duration) {
          clearInterval(interval);
          readNotification();
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  useEffect(() => {
    if (isRequest) {
      const isRequestReceivedResponse = type === NOTIFICATION_TYPES.sessionAssistanceRequest
        ? transferOrAssistance && !transferOrAssistance.includes(notification.user_id)
        : transferOrAssistance !== notification.user_id;

      if (isRequestReceivedResponse && !notification.viewed) {
        readNotification();
      }
    }
  }, [transferOrAssistance]);

  useWillUnmount(() => setReadPending(false));

  const getText = () => {
    switch (type) {
      case NOTIFICATION_TYPES.sessionView: {
        return 'is viewing your session ';
      }
      case NOTIFICATION_TYPES.sessionProposed: {
        return 'made propose in your session ';
      }
      case NOTIFICATION_TYPES.sessionAssistanceRequest: {
        return 'is asking for assistance in session ';
      }
      case NOTIFICATION_TYPES.sessionAssistanceDelete: {
        return 'removed you from session ';
      }
      case NOTIFICATION_TYPES.sessionAssistanceResponse: {
        return notification.data.isAccept
          ? 'accepted assistance request '
          : 'rejected assistance request ';
      }
      case NOTIFICATION_TYPES.sessionTransferRequest: {
        return 'wants transfer to you a session ';
      }
      case NOTIFICATION_TYPES.sessionTransferResponse: {
        return notification.data.isAccept
          ? 'accepted transfer request '
          : 'rejected transfer request ';
      }
      default:
        return type;
    }
  };

  const clientAction = () => {
    if (sessionState.status === 'not-available') return;

    if (sessionState.status === 'available') {
      const isSessionNotActive = activeSession !== sessionId;
      const isProposed = type === NOTIFICATION_TYPES.sessionProposed && operator;

      if (isSessionNotActive) {
        props.updateActiveSession(sessionId)
      }
      if (isProposed) {
        const filter = `Proposed:${operator.username}:${userId}`;

        props.updateActiveFilters(filter, sessionId, [], true);
      }
    }
    else {
      props.showSessionFromHistory(sessionState.session);
    }

    props.isNewNotification && readNotification()
    props.toggleOpen && props.toggleOpen(false);

    if (!isSalesPage) {
      navigate('/sales');
    }
  };

  const operatorAction = () => {
    if (!operator) return;

    if (isSalesPage) {
      navigate('/');
    }

    props.updateActiveChat(roomId);
    props.isNewNotification && readNotification();
  };

  const readNotification = () => {
    setReadPending(true);
    props.readNotification(notification.id);
  };

  const replyToRequest = (status) => {
    let reply;

    switch (type) {
      case NOTIFICATION_TYPES.sessionAssistanceRequest:
        reply = API.responseForAddToAssistant;
        break;
      case NOTIFICATION_TYPES.sessionTransferRequest:
        reply = API.responseForTransferSession;
        break;
    }

    readNotification();
    reply(sessionId, status);
  };

  const isRequest = REQUEST_NOTIFICATIONS.includes(type)

  if (!client && !isRequestForContactSent) {
    return <div className={classModifier('notification__pending', color)}>
      <Spinner spinnerSize={30} />
    </div>
  }

  return (
    <div
      className={`${className} ${classModifier('notification__item', [
        color,
        notification.viewed && 'viewed',
        isRequest && 'request',
        props.isNewNotification && 'new',
        (props.isNewNotification && color) && `new-${color}`
      ])}`}
    >
      <div className="notification__item-text-wrap">
        <div className="notification__item-text">
          <span>Your partner</span>

          <button
            className="notification__link"
            onClick={operatorAction}
            disabled={!operator}
          >
            {operator
              ? operator.username
              : 'DELETED OPERATOR'
            }
          </button>

          <span>{getText()} with &nbsp;</span>

          <button
            className="notification__link notification__link--session-contact"
            onClick={clientAction}
            disabled={sessionState.status === 'not-available'}
          >
            {client
              ? client.fn
              : 'DELETED CONTACT'
            }
          </button>
        </div>
      </div>

      <div className="notification__additional-info-wrap">
        <div className="notification__item-date">
          <span>{props.getDate(date)}</span>
          <span>{formatDateToTime(date, props.userHour12, false)}</span>
        </div>

        {!notification.viewed &&
          <button
            className="notification__read"
            onClick={readNotification}
            disabled={isReadPending}
          >
            Read
          </button>
        }

        {isRequest &&
          <div className='notification__request'>
            <button
              className="notification__request-btn notification__request-btn--accept"
              onClick={() => replyToRequest('accept')}
              disabled={!!notification.viewed}
            >
              Accept
            </button>

            <button
              className="notification__request-btn notification__request-btn--cancel"
              onClick={() => replyToRequest('ignore')}
              disabled={!!notification.viewed}
            >
              Reject
            </button>
          </div>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  userTimezone: selectUserTimezone(state),
  activeSession: state.sessions.activeSession,
  operator: state.operators.entities[ownProps.notification.data.userId],
  roomId: selectRoomIdByOperatorId(state, ownProps.notification.data.userId),
  client: state.contacts.entities[ownProps.notification.data.callerId],
  session: state.sessions.entities[ownProps.notification.data.session_id],
  transferOrAssistance: selectTransferOrAssistanceForNotification(state, ownProps.notification.id),
  userHour12: state.user.hour12,
});

const mapDispatchToProps = {
  updateActiveChat,
  updateActiveFilters,
  updateActiveSession,
  getContactsById,
  showSessionFromHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionNotification);