import {
  useReducer,
  useCallback,
  useEffect,
  useRef,
} from 'react';

const useThunkReducer = (reducer, initialState, reducerName) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const prevState = useRef(state);

  useEffect(() => {
    const { action, state: previousState } = prevState.current;

    if (action && reducerName) {
      console.group(`${action.type}`);
      console.log('%c prev state', 'font-weight: 700; color: #9E9E9E', previousState);
      console.log('%c action', 'font-weight: 700; color: #6592fc', action)
      console.log('%c next state', 'font-weight: 700; color: #47B04B', state);
      console.groupEnd();
    }

    prevState.current = { ...prevState.current, state }
  }, [state])

  const thunkDispatch = useCallback(
    (action) => {
      if (typeof action === 'function') {
        return action(thunkDispatch);
      } else {
        prevState.current = { ...prevState.current, action };
        return dispatch(action);
      }
    }, []);

  return [state, thunkDispatch];
}

export default useThunkReducer;