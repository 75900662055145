import convertDateFromUTC from "./convertDateFromUTC";
import getDateByTimezoneOffset from "./getDateByTimezoneOffset";


const convertTimeToAMPM = (date, hour12 = false, locales) =>
  (typeof date === 'string'
    ? convertDateFromUTC(date)
    : new Date(date)
  )
    .toLocaleString(locales, { hour: 'numeric', minute: 'numeric', hour12 });


const getFormattedDate = ({ 
    dateWithoutTimezoneOffset, 
    hour12, 
    userTimezone,
    day = 'numeric',
    weekday = 'long',
    locales = 'en-US',
    month = 'numeric' }) => {
    
  let date = getDateByTimezoneOffset(userTimezone, dateWithoutTimezoneOffset);

  const oneDay = 1000 * 60 * 60 * 24; // in ms
  const today = new Date().setHours(0, 0, 0, 0);
  let formattedDate;

  if (typeof date === 'string') {
    date = convertDateFromUTC(date);
  }
  else {
    date = new Date(date); // if it's number, that create date, if Date keep it unchanged
  }

  const onlyDate = new Date(date).setHours(0, 0, 0, 0);

  if (onlyDate === today) {
    formattedDate = 'Today';
  }
  else if (onlyDate === today - oneDay) {
    formattedDate = 'Yesterday';
  }
  else if (onlyDate < today && today - onlyDate < oneDay * 6) {
    formattedDate = date.toLocaleString(locales, { weekday }); // last 6 days
  }
  else {
    formattedDate = date.toLocaleString(locales, { day, month });
  }

  return { time: convertTimeToAMPM(date, hour12, locales), date: formattedDate};
}
export default getFormattedDate;