import API from 'api/api';
import { LS } from 'utils';

import { CHAT_TYPES } from 'config/constants';
import { getConnectedContacts, getAllCalls } from './calls';
import { initUnfixedClientTab } from './clientChats';
import { initUnfixedRoomTab, setGirlsTodayMsgCount } from './roomChats';
import { getInitialDataForRooms, getRoomsFromTabs } from './rooms';
import { getAllTemplates, getMsgShortcuts } from './msgTemplates';
import { getAllSessions, checkExistingOfActiveSession } from "./sessions";
import { openModal, MODAL_TYPES } from './activeWindows';
import { getAllConferences } from './conferences';
import { initUnfixedGirlTab } from './girlChats';
import { updateNotificationsVolume, NOTIFICATIONS_VOLUME_TYPE } from './settings';
import { getWebrtcConferences } from './webrtc';
import { getAllRequirements } from './bookings'
import { setAllVoicemailsCount } from './missedCalls';
import { setNotificationsUnreadCount } from './notifications';
import { setMergeContactsCount, setNightBotUnviewedSessionsCount } from './counters';
import {
  getAllContactsList,
  getContactsById,
  updateRecentTabs,
  setContactsAndUnreadMessagesCount
} from './contacts';

export const SET_USER_INFO = 'SET_USER_INFO';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SALES_FILTERS = 'UPDATE_USER_SALES_FILTERS';
export const UPDATE_USER_TIME_FORMAT = 'UPDATE_USER_TIME_FORMAT';

export const SAVED_SESSIONS_FILTERS = {
  exclusive: { name: 'exclusive', filterType: 'other-params' },
  new: { name: 'new', filterType: 'other-params' },
  recommended: { name: 'recommended', filterType: 'other-params' },
  location: { name: 'location', filterType: 'other-params' },
  nearby: { name: 'nearby', filterType: 'other-params' },
  price: { name: 'price', filterType: 'other-params' },
  offer: { name: 'offer', filterType: 'other-params' },
  '30min': { name: '30min', filterType: 'other-params' },
  mistress: { name: 'mistress', filterType: 'other-params' },
  international: { name: 'international', filterType: 'other-params' },
  shemale: { name: 'shemale', filterType: 'other-params' },
  incall: { name: 'incall', filterType: 'other-params' },
  outcall: { name: 'outcall', filterType: 'other-params' },
  natural: { name: 'natural', filterType: 'other-params' },
  enhanced: { name: 'enhanced', filterType: 'other-params' },
  'willingtosee-black': { name: 'willingtosee-black', filterType: 'other-params' },
  'willingtosee-romanian': { name: 'willingtosee-romanian', filterType: 'other-params' },
  'profile-smoker': { name: 'profile-smoker', filterType: 'other-params' },
  'profile-non-smoker': { name: 'profile-non-smoker', filterType: 'other-params' },
  tattoo: { name: 'tattoo', filterType: 'other-params' },
  notattoo: { name: 'notattoo', filterType: 'other-params' },
  male: { name: 'male', filterType: 'other-params' },
  female: { name: 'female', filterType: 'other-params' },
  duo: { name: 'duo', filterType: 'other-params' },
}

export const SAVED_SERVICES_FILTERS_LENGTH =
  Object.keys(SAVED_SESSIONS_FILTERS).filter(key => SAVED_SESSIONS_FILTERS[key].filterType === 'services').length;

export const SAVED_PARAMS_FILTERS_LENGTH =
  Object.keys(SAVED_SESSIONS_FILTERS).filter(key => SAVED_SESSIONS_FILTERS[key].filterType === 'other-params').length;

export const initApp = (data) => async dispatch => {
  const userStorage = LS.getUserStorage(data.id);

  const clientChatsRecentTabs = userStorage['clientChatsRecentTabs'];
  const girlChatsRecentTabs = userStorage['girlChatsRecentTabs'];
  const roomChatsRecentTabs = userStorage['roomChatsRecentTabs'];

  if (!userStorage['hour12']) {
    LS.setItem('hour12', false, data.id);
  }

  // if (userStorage['defaultSession'] && !userStorage['defaultSession'].additionalFilters) {
  //   LS.setItem('defaultSession', { ...userStorage['defaultSession'], additionalFilters: {} }, data.id);
  // }

  dispatch({
    type: SET_USER_INFO,
    payload: {
      ...data,
      showUserModesAtLogin: userStorage['showUserModesAtLogin'],
      hour12: userStorage['hour12'],
    }
  });

  if (localStorage.isLoginAction) { // do some logic exactly after login
    if (userStorage['showUserModesAtLogin']) {
      setTimeout(() => dispatch(openModal(MODAL_TYPES.userSettings)), 1000);
    }
    delete localStorage.isLoginAction;
  }

  //set initial notifications volume
  const notificationsVolume = userStorage[NOTIFICATIONS_VOLUME_TYPE.ALL];
  (notificationsVolume || notificationsVolume === 0)
    && dispatch(updateNotificationsVolume(notificationsVolume, NOTIFICATIONS_VOLUME_TYPE.ALL, data.id));

  if (userStorage['unfixedClientTab']) {
    dispatch(initUnfixedClientTab(userStorage['unfixedClientTab']));
  }
  if (userStorage['unfixedGirlTab']) {
    dispatch(initUnfixedGirlTab(userStorage['unfixedGirlTab']));
  }
  if (userStorage['unfixedChatTab']) {
    dispatch(initUnfixedRoomTab(userStorage['unfixedChatTab']));
  }
  if (clientChatsRecentTabs) {
    dispatch(updateRecentTabs(clientChatsRecentTabs, CHAT_TYPES.CLIENT));
    dispatch(getContactsById(clientChatsRecentTabs.all, CHAT_TYPES.CLIENT, 'recent-tabs', null, data.id));
  }
  if (girlChatsRecentTabs) {
    dispatch(updateRecentTabs(girlChatsRecentTabs, CHAT_TYPES.GIRL));
    dispatch(getContactsById(girlChatsRecentTabs.all, CHAT_TYPES.GIRL, 'recent-tabs', null, data.id));
  }
  if (roomChatsRecentTabs) {
    dispatch(updateRecentTabs(roomChatsRecentTabs));
    dispatch(getRoomsFromTabs(roomChatsRecentTabs.all, null, data.id));
  }

  dispatch(getAllCalls());
  dispatch(getAllConferences());
  dispatch(getWebrtcConferences());
  dispatch(getConnectedContacts());

  dispatch(getAllContactsList());

  dispatch(getAllRequirements());

  dispatch(setGirlsTodayMsgCount(data.timezone.offset_minutes));

  // get data for clientChat
  if (userStorage['cTabs'] && userStorage['cTabs'].length) {
    dispatch(getContactsById(userStorage['cTabs'], CHAT_TYPES.CLIENT, 'tabs', userStorage['aClient']));
  }

  // get data for girlChat
  if (userStorage['gTabs'] && userStorage['gTabs'].length) {
    dispatch(getContactsById(userStorage['gTabs'], CHAT_TYPES.GIRL, 'tabs', userStorage['aGirl']));
  }

  // get data for publicChat
  await dispatch(getInitialDataForRooms(data.id));

  dispatch(getAllTemplates());
  dispatch(getMsgShortcuts());

  dispatch(getAllSessions(data.id, userStorage['aSession']/* , userStorage['defaultSession'] */)) //TODO
  // .then(() => {
  //   dispatch(checkExistingOfActiveSession()); // history session may not exist when page is updated
  // });

  API.getCounters()
    .then(({ data }) => {
      dispatch(setAllVoicemailsCount(data.voicemails));
      dispatch(setContactsAndUnreadMessagesCount(data));
      dispatch(setMergeContactsCount(data.mergeContacts));
      dispatch(setNotificationsUnreadCount(data.unreadNotifications));
      dispatch(setNightBotUnviewedSessionsCount(data.nightBotUnviewedSessions));
    })
    .catch(console.error);
};

export const setUserInfo = user => dispatch => {
  dispatch({
    type: SET_USER_INFO,
    payload: user
  });
};

export const updateUser = (user) => dispatch => {
  dispatch({
    type: UPDATE_USER,
    payload: user
  });
};

export const updateUserSalesFilters = (filters) => dispatch => {
  return API.updateUserSalesFilters(filters)
    .then(res => {
      const filters = res.data;

      dispatch({
        type: UPDATE_USER_SALES_FILTERS,
        payload: filters
      });
    })
    .catch(console.log);
};

export const updateUserTimeFormat = (hour12, userId) => dispatch => {
  LS.setItem("hour12", hour12, userId);

  dispatch({
    type: UPDATE_USER_TIME_FORMAT,
    payload: hour12
  });
}

const initialState = {};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_USER:
    case SET_USER_INFO: {
      const { settings, ...user } = payload;

      const updatedSalesFilters = { ...SAVED_SESSIONS_FILTERS, ...payload.salesFilters };

      const servicesIds = [];
      const paramsIds = [];

      for (const key in updatedSalesFilters) {
        if (updatedSalesFilters[key].filterType) {
          if (updatedSalesFilters[key].filterType === 'services') {
            servicesIds.push(key);
          }
          else if (updatedSalesFilters[key].filterType === 'other-params') {
            paramsIds.push(key);
          }
        }
      }

      return {
        ...state,
        ...user,
        salesFilters: updatedSalesFilters,
        salesServicesIds: servicesIds,
        salesParamsIds: paramsIds,
      }
    }

    case UPDATE_USER_SALES_FILTERS: {
      const servicesIds = [];
      const paramsIds = [];

      for (const key in payload) {
        if (payload[key].filterType) {
          if (payload[key].filterType === 'services') {
            servicesIds.push(key);
          }
          else if (payload[key].filterType === 'other-params') {
            paramsIds.push(key);
          }
        }
      }

      return {
        ...state,
        salesFilters: payload,
        salesServicesIds: servicesIds,
        salesParamsIds: paramsIds,
      };
    }

    case UPDATE_USER_TIME_FORMAT: {
      return {
        ...state,
        hour12: payload,
      };
    }

    default:
      return state;
  }
};