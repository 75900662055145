import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { selectUserTimezone } from 'redux/selectors/selectors';
import { getDateByTimezoneOffset } from 'utils';
import './DateTimePicker.scss';
import Calendar from 'components/Calendar/Calendar';
import TimeSelect from "components/TimeSelect/TimeSelect";


const DateTimePicker = (props) => {
  const {
    timeIntervals = 30,
    onSelectDate,
    onCancel,
    initDate,
    maxDate,
    minDate,
  } = props;

  const userTimezone = useSelector(selectUserTimezone);

  const [date, setDate] = useState(getDateByTimezoneOffset(userTimezone, initDate));
  const [timeOption, setTimeOption] = useState(null);

  const getDateWithTime = useCallback((date, time) => {
    const newDate = new Date(date);
    const [hours, minutes] = time.split(':');

    newDate.setHours(hours, minutes, 0);

    return newDate - (userTimezone - (new Date().getTimezoneOffset() * (-1))) * 60000;
  }, [userTimezone]);

  const handleSelectDate = () => {
    onSelectDate(getDateWithTime(date, timeOption.value));
  };

  const getMinTime = () => {
    if (!minDate) return;

    const d = new Date(minDate);
    const isCurrentDay = new Date(date).getDate() === d.getDate();

    if (!isCurrentDay) return;

    const hours = String(d.getHours()).length === 1
      ? '0' + d.getHours()
      : d.getHours();
    const mins = String(d.getMinutes()).length === 1
      ? '0' + d.getMinutes()
      : d.getMinutes();

    return hours + ':' + mins;
  }

  return (
    <div className='date-time-picker'>
      <Calendar
        onChange={setDate}
        value={date}
        maxDate={maxDate ? new Date(maxDate) : null}
        minDate={minDate ? new Date(minDate) : null}
      />

      <TimeSelect
        currentOption={timeOption}
        onChange={setTimeOption}
        timeIntervals={timeIntervals}
        minTime={getMinTime()}
        initDate={initDate}
      />

      <div className="date-time-picker__actions">
        <button
          className="date-time-picker__cancel"
          onClick={onCancel}
          type="button"
        >
          Cancel
        </button>

        <button
          className="date-time-picker__submit"
          onClick={handleSelectDate}
          disabled={!date || !timeOption}
          type="button"
        >
          Set
        </button>
      </div>
    </div>
  );
};

export default DateTimePicker;
