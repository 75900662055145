import { useState } from 'react';
import classNames from 'classnames';
import { usePopperTooltip } from 'react-popper-tooltip';

import './IconMenu.scss';
import DropdownMenu from 'components/DropdownMenu/DropdownMenu';

const IconMenu = ({
    icon: Icon,
    items = [],
    disabled,
    tooltipOptions={},
    popperOptions,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    setTriggerRef,
    setTooltipRef,
    getTooltipProps,
  } = usePopperTooltip(
    {
      trigger: 'click',
      visible: isOpen,
      onVisibleChange: setIsOpen,
      ...tooltipOptions,
    },
    popperOptions,
  );

  return (
    <>
      <button
        className={classNames('icon-menu', isOpen && 'menu-open')}
        ref={setTriggerRef}
        disabled={disabled}
        type="button"
      >
        <Icon />
      </button>

      {isOpen &&
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'icon-menu-popup' })}
        >
          <DropdownMenu
            items={items}
            closeDropdownMenu={() => setIsOpen(false)}
          />
        </div>
      }
    </>
  );
};

export default IconMenu;
