import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { Field } from 'react-final-form';
// import PropTypes from 'prop-types';

import { useToggle } from 'hooks';
import getCroppedImg from 'utils/cropImage';

import Toggle from './Toggle/Toggle';
import Slider from './AudioPlayer/Slider';
import ImgInput from './ImgInput/ImgInput';

const SHAPE = {
  round: 'round',
  rect: 'rect'
};


const ImgCropper = (props) => {
  const {
    photo,
    aspect,
    setPhoto,
    cropSubmit,
    isDisabled,
    classNamePrefix,
    toggleAvaCropper,
  } = props;

  const [thumbWasMoved, setThumbWasMoved] = useState();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [cropShape, toggleCropShape] = useToggle(true);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, pixels) => {
    setCroppedAreaPixels(pixels);
  }, []);

  const onUpdate = (z) => {
    setZoom(z + 1);
    if (z + 1 !== zoom) {
      setThumbWasMoved(!thumbWasMoved);
    }
  };

  const onCropSubmitHandler = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(photo, croppedAreaPixels);

      cropSubmit(croppedImage);
    }
    catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const changeContactAvatar = (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      if (file.type.startsWith('image')) {
        setPhoto(file);
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className={`${classNamePrefix}__ava-cropper-wrap`} onClick={e => e.stopPropagation()}>
      <section className={`${classNamePrefix}__ava-cropper`}>
        <Cropper
          name="image"
          image={photo}
          crop={crop}
          zoom={zoom}
          showGrid={!isDisabled}
          aspect={aspect}
          cropShape={cropShape ? SHAPE.round : SHAPE.rect}
          onCropChange={(crop) => isDisabled ? false : setCrop(crop)}
          onCropComplete={onCropComplete}
          onZoomChange={(z) => isDisabled ? false : onUpdate(z - 1)}
        />
      </section>

      {!isDisabled &&
        <div className={`${classNamePrefix}__ava-cropper-options-bar`}>
          <Slider
            onUpdate={onUpdate}
            countOfSteps={2}
            currentStep={zoom - 1}
            thumbWasMoved={thumbWasMoved}
          />

          <div className={`${classNamePrefix}__toggle-wrap`}>
            <span className={`${classNamePrefix}__toggle-title`}>{cropShape ? SHAPE.round : SHAPE.rect}</span>

            <Toggle
              className={`${classNamePrefix}__ava-cropper-toggler`}
              isActive={cropShape}
              onToggle={() => toggleCropShape()}
            />
          </div>
        </div>
      }

      <nav className={`${classNamePrefix}__ava-cropper-navigation-bar`}>
        <label
          htmlFor="btn-file-add"
          className={`${classNamePrefix}__ava-cropper-btn-file-add ava-cropper-btn`}
        >
          Choose photo
        </label>

        <Field
          id="btn-file-add"
          name="image"
          component={ImgInput}
          className="edit-contact-form__file"
          onChangeHandler={changeContactAvatar}
        />

        <button
          onClick={onCropSubmitHandler}
          type="button"
          disabled={isDisabled}
          className={`${classNamePrefix}__ava-cropper-btn-file-crop ava-cropper-btn`}
        >
          Crop and save
        </button>

        <button
          className={`${classNamePrefix}__ava-cropper-btn-file-submit ava-cropper-btn`}
          type="button"
          onClick={() => toggleAvaCropper()}
        >
          Save
        </button>
      </nav>
    </div>
  );
};

// ImgCropper.propTypes = {
//   classNamePrefix: PropTypes.string.isRequired,
//   toggleAvaCropper: PropTypes.func.isRequired,
//   photo: PropTypes.string.isRequired,
//   setPhoto: PropTypes.func.isRequired,
//   cropSubmit: PropTypes.func.isRequired,
//   aspect: PropTypes.number.isRequired,
// }

export default ImgCropper;
