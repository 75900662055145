import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { connect } from 'react-redux';

import API from 'api/api';
import { v4 as uuidv4 } from 'uuid';
import { CHAT_TYPES, CONTACT_TYPES } from 'config/constants';
import { useCancelToken, useDidMount, useDidUpdate, useToggle } from 'hooks';
import { classModifier, getIsFieldEmpty, isEmptyObj } from 'utils';
import { addDivaGirlsToEntities } from 'redux/ducks/divaGirls';
import { toggleCreateContactForm, updateActiveAdrBookContactId } from 'redux/ducks/addressBook';
import { 
  addAgentData, 
  addNewContact, 
  updateContact,
  removeAgentData, 
  updateAgentsGirlIds
 } from 'redux/ducks/contacts';
import { selectAgentContactById, selectContactById } from 'redux/selectors/selectors';

import './AdrBookContactForm.scss';
import AdrBookContactFormAutoSave from './AdrBookContactFormAutoSave';
import ViaItems from 'components/ContactFormItems/ViaItems/ViaItems';
import TagsField from 'components/ContactFormItems/TagsField';
import PhoneNumbersField from 'components/ContactFormItems/PhoneNumbersField/PhoneNumbersField';
import EmailsField from 'components/ContactFormItems/EmailsField/EmailsField';
import LockForm from 'components/LockForm/LockForm';
import CopyToClipboardButton from 'components/CopyToClipboardButton/CopyToClipboardButton';
import AdrBookContactFormHeader from '../AdrBookContactFormHeader/AdrBookContactFormHeader';
import AdrBookContactFormTabs from '../AdrBookContactFormTabs/AdrBookContactFormTabs';
import NotForField from 'components/ContactFormItems/NotForField/NotForField';

const AdrBookContactForm = (props) => {
  const {
    active,
    callbacksRef,
    isNewContactCreation,
  } = props;

  const [editedContact, setEditedContact] = useState(props.editedContact || { callerTags: [] });
  const [historyStateList, setHistoryStateList] = useState([props.editedContact]);

  const [initialValues, setInitialValues] = useState({});
  const [photo, setPhoto] = useState({ url: null, file: null });
  const [phoneLabels, setPhoneLabels] = useState([]);
  const [linksLabels, setLinksLabels] = useState([]);
  const [initialTags, setInitialTags] = useState([]);
  const [editedContactTags, setEditedContactTags] = useState([]);

  const [serverError, setServerError] = useState({});
  const [pending, setPending] = useState(false);

  const [newContactTags, setNewContactTags] = useState([]);
  const [feedbackList, setFeedbackList] = useState([]);
  const [newContactAdr, setNewContactAdr] = useState('');
  const [isCreatedNewContact, setIsCreatedNewContact] = useState(false);

  const refId = useRef(active);

  const [avaCropper, toggleAvaCropper] = useToggle(false);
  const { newCancelToken, cancelPrevRequest } = useCancelToken();

  const location = useLocation();
  const telFromDialPad = location.state?.telFromDialPad;

  const [lastStateListItem] = historyStateList.slice(-1);

  useEffect(() => {
    props.setUndoData({
      pending,
      historyStateListLength: historyStateList.length,
      lastStateListItemId: lastStateListItem?.id,
      isCreatedNewContact
    })
  }, [
    pending,
    isCreatedNewContact,
    historyStateList.length,
    lastStateListItem?.id
  ]);

  useImperativeHandle(callbacksRef, () => ({
    setPrevState
  }))

  useDidMount(() => {
    Promise.all([
      API.getPhoneLabels(),
      API.getLinksLabels()
    ])
    .then(results => {
      const loadedTelLabels = results[0].data;
      const loadedLinksLabels = results[1].data;

      let telsOptions = [];
      let labelsOptions = [];

      let defaultTelLabel = '';

      if (loadedTelLabels.length) {
        loadedTelLabels.map(label => {
          if (label) telsOptions.push({ value: label, label})
        });

        defaultTelLabel = loadedTelLabels[0];
      }
      else {
        telsOptions.push({ value: 'default', label: 'default' });
        defaultTelLabel = 'default';
      }

      if (loadedLinksLabels.length) {
        loadedLinksLabels.map(label => {
          if (label) labelsOptions.push({ value: label, label})
        });
      }
      else {
        labelsOptions.push({ value: 'default', label: 'default' });
      }

      setPhoneLabels(telsOptions);
      setLinksLabels(labelsOptions);

      updateFormContact(defaultTelLabel);
    })
    .catch(err => console.log(err));
  });

  useDidUpdate(() => {
    if(!isNewContactCreation && !avaCropper && photo.file) {
      saveContact({
        ...editedContact,
        image: photo.file
      })
    }
  }, [avaCropper]);

  useDidUpdate(() => {
    if(refId.current && active !== refId.current) {
      setIsCreatedNewContact(false);
    }
  }, [active]);

  useDidUpdate(() => {
    if (isNewContactCreation) {
      initialize({});
      setFeedbackList([]);
      setNewContactTags([]);
      setPhoto({ url: null, file: null });
    } else {
      setPhoto({ 
        url: props.editedContact?.photo ? props.editedContact.photo.src : null, 
        file: null 
      });

      initialize(props.editedContact);
      setEditedContact(props.editedContact);
      setHistoryStateList([props.editedContact]);
    }

    !isEmptyObj(serverError) && setServerError({});
  }, [active, isNewContactCreation]);

  useDidUpdate(() => {
    refId.current = active;
  }, [active]);

  useEffect(() => {
    const tags = editedContact.callerTags?.map(tag => tag.title) || [];

    setInitialTags(tags);
    setEditedContactTags(tags);
  }, [editedContact.callerTags]);

  useEffect(() => {
    if (editedContact.type === CONTACT_TYPES.GIRL && editedContact.id && editedContact.diva_default_id) {
      API.getDivaGirlsByContactsIds(editedContact.id)
        .then(res => {
          props.addDivaGirlsToEntities([res.data]);
        })
        .catch(console.error);
    }
  }, [editedContact.type, editedContact.id, editedContact.diva_default_id])

  const initialize = (editedContact, defaultLabel = 'default') => {
    let addingFields = {
      type: isNewContactCreation ? "1" : String(editedContact.type),
      is_banned_for_media: isNewContactCreation ? false : editedContact.is_banned_for_media,
      addresses: editedContact.addresses || [],
    }

    if(telFromDialPad) {
      addingFields.tels = [{
        uuid: uuidv4(),
        default: 1,
        labelName: "default",
        tel: telFromDialPad,
      }];
      delete location.state;
    }

    const setInitial = (addingFields) => {
      isNewContactCreation
        ? setInitialValues({ ...addingFields })
        : setInitialValues({
          ...editedContact,
          ...addingFields,
          note: editedContact.note || '',
          addresses: editedContact.addresses || [],
        });
    };

    if (isNewContactCreation) {
      addingFields.type = String(CHAT_TYPES.CLIENT);

      return setInitial(addingFields);
    }

    if (editedContact.type === 2) {
      addingFields.agent = props.defaultAgent;

      return setInitial(addingFields);
    }

    setInitial(addingFields);
  }

  const updateFormContact = (defaultLabel) => {
    if (!isNewContactCreation) {
      setNewContactAdr(editedContact.addresses || []);
    }

    initialize(editedContact, defaultLabel);
  }

  const saveContact = (values, undo = false) => {
    setPending(true);
    isCreatedNewContact && setIsCreatedNewContact(false);

    if (isNewContactCreation) {
      props.addNewContact({
        ...values,
        image: photo.file,
        callerTags: newContactTags,
        // "notes" param was changed 17.03.2022 from String to Array for support
        // multiple feedbacks with new contact; and also requires back-end changes.
        // When new contact opened, feedbackList items contains "tempId"
        // (generated on front-end). And "tempId" should be replaced with "id"
        // after first sending to back-end
        notes: feedbackList,
        adr: newContactAdr,
      })
        .then((res) => {
          setPending(false);
          setIsCreatedNewContact(true);
          props.updateActiveAdrBookContactId(res.data?.id);
        })
        .catch(err => {
          setPending(false);
          setServerError(JSON.parse(err.response.data.message));
        })

      return;
    }

    if (editedContact.type === 3 && editedContact.type !== +values.type) {
      props.removeAgentData(editedContact);
    }
    else if (+values.type === 3 && editedContact.type !== +values.type) {
      props.addAgentData(editedContact.id);
    }

    if (values.agent && values.agent.id && editedContact.agentId !== values.agent.id) { // if we change agentId
      props.updateAgentsGirlIds(editedContact.agentId, values.agent.id, editedContact.id);
    }

    cancelPrevRequest();

    const params = {
      ...values, 
      image: undo ? values.photo?.src : photo.file || values.photo?.src,
      addresses: values?.addresses || [],
      callerTags: undo ? values.callerTags?.map(tag => tag.title) || [] : editedContactTags,
    }

    props.updateContact(params, editedContact.type, newCancelToken())
      .then(res => {
        setPending(false);

        if(undo) {
          const contactUrl = res.data.photo ? res.data.photo.src : null;

          setHistoryStateList(prev => prev.slice(0, -1));
          initialize(res.data);
          setEditedContact(res.data);
          setPhoto({ url: contactUrl, file: null });
        } else {
          if(active === refId.current) {
            setPhoto({ ...photo, file: null });
            setHistoryStateList([...historyStateList, res.data]);
            setEditedContact(res.data);
          }
        }
      })
      .catch(err => {
        setPending(false);

        err.__CANCEL__ && setPending(true);
        err.response && setServerError(JSON.parse(err.response.data.message));
      })
  };

  // const handleCancelButton = (form, pristine) => {
  //   if (!isNewContactCreation && active) { //if edit
  //     !pristine && form.reset();

  //     setPhoto({ file: null, url: null });
  //     setNoteData(editedContact.note || '');
  //     setEditedContactTags(() => editedContact?.callerTags?.length
  //       ? editedContact.callerTags.map((tag) => tag.title)
  //       : []
  //     );
  //   }
  //   else {
  //     props.toggleCreateContactForm(false)
  //   }
  // }

  const isEditedTags = useMemo(() => (!isNewContactCreation && 
    JSON.stringify(initialTags.slice().sort()) !== JSON.stringify(editedContactTags.slice().sort())
  ), [initialTags, editedContactTags]);
  
  const isEditedTelLabel = (values) => !isNewContactCreation && 
    values.tels?.some((obj, idx) => obj?.labelName !== lastStateListItem?.tels[idx]?.labelName);

  const isFieldEdited = (values, initialValues) => {
    return !isNewContactCreation &&
      !(values === undefined && initialValues === '') &&
      initialValues !== undefined &&
      initialValues !== values;
  }

  const setPrevState = () => {
    const [penultimateStateItem] = historyStateList.slice(-2, -1);

    saveContact(penultimateStateItem, true);
  };

  console.log('AdrBookContactForm render')

  return (
    <Form
      onSubmit={saveContact}
      validate={validate}
      initialValues={initialValues}
      mutators={arrayMutators}
      render={({ handleSubmit, form, values, dirtyFields }) => {
        return (
          <form onSubmit={handleSubmit} className="adr-book-contact-form">
            <AdrBookContactFormAutoSave
              isPhotoFile={photo.file}
              isEditedTags={isEditedTags}
              callbacksRef={callbacksRef}
              lastStateListItem={lastStateListItem}
              saveContact={() => saveContact(values)}
              isNewContactCreation={isNewContactCreation}
            />

            <AdrBookContactFormHeader 
              photo={photo}
              active={active}
              values={values}
              setPhoto={setPhoto}
              setValues={form.change}
              avaCropper={avaCropper}
              saveContact={saveContact}
              serverError={serverError}
              dirtyFields={dirtyFields}
              editedContact={props.editedContact}
              toggleAvaCropper={toggleAvaCropper}
              isNewContactCreation={isNewContactCreation}
            />
    
            <div className="adr-book-contact-form__content">
              <div className='adr-book-contact-form__form'>
                <div className={classModifier("adr-book-contact-form__field", "tags")}>
                  <h3 className="adr-book-contact-form__field-title">
                    Tags
                  </h3>
      
                  <TagsField
                    values={values}
                    saveContact={saveContact}
                    initialTags={initialTags}
                    isEditedTags={isEditedTags}
                    editedContactTags={editedContactTags}
                    setEditedContactTags={setEditedContactTags}
                    newContactTags={newContactTags}
                    setNewContactTags={setNewContactTags}
                    isNewContactCreation={isNewContactCreation}
                  />
                </div>

                <div className={classModifier("adr-book-contact-form__field", "via")}>
                  <h3 className={classModifier("adr-book-contact-form__field-title", "via")}>
                    Contact only via:
                  </h3>

                  <ViaItems 
                    telsValues={values.tels}
                    emailsValues={values.emails}
                  />  
                </div>
      
                <div className={classModifier("adr-book-contact-form__field", "phone-numbers")}>
                  <h3 className={classModifier("adr-book-contact-form__field-title", "phone-numbers")}>
                    Phone number
                  </h3>

                  <PhoneNumbersField
                    values={values}
                    isEditedTelLabel={isEditedTelLabel(values)}
                    saveContact={saveContact}
                    classPrefix="adr-book-contact-form"
                    phoneLabels={phoneLabels}
                    serverError={serverError.tels}
                    setServerError={setServerError}
                    isNewContactCreation={isNewContactCreation}
                    isInAdrBook
                  />
                </div>

                <div className={classModifier("adr-book-contact-form__field", "email")}>
                  <h3 className={classModifier("adr-book-contact-form__field-title", "email")}>Email</h3>

                  <EmailsField
                    classPrefix="adr-book-contact-form"
                    phoneLabels={phoneLabels}
                    serverError={serverError.emails}
                    setServerError={setServerError}
                    isNewContactCreation={isNewContactCreation}
                    isInAdrBook
                  />
                </div>

                {+values.type === CONTACT_TYPES.CLIENT && 
                  <>
                    <NotForField 
                      values={values}
                      saveContact={saveContact}
                      callerId={editedContact.id}
                      isNewContactCreation={isNewContactCreation}
                      classPrefix="adr-book-contact-form"
                    />

                    <Field
                      isInAdrBook
                      type='checkbox'
                      component={LockForm}
                      editedContact={values}
                      name="is_banned_for_media"
                      classPrefix='adr-book-contact-form-lock'
                    />

                    {!isNewContactCreation && 
                      <div className={classModifier("adr-book-contact-form__field", "uid")}>
                        <h4 className={classModifier("adr-book-contact-form__field-title", "uid")}>Fastmail UID:</h4>

                        {editedContact.uid
                          ? <div className={classModifier("adr-book-contact-form__field-content", "uid")}>
                              {editedContact.uid}

                              <CopyToClipboardButton
                                value={editedContact.uid}
                                disabled={!editedContact.uid}
                                title='Copy note'
                              />
                            </div>
                          : <div className="adr-book-contact-form__field-content"> No UID </div>
                        }
                      </div>
                    }
                  </>
                }
              </div>

              <div className='adr-book-contact-form__info'>
                <AdrBookContactFormTabs
                  values={values}
                  saveContact={saveContact}
                  editedContact={editedContact}
                  serverError={serverError}
                  setServerError={setServerError}
                  feedbackList={feedbackList}
                  setFeedbackList={setFeedbackList}
                  setNewContactAdr={setNewContactAdr}
                  isNewContactCreation={isNewContactCreation}
                  linksLabels={linksLabels}
                  isFieldEdited={isFieldEdited}
                />
              </div>
            </div>
          </form>
        )
      }}
    />
  )
};

const validate = ({ fn, emails, tels, urls, addresses }) => {
  const errors = {
    tels: [],
    emails: [],
    urls: [],
    addresses: []
  };

  if (tels) {
    tels.forEach((currentTel, index) => {
      const { tel } = currentTel;

      if (!tel) {
        return errors.tels[index] = { error: 'Enter the phone number' };
      }
      const numbers = /^\d+$/;
      const isCorrect = tel[0] === '+'
        ? numbers.test(tel.slice(1))
        : numbers.test(tel);

      if (!isCorrect) {
        return errors.tels[index] = { error: 'Incorrect phone number' };
      }
      const countOfCoincidence = tels.filter(t => t.tel === tel).length;

      if (countOfCoincidence > 1) {
        return errors.tels[index] = { error: 'Phone number has already exist' };
      }
    })
  }

  if (emails) {
    emails.forEach((currentEmail, index) => {
      const { email } = currentEmail;

      if (!email) {
        return errors.emails[index] = { error: 'Enter the email' };
      }
      const emailRegExp = /[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]/igm;

      if (!emailRegExp.test(email)) {
        return errors.emails[index] = { error: 'Incorrect email' };
      }
      const countOfCoincidence = emails.filter(e => e.email === email).length;

      if (countOfCoincidence > 1) {
        return errors.emails[index] = { error: 'Email has already exist' };
      }
    })
  }

  if (!fn) {
    errors.fn = "Name is required";
  }

  if (urls) {
    urls.forEach((currentUrl, index) => {
      const { url } = currentUrl;

      if (getIsFieldEmpty(url)) {
        errors.urls[index] = {
          ...errors.urls[index],
          url: 'Enter the link',
        };
      }
    })
  }

  return errors;
};

const mapStateToProps = (state, ownProps) => ({
  editedContact: selectContactById(state, ownProps.active),
  defaultAgent: selectAgentContactById(state, ownProps.active),
  divaProfile: state.divaGirls.entities[selectContactById(state, ownProps.active)?.diva_default_id],
});

const mapDispatchToProps = {
  addNewContact,
  updateContact,
  removeAgentData,
  addAgentData,
  updateAgentsGirlIds,
  toggleCreateContactForm,
  addDivaGirlsToEntities,
  updateActiveAdrBookContactId
};

export default connect(mapStateToProps, mapDispatchToProps)(AdrBookContactForm);