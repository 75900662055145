import React from 'react';
import { useSelector } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';

import {
  classModifier,
  getContactAvatar,
  isEmptyObj,
} from 'utils';
import {selectOperatorActiveSessionsCountByOperatorId} from 'redux/selectors/selectors';
import ICONS from 'assets/icons';

import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import Spinner from 'components/UI/Spinner/Spinner';
import IconMenu from 'components/IconMenu/IconMenu';
import Portal from 'components/Portal';

const SidebarOperatorItem = (props) => {
  const {
    item,
  } = props;

  const {
    visible,
    setTooltipRef,
    setTriggerRef,
    getTooltipProps
  } = usePopperTooltip({
    trigger: 'hover',
    placement: 'top-start',
    offset: [19, 5],
  });

  const operatorActiveSessionsCount = useSelector(
    (state) => selectOperatorActiveSessionsCountByOperatorId(state, item.id)
  );

  const getOperatorMode = () => {
    const {is_call_active, is_sms_active} = item.mode;

    if (is_call_active && is_sms_active) {
      return "full"
    } else if (is_call_active && !is_sms_active) {
      return "phone"
    } else if (!is_call_active && is_sms_active) {
      return "sms"
    } else {
      return "off"
    }
  };

  if (isEmptyObj(item)) {
    return (
      <div className="sidebar-operator-item sidebar-operator-item--pending">
        <Spinner spinnerSize="32px" />
      </div>
    )
  }

  const renderModeTable = (mode) => {
    const renderMode = (modeType) => {
      const modeTypeFilterArray = [];
      modeType.agent && modeTypeFilterArray.push("Ag.");
      modeType.client && modeTypeFilterArray.push("Cl.");
      modeType.girl && modeTypeFilterArray.push("Esc.");
      modeType.service && modeTypeFilterArray.push("Sr.");
    
      return modeTypeFilterArray.join(' / ');
    }

    return (
      <Portal>
        <div 
          ref={setTooltipRef} 
          {...getTooltipProps({
            className: "sidebar-operator-item__tooltip",
          })}
        >
          CALL = {renderMode(mode.call)}
          <br/>
          SMS = {renderMode(mode.sms)}
        </div>
      </Portal>
    )
  };

  return (
    <div className="sidebar-operator-item" ref={setTriggerRef}>
      <div className="sidebar-operator-item__img-wrap">
        <LazyLoadImage src={getContactAvatar(item)} alt="ava" className="sidebar-operator-item__img" />
        
        <span className={classModifier('sidebar-operator-item__status', [item.status && `${item.status}`])}></span>
      </div>

      <div className="sidebar-operator-item__content-wrap">
        <div className="sidebar-operator-item__content">
          <div className="sidebar-operator-item__info">
            <div className="sidebar-operator-item__name-wrap">
              <p className="sidebar-operator-item__name">
                {item.username}
              </p>
            </div>

            <div className="sidebar-operator-item__sessions-indicator">
              <ICONS.puzzle className="sidebar-operator-item__puzzle-icon"/>

              <span>{operatorActiveSessionsCount}</span>
            </div>
          </div>
          { !!item.phone &&
            <p className="sidebar-operator-item__tel">
              {item.phone}
            </p>
          }
        </div>

        <div className="sidebar-operator-item__menu-wrapper">
          {!!item.mode && 
            <p className={classModifier("sidebar-operator-item__mode", [`${getOperatorMode()}`])}>
              {getOperatorMode()}
            </p>
          }

          <IconMenu icon={ICONS.burgerMenu} />
        </div>
      </div>

      {visible && renderModeTable(item.mode)}
    </div>
  );
};

export default SidebarOperatorItem;