import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { classModifier, isEmptyObj } from 'utils';
import { getTagsForAdrBook, updateTagsFilter } from 'redux/ducks/addressBook';

import './AdrBookTags.scss';
import Spinner from 'components/UI/Spinner/Spinner';
import AdrBookTag from 'containers/AdrBookTags/AdrBookTag';


const AdrBookTags = (props) => {
  const {
    tags: { ids, entities, pending, active, allContactsCount },
    contactType,
    getTagsForAdrBook,
  } = props;

  useEffect(() => {
    getTagsForAdrBook();
  }, [contactType]);

  const isActiveTagsFilter = !isEmptyObj(active);

  const clearTagsFilter = () => {
    if (isActiveTagsFilter) {
      props.updateTagsFilter();
    }
  }

  const renderSpinner = () => (
    <div className="adr-book-tags__spinner-wrap">
      <Spinner spinnerSize='30px' />
    </div>
  );

  return (
    <div className="adr-book-tags">
      <header className="adr-book-tags__title">
        Filter by tag
      </header>

      {pending
        ? renderSpinner()
        : (
          <ul className="adr-book-tags__list">
            <li className="adr-book-tags__list-item">
              <button
                className={classModifier("adr-book-tags__tag", [
                  !isActiveTagsFilter && "active"
                ])}
                onClick={clearTagsFilter}
              >
                All Contacts 
                <span className="adr-book-tags__tag-count">
                  &nbsp;({allContactsCount || 0})
                </span>
              </button>
            </li>

            {!!ids.length && ids.map(id => (
              <AdrBookTag
                key={id}
                tag={entities[id]}
                updateTagsFilter={props.updateTagsFilter}
              />
            ))}
          </ul>
        )
      }
    </div>
  )
}

const mapStateToProps = state => ({
  tags: state.addressBook.tags,
  contactsPending: state.addressBook.contactsPending,
  includeUnsaved: state.addressBook.includeUnsaved,
  contactType: state.addressBook.contactType,
  totalClientsCount: state.contacts.clients.totalCount,
  totalGirlsCount: state.contacts.girls.totalCount,
});

const mapDispatchToProps = {
  updateTagsFilter,
  getTagsForAdrBook,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdrBookTags);