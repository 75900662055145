import React from 'react';
import { connect } from 'react-redux';

import AdrBookContactForm from './components/AdrBookContactForm/AdrBookContactForm';


const AdrBookContactFormWrap = ({ active, isNewContactCreation, callbacksRef, setUndoData }) => {

  return (
    <div className="adr-book-contact-form-wrap">
      {(active || isNewContactCreation) &&
        <AdrBookContactForm
          active={active}
          setUndoData={setUndoData}
          callbacksRef={callbacksRef}
          isNewContactCreation={isNewContactCreation}
        />
      }
    </div>
  )
};

const mapStateToProps = (state) => ({
  active: state.addressBook.active,
  isNewContactCreation: state.addressBook.isNewContactCreation,
})

export default connect(mapStateToProps)(AdrBookContactFormWrap);
