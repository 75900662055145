import React, { useState } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';

import ICONS from 'assets/icons';
import { classModifier, getContactAvatar } from 'utils';
import { CONTACT_TYPES } from 'config/constants';
import { removeContact } from 'redux/ducks/contacts';
import { 
  changeContactType, 
  toggleCreateContactForm, 
  updateSearch } from 'redux/ducks/addressBook';

import './AdrBookHeader.scss';
import SearchInput from 'components/SearchInput/SearchInput';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import Spinner from 'components/UI/Spinner/Spinner';


const options = [
  { label: 'All Contacts', value: 0 },
  { label: 'Clients Contacts', value: CONTACT_TYPES.CLIENT },
  { label: 'Escort Contacts', value: CONTACT_TYPES.GIRL },
];


const AdrBookHeader = (props) => {

  const {
    isCreatedNewContact,
    historyStateListLength,
    lastStateListItemId,
  } = props.undoDataProps;

  const [query, setQuery] = useState(props.search || '');

  const onContactTypeChange = (option) => {
    props.changeContactType(option.value);
  };

  const showCreateContactForm = () => {
    if(!props.active && props.isNewContactCreation) {
      props.saveContact();
    }

    if(!props.isNewContactCreation) {
      props.toggleCreateContactForm(true);
    }
  };

  const deleteContact = () => {
    if (window.confirm('Are you sure you want to delete this contact?')) {
      return props.removeContact(props.active);
    }
  };

  const renderUndoBlock = () => {
    if(isCreatedNewContact && props.active) {
      return (
        <div className="adr-book-header__undo-block">
          <span className="adr-book-header__undo-block-saved-msg">Contact saved!</span>
        </div>
      )
    }

    if (props.undoDataProps.pending) {
      return (
        <div className="adr-book-header__undo-block">
          <div className="adr-book-header__undo-spinner-wrapper">
            <Spinner spinnerSize={30} />
          </div>
        </div>
      );
    }
    if (
      !props.isNewContactCreation && 
      historyStateListLength > 1 && 
      props.active === lastStateListItemId
    ) {
      return (
        <div className="adr-book-header__undo-block">
          <button
            className="adr-book-header__undo-btn"
            type="button"
            onClick={props.setPrevState}
          >
            <span className="adr-book-header__undo-text">Saved !<br/>Undo changes ?</span>
            <ICONS.undo className='adr-book-header__undo-btn-icon' />
          </button>
        </div>
      );
    }
  };

  return (
    <header className="adr-book-header">
      <ICONS.adrBook className="adr-book-header__icon" />

      <Select 
        options={options}
        isSearchable={false}
        className="react-select"
        classNamePrefix="react-select"
        defaultValue={options.find(el => el.value === props.contactType)}
        onChange={onContactTypeChange}
        getOptionValue={option => option.value}
      />

      <SearchInput 
        query={query}
        placeholder="Search contact"
        inputClassName="input adr-book-header__search-input"
        setQuery={setQuery}
        startSearch={props.updateSearch}
        stopSearch={props.updateSearch}
        showSearchBtn
        showClearBtn
      />

      <div className="adr-book-header__actions">
        <button 
          className={classModifier("adr-book-header__btn-action", "add-contact")}
          onClick={showCreateContactForm}
        >
          New Contact
          <ICONS.plus width="14px" height="14px" />
        </button>

        {!props.isNewContactCreation && props.active && 
          <button 
          className={classModifier("adr-book-header__btn-action", "delete")}
            onClick={deleteContact}
          >
            Delete
            <ICONS.trash />
          </button>
        }
      </div>

      {renderUndoBlock()}

      <div className="adr-book-header__mailbox-users-wrapper">
        <span>Users using this mailbox</span>
        <div className="adr-book-header__mailbox-users">
          <LazyLoadImage src={getContactAvatar("")} alt="ava" className="adr-book-header__mailbox-user__img" />
          <LazyLoadImage src={getContactAvatar("")} alt="ava" className="adr-book-header__mailbox-user__img" />
        </div>
      </div>
    </header>
  )
}

const mapStateToProps = state => ({
  contactsPending: state.addressBook.contactsPending,
  contactType: state.addressBook.contactType,
  search: state.addressBook.search,
  isNewContactCreation: state.addressBook.isNewContactCreation,
  active: state.addressBook.active,
});

const mapDispatchToProps = {
  changeContactType,
  toggleCreateContactForm,
  updateSearch,
  removeContact,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdrBookHeader);