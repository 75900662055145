import React, { memo, useState } from 'react';

import ICONS from 'assets/icons';

const MessageTemplates = (props) => {
  const [visible, setVisible] = useState(false);

  const renderTemplate = (template) => (
    <li
      key={template.id}
      className="chat-input__template-list-item popup-menu__item"
      onClick={() => handleTemplateClick(template.text)}>
      {template.text}
    </li>
  );

  const handleTemplateClick = (text) => {
    props.addBoilerplate(text);
    setVisible(false);
  };

  return (
    <button
      title="message templates"
      className="chat-input__btn chat-input__btn--template"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <ICONS.plusCircle className="chat-input__btn-icon"  />

      {visible &&
        <div className="chat-input__template-list-wrap">
          <ul className="chat-input__template-list popup-menu">
            {props.msgTemplates.map(item => renderTemplate(item))}
          </ul>
        </div>
      }
    </button>
  );
};

export default memo(MessageTemplates);
