import React from 'react';
import Select from 'react-select';

const ChatListFilter = (props) => {
  const defaultFilter = props.filters.find((filter) => filter.name === props.defaultFilter);

  const onFilterChange = (filter) => {
    if (props.isQuery) {
      props.setQuery('');
    }
    filter.action();
  }

  return (
    <div className="sidebar-filter-select">
      <Select
        className="react-select"
        classNamePrefix="react-select"
        options={props.filters}
        getOptionValue={option => option.name}
        getOptionLabel={option => option.name}
        defaultValue={defaultFilter}
        onChange={onFilterChange}
        isSearchable={false}
        components={{
          IndicatorSeparator: null,
        }}
      />
    </div>
  )
}

export default React.memo(ChatListFilter);