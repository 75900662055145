import React from 'react';
import { connect } from 'react-redux';

import API from 'api/api';
import { getContactAvatar } from 'utils';
import { killConnection } from 'redux/ducks/twilio';
import { selectContactFromEntities } from 'redux/selectors/selectors';

// import Tag from 'components/TagsForm/Tag';


const OutgoingCallModal = ({ connection, connectedCaller, activeCallId }) => {
  console.log(`@@@OutgoingCallModal`, OutgoingCallModal)
  const hangUp = (e) => {
    e.preventDefault();

    if (connectedCaller.type === 9) {
      API.dismissWebrtcCall(activeCallId);
    }
    else {
      killConnection(connection);
    }
  };


  const renderCountryInfo = () => {
    const tel = connectedCaller && connectedCaller.tels
      ? connectedCaller.tels.find(t => t.default === 1)
      : [];

    const country = tel && tel.country ? tel.country : 'Unknown country';

    const flag = tel && tel.flag ? tel.flag : 'https://barrakuda.biz/images/stories/virtuemart/product/_________________4d3813533c43a.png';

    return <span>From: <img src={flag} alt="flag" /> {country}</span>
  };

  return (
    <div className="call-modal">
      <h1>Outgoing Call</h1>

      <div className="call-modal__img-wrap">
        <img src={getContactAvatar(connectedCaller)} alt="avatar" />
      </div>

      <p className="call-modal__name">{connectedCaller.short_name || connectedCaller.fn || connectedCaller.username}</p>

      <div className="call-modal__nationality">
        {renderCountryInfo()}
      </div>

      {/* {connectedCaller.callerTags && !!connectedCaller.callerTags.length && //TODO v2
        <ul className="call-modal__tags-list">
          {connectedCaller.callerTags.map(tag =>
            <Tag tag={tag} key={tag.id} />
          )}
        </ul>
      } */}

      <div className="call-modal__btns">
        <button className="call-modal__btn" onClick={hangUp}>
          Dismiss
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  connection: state.twilio.connection,
  connectedCaller: selectContactFromEntities(state, ownProps.contact.id, ownProps.contact.type) || {},
  activeCallId: state.calls.activeCall.id,
});

export default connect(mapStateToProps)(OutgoingCallModal);
