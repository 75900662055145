import React, { memo, useRef } from 'react'
import { Field } from "react-final-form";
import Creatable from 'react-select/creatable';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';

import Spinner from '../../UI/Spinner/Spinner';
import { useDidMount } from 'hooks';

const PhoneNumbersFieldItem = (props) => {

  const {
    tel, 
    index,
    values,
    fields, 
    isInAdrBook,
    serverError,
    saveContact, 
    phoneLabels, 
    classPrefix, 
    fieldArrayMeta, 
    setServerError,
    isEditedTelLabel,
    isNewContactCreation 
  } = props;

  const telRef = useRef();

  const isAnyTelFieldError = !!fieldArrayMeta.error.filter(Boolean).length;
  const isValidForSave = !isNewContactCreation && !isAnyTelFieldError;

  useDidMount(() => {
    if(index === fields.length - 1 && !telRef.current.value) {
      telRef.current.focus();
    }
  })

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '24px',
      height: '24px',
      fontSize: '12px',
      width: '100px',
      backgroundColor: isInAdrBook ? '#fff' : '#f6f6f6',
      borderColor: 'transparent',
      borderRadius: '6px',
      maxWidth: isInAdrBook ? '100%' : '122px',
      backgroundColor: '#808080',
      color: 'white',
      cursor: 'pointer',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none'
      }
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 90,
      backgroundColor: '#fff',
      width: '150px',
    }),
    container: (provided) => ({
      ...provided,
      maxWidth: '75px'
    }),
    input: (provided) => ({
      ...provided,
      color: 'white',
      fontWeight: '500'
    }),
    dropdownIndicator: () => ({
      paddingRight: '4px',
      display: 'flex',
      alignItems: 'center',
      width: '18px'
    }),
    singleValue: (provided) => ({
      ...provided,
      lineHeight: '1.6',
      color: 'white',      
      display: 'flex',
      alignItems: 'center',
      fontWeight: '500',
      marginTop: '2px'
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 2px',
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '12px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      backgroundColor: state.isSelected && '#88D6D9',
      '&:hover': {
        backgroundColor: state.isSelected ? '#88D6D9' : '#E6EBF1',
      },
    }),
  };

  const isErrorExist = (fieldArrayMeta, inputMeta, index) => {
    const { error } = fieldArrayMeta;
    const isValidationError = error && error.length && error[index] && error[index].error;

    if (isValidationError && inputMeta.touched && !inputMeta.active) {
      return 'client';
    }
    else if (!isValidationError && serverError && serverError[index]) {
      return 'server';
    }

    return null;
  }

  const telLabelsChange = (props, fields, index) => {
    fields.update(index, {
      ...fields.value[index],
      labelName: props.value,
    });
  };

  const hideServerErrorOnChange = (e, input, index) => {
    input.onChange && input.onChange(e);

    serverError && setServerError((prev) => {
      const updatedServerError = JSON.parse(JSON.stringify(prev));

      delete updatedServerError.tels[index];

      return updatedServerError;
    });
  };

  const onBlur = (e, input) => {
    input.onBlur && input.onBlur(e);

    if (!e.currentTarget.contains(e.relatedTarget) && e.relatedTarget?.dataset.action === 'delete') {
      fields.remove(index);
    } 
  };

  return (
    <div className={`${classPrefix}__tel`}>
      <div className={`${classPrefix}__tel-wrapper`}>
        <Field
          name={`${tel}.tel`}
          type="text"
        >
          {({ input, meta }) => {
            return (<>
              {!!phoneLabels.length
                ? (
                  <Creatable
                    styles={customStyles}
                    className={classModifier(`${classPrefix}__select-tel-label`,
                      !fields.value[index].default && 'removable'
                    )}
                    value={!!fields.value[index].labelName
                      ? { value: fields.value[index].labelName, label: fields.value[index].labelName }
                      : { value: phoneLabels[0].label, label: phoneLabels[0].label }
                    }
                    options={phoneLabels}
                    placeholder='Phone type'
                    onChange={(e) => telLabelsChange(e, fields, index)}
                    components={{
                      IndicatorSeparator: () => null
                    }}
                    onBlur={() =>  isEditedTelLabel && isValidForSave && saveContact(values)}
                  />
                ) : (
                  <div className={classModifier(`${classPrefix}__select-tel-label`, ['pending'])}>
                    <Spinner spinnerSize="30px" />
                  </div>
                )
              }

            <div className={`${classPrefix}__input-wrapper`}>
              <input
                {...input}
                ref={telRef}
                className={classModifier(`${classPrefix}__input`, [
                  'phone-number',
                  (!fields.value[index].default || fields.value.length === 1) && 'removable',
                  isErrorExist(fieldArrayMeta, meta, index) && 'error',
                ])}
                placeholder={`Phone ${index + 1}`}
                autoComplete='off'
                onBlur={(e) => onBlur(e, input)}
                onChange={e => hideServerErrorOnChange(e, input, index)}
              />

              <button
                type="button"
                className={classModifier(`${classPrefix}__input-field-btn`, 'field-action')}
              >
                <ICONS.phoneSquare className={`${classPrefix}__icon`}/>
              </button>

              {isErrorExist(fieldArrayMeta, meta, index) &&
                <div className="form-error-text__wrap">
                  <div className="form-error-text">
                    {isErrorExist(fieldArrayMeta, meta, index) === 'server'
                      ? serverError[index]
                      : fieldArrayMeta.error[index].error
                    }
                  </div>
                </div>
              }
            </div>

            <button
              type="button"
              title="Remove Number"
              data-action="delete"
              className={classModifier(`${classPrefix}__input-field-btn`, 'del')}
              onClick={() => fields.remove(index)}
            >
              <ICONS.close />
            </button>
          </>)}}
        </Field>
      </div>
    </div>
  )
}

export default memo(PhoneNumbersFieldItem);