import ICONS from 'assets/icons';
import { DRAFT_STATUSES } from 'redux/ducks/mail';

import MailHeaderActionButton from './MailHeaderActionButton';

const MailHeaderComposeActions = ({ pending, handleDeleteDraft, draftStatus }) => {
  const somePending = Object.values(pending).includes(true);

  const getDraftStatusLabel = () => {
    if (draftStatus === DRAFT_STATUSES.savedNotice || draftStatus === DRAFT_STATUSES.saved) {
      return 'Saved';
    }

    if (draftStatus === DRAFT_STATUSES.pending) {
      return 'Saving...';
    }

    return 'Save Draft';
  }

  return (
    <>
      <MailHeaderActionButton
        type='submit'
        title='Send'
        content='Send'
        iconName='arrow'
        classNameModifier='send'
        disabled={somePending}
        pending={pending.sendMsg}
        iconClassNameModifier='send'
        handleClick={() =>
          document
            .getElementById('mailComposerForm')
            .dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))}
      />

      <button
        className="mail-header__action-btn mail-header__action-btn--save"
        disabled={draftStatus !== DRAFT_STATUSES.notSaved}
      >
        {getDraftStatusLabel()}
        <ICONS.file className="mail-header__action-btn-icon" />
      </button>

      <button
        type="button"
        className="mail-header__action-btn"
        title="Discard"
        onClick={() => handleDeleteDraft()}
        disabled={somePending}
      >
        Discard
        <ICONS.trash className="mail-header__action-btn-icon" />
      </button>
    </>
  );
};

export default MailHeaderComposeActions;
