import React from 'react';
import { useSelector } from 'react-redux';

import {
  getContactAvatar,
  classModifier,
  isEmptyObj,
  showContactName,
} from 'utils';
import { selectContactById } from 'redux/selectors/selectors';

import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import TimeAgo from 'components/TimeAgo';
import Spinner from 'components/UI/Spinner/Spinner';
import ICONS from 'assets/icons';
import './PublicChatGirlsItem.scss'

const PublicChatGirlsItem = (props) => {
  const contact = useSelector((state) => selectContactById(state, props.id));

  const isLastMsgFormTelegram = !!contact.interactionObject && (
    contact.interactionObject.type === 9 ||  contact.interactionObject.type === 10
  );

  if (isEmptyObj(contact)) {
    return (
      <div className="public-chat-girls-item public-chat-girls-item--pending">
        <Spinner spinnerSize="32px"/>
      </div>
    )
  }

  const dateMsg = contact.availabilityTriggerMsgs?.data_created || contact.statusUpdatedDate

  return (
    <>
      <div className="public-chat-girls-item">
        <div
          className={classModifier("public-chat-girls-item__img-wrap", [
            contact.is_active_chat && "active",
          ])}
        >
          <LazyLoadImage
            src={getContactAvatar(contact)}
            alt="ava"
            className="public-chat-girls-item__img"
          />

          <span
            className={classModifier("public-chat-girls-item__status", [
              contact.availability !== "available" && "off-today",
            ])}
          ></span>

          {isLastMsgFormTelegram && (
            <ICONS.telegram className="public-chat-girls-item__telegram-icon" />
          )}
        </div>

        {/* <ICONS.info className="public-chat-girls-item__icon-info"/> */}

        <div className="public-chat-girls-item__box">
          <h4 className="public-chat-girls-item__name">
            {showContactName(contact.fn, 'public-chat-girls-item__ex')}
          </h4>

          {dateMsg && (
            <span className="public-chat-girls-item__time">
              {<TimeAgo minPeriod={60} date={dateMsg} />}
            </span>
          )}
        </div>
      </div>

      {contact.availabilityTriggerMsgs && (
        <div className="public-chat-girls-item__msg-wrapper">
          <div className="public-chat-girls-item__last-msg">
            {contact.availabilityTriggerMsgs?.message}
          </div>

          {/* <ICONS.undo className="public-chat-girls-item__icon-undo"/> */}
        </div>
      )}
    </>
  );
};


export default PublicChatGirlsItem;