import React, { memo, useRef } from 'react'
import { Field } from "react-final-form";

import { classModifier } from '../../../utils';
import ICONS from 'assets/icons';
import { useDidMount } from 'hooks';

const EmailsFieldItem = props => {

  const {
    email, 
    index,
    fields, 
    serverError,
    classPrefix, 
    fieldArrayMeta, 
    setServerError,
    isNewContactCreation 
  } = props;

  const emailRef = useRef();

  const isAnyEmailFieldError = !!fieldArrayMeta.error.filter(Boolean).length;

  useDidMount(() => {
    if(index === fields.length - 1 && !emailRef.current.value) {
      emailRef.current.focus();
    }
  })

  const isErrorExist = (fieldArrayMeta, inputMeta, index) => {
    const { error } = fieldArrayMeta;
    const isValidationError = error && error.length && error[index] && error[index].error;

    if (isValidationError && inputMeta.touched && !inputMeta.active) {
      return 'client';
    }
    else if (!isValidationError && serverError && serverError[index]) {
      return 'server';
    }

    return null;
  }

  const hideServerErrorOnChange = (e, input, index) => {
    input.onChange && input.onChange(e);

    serverError && setServerError((prev) => {
      const updatedServerError = JSON.parse(JSON.stringify(prev));

      delete updatedServerError.emails[index];

      return updatedServerError;
    });
  };

  const onBlur = (e, input) => {
    input.onBlur && input.onBlur(e);

    if (!e.currentTarget.contains(e.relatedTarget) && e.relatedTarget?.dataset.action === 'delete') {
      fields.remove(index);
    } 
  };

  return (
    <div className={`${classPrefix}__mail`}>
      <Field
        name={`${email}.email`}
        type="text"
      >
        {({ input, meta }) => {
          return (<>
            <div className={classModifier(`${classPrefix}__input-wrapper`, 'email')}>
              <input
                {...input}
                ref={emailRef}
                className={classModifier(`${classPrefix}__input`, [
                  'email',
                  (!fields.value[index].default || fields.value.length === 1) && 'removable',
                  isErrorExist(fieldArrayMeta, meta, index) && 'error',
                ])}
                placeholder={`Email ${index + 1}`}
                autoComplete='off'
                onChange={e => hideServerErrorOnChange(e, input, index)}
                onBlur={(e) => onBlur(e, input)}
              />

              <button
                type="button"
                className={classModifier(`${classPrefix}__input-field-btn`, 'field-action')}
              >
                <ICONS.mail className={`${classPrefix}__icon`}/>
              </button>

              {isErrorExist(fieldArrayMeta, meta, index) &&
                <div className="form-error-text__wrap">
                  <div className="form-error-text">
                    {isErrorExist(fieldArrayMeta, meta, index) === 'server'
                      ? serverError[index]
                      : fieldArrayMeta.error[index].error
                    }
                  </div>
                </div>
              }
            </div>

            <button
              type="button"
              title="Remove Email"
              data-action="delete"
              className={classModifier(`${classPrefix}__input-field-btn`, 'del')}
              onClick={() => fields.remove(index)}
            >
              <ICONS.close />
            </button>
          </>
        )}}
      </Field>
    </div>
  )
}

export default memo(EmailsFieldItem);