import React, { useState } from 'react';
import { connect } from 'react-redux';

import { killConnection, callTo, callToWithParams } from 'redux/ducks/twilio';
import { acceptTransferedCall, acceptIncomingQueueCall, unholdCall } from 'redux/ducks/calls';
import { onHoldActiveConference } from 'redux/ducks/webrtc';
import { selectActiveWebrtcConference } from 'redux/selectors/selectors';
import { classModifier, isEmptyObj } from 'utils';

import './CallButton.scss';
import DropWrapper from 'components/DropWrapper';
import ICONS from 'assets/icons';

const CallButton = React.forwardRef((props, ref) => {
  const { 
    onClick, 
    disabled = false, 
    className = '', 
    width = 16, 
    closeConfirm 
  } = props;
  console.log(`@@@props.fill`, props.fill)
  return (
    <button
      ref={ref}
      className={`${className} call-btn`}
      title={props.title}
      disabled={disabled}
      onClick={() => {
        props.closeActiveConference && props.closeActiveConference();
        onClick && onClick(); //TODO v2
        closeConfirm && closeConfirm();
      }}
    >
      {props.isConfirm
        ? 'Confirm call'
        : <ICONS.phoneSquare width={width} height={width} fill={props.fill} />
      }
    </button>
  )
});

const CallButtonWrapper = (props) => {
  const { activeRecipient, connectedContacts } = props;
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);

  const isActiveCall = props.connection && props.connectedCaller &&
    props.connectedCaller.id === activeRecipient.id &&
    props.connectedCaller.type === activeRecipient.type;

  const closeActiveConference = () => {
    if (props.activeConference) {
      props.onHoldActiveConference(props.activeConference, props.peerConnections, props.localStream);
    };
  };

  const unholdCall = (call) => {
    props.device.connect(callToWithParams('unhold', call.id))

    props.unholdCall(call);
  }

  const acceptIncomingQueueCall = (call) => {
    props.device.connect(callToWithParams('incoming_queue', call.id))

    props.acceptIncomingQueueCall(call);
  }

  const getCallBtnProps = () => {
    if (!activeRecipient) {

      return {
        disabled: true
      }
    }
    if (!connectedContacts[activeRecipient.id] && activeRecipient.tels) {      // if caller not in call and we can call to him
      return {
        disabled: props.callInProcess || !activeRecipient.tels[0],
        fill: "#00A6AD",
        onClick: () => props.callTo(activeRecipient)
      }
    }
    if (activeRecipient.status) {                                   // if operator and we can call to him if we haven't call in progress already
      return {
        disabled: activeRecipient.status !== "online" || props.callInProcess,
        fill: "#00A6AD",
        onClick: () => props.callTo(activeRecipient)
      }
    }
    if (isActiveCall) {               // this contact in activeCall => we can hang up this call
      return {
        fill: "#F8737F",
        onClick: () => killConnection(props.connection)
      }
    }
    if (!isEmptyObj(props.callsOnHold)) {                                // if caller onHold => pickUp
      const call = Object.values(props.callsOnHold)
        .find(call => call.caller_id === activeRecipient.id);

      if (call) {
        return {
          disabled: props.callInProcess,
          fill: "#01DF85",
          onClick: () => unholdCall(call),
        }
      }
    }
    if (!isEmptyObj(props.callsOnTransfer)) {               // if caller onTransfer => pickUp
      const call = Object.values(props.callsOnTransfer)
        .find(call => call.caller_id === activeRecipient.id);

      if (call) {
        return {
          disabled: props.callInProcess,
          fill: "#01DF85",
          onClick: () => props.acceptTransferedCall(call),
        }
      }
    }
    if (!isEmptyObj(props.incomingCalls)) {                 // if caller in incomingQueue => pickUp
      const call = Object.values(props.incomingCalls)
        .find(call => call.status === "in-queue" && call.caller_id === activeRecipient.id);

      if (call) {
        return {
          disabled: props.callInProcess,
          fill: "#01DF85",
          onClick: () => acceptIncomingQueueCall(call),
        }
      }
    }

    return {                                                                  // default call btn if caller busy => disabled
      title: "Busy",
      disabled: true,
      onClick: () => props.callTo(activeRecipient)
    }
  }


  if (props.isConfirm && !isActiveCall) {
    return (
      <>
        <CallButton
          {...getCallBtnProps()}
          onClick={() => {}}
          ref={setReferenceElement}
          width={props.width}
          className={props.className}
        />

        <DropWrapper
          isActive={dropdownStatus}
          setIsActive={setDropdownStatus}
          referenceElement={referenceElement}
        >
          <div className={classModifier("call-btn__dropdown", props.dropdownPosition)}>
            <CallButton
              {...getCallBtnProps()}
              isConfirm
              width={props.width}
              className={props.className}
              closeActiveConference={closeActiveConference}
            />
          </div>
        </DropWrapper>
      </>
    )
  }

  return <CallButton {...getCallBtnProps()} width={props.width} closeActiveConference={closeActiveConference} className={props.className} />
}

const mapStateToProps = state => ({
  connection: state.twilio.connection,
  device: state.twilio.device,
  connectedContacts: state.calls.connectedContacts,
  callsOnHold: state.calls.callsOnHold,
  callsOnTransfer: state.calls.callsOnTransfer,
  connectedCaller: state.calls.activeCall.caller,
  callInProcess: state.calls.activeCall.status,
  incomingCalls: state.calls.incomingCalls,
  localStream: state.webrtc.localStream,
  peerConnections: state.webrtc.peerConnections,
  activeConference: selectActiveWebrtcConference(state),
});

const mapDispatchToProps = {
  callTo,
  acceptTransferedCall,
  acceptIncomingQueueCall,
  onHoldActiveConference,
  unholdCall
};

export default connect(mapStateToProps, mapDispatchToProps)(CallButtonWrapper);