import { useSelector } from 'react-redux';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';
import { selectActiveSessionId } from 'redux/selectors/selectors';

import './SalesHeader.scss';
import GallerySearch from './components/GallerySearch/GallerySearch';

const SalesHeader = () => {
  const activeSessionId = useSelector(selectActiveSessionId);

  return (
    <header className="sales-header">
      <div className="sales-header__left-wrap">
        <button className={classModifier("sales-header__primary-button", "full-width")}>
          Default Session
          <ICONS.puzzle />
        </button>
      </div>

      <div className="sales-header__center-wrap">
        {activeSessionId === 0 &&   // default session
          <button className="sales-header__primary-button">
            Add Client
            <ICONS.adrBookInvert />
          </button>
        }
        {!!activeSessionId &&   // any session with client
          // To replace with new component
          // <SessionClientCard aSessionClientId={aSessionClientId} />
          "SessionClientInfo will be here"
        }
      </div>

      <div className="sales-header__right-wrap">
        {/* Most likely: to replace with new component */}
        <div className="sales-header__buffer-button-wrap">
          <button className="sales-header__secondary-button">
            Buffer ( 0 )
            <ICONS.buffer />
          </button>

          <button className="sales-header__clear-buffer-button">
            <ICONS.close />
          </button>
        </div>

        {/* Most likely: to replace with new component */}
        <button className="sales-header__secondary-button">
          Booked (0)
          <ICONS.booking />
        </button>

        <GallerySearch />
      </div>
    </header>
  );
}

export default SalesHeader;
