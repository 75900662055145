import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faChevronDown, faStar } from '@fortawesome/free-solid-svg-icons';

import API from 'api/api';
import {
  getContactAvatar,
  formatDateToTime,
  getDateByTimezoneOffset,
  classModifier
} from 'utils';
import { useToggle } from 'hooks';
import { selectContactById } from 'redux/selectors/selectors';

import MsgText from './MsgText';
import InteractionMenu from '../InteractionMenu/InteractionMenu';
import TriStateSwitch from 'components/TriStateSwitch/TriStateSwitch';

const IncomingGirlsChatMessage = ({ interaction, activeRecipient, search, userRole, girl = {}, ...props }) => {
  const [glossaryItemInfo, setGlossaryItemInfo] = useState({
    glossaryItemId: null,
    glossaryType: null,
  });

  const isFromTelegram = interaction.type === 9;

  const [isMsgExpanded, toggleMsgExpanded] = useToggle(false);
  const [menuAndGlossaryDrop, toggleMenuAndGlossaryDrop] = useToggle(false);
  const [isOpenGlossary, toggleOpenGlossary] = useToggle(false);

  const handleMsgClick = (e) => {
    if (!e.target.className.indexOf) {
      return;
    }
    if (e.target.className.indexOf("interaction__ava") !== -1) {
      return props.updateActiveContact(girl);
    }
    else if (~e.target.className.indexOf("ii-")
      || ~e.target.className.indexOf("interaction__glossary")
      || ~e.target.className.indexOf("interaction__text-drop")) {
      return;
    }
    console.log('toggle ', isMsgExpanded);
    toggleMsgExpanded();
  }

  const items = useMemo(() => ([
    {
      component: (props) =>
        <TriStateSwitch
          {...props}
          value={girl.availability ? girl.availability : "waiting"}
          onChange={(value) => API.changeGirlsStatus(value, girl.id)}
        />,
    },
    {
      title: 'W',
      tooltipOnHover: 'Webmaster task',
      action: () => API.addWebmasterTask(interaction.id)
    }
  ]), [girl.availability]);

  const removeMessageFromAvailableGlossary = () => {
    API.removeMessageFromAvailableGlossary(glossaryItemInfo.glossaryItemId, interaction.id)
      .then(() => setGlossaryItemInfo({
        glossaryItemId: null,
        glossaryType: null,
      }))
  };

  const glossaryItems = [
    {
      title: `${glossaryItemInfo.glossaryType === 'inAvailable'
        ? 'Remove from available glossary'
        : 'Add to glossary as available'}`,
      action: () => glossaryItemInfo.glossaryType === 'inAvailable'
        ? removeMessageFromAvailableGlossary()
        : API.addMessageToAvailableGlossary(interaction.id, 'in')
          .then(({ data }) => {
            setGlossaryItemInfo({
              glossaryItemId: +data,
              glossaryType: 'inAvailable',
            });
          }),
      isDisabled: glossaryItemInfo.glossaryType === 'inOff',
    },
    {
      title: `${glossaryItemInfo.glossaryType === 'inOff'
        ? 'Remove from Off glossary'
        : 'Add to glossary as Off Today'}`,
      action: () => glossaryItemInfo.glossaryType === 'inOff'
        ? removeMessageFromAvailableGlossary()
        : API.addMessageToAvailableGlossary(interaction.id, 'out')
          .then(({ data }) => {
            setGlossaryItemInfo({
              glossaryItemId: +data,
              glossaryType: 'inOff',
            });
          }),
      isDisabled: glossaryItemInfo.glossaryType === 'inAvailable',
    }
  ];

  const showInteractionMenu = () => {
    if (interaction.is_deleted) return null;

    return <InteractionMenu
      isOpen={menuAndGlossaryDrop}
      menuItems={items}
      closeMenu={toggleMenuAndGlossaryDrop} />
  };

  return (
    <li
      className="interaction interaction--girls"
      onClick={handleMsgClick}
      onMouseEnter={() => toggleMenuAndGlossaryDrop(true)}
      onMouseLeave={() => {
        toggleMenuAndGlossaryDrop(false)
        toggleOpenGlossary(false)
      }}
    >
      <header className="interaction__header">
        <div className={`interaction__ava ${girl.availability && 'interaction__ava--' + girl.availability
          }`}
        >
          <img
            src={getContactAvatar(girl)}
            className="interaction__ava-img"
            alt="ava" />
          {isFromTelegram &&
            <FontAwesomeIcon onClick={(e) => e.stopPropagation()} icon={faTelegram} size="lg" color="#0092F2" />
          }
        </div>

        <div className="interaction__main-wrap">
          <div className="interaction__main-info">
            <span className="interaction__name">
              {girl && (girl.short_name || girl.fn)}

              {girl.exclusive &&
                <FontAwesomeIcon
                  icon={faStar}
                  title="exclusive"
                  className="interaction__name--exclusive"
                />
              }

              <span className="interaction__rates">
                {girl && !!girl.rates && girl.rates}
              </span>
            </span>

            <span className="interaction__time interaction__time--msg">
              {formatDateToTime(getDateByTimezoneOffset(props.userTimezone, interaction.dateCreated), props.userHour12)}
            </span>
          </div>

          <div className="interaction_text-wrap">
            {glossaryItemInfo.glossaryItemId &&
              <span
                className="interaction__glossary-undo"
                onClick={removeMessageFromAvailableGlossary}
              >
                Undo
              </span>
            }

            {!glossaryItemInfo.glossaryItemId && menuAndGlossaryDrop &&
              <FontAwesomeIcon
                className={classModifier("interaction__glossary-drop", [
                  isOpenGlossary && 'open'
                ])}
                icon={faChevronDown}
                color='#808080'
                title="Glossary"
                size="lg"
                onClick={() => toggleOpenGlossary()}
              />
            }

            <MsgText
              interaction={interaction}
              isMsgExpanded={isMsgExpanded}
              mode={glossaryItemInfo.glossaryType}
            />
          </div>

        </div>
      </header>

      {showInteractionMenu()}

      {!glossaryItemInfo.glossaryItemId
        && isOpenGlossary
        && userRole && ['admin', 'operator', 'senior operator'].includes(userRole.toLowerCase()) &&
        <ul className='interaction__glossary' >
          {glossaryItems.map(({ title, action, isDisabled }, idx) => {
            return (
              <li
                key={idx}
                onClick={() => (!isDisabled && action())}
                className={classModifier('interaction__glossary-item', [
                  isDisabled && 'disabled'
                ])}
              >
                {title}
              </li>
            )
          })}
        </ul>
      }
    </li>
  )
}

const mapStateToProps = (state, { interaction: { caller_id } }) => ({
  girl: selectContactById(state, caller_id),
  userRole: state.user.role,
});

export default connect(mapStateToProps)(IncomingGirlsChatMessage);