import { useDispatch } from 'react-redux';

import { useWillUnmount } from 'hooks';
import { clearDelayedActions } from 'redux/ducks/mail';

import './MailPage.scss';
import MailHeader from 'containers/MailHeader/MailHeader';
import MailSidebar from 'containers/MailSidebar/MailSidebar';
import MailMain from 'containers/MailMain/MailMain';

const MailPage = () => {
  const dispatch = useDispatch();

  useWillUnmount(() => {
   dispatch(clearDelayedActions());
  });

  return (
    <div className="mail-page">
      <MailHeader />

      <div className="mail-page__bottom-wrap">
        <MailSidebar />

        <MailMain />
      </div>
    </div>
  )
}

export default MailPage;
