import axios from 'axios';
import { handleNetError, LS } from '../utils';

const protocol = 'https://';
const currentHostname = window && window.location && window.location.hostname;

let backendHostname;

if (currentHostname === 'localhost') {
  backendHostname = 'api.comdiva.local';
}
else if (currentHostname === 'v2.connect.divaes.co') {
  backendHostname = 'cronjobs.divaes.co';
}
else if (currentHostname && currentHostname !== 'chromewebdata') {
  backendHostname = 'api.' + currentHostname.replace('v2.', '');
}
else {
  backendHostname = process.env.REACT_APP_BACKEND_HOST || 'unknownhosterror';
}

export const API_CRONJOBS_ROOT = protocol + backendHostname; 

const axiosCronjobs = axios.create({
  baseURL: API_CRONJOBS_ROOT,
  withCredentials: true,
})

axiosCronjobs.interceptors.response.use(
  response => {
    // Do something with response data

    LS.setItem('lastInteractionTime', Date.now());

    return Promise.resolve(response);
  },
  error => {
    // Do something with response error
    handleNetError(error);
    return Promise.reject(error);
  });

export default axiosCronjobs;
