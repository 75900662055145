import { useEffect } from "react";
import { connect } from "react-redux";

// import API from "../../api/api"
import {
  selectDivaNotLoadedIds,
  selectActiveSession,
  selectActiveSessionId,
} from "redux/selectors/selectors";
import { 
  getClientPrevBookedProfiles, 
  // showSessionFromHistory, 
  // updateActiveSession, 
  // normalizeSession,
} from "redux/ducks/sessions";
import { getDivaGirls } from "redux/ducks/divaGirls";

import "./SalesPage.scss";
import Spinner from "components/UI/Spinner/Spinner";
import SalesHeader from "containers/SalesHeader/SalesHeader";
import Gallery from "containers/Gallery/Gallery";
import ProfilesCompareZone from "containers/ProfilesCompareZone/ProfilesCompareZone";
// import SalesHeader from "../../containers/SalesHeader/SalesHeader";
// import SalesSidebar from "../../containers/SalesSidebar/SalesSidebar";
// import ProfilesCompareZone from "../../containers/ProfilesCompareZone/ProfilesCompareZone";
// import Gallery from "../../containers/Gallery/Gallery";

const SalesPage = ({
  addSessionPending,
  idsNotLoaded,
  activeSessionId,
  aSessionPrevBookedProfiles,
  aSessionClientId,
  getDivaGirls,
  getClientPrevBookedProfiles,
}) => {
  // const urlQuery = new URLSearchParams(window.location.search);

  // const [sessionIdFromURL, setSessionIdFromURL] = useState(+urlQuery.get('sessionId'));

  // useEffect(() => {
  //   if (!props.addSessionPending && sessionIdFromURL) {
  //     API.getSessionById(sessionIdFromURL)
  //       .then(({ data: session }) => {
  //         session.active && !session.is_hidden
  //           ? props.updateActiveSession(session.id)
  //           : props.showSessionFromHistory(normalizeSession(session));
  //       })
  //       .catch((err) => {
  //         props.updateActiveSession(0);
  //         console.log(err);
  //       })
  //       .finally(() => {
  //         window.history.replaceState(null, null, window.location.pathname);
  //         setSessionIdFromURL(null);
  //       })
  //   }
  // }, [props.addSessionPending, sessionIdFromURL])

  useEffect(() => {
    if (idsNotLoaded) {
      getDivaGirls({ 'filter-ids': idsNotLoaded });
    }
  }, [idsNotLoaded]);

  useEffect(() => {
    if (
      aSessionClientId && activeSessionId &&
      !aSessionPrevBookedProfiles && aSessionPrevBookedProfiles !== null
    ) {
      getClientPrevBookedProfiles(aSessionClientId, activeSessionId);
    }
  }, [activeSessionId, aSessionPrevBookedProfiles, aSessionClientId]);

  const isPageInit = !addSessionPending && !idsNotLoaded;   // && !sessionIdFromURL;

  if (!isPageInit) {
    return (
      <main className="sales-page sales-page--pending">
        <Spinner spinnerSize={160} />
      </main>
    );
  }

  return (
    <main className="sales-page">
      <SalesHeader />

      {/* To replace with new component and move css */}
      {/* <SalesSidebar /> */}
      <aside className="sales-sidebar">SalesSidebar will be here</aside>

      {/* To replace with new component and move css */}
      {/* <ProfilesCompareZone /> */}
      <section className="sales-compare">
        <ProfilesCompareZone />
      </section>

      <Gallery />
    </main>
  );
};

const mSTP = state => {
  const activeSessionId = selectActiveSessionId(state);
  const activeSession = selectActiveSession(state);
  
  return {
    addSessionPending: state.sessions.addSessionPending,
    idsNotLoaded: activeSession && selectDivaNotLoadedIds(state, activeSessionId).toString(),
    activeSessionId,
    aSessionPrevBookedProfiles: activeSession?.prevBookedProfiles,
    aSessionClientId: activeSession?.callerId,
  };
};

const mDTP = {
  getDivaGirls,
  getClientPrevBookedProfiles,
  // showSessionFromHistory,
  // updateActiveSession,
};

export default connect(mSTP, mDTP)(SalesPage);
