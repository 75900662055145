import { useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useLocation, useNavigate } from 'react-router-dom';

import API from 'api/api';
import { capitalizeFirstLetter, LS } from 'utils';
import { ROUTES } from 'config/constants';

import AsyncButton from 'components/AsyncButton/AsyncButton';
import FormTextField from 'components/FormTextField/FormTextField';

const COUNTER_DURATION = 59;

const TwoFactorPage = () => {
  const [loginPending, setLoginPending] = useState(false);
  const [serverErrors, setServerErrors] = useState({});
  const [counter, setCounter] = useState(COUNTER_DURATION);
  const [isCounterActive, setIsCounterActive] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let timer = null;

    if (isCounterActive && counter) {
      timer = setTimeout(() => {
        setCounter((counter) => counter - 1);
      }, 1000);
    }

    if (counter === 0) {
      setIsCounterActive(false);
      setCounter(COUNTER_DURATION);
    }

    return () => {
      clearInterval(timer);
    };
  }, [counter, isCounterActive]);

  const onSubmit = (values) => {
    setLoginPending(true);
   
    const config = { 
      ...values, 
      email: location.state.email 
    };

    API.sendVerificationCode(config)
      .then(({ data }) => {
        if (data.success === 1 && data.token.length > 0) {
          const userId = data.user.id;

          localStorage.isLoggedIn = 1; // only for development mode
          localStorage.currentUserId = userId;
          localStorage.isLoginAction = 1;

          const afkTime = LS.getAFKTime(Date.now(), userId);

          if (afkTime > 4) {
            LS.cleanUserChatTabs(userId);
          }

          navigate(ROUTES.main, { replace: true });
        }
      })
      .catch((err) => {
        console.error(err);
        setLoginPending(false);
        setServerErrors(JSON.parse(err.response?.data?.message));
      })
  }

  const handleResendBtn = () => {
    setIsCounterActive(true);

    API.resendVerificationCode(location.state.email)
      .catch(console.log);
  }

  const toNumber = (value) => {
    if (!value || typeof value === 'number') return value;
    return value.replace(/[^0-9]/g, '');
  }

  return (
    <div className="login two-factor">
      <h2 className="login__subtitle">Please enter code from SMS</h2>

      <Form
        onSubmit={onSubmit}
        validate={validate}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className="login__form">
            <div className="login__form-field-wrap">
              <Field
                name="code"
                parse={toNumber}
                format={toNumber}
                label="Code"
                className="login__form-field login__form-field--code"
                labelClassName="login__label"
                inputClassName="login__input login__input--code"
                inputWrapClassName="login__input-wrap"
                component={FormTextField}
                serverErrors={serverErrors}
                inputProps={{
                  tabIndex: 1,
                  maxLength: 4,
                  autoFocus: true,
                }}
              />

              <button
                type="button"
                className="login__form-field-btn"
                onClick={handleResendBtn}
                disabled={isCounterActive}
              >
                {isCounterActive ? counter : 'Resend'}
              </button>
            </div>

            {serverErrors.general &&
              <div className="login__general-error">{capitalizeFirstLetter(serverErrors.general)}</div>
            }

            <AsyncButton
              type="submit"
              className="btn btn--primary login__submit-btn"
              tabIndex={2}
              pending={loginPending}
              disabled={submitting || loginPending}
            >
              Sign in
            </AsyncButton>
          </form>
        )}
      </Form>
    </div>
  )
}

const validate = ({ code }) => {
  const errors = {};

  if (!code || !code.trim().length || code.length < 4) {
    errors.code = 'Code must be four digits';
  }

  return errors;
}

export default TwoFactorPage;
