import React from 'react';
import { connect } from 'react-redux';

import ICONS from 'assets/icons';
import { classModifier, getContactAvatar, showContactName } from 'utils';
import { selectContactById } from 'redux/selectors/selectors';
import { updateActiveAdrBookContactId } from 'redux/ducks/addressBook';

import "./AdrBookItem.scss";
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';


const AdrBookItem = ({ style, ...props }) => {
  const { contact } = props;

  const handleClick = () => {
    if (!props.isActive) {
      props.updateActiveAdrBookContactId(contact.id);
    }
  }

  return (
    <div
      style={{
        ...style,
        bottom: style.bottom ? style.bottom + 8 : 8,
        height: style.height - 8
      }}
      className={classModifier('adr-book-item', [
        props.isActive && "active"
      ])}
      onClick={handleClick}
    >
      {/* {contact.type === 2 && <div className={classModifier("adr-book-item__contact-status", "active")}></div>} */}

      <div className={classModifier("adr-book-item__img-wrap", [
        contact.availability && contact.availability === 'off today'
          ? 'off-today'
          : contact.availability,
        ])}
      >
        <LazyLoadImage src={getContactAvatar(contact)} alt="ava" className="adr-book-item__img" />

        {/* {contact.type === 2 && contact.availability && <ICONS.arrowUpdate width="10px" height="10px" />} */}
      </div>

      <div className="adr-book-item__info">
        <span className="adr-book-item__name">
          {contact.fn && showContactName(contact.fn, 'adr-book-item__ex')}
        </span>
        <span className="adr-book-item__tel">
          {props.contact.tels[0]?.tel}
        </span>
      </div>

      {contact.type === 2 && !!contact.urls.length && 
        <div className="adr-book-item__link-wrap">
          <a
            href={contact.urls[0].url}
            target="_blank"
            className="adr-book-item__external-link"
          >
            <ICONS.externalLinkSquare width="14px" height="14px" />
          </a>
        </div>
      }
    </div>
  );
}

const mapStateToProps = (state, { id }) => ({
  contact: selectContactById(state, id),
  isActive: state.addressBook.active === id,
});

const mapDispatchToProps = {
  updateActiveAdrBookContactId
};

export default connect(mapStateToProps, mapDispatchToProps)(AdrBookItem);