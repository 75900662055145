import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import LS from 'utils/localStorageAPI';
import { CHAT_TYPES } from 'config/constants';
import { removeContactTab, updateActiveContact } from 'redux/ducks/clientChats';
import {
  updateActiveChat,
  fixRoomTab,
  createNewRoom,
  onRemoveChatTab,
  PUBLIC_CHAT_TABS
} from 'redux/ducks/roomChats';

import ICONS from 'assets/icons';
import AdaptiveTabs from "../components/ChatTabs/AdaptiveTabs";
import Title from 'components/UI/Title/Title';
import ChatTabs from '../components/ChatTabs/ChatTabs';
import OperatorsChat from './OperatorsChat/OperatorsChat';
import PublicChatNotificationsList from './PublicChatNotificationsList/PublicChatNotificationsList';
import BookingsList from './BookingsList/BookingsList';
// import ChatCreatorButton from './../ChatCreatorForm/ChatCreatorButton/ChatCreatorButton';
// import CreateRoomDialog from '../Sidebar/components/RoomTabs/CreateRoomDialog/CreateRoomDialog';
// import Bookings from '../Bookings/Bookings';
import AvailabilityList from './AvailabilityList/AvailabilityList';


class PublicChat extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.active !== this.props.active) {
      LS.setItem('aChat', this.props.active, this.props.userId);
    }

    if (prevProps.tabs !== this.props.tabs && prevProps.tabs.length !== 0) {
      LS.setItem('chTabs', this.props.tabs, this.props.userId);
    }
    if (prevProps.unfixedTab !== this.props.unfixedTab) {
      LS.setItem('unfixedChatTab', this.props.unfixedTab, this.props.userId);
    }
  }

  render() {
    const {
      activeRecipient,
      tabs,
      className,
    } = this.props;

    return (
      <div className="chat public-chat">
        {tabs.length > 0 &&
          <Fragment>
            <div className="chat__title-wrap">
              <h2 className="chat__title">
                <ICONS.comments className="chat__title-icon" />
                Public Chat
              </h2>

              {/* {this.props.active !== 'new_chat' && //TODO v2 remove
                <ChatCreatorButton onClick={this.props.createNewRoom}>
                  <span className="chat-creator-button__text">
                    New Group Chat
                  </span>
                </ChatCreatorButton>
              } */}
            </div>

            <AdaptiveTabs
              // tabs={tabs}
              tabs={[
                PUBLIC_CHAT_TABS.rooms, 
                PUBLIC_CHAT_TABS.bookings,
                PUBLIC_CHAT_TABS.offToday,
                PUBLIC_CHAT_TABS.available,
                PUBLIC_CHAT_TABS.notifications, 
              ]}
              type={CHAT_TYPES.ROOM} 
            >
              <ChatTabs
                // withUnfixedTab
                unfixedTab={this.props.unfixedTab}
                fixTab={this.props.fixRoomTab}
                activeRecipient={activeRecipient}
                removeContactTab={this.props.removeContactTab}
                updateActiveContact={this.props.updateActiveChat}
                isShowUndoTabs={true} 
              />
            </AdaptiveTabs>

            <div className="chat__wrapper chat__wrapper--public">
              {this.props.active === PUBLIC_CHAT_TABS.rooms && 
                <OperatorsChat />
              }

              {this.props.active === PUBLIC_CHAT_TABS.notifications &&
                <PublicChatNotificationsList />
              }

              {/* {this.props.active === 'new_chat' &&
                <CreateRoomDialog
                  active={this.props.active}
                  onRemoveChatTab={this.props.onRemoveChatTab}
                  userId={this.props.userId}
                />
              } */}
              {this.props.active === 'bookings' &&
                <BookingsList/>
              }
              {this.props.active === PUBLIC_CHAT_TABS.offToday &&
                <AvailabilityList availability={PUBLIC_CHAT_TABS.offToday}/>
              }
              {this.props.active === PUBLIC_CHAT_TABS.available &&
                <AvailabilityList availability={PUBLIC_CHAT_TABS.available}/>
              }
            </div>
          </Fragment>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  active: state.roomChats.active,
  activeRecipient: state.rooms.entities[state.roomChats.active],
  tabs: state.roomChats.tabs,
  unfixedTab: state.roomChats.unfixedTab,
  userId: state.user.id,
});

const mapDispatchToProps = {
  updateActiveChat,
  removeContactTab,
  updateActiveContact,
  fixRoomTab,
  createNewRoom,
  onRemoveChatTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicChat);