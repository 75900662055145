import React, { useEffect } from 'react';

import './ContextMenu.scss';

const Contextmenu = (props) => {
  useEffect(() => {
    document.addEventListener('mousedown', props.closeContextMenu);
    document.addEventListener('scroll', props.closeContextMenu);

    return () => {
      document.removeEventListener('mousedown', props.closeContextMenu);
      document.removeEventListener('scroll', props.closeContextMenu);
    }
  }, [])

  const handleContextMenuClick = (e, item) => {
    e.preventDefault();

    item.action();
    props.closeContextMenu();
  }

  const getStyle = () => {
    let right = document.body.clientWidth - props.coords[0];

    if (props.displayLeft) {
      return { right: right, top: props.coords[1] };
    } else {
      return { left: props.coords[0], top: props.coords[1] };
    }
  }

  const renderItems = (item, index) => (
    <li
      key={index}
      onMouseDown={item.action}
      onContextMenu={(e) => handleContextMenuClick(e, item)}
      className="popup-menu__item context-menu__item">
      {item.text}
    </li>
  )

  return (
    <ul
      className="popup-menu context-menu"
      style={getStyle()} >

      {props.items.map((item, index) => renderItems(item, index))}

    </ul >
  );
}

export default Contextmenu;