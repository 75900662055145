import React, { useState, useRef, useEffect, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faComments,
  faCommentSlash,
  faGlobeEurope,
  faHistory,
  faImages,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

import { useToggle, useDidUpdate } from "hooks";
import { CHAT_SOURCES, CHAT_TYPES } from "config/constants";

import "./ChatSources.scss";
import SearchInput from "components/SearchInput/SearchInput";
import ICONS from "assets/icons";
import { classModifier } from "utils";

const ChatSources = (props) => {
  const {
    activeRecipient,
    isGlobalSearch,
    search,
    showSearchQuery,
    contextDate,
    activeChatSource,
    setActiveChatSource,
    searchSource = 'contacts',
  } = props;

  const [query, setQuery] = useState("");
  const [ignoreRequest, setIgnoreRequest] = useToggle(false);
  const [showSearch, toggleShowSearch] = useToggle(false);

  const searchInputRef = useRef();

  // if global search from another type of chat => open searchBar with query
  useEffect(() => {
    if (showSearchQuery && searchSource === 'msgs') {
      if (search && search.length > 1) {
        setQuery(search);
        toggleShowSearch(true);
      }
    }
    else if (showSearchQuery) {
      if (search && search.length > 1) {
        setQuery(search);
        toggleShowSearch(true);
        setIgnoreRequest(true);
      }
    } else if (!showSearchQuery) {
      setIgnoreRequest(false);
    }
    // if we start to search when timelineMedia is open
    if (search && search.length > 1) {
      setActiveChatSource(CHAT_SOURCES.MSGS);
    }
  }, [showSearchQuery, search]);

  useEffect(() => {
    if (props.setIsSearchOpen) {
      props.setIsSearchOpen(showSearch);
    }
  }, [showSearch]);

  useDidUpdate(() => {
    setActiveChatSource(CHAT_SOURCES.MSGS);

    if (query && !isGlobalSearch) {
      setQuery("");
    }
    if (!isGlobalSearch) {
      toggleShowSearch(false);
    }
  }, [activeRecipient.id]);

  useEffect(() => {
    if (contextDate) {
      if (query) {
        setQuery("");
        toggleShowSearch(false);
      }
      setActiveChatSource(CHAT_SOURCES.MSGS);
    }
  }, [contextDate]);

  const handleSearch = (e) => {
    e.preventDefault();

    if (!showSearch) {
      toggleShowSearch();

      return searchInputRef.current.focus();
    }
    if (!query) {
      return toggleShowSearch();
    }
  };

  console.log("CHAT SOURCE RENDER");

  if (activeRecipient.id && ["girls", "webmaster"].includes(activeRecipient.id)) {
    return null;
  }

  const getIsSelectedSource = (source) => activeChatSource === source;

  const getSelectedClass = (source) => getIsSelectedSource(source)
    ? "chat-sources__source-btn--selected"
    : "";

  return (
    <div className={classModifier('chat-sources', showSearch && 'search')}>
      <button
        title='chat'
        className={`chat-sources__source-btn ${getSelectedClass(CHAT_SOURCES.MSGS)}`}
        onClick={() => setActiveChatSource(CHAT_SOURCES.MSGS)}
      >
        <ICONS.comments className="chat-sources__icon" />
      </button>

      <button
        title='media'
        className={`chat-sources__source-btn ${getSelectedClass(CHAT_SOURCES.MEDIA)}`}
        onClick={() => setActiveChatSource(CHAT_SOURCES.MEDIA)}
      >
        <ICONS.media className="chat-sources__icon" />
      </button>

      {props.type !== CHAT_TYPES.ROOM && (
        <>
          <button
            title='reminders'
            className={`chat-sources__source-btn ${getSelectedClass(CHAT_SOURCES.REMINDERS)}`}
            // onClick={() => setActiveChatSource(CHAT_SOURCES.REMINDERS)}
          >
            <ICONS.checkbox className="chat-sources__icon" />
          </button>

          <button
            className={`chat-sources__source-btn ${getSelectedClass(CHAT_SOURCES.SCHEDULED_MSGS)}`}
            // onClick={() => setActiveChatSource(CHAT_SOURCES.SCHEDULED_MSGS)}
          >
            {!!props.scheduledMsgsCount &&
              <span className="chat-sources__count">
                {props.scheduledMsgsCount > 99 ? '+99' : props.scheduledMsgsCount}
              </span>
            }

            <ICONS.history className="chat-sources__icon" />
          </button>

          {/* {props.activeRecipient.type === 2 && //TODO v2
            <button
              className={`chat-sources__source-btn ${getSelectedClass(CHAT_SOURCES.SYSTEM_MSGS)}`}
              onClick={() => setActiveChatSource(CHAT_SOURCES.SYSTEM_MSGS)}
            >
              {!!props.serviceMsgCount &&
                <span className="chat-sources__count">{props.serviceMsgCount}</span>
              }

              <FontAwesomeIcon
                icon={faCommentSlash}
                color={getIsSelectedSource(CHAT_SOURCES.SYSTEM_MSGS) ? "#0092F2" : "#808080"}
                title='service messages'
              />
            </button> */}
          {/* } */}
        </>
      )}

      <form
        action=""
        className={`chat-sources__search-form ${showSearch ? "chat-sources__search-form--open" : ""}`}
      >
        <SearchInput
          classPrefix="chat-sources"
          startSearch={ignoreRequest ? () => console.log('ignore') : props.startMessageSearch}
          stopSearch={props.stopMessageSearch}
          inputRef={searchInputRef}
          query={query}
          setQuery={setQuery}
        />

        {query.length >= 2 && (
          <button
            className="chat-sources__clear-btn"
            onClick={() => setQuery('')}
            type="button"
          >
            <ICONS.close className="chat-sources__clear-btn-icon"/>
          </button>
        )}

        <button
          type="submit"
          title="search"
          className="chat-sources__search-btn"
          onClick={handleSearch}
        >
          <ICONS.search className={classModifier('chat-sources__search-btn-icon', showSearch && 'active')} />
        </button>
      </form>
    </div>
  );
};

export default memo(ChatSources);
