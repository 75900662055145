
import { NavLink } from "react-router-dom";

import { ROUTES } from "config/constants";

import './Navbar.scss';
import ICONS from 'assets/icons';

const getLinkClassName = ({ isActive }) => {
  return isActive 
    ? 'navbar__link navbar__link--active'
    : 'navbar__link';
}

const Navbar = () => {
  return (
    <nav className="navbar">
      <ul className="navbar__list">
        <li className="navbar__item">
          <NavLink to={ROUTES.main} className={getLinkClassName}>
            <ICONS.comments className="navbar__icon" />
          </NavLink>
        </li>

        <li className="navbar__item">
          <NavLink to={ROUTES.sales} className={getLinkClassName}>
            <ICONS.puzzle className="navbar__icon" />
          </NavLink>
        </li>

        <li className="navbar__item">
          <NavLink to={ROUTES.adrBook} className={getLinkClassName}>
            <ICONS.adrBook className="navbar__icon"/>
          </NavLink>
        </li>

        <li className="navbar__item">
          <NavLink to={ROUTES.mail} className={getLinkClassName} replace>
            <ICONS.mail className="navbar__icon" />
          </NavLink>
        </li>

        <li className="navbar__item navbar__item--calendar">
          <div>
            <ICONS.calendar className="navbar__icon" />
          </div>
        </li>
      </ul>
    </nav>
  )
}

export default Navbar;