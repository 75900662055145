import React, { Component } from 'react'

import errorImg from 'assets/images/error.png';
import Spinner from '../UI/Spinner/Spinner';
import './LazyLoadImage.scss'
import cn from 'classnames';

class LazyLoadImage extends Component {

  state = {
    imageLoaded: false
  };

  handleImageLoaded = () => {
    this.setState({
      imageLoaded: true,
      error: ''
    })
  }

  handleImageErrored = () => {
    this.setState({
      error: "Failed to load image...",
      imageLoaded: true
    });
  }

  renderSpinner = () => (
    this.state.imageLoaded
      ? null
      : <Spinner spinnerSize={this.props.spinnerSize} />
  )

  renderError = (props) => (
    this.state.error
      ? <img
        {...props}
        className="lazy-load__error"
        alt="error"
        title={this.state.error}
        data-image
        src={errorImg} />
      : null
  )

  render() {
    const { alt, className, onClick, spinnerSize, containerClassName, ...restProps } = this.props;

    const activeClass = !this.state.error && this.state.imageLoaded
      ? "lazy-load__image"
      : "lazy-load__image--hidden";
    const propsClass = className
      ? className
      : '';

    return (
      <div className={cn('lazy-load__container', containerClassName)}>
        {this.renderSpinner()}
        {this.renderError(restProps)}

        <img
          {...restProps}
          alt={alt ? alt : "lazy"}
          className={`${activeClass} ${propsClass}`}
          onClick={onClick}
          onLoad={this.handleImageLoaded}
          data-image
          onError={this.handleImageErrored} />
      </div>
    );
  }
}

export default LazyLoadImage;