import classNames from 'classnames';

import { classModifier } from 'utils';

import './FormTextInput.scss';

const FormTextInput = (props) => {
  const {
    inputProps = {},
    className,
    serverErrors,
    inputClassName,
    isErrorRelative,
    errorInputClassName,
    input: finalFormInputProps,
    submitFailedNotRequiredForError,
    meta: { error, touched, active, submitFailed },
  } = props;

  const isSubmitFailed = submitFailedNotRequiredForError ? true : submitFailed;

  const renderError = (errorMsg) => (
    <div className={classModifier('form-text-input__error', isErrorRelative && 'relative')}>
      {errorMsg}
    </div>
  );

  const isClientError = error && touched && !active && isSubmitFailed;
  const isServerError = serverErrors && serverErrors[finalFormInputProps.name];
  const errorInputClass = (isClientError || isServerError)
    ? classNames(errorInputClassName, 'input--error')
    : '';

  return (
    <div className={classNames('form-text-input', className)}>
      <input
        {...finalFormInputProps}
        type={finalFormInputProps.type || 'text'}
        className={classNames('input', inputClassName, errorInputClass)}
        {...inputProps}
      />

      {isClientError
        ? renderError(error)
        : isServerError
          ? renderError(serverErrors[finalFormInputProps.name])
          : null
      }
    </div>
  )
}

export default FormTextInput;