import { useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';

import API from 'api/api';
import { classModifier, convertDateFromUTC } from 'utils';
import { selectUserTimezone } from 'redux/selectors/selectors';
import { closeModal, MODAL_TYPES } from 'redux/ducks/activeWindows';
import formatDateToApiUTCString from 'utils/formatDateToApiUTCString';

import './FeedbackForm.scss';
import {
  FeedbackContactField,
  FeedbackSelectField,
  FeedbackCalendarField,
  FeedbackTextareaField,
  DURATION_FIELD_OPTIONS,
  TYPE_FIELD_OPTIONS,
} from './components';

const FeedbackForm = props => {
  const { 
    isEdit,
    initValues,
    setFeedbackList } = props;

  const initValuesMemo = useMemo(() => {
    const date = new Date(initValues.booking_date + ' ' + initValues.booking_time);

    return {
      ...initValues, 
      date: +convertDateFromUTC(date) || '',
      duration: DURATION_FIELD_OPTIONS.find(item => item.value === initValues.booking_duration) || initValues.duration,
      escort: initValues.diva || '',
      text: initValues.body || '',
      type: TYPE_FIELD_OPTIONS.find(item => item.value === initValues.type) || '',
    } 
  }, []);

  const getFeedbackData = values => {
    const { client, escort, type, date, duration, text, source } = values;

    const [dateStr, timeStr] = formatDateToApiUTCString(date).split(' ');

    return { 
      clientId: client.id,
      divaId: escort.diva_default_id,
      type: type.value,
      date: dateStr,
      time: timeStr,
      duration: duration.value,
      sourceId: source.id,
      sourceType: source.type,
      text,
      date_created: formatDateToApiUTCString(),
      noteId: initValues.id, 
    }
  }

  const onSubmit = async values => {
    const { data: feedback } = isEdit 
      ? await API.editContactNote(getFeedbackData(values))
      : await API.addFeedback(getFeedbackData(values));

      setFeedbackList(prev => {
        return isEdit 
          ? prev.map(item => item.id === feedback.id ? feedback : item)
          : [feedback, ...prev]
      });
    
    props.closeModal(MODAL_TYPES.feedback);
  }

  const isFormEdited = (dirtyFields, initialValues, values) => {
    return (
      dirtyFields.text ||
      dirtyFields.type ||
      dirtyFields.date ||
      dirtyFields.duration ||
      initialValues.escort?.id !== values.escort?.id || 
      initialValues.client?.id !== values.client?.id || 
      initialValues.source?.id !== values.source?.id
    )
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initValuesMemo}
      validate={validateForm}
      render={({ handleSubmit, submitting, hasValidationErrors, dirtyFields, values, initialValues }) => (
        <form
          className="feedback-form"
          onSubmit={handleSubmit}
        >
          <p className='feedback-form__title'>Add feedback</p>

          <div className="feedback-form__main">
            <div className="feedback-form__fields-group">
              <Field
                name="client"
                component={FeedbackContactField}
                fieldType='client'
              />

              <Field
                name="escort"
                component={FeedbackContactField}
                fieldType='escort'
              />

              <Field
                name='type'
                component={FeedbackSelectField}
                fieldType='type'
              />

              <Field
                name='date'
                component={FeedbackCalendarField}
              />

              <Field
                name='duration'
                component={FeedbackSelectField}
                fieldType='duration'
              />

              <Field
                name='source'
                component={FeedbackContactField}
                fieldType='source'
              />

              <Field
                name='text'
                component={FeedbackTextareaField}
              />
            </div>
          
            <div className="feedback-form__submit-group">
              <button
                className='feedback-form__cancel-button'
                onClick={() => props.closeModal(MODAL_TYPES.feedback)}
                type="button"
                disabled={submitting}
              >
                Cancel
              </button>

              <button
                className={classModifier('feedback-form__publish-button', 
                  (hasValidationErrors || !isFormEdited(dirtyFields, initialValues, values)) && 'validation-errors'
                )}
                type="submit"
                disabled={submitting || !isFormEdited(dirtyFields, initialValues, values)}
              >
                Publish
              </button>
            </div>
          </div>
        </form>
      )}
    />
  );
}

const validateForm = values => {
  const { text } = values;
  const errors = {};

  setRequiredIfEmpty('client');
  setRequiredIfEmpty('escort');
  setRequiredIfEmpty('type');
  setRequiredIfEmpty('duration');
  setRequiredIfEmpty('date');
  setRequiredIfEmpty('source');

  if (!text?.trim()) {
    errors.text = 'Required';
  }

  return errors;

  function setRequiredIfEmpty(fieldName) {
    if (!values[fieldName]) {
      errors[fieldName] = 'Required';
    }
  }
}


const mSTP = (state) => ({
  userTimezone: selectUserTimezone(state),
});

const mDTP = {
  closeModal
}

export default connect(mSTP, mDTP)(FeedbackForm);
