import { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Routes, Route, useLocation, useNavigate, Navigate, Outlet } from 'react-router-dom';

import API from 'api/api';
import { initApp } from './redux/ducks/user';
import { detectMobileDevices, deleteCookie, capitalizeFirstLetter, getCookie } from './utils';
import { ROUTES, TITLE } from 'config/constants';
import { useDidMount } from 'hooks';

import './App.scss';
import Header from 'containers/Header/Header';
import ChatSocket from './components/ChatSocket';
import MainPage from 'pages/MainPage/MainPage';
import SalesPage from 'pages/SalesPage/SalesPage';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import LoginPage from 'pages/LoginPage/LoginPage';
import TwoFactorPage from 'pages/TwoFactorPage/TwoFactorPage';
import AdrBookPage from 'pages/AdrBookPage/AdrBookPage';
import GlobalPreloader from 'components/GlobalPreloader/GlobalPreloader';
import MobileDevicesPlug from 'containers/MobileDevicesPlug/MobileDevicesPlug';
import MailPage from 'pages/MailPage/MailPage';
import TwilioSetup from 'components/TwilioSetup';
import Dialogs from 'containers/Dialogs/Dialogs';

const PrivateRoutes = (props) => {
  const [pagePending, setPagePending] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const pathName = location.pathname;
    const tabTitle = pathName.substring(pathName.lastIndexOf('/') + 1);

    if (tabTitle) {
      document.title = `${capitalizeFirstLetter(tabTitle)} | ${TITLE}`;
    }
    else {
      document.title = TITLE;
    }
  }, [location]);

  useDidMount(() => {
    const initApp = async () => {
      await API.getIsIAmLoggedIn()
        .then(res => {
          if (!localStorage.isLoggedIn) {
            localStorage.isLoggedIn = 1;
          }

          localStorage.currentUserId = res.data.id;

          setPagePending(false);

          props.initApp(res.data);
        })
        .catch(() => {
          deleteCookie('isAuthenticated');

          console.log('hello, cookie remove');

          delete localStorage.isLoggedIn;
          delete localStorage.currentUserId;

          navigate(ROUTES.login);
        })
    }
    initApp();
  });

  if (pagePending) {
    return <GlobalPreloader />;
  }

  return (
    <div className="app">
      <TwilioSetup />
      <ChatSocket />

      <Dialogs />

      <Header setPagePending={setPagePending} />

      <Routes>
        <Route path={ROUTES.main} element={<MainPage />} />
        <Route path={ROUTES.sales} element={<SalesPage />} />
        <Route path={ROUTES.adrBook} element={<AdrBookPage />} />
        <Route path={`${ROUTES.mail}/*`} element={<MailPage />} />

        <Route path="*" element={<Navigate replace to={ROUTES.main} />} />
      </Routes>
    </div>
  );
};

const mapDispatchToProps = {
  initApp,
};

const ConnectedPrivateRoute = connect(null, mapDispatchToProps)(PrivateRoutes);

const RequireAuth = () => {
  const isAuth = process.env.NODE_ENV === 'development'
    ? !!localStorage.isLoggedIn
    : !!getCookie('isAuthenticated');

  if (!isAuth) {
    return <Navigate to={ROUTES.login} />;
  }

  return <Outlet />;
}

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const isMobileDevice = useMemo(detectMobileDevices, []);

  if (isMobileDevice) {
    return <MobileDevicesPlug />;
  }

  return (
    <Routes>
      <Route
        path={ROUTES.login}
        element={<LoginPage setIsLoggedIn={setIsLoggedIn} />}
      />

      <Route
        path={ROUTES.twoFactor}
        element={isLoggedIn ? <TwoFactorPage /> : <Navigate to={ROUTES.login} />}
      />

      <Route element={<RequireAuth />}>
        <Route path="/*" element={<ConnectedPrivateRoute />} />
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
