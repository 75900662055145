import { useMemo, useState } from 'react';
import { Scrollbars } from "react-custom-scrollbars";
import InfiniteScroll from 'react-infinite-scroll-component';
import { v4 as uuid } from 'uuid';
import axios from 'axios';

import { classModifier } from 'utils';

import './SearchList.scss';
import Spinner from 'components/UI/Spinner/Spinner';
import DebounceInput from 'components/DebounceInput/DebounceInput';
import { useCancelToken, useDidUpdate } from 'hooks';

const SearchList = ({
  fetchData,
  itemComponent: ItemComponent,
  itemKey = 'id',
  onChoose,
}) => {
  const [query, setQuery] = useState('');
  const [firstPagePending, setFirstPagePending] = useState(false);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  const isNoItems = !firstPagePending && !items.length;
  const isNoSearch = isNoItems && !query;
  const isNoResults = isNoItems && query;

  // Hooks

  const inputId = useMemo(() => uuid(), []);
  const scrollbarsViewId = useMemo(() => uuid(), []);

  const { newCancelToken, cancelPrevRequest } = useCancelToken();

  useDidUpdate(() => {
    cancelPrevRequest();

    if (query) {
      fetchFirstPage();
    } else {
      setNoItems();
    }
  }, [query]);

  // Main functions

  const fetchFirstPage = () => {
    setFirstPagePending(true);

    return fetchData({
      offset: 0,
      query,
      cancelToken: newCancelToken(),
    })
      .then(({ newItems, newHasMore }) => {
        setItems(newItems);
        setHasMore(newHasMore);
        setFirstPagePending(false);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setNoItems();
        }
      });
  }

  const fetchNextPage = () => {
    return fetchData({
      offset: items.length,
      query,
      cancelToken: newCancelToken(),
    })
      .then(({ newItems, newHasMore }) => {
        setItems(prev => [...prev, ...newItems]);
        setHasMore(newHasMore);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {   // optional condition
          setHasMore(false);
        }
      });
  }

  // Helper functions

  const setNoItems = () => {
    setItems([]);
    setHasMore(false);
    setFirstPagePending(false);
  }

  // Calculated props

  const scrollbarsRenderProps = {
    renderView: props => <div {...props} id={scrollbarsViewId} />,
    renderTrackVertical: props => <div {...props} className="track-vertical" />,
    renderThumbVertical: props => <div {...props} className="thumb-vertical" />,
  }

  const bottomSpinner = (
    <Spinner
      spinnerSize={36}
      className='search-list__bottom-spinner'
    />
  );

  return (
    <div className='search-list'>
      <div className='search-list__header'>
        <label className='search-list__input-label' htmlFor={inputId}>
          Search
        </label>

        <DebounceInput onDebounce={setQuery} id={inputId} autoFocus />
      </div>

      <div className={classModifier('search-list__main', [
        (firstPagePending || isNoSearch || isNoResults) && 'centered',
      ])}>
        {firstPagePending &&
          <Spinner spinnerSize={36} />
        }
        {isNoSearch &&
          <p className='search-list__no-items'>Enter search...</p>
        }
        {isNoResults &&
          <p className='search-list__no-items'>No results</p>
        }

        {!firstPagePending && items.length > 0 &&
          <Scrollbars {...scrollbarsRenderProps}>
            <InfiniteScroll
              className='search-list__infinite-scroll'
              scrollableTarget={scrollbarsViewId}
              dataLength={items.length}
              next={fetchNextPage}
              hasMore={hasMore}
              loader={bottomSpinner}
            >
              {items.map(item => (
                <ItemComponent
                  key={item[itemKey]}
                  item={item}
                  onClick={onChoose}
                />
              ))}
            </InfiniteScroll>
          </Scrollbars>
        }
      </div>
    </div>
  )
}

export default SearchList;
