import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { faDownload, faTimes } from '@fortawesome/free-solid-svg-icons';

import { API_ROOT } from 'services/axiosInstance';
import { classModifier, formatMailFileSize } from 'utils';

import './MailAttachment.scss';
import Spinner from 'components/UI/Spinner/Spinner';

const MailAttachment = ({
  id,
  fileName,
  fileSize,
  loading,
  onDelete,
  disableDelete,
  size = "normal",
  type = "new",
}) => {
  const sizeLarge = size === "large";
  const typeNew = type === "new";
  const typeExist = type === "exist";

  const humanFileSize = formatMailFileSize(fileSize);

  return (
    <div className={classModifier("mail-attachment", [
        sizeLarge && "large",
        typeExist && "exist",
        typeNew && "new",
      ])}
    >
      <div className="mail-attachment__info">
        <div className="mail-attachment__icon">
          <FontAwesomeIcon icon={faFile} />
        </div>

        <div className="mail-attachment__text">
          <p className="mail-attachment__name" title={fileName}>{fileName}</p>

          <p className="mail-attachment__size">
            {typeNew && `(${humanFileSize})`}
            {typeExist && humanFileSize}
          </p>
        </div>
      </div>

      {typeNew && loading &&
        <div className="mail-attachment__spinner">
          <Spinner spinnerSize={20} />
        </div>
      }

      {typeNew && !loading &&
        <button
          className="mail-attachment__remove"
          onClick={onDelete}
          type="button"
          disabled={disableDelete}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      }

      {typeExist &&
        <a
          className="mail-attachment__download"
          href={`${API_ROOT}mail-attachment/save-file?id=${id}`}
        >
          <FontAwesomeIcon icon={faDownload} />
        </a>
      }
    </div>
  )
}

export default MailAttachment;
