import React from 'react';
import classNames from 'classnames';

import { getDateByTimezoneOffset } from '../utils';

import ICONS from 'assets/icons';
import Icon from './Icon/Icon';

const BookingIcon = props => {
  const {
    className = '',
    booking,
    userTimezone,
    onClick,
  } = props;

  if (!booking) return '';

  if (!booking.date) {
    return (
      <ICONS.f 
        className={classNames('booking-icon', className)}
        fill="#809fff"
      />
    )
  }

  const bookingDurations = {
    '30 minutes': 30 * 60 * 1000,
    '45 minutes': 45 * 60 * 1000,
    '1 hour': 60 * 60 * 1000,
    '90 minutes': 90 * 60 * 1000,
    '2 hours': 2 * 60 * 60 * 1000,
    '3 hours': 3 * 60 * 60 * 1000,
    'Overnight': 12 * 60 * 60 * 1000,
  };

  const bookingStages = {
    sixHours: 6 * 60 * 60 * 1000,
    twoHours: 2 * 60 * 60 * 1000,
    oneHour: 60 * 60 * 1000,
  }

  const dateByTimezone = getDateByTimezoneOffset(userTimezone);
  const bookingDateByTimezone = getDateByTimezoneOffset(userTimezone, booking.date);
  const dateDiff = bookingDateByTimezone - dateByTimezone;

  const onIconClick = () => {
    if (onClick && typeof onClick === 'function') {
      onClick();
    }
  };

  // if unknown or empty duration
  if (!bookingDurations[booking.duration]) return '';
  // if booking finished
  if (dateDiff + bookingDurations[booking.duration] < 0) return '';

  let iconColor = '#f0a8d2'; // pink

  if (dateDiff >= bookingStages.sixHours && booking.is_prebooking) {
    iconColor = '#b380ff';
  }
  else if (dateDiff >= bookingStages.sixHours) {
    iconColor = '#32bf45'; // green
  }
  else if (dateDiff <= bookingStages.sixHours && dateDiff >= bookingStages.twoHours) {
    iconColor = '#99c23a'; // green-yellow
  }
  else if (dateDiff <= bookingStages.twoHours && dateDiff >= bookingStages.oneHour) {
    iconColor = '#f5aa42'; // orange
  }
  else if (dateDiff <= bookingStages.oneHour && dateDiff >= 0) {
    iconColor = '#f54242'; // red
  }

  return (
    <Icon
      className={classNames('booking-icon', className)}
      icon={booking.is_prebooking ? ICONS.p : ICONS.booking}
      fill={iconColor}
      onClick={onIconClick}
      data-booking-icon
    />
  )
};

export default BookingIcon;