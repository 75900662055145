import axios from 'axios';
import axiosInstance from 'services/axiosInstance';
import axiosInstanceCronjobs from 'services/axiosInstanceCronjobs';

import { LIMIT } from 'redux/ducks/clientChats';
import { CONTACTS_LIMIT, GIRLS_LIMIT } from 'redux/ducks/contacts';
import { ADR_BOOK_LIMIT } from 'redux/ducks/addressBook';
import { ROOMS_LIMIT } from 'redux/ducks/rooms';
import { DIVA_GIRLS_LIMIT } from 'redux/ducks/divaGirls';
import { getDateByTimezoneOffset } from 'utils';
import { MAIL_SEARCH_CONTACTS_LIMIT } from 'config/constants';


const CancelToken = axios.CancelToken;

const baseUrlV2 = axiosInstance.defaults.baseURL.replace('/api/', '/apiV2/');

const loginUser = credentials => {
  const Credentials = new FormData();

  Credentials.set("email", credentials.email);
  Credentials.set("password", credentials.password);
  Credentials.set("timezone", Intl.DateTimeFormat().resolvedOptions().timeZone);

  return axiosInstance.post('auth-v2/login', Credentials, { baseURL: baseUrlV2 });
};

const sendVerificationCode = ({ email, code }) => {
  const Code = new FormData();

  Code.set('email', email);
  Code.set('code', code);

  return axiosInstance.post('auth-v2/confirm-sms', Code, { baseURL: baseUrlV2 })
}


const resendVerificationCode = (email) => {
  const Email = new FormData();

  Email.set('email', email);

  return axiosInstance.post('auth-v2/resend-code', Email, { baseURL: baseUrlV2 })
}

const getIsIAmLoggedIn = () => {
  return axiosInstance.get("user/get-current-user");
};

const logoutUser = () => axiosInstance.post("auth/logout");

const updateUserTimezone = (timezone) => {
  const Timezone = new FormData();

  Timezone.set("timezone", timezone);

  return axiosInstance.post("user/set-timezone", Timezone);
};

const getTimezones = () => axiosInstance.get('timezones');

const getTwilioToken = () => axiosInstance("tokens/capability-token");

const getConnectedContacts = () => axiosInstance.get("callers/active");

const markVoicemailAsListened = id => {
  const formData = new FormData();

  axiosInstance.post("voicemail/listen/" + id, formData);
};

const lockContact = (action, id) => {
  let api = "caller/unlock";

  if (action === "lock") {
    api = "caller/lock";
  }
  if (action === "hard-lock") {
    api = "caller/hard-lock";
  }

  const LockData = new FormData();

  LockData.set("callerId", id);

  return axiosInstance.post(api, LockData);
};

export const getUnreadChats = (offset = 0) => {
  const config = {
    params: {
      limit: ROOMS_LIMIT,
      offset
    }
  };

  return axiosInstance.get("chats/unread", config);
};

const removeMissedCallForAll = (callId, callerType) => {
  const MissedCall = new FormData();

  MissedCall.set("interactionId", callId);
  MissedCall.set("isUserCall", callerType === 9 ? 1 : 0);

  return axiosInstance.post("calls/remove-missed-call", MissedCall);
};

const putCallOnHold = callId => {
  const Call = new FormData();

  Call.set("interactionId", callId);

  return axiosInstance.post("calls/prepare-hold", Call);
};

const hangUpCallOnHold = callId => {
  const Call = new FormData();

  Call.set("interactionId", callId);

  return axiosInstance.post("calls/hang-up", Call);
};

const createTransferredCall = (callId, info) => {
  const Call = new FormData();

  Call.set("interactionId", callId);

  if (info) {
    Call.set("description", info.reason || "");
    Call.set("recipientId", info.operatorId || "");
  }

  return axiosInstance.post("calls/prepare-transfer", Call);
};

const createWarmTransferCall = (callId, info) => {
  const Call = new FormData();

  Call.set("interactionId", callId);

  if (info) {
    Call.set("description", info.reason || "");
    Call.set("participantIds", [info.operatorId]);
  }

  return axiosInstance.post("calls/prepare-warm-transfer", Call);
};

const getAllVoicemails = () => axiosInstance.get("voicemail/get-all");

let cancelGetInteractions;

const getInteractions = (contactId, page = null, withCancel = true) => {
  if (cancelGetInteractions) {
    cancelGetInteractions('Request "getInteractions" canceled');
  }

  const config = {
    baseURL: baseUrlV2,
    params: {
      limit: LIMIT
    },
    cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      if (withCancel) {
        cancelGetInteractions = c;
      }
    })
  };

  if (page) {
    config.params.page = page
  }

  return axiosInstance.get("interactions/caller/" + contactId, config)
};

const changeContactDefaultChannel = (id, channel) => {
  const DefaultChannel = new FormData();

  DefaultChannel.set('id', id);
  DefaultChannel.set('channel', channel);

  return axiosInstance.post("callers/change-default-channel-manually", DefaultChannel);
}

const sendMessage = (message, recipient) => {
  const Message = new FormData();

  Message.set("message", message.text || "");
  Message.set("shortcuts", message.shortcuts);
  Message.set("To", recipient.id);

  if (message.repliedMsgId) {
    Message.set("repliedTo", message.repliedMsgId);
  }
  if (message.voiceMsg) {
    Message.set("voiceMsg", message.voiceMsg.blob);
    Message.set("voiceMsgDuration", message.voiceMsg.duration);
  }
  if (message.files) {
    message.files.forEach(file => Message.append("files[]", file))
  }

  !!message.channel && Message.set('channel', message.channel);

  return axiosInstance.post("messages/send", Message);
};

const markMessageAsUnread = msgId => {
  return axiosInstance.get(`messages/mark-unread/${msgId}`);
};

const markContactChatAsRead = contactId => {
  return axiosInstance.get("callers/mark-read/" + contactId);
};

const setMessageReminder = (callerId, msgId, inTime) => {
  const Message = new FormData();

  Message.set("callerId", callerId);
  Message.set("messageId", msgId);
  Message.set("remind_at", inTime);

  return axiosInstance.post("messages/remind", Message);
};

const removeMessageReminder = (remindId) => {
  const Remind = new FormData();

  Remind.set("remindId", remindId);

  return axiosInstance.post("messages/delete-remind", Remind);
};

const getConversationMedia = (id, page = null) => {
  const config = {
    params: {
      callerId: id,
      limit: 10
    }
  };

  if (page) {
    config.params.page = page
  }

  return axiosInstance.get("interactions/get-conversation-media/", config);
};

const getChatConversationMedia = (id, page = null) => {
  const Chat = new FormData();

  Chat.set("chatId", id);
  Chat.set("limit", LIMIT);

  page && Chat.set("page", page);

  return axiosInstance.post("chats/get-rooms-conversation-media", Chat);
};

const getContactLog = (id, offset = 0) => {
  const config = {
    baseURL: baseUrlV2,
    params: {
      id,
      limit: CONTACTS_LIMIT,
      offset
    }
  };

  return axiosInstance.get('log/caller', config);
};

const getAgentGirls = (id, offset = 0) => {
  const config = {
    params: {
      limit: CONTACTS_LIMIT,
      offset
    }
  };

  return axiosInstance.get("callers/get-agent-girls/" + id, config);
};

const deleteGirlFromAgent = girlId => {
  const Girl = new FormData();

  Girl.set("girlId", girlId);

  return axiosInstance.post("callers/delete-girl-from-agent", Girl);
};

const addGirlToAgent = (agentId, girlId) => {
  const Girl = new FormData();

  Girl.set("girlId", girlId);
  Girl.set("agentId", agentId);

  return axiosInstance.post("callers/add-girl-to-agent", Girl);
};

const searchGirls = (query, agentId, offset = 0, limit) => {
  const config = {
    params: {
      q: query,
      agentId,
      offset,
      limit: limit || GIRLS_LIMIT
    }
  };

  return axiosInstance.get("callers/search-girls", config);
};

const getActiveDivaProfiles = (offset = 0, limit) => {
  const config = {
    params: {
      limit: limit || GIRLS_LIMIT,
      offset,
    }
  };

  return axiosInstance.get("callers/get-active-diva-profiles", config);
}

const getAllContactsList = () => {
  return axios
    .all([getContactList(1), getContactList(2), getContactList(3, 0, -1)])
    .then(
      axios.spread((clients, girls, agents) => ({ clients, girls, agents }))
    );
};

const getAllMessageCount = () => {
  return axiosInstance
    .all([getCountMessageByType(1), getCountMessageByType(2)])
    .then(
      axiosInstance.spread((clients, girls) => ({ clients, girls }))
    )
};

const getContactsById = ids => {
  const Ids = new FormData();

  Ids.set("ids", Array.isArray(ids) ? ids : [ids]); // if its not array it means only one id:integer

  return axiosInstance.post("callers/list", Ids);
};

// FIXME: remove this function with serverSide fix
// FIXME: photo does not work
const formatDefaultContactFields = (fieldsArray, fieldName) => {
  if (!fieldsArray || !fieldsArray.length) {
    return;
  }
  if (fieldsArray.length === 1 && !fieldsArray[0]) {
    return;
  }

  const fieldsData = fieldsArray.map((item, index) => {
    if (item.id) {
      return item;
    }

    const name = Object.keys(item)[0];
    const field = Object.values(item)[0];

    const result = {
      default: item.default,
      label: fieldName,
      [fieldName]: item[fieldName],
    };

    if (item.uuid) {
      result.uuid = item.uuid;
    }

    return result;

    return {
      default: index === 0 ? 1 : 0,
      name,
      [fieldName]: field
    };
  });

  return JSON.stringify(fieldsData);
};

const saveContact = async (contact, action, initialType, cancelToken) => {
  let Contact = new FormData();
  let image = '';
  const config = {};

  if (cancelToken) {
    config.cancelToken = cancelToken;
  }

  // const tels = formatDefaultContactFields(contact.tels, "tel");
  const emails = formatDefaultContactFields(contact.emails, "email");

  Contact.set("fn", contact.fn || "");
  contact.tels && Contact.set("tels", JSON.stringify(contact.tels));
  emails && Contact.set("emails", emails);
  contact.type && Contact.set("type", +contact.type);
  Contact.set("note", contact.note || "");
  Contact.set("urls", JSON.stringify(contact.urls ? contact.urls : []));
  contact.not_for_clients && +contact.type === 1 && Contact.set("not_for_clients", JSON.stringify(contact.not_for_clients));
  Contact.set("is_banned_for_media", contact.is_banned_for_media ? 1 : 0);
  Contact.set("addresses", JSON.stringify(contact.addresses));
  Contact.set("ex", contact.is_ex !== undefined ? +contact.is_ex : 0);
  contact.callerTags?.length && Contact.set("callerTags", JSON.stringify(contact.callerTags));
  contact.notes?.length && Contact.set("notes", JSON.stringify(contact.notes));
  Contact.set("via", JSON.stringify(contact.via));

  if (action === "update") {
    initialType && Contact.set("initialType", initialType);
    contact.isActive && Contact.set("isActive", contact.isActive || "");
    contact.unreadCount && Contact.set("unreadCount", contact.unreadCount || "");
  }
  if (contact.type === 2 || contact.type === "2") {
    contact.agentId && Contact.set("agentId", contact.agentId);
  }

  // if (contact.image) {
  //   await new Promise(
  //     (res, rej) =>
  //       new Compressor(contact.image, {
  //         quality: 0.6,
  //         success(result) {
  //           console.log("result", result);
  //           image = result;
  //           res(result);
  //         },
  //         error(err) {
  //           console.log("error image", err);
  //         }
  //       })
  //   );
  // }

  Contact.set("file", contact.image || null);

  return action === "update"
    ? axiosInstance.post("callers/update/" + contact.id, Contact, config)
    : axiosInstance.post("callers/create/", Contact);
};

const getNotForContacts = (config = {}) => {

  const apiConfig = {
    params: {
      limit: ADR_BOOK_LIMIT,
    }
  };
  
  if (config.ids) {
    apiConfig.params.ids = config.ids;
  }
  if (config.offset) {
    apiConfig.params.offset = config.offset;
  }
  if (config.search) {
    apiConfig.params.search = config.search;
  }

  return axiosInstance.get("callers/get-list-for-not-for", apiConfig);
}

const removeContact = contactId => {
  return axiosInstance.post(`callers/delete/${contactId}`);
};

const toggleContactPin = contact => {
  return axiosInstance.get(`callers/${contact.pinned ? "un" : ""}pin/${contact.id}`);
};

const toggleContactMark = contact => {
  return axiosInstance.get(`callers/${contact.marked ? "un" : ""}mark/${contact.id}`);
};

const toggleContactBlock = (contact, reason = "") => {
  const BlockData = new FormData();

  BlockData.set("reason", reason);
  BlockData.set("callerId", contact.id);

  return axiosInstance.post(`callers/${contact.blocked ? "un" : ""}block`, BlockData);
};

const getFilteredContacts = (action, type, offset = 0) => {
  const config = {
    params: {
      limit: CONTACTS_LIMIT,
      type,
      offset
    }
  };

  let apiAction;

  if (action === "marked") {
    apiAction = "marked";
  } else if (action === "unread") {
    apiAction = "has-unread";
  } else if (action === "related") {
    apiAction = "related-callers"
  } else if (action === "available") {
    apiAction = "available-girls"
  }

  return axiosInstance.get("callers/" + apiAction, config);
};

const toggleContactActiveChat = contact => {
  const Data = new FormData();

  Data.set("id", contact.id);

  return axiosInstance.post(`callers/${contact.is_active_chat ? "delete-from-active-chat" : "add-to-active-chat"}`, Data);
};

const getTopTags = () => {
  return axiosInstance.get("tags/get-top-tags");
};

const searchTags = query => {
  return axiosInstance.get(`tags/search?query=${query}`);
};

const addContactTag = (contactId, tag) => {
  const Tag = new FormData();

  Tag.set("title", tag.title);
  Tag.set("color", tag.color);

  return axiosInstance.post("callers/add-tag/" + contactId, Tag);
};

const removeContactTag = (contactId, tag) => {
  const Tag = new FormData();

  Tag.set("callerId", contactId);
  Tag.set("tagId", tag.id);

  return axiosInstance.post("callers/delete-tag", Tag);
};

const getAllTemplates = () => {
  return axios
    .all([
      axiosInstance.get("pattern-msg/get-patterns-by-caller-type?type=1"),
      axiosInstance.get("pattern-msg/get-patterns-by-caller-type?type=2")
    ])
    .then(
      axios.spread((clientsTemplates, girlsTemplates) => ({
        clientsTemplates,
        girlsTemplates
      }))
    );
};

const getMsgShortcuts = () => axiosInstance.get("shortcuts");

const changeBusyStatus = status => {
  const BusyStatus = new FormData();

  BusyStatus.set("status", status === "online" ? 0 : 2);

  return axiosInstance.post("user/change-status", BusyStatus);
};

const changeUserAvatar = (ava, userId) => {
  const Avatar = new FormData();

  Avatar.set("file", ava);

  return axiosInstance.post("users/update-avatar/" + userId, Avatar);
};

const getUserRingtones = type => {
  return axiosInstance.get(`ringtones/get-all-by-type/${type}`);
};

const updateUserRingtone = (type, ringtoneId) => {
  const Ringtone = new FormData();

  Ringtone.set("ringtoneId", ringtoneId);
  Ringtone.set("type", type);

  return axiosInstance.post("ringtones/update/", Ringtone);
};

let cancelGetChatInteractions;

const getChatInteractions = (chatId, page) => {

  if (cancelGetChatInteractions) {
    cancelGetChatInteractions('Request "getChatInteraction" canceled');
  }

  const config = {
    params: {
      limit: LIMIT,
    },
    cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancelGetChatInteractions = c;
    })
  };

  if (page) {
    config.params.page = page
  }

  return axiosInstance.get("chat?chatId=" + chatId, config);
};

const getGirlsChatInteraction = (page = null) => {
  const config = {
    params: {
      limit: 30,
    }
  };

  if (page) {
    config.params.page = page
  }

  return axiosInstance.get("interactions/girls", config);
};

const sendMessageToChat = (message, chatId) => {
  const ChatMsg = new FormData();

  ChatMsg.set("message", message.text || "");
  ChatMsg.set("shortcuts", message.shortcuts);
  ChatMsg.set("To", chatId);

  if (message.sharedMsgId) {
    ChatMsg.set("sharedMsgId", message.sharedMsgId);
  }
  if (message.sharedContactId) {
    ChatMsg.set("sharedCallerId", message.sharedContactId);
  }
  if (message.sharedVoiceMsg) {
    ChatMsg.set("sharedVoiceMsg", message.sharedVoiceMsg);
  }
  if (message.voiceMsg) {
    ChatMsg.set("voiceMsg", message.voiceMsg.blob);
    ChatMsg.set("voiceMsgDuration", message.voiceMsg.duration);
  }
  if (message.files) {
    message.files.forEach(file => ChatMsg.append("files[]", file))
    // ChatMsg.set("files", message.files);
  }
  console.log(ChatMsg.getAll('files[]'))

  return axiosInstance.post("chat/send", ChatMsg);
};

const updateChatMsg = msg => {
  const msgForm = new FormData();

  msgForm.set("message", msg.body);

  return axiosInstance.post("message/edit/" + msg.id, msgForm);
};

const removeChatMsg = msgId => {
  const msgForm = new FormData();

  return axiosInstance.post("message/delete/" + msgId, msgForm);
};

const markChatAsRead = chatId => {
  const Chat = new FormData();

  Chat.set("chatId", chatId);

  return axiosInstance.post("chat/mark-read/", Chat);
};

const sendDeliveredStatus = msgId => {
  const MsgStatus = new FormData();

  return axiosInstance.post("message/viewed/" + msgId, MsgStatus);
};

// const sendTypingPing = () => {
//   return axiosInstance.get('chats/start-typing')
//     .then(console.log('typing ping sent.'))
//     .catch(console.error);
// };

// const getPrivateRooms = () => axiosInstance.get("chats/private");

const getRoomsById = ids => axiosInstance.get(`chats/list?ids=${ids}`);

const getRoomsList = (offset = 0) => {
  const config = {
    baseURL: baseUrlV2,
    params: {
      limit: CONTACTS_LIMIT,
      offset
    }
  };

  return axiosInstance.get("chats/", config);
};

const searchRooms = (query, offset = 0, cancelToken) => {
  const config = {
    params: {
      limit: CONTACTS_LIMIT,
      q: query,
      offset
    }
  };

  if (cancelToken) {
    config.cancelToken = cancelToken;
  }

  return axiosInstance.get("chats/search", config);
};

const saveRoom = async (roomName, operatorIds, photo) => {
  console.log(roomName, operatorIds, photo);
  const Room = new FormData();
  let image = null;

  Room.set("roomName", roomName);
  Room.set("usersIds", JSON.stringify(operatorIds));

  // if (photo) {
  //   await new Promise(
  //     (res, rej) =>
  //       new Compressor(photo, {
  //         quality: 0.6,
  //         success(result) {
  //           image = result;
  //           res(result);
  //         },
  //         error(err) {
  //           console.log("error image", err);
  //         }
  //       })
  //   );
  // }
  Room.set("photo", photo || "");

  return axiosInstance.post("chats/create-room", Room);
};

const getChatRooms = (offset, limit) => {
  const config = {
    params: {}
  };

  if (limit) {
    config.params.limit = limit;
  }

  if (offset) {
    config.params.offset = offset;
  }

  return axiosInstance.get("chats/rooms", config);
};

const searchContacts = (type, query, offset = 0, limit = CONTACTS_LIMIT, cancelToken) => {
  const SearchData = new FormData();

  SearchData.set("query", query);
  SearchData.set("type", type);
  SearchData.set("limit", limit);
  SearchData.set("offset", offset);

  const config = {};

  if (cancelToken) {
    config.cancelToken = cancelToken;
  }

  return axiosInstance.post("callers/search-by-callers", SearchData, config);
};

const searchMessageInChat = (activeChatId, query, page = null) => {
  const SearchData = new FormData();

  SearchData.set("query", query);
  SearchData.set("chatId", activeChatId);
  SearchData.set("limit", LIMIT);

  page && SearchData.set("page", page);

  return axiosInstance.post("chat/search", SearchData);
};

const searchContactMessage = (contactId, query, page = null) => {
  const SearchData = new FormData();

  SearchData.set("query", query);
  SearchData.set("contact_id", contactId);
  SearchData.set("limit", LIMIT);

  page && SearchData.set("page", page);

  return axiosInstance.post("callers/search-message", SearchData);
};

const getMessageContext = (messageId, page = null) => {
  const Msg = new FormData();

  Msg.set("interactionId", messageId);
  Msg.set("limit", LIMIT);

  page && Msg.set("page", page);

  return axiosInstance.post("messages/get-context", Msg);
};

const getChatMessageContext = (messageId, page = null, chatId) => {
  const Msg = new FormData();

  Msg.set("interactionId", messageId);
  Msg.set("chatId", chatId);

  page && Msg.set("page", page);

  return axiosInstance.post("chat/get-message-context", Msg);
};

const getAllSessions = () => {
  const Sessions = new FormData();

  return axiosInstance.post("sales-sessions/", Sessions);
};

const createBooking = (data) => {
  const {
    sessionId,
    profileId,
    date,
    type,
    duration,
    discount,
    rate,
    contact_uid,
    requirements,
    caller_id,
    operatorId,
    services
  } = data;
  const Booking = new FormData();

  Booking.set('session_id', sessionId);
  Booking.set('profile_id', profileId);
  Booking.set('date', date);
  Booking.set('type', type);
  Booking.set('duration', duration);
  Booking.set('rate', rate);
  Booking.set('contact_uid', contact_uid || '');
  Booking.set('services', JSON.stringify(services)); 
  Booking.set('caller_id', caller_id);
  operatorId && Booking.set('operator_id', operatorId);
  discount && Booking.set('discount_rate', discount);
  requirements && Booking.set('requirements', JSON.stringify(requirements));

  return axiosInstance.post('booking/create', Booking);
}

const getBooking = (bookingId) => {
  const config = {
    params: {
      bookingId,
    }
  };

  return axiosInstance.get("booking/get", config);
}

const getBookingsByCallerId = (callerId, offset) => {
  const config = {
    params: {
      callerId,
      offset,
      limit: LIMIT,
    }
  };

  return axiosInstance.get('booking/get-bookings-by-caller', config);
}

const getBookingsDividedByDate = (search, offset = 0, limit = 20) => {
  const config = {
    params: {
      search,
      limit,
      offset,
    }
  }
  return axiosInstance.get('bookings/search', config);
}

const getContactsForBookings = (params) => {
  const {
    type, 
    query, 
    offset = 0, 
    contactId,
    limit = CONTACTS_LIMIT,
  } = params;

  const config = {
    params: {
      limit,
      type,
      offset,
    }
  };

  if (query) {
    config.params.query = query;
  }
  if (contactId) {
    config.params.contact_id = contactId;
  }

  return axiosInstance.get('bookings/get-contacts-for-bookings', config);
}

const getContactUnfinishedBookings = (callerId, offset) => {
  const config = {
    params: {
      callerId,
      offset,
      limit: LIMIT,
    }
  }

  return axiosInstance.get('booking/get-unfinished-bookings', config);
}

const updateBooking = (data) => {
  const {
    bookingId,
    sessionId,
    profileId,
    date,
    type,
    duration,
    rate,
    discount,
    isSuccess,
    reason,
    description,
    operatorId,
    requirements,
    caller_id,
    services
  } = data;
  const Booking = new FormData();

  Booking.set('bookingId', bookingId);
  Booking.set('session_id', sessionId);
  Booking.set('profile_id', profileId);
  Booking.set('caller_id', caller_id);
  Booking.set('date', date);
  Booking.set('type', type);
  Booking.set('duration', duration);
  Booking.set('rate', rate);
  Booking.set('is_success', +isSuccess);
  operatorId && Booking.set('operator_id', operatorId);
  Booking.set('services', JSON.stringify(services)); 
  discount && Booking.set('discount_rate', discount);
  reason && Booking.set('reason', reason);
  description && Booking.set('description', description);
  requirements && Booking.set('requirements', JSON.stringify(requirements));

  return axiosInstance.post('booking/update', Booking);
}

const deleteBooking = (bookingId) => {
  const Booking = new FormData();

  Booking.set('bookingId', bookingId);

  return axiosInstance.post('booking/delete', Booking);
}

const updateBookingsRequirementChecked = (id, isChecked) => {
  const Checked = new FormData();

  Checked.set('id', id);
  Checked.set('isChecked', +isChecked);

  return axiosInstance.post('booking/toggle-booking-requirement-checked', Checked);
}

const getClientPrevBookedProfiles = (clientId) => {
  const Booking = new FormData();

  Booking.set('callerId', clientId);

  return axiosInstance.post('callers/get-count-of-booked-profiles-by-caller-id', Booking);
}

const getOverdueBookings = () => {
  return axiosInstance.post('bookings/get-overdue');
}

const addBookingRequest = (config) => {
  const {
    description,
    text,
    booking_id,
    is_success
  } = config;
  const Request = new FormData();

  Request.set("text", text);
  Request.set("bookingId", String(booking_id));
  Request.set("isSuccess", String(+is_success));
  description && Request.set("description", description);

  return axiosInstance.post("callers-requests/add", Request);
};

const updateBookingRequest = (config) => {
  const {
    description,
    text,
    id,
    is_success
  } = config;
  const Request = new FormData();

  text && Request.set("text", text);
  Request.set("id", String(id));
  is_success !== undefined && Request.set("isSuccess", String(+is_success));
  (typeof description === 'string') && Request.set("description", description);

  return axiosInstance.post("callers-requests/update", Request);
};

const deleteBookingRequest = (id) => {
  const Request = new FormData();

  Request.set("id", String(id));

  return axiosInstance.post("callers-requests/delete", Request);
};

const getContactRequests = (callerId, offset = 0, isSortByNewest) => {
  const config = {
    params: {
      limit: LIMIT,
      offset,
      callerId,
    }
  };

  if (isSortByNewest) {
    config.params.sortByNewest = 1
  }

  return axiosInstance.get("callers-requests/index", config);
};

const getContactRequestsCount = (callerId) => {
  const config = {
    params: {
      callerId
    }
  };

  return axiosInstance.get("callers-requests/count", config);
};

const getSuccessfulSessions = (offset = 0) => {
  const Session = new FormData();

  Session.set("limit", 20);
  Session.set("offset", offset);

  return axiosInstance.post("sales-sessions/get-booked-list", Session);
};

const getSessionById = (sessionId, viewingOfSession) => {
  const Session = new FormData();

  Session.set("sessionId", sessionId);
  viewingOfSession && Session.set("viewingOfSession", viewingOfSession);

  return axiosInstance.post("sales-session/", Session);
};

const createSession = (
  contactId,
  bufferedIds = [],
  comparedIds = [],
  activeFilters,
  additionalFilters,
  recentlyViewedIds,
) => {
  const Session = new FormData();

  Session.set("callerId", contactId);
  Session.set("bufferedIds", JSON.stringify(bufferedIds));
  Session.set("comparedIds", JSON.stringify(comparedIds));
  activeFilters && Session.set("activeFilters", JSON.stringify(activeFilters));
  additionalFilters && Session.set("additionalFilters", JSON.stringify(additionalFilters));
  recentlyViewedIds && Session.set("recentlyViewedIds", JSON.stringify(recentlyViewedIds));

  return axiosInstance.post("sales-sessions/start", Session);
};

const closeSession = sessionId => {
  const Session = new FormData();

  Session.set("sessionId", sessionId);

  return axiosInstance.post("sales-session/close", Session);
};

const deleteSession = sessionId => {
  const Session = new FormData();

  Session.set("sessionId", sessionId);

  return axiosInstance.post("sales-session/delete", Session);
};

const updateComparedIds = (sessionId, newComparedIds) => {
  const ComparedIds = new FormData();

  ComparedIds.set("sessionId", sessionId);
  ComparedIds.set("profilesIds", `[${newComparedIds}]`);

  return axiosInstance.post("sales-session/to-compare", ComparedIds);
};

const removeComparedId = (sessionId, newComparedIds) => {
  const ComparedId = new FormData();

  ComparedId.set("sessionId", sessionId);
  ComparedId.set("profilesIds", `[${newComparedIds}]`);

  return axiosInstance.post("sales-session/to-compare", ComparedId);
};

const addBufferId = (profileId, sessionId, profileIdNew, contact_uid = '') => {
  const BufferIds = new FormData();

  BufferIds.set("sessionId", sessionId);
  BufferIds.set("profilesIds", `[${profileId}]`);

  BufferIds.set("profileId", profileId);
  BufferIds.set("profileIdNew", profileIdNew);

  BufferIds.set("contact_uid", contact_uid);

  return axiosInstance.post("sales-session/add-to-buffer", BufferIds);
};

// profileIds may be a one (Number) or several ids (Array)
const addProfilesToSessionBuffer = ({ sessionId, profileIds }) => {
  const Data = new FormData();

  Data.set("sessionId", sessionId);
  Data.set("profilesIds", JSON.stringify(profileIds));

  return axios.post("sales-session/add-to-buffer", Data);
}

const removeBufferedId = (profileId, sessionId) => {
  const BufferId = new FormData();

  BufferId.set("sessionId", sessionId);
  BufferId.set("profilesIds", `[${profileId}]`);

  return axiosInstance.post("sales-session/remove-from-buffer", BufferId);
};

const updateActiveFilters = (sessionId, newActiveFilters) => {
  const ActiveFilters = new FormData();

  ActiveFilters.set("sessionId", sessionId);
  ActiveFilters.set("activeFilters", JSON.stringify(newActiveFilters));

  return axiosInstance.post("sales-session/active-filters", ActiveFilters);
};

const updateAdditionalFilters = (sessionId, additionalFilters) => {
  const AdditionalFilters = new FormData();

  AdditionalFilters.set("sessionId", sessionId);
  AdditionalFilters.set("additionalFilters", JSON.stringify(additionalFilters));

  return axiosInstance.post("sales-session/additional-filters", AdditionalFilters);
};

const addBookedId = (profileId, sessionId) => {
  const BookedIds = new FormData();

  BookedIds.set("sessionId", sessionId);
  BookedIds.set("profilesIds", `[${profileId}]`);

  return axiosInstance.post("sales-session/add-to-booked", BookedIds);
};

const removeBookedId = (profileId, sessionId) => {
  const BookedIds = new FormData();

  BookedIds.set("sessionId", sessionId);
  BookedIds.set("profilesIds", `[${profileId}]`);

  return axiosInstance.post("sales-session/remove-from-booked", BookedIds);
};

const addProposedId = (operatorId, sessionId, profileId) => {
  const ProposedIds = new FormData();

  ProposedIds.set("sessionId", sessionId);
  ProposedIds.set("operatorId", operatorId);
  ProposedIds.set("profileId", profileId);

  return axiosInstance.post("sales-session/add-to-proposed", ProposedIds);
};

const removeProposedId = (operatorId, sessionId, profileId) => {
  const ProposedIds = new FormData();

  ProposedIds.set("sessionId", sessionId);
  ProposedIds.set("operatorId", operatorId);
  ProposedIds.set("profileId", profileId);

  return axiosInstance.post("sales-session/remove-from-proposed", ProposedIds);
};

const getSessionsHistory = (type, id, config = {}) => {
  return axiosInstance.get(`${type}/sales-sessions/${id}`, config);
};

const toggleHistorySessionSuccess = id => {
  const HistorySession = new FormData();

  HistorySession.set("sessionId", id);

  return axiosInstance.post("sales-session/toggle", HistorySession);
};

const continueClosedSession = id => {
  const HistorySession = new FormData();

  HistorySession.set("sessionId", id);

  return axiosInstance.post("sales-session/continue", HistorySession);
};

const duplicateClosedSession = (id, clientId) => {
  const HistorySession = new FormData();

  HistorySession.set("sessionId", id);

  if (clientId) {
    HistorySession.set("callerId", clientId);
  }

  return axiosInstance.post("sales-session/duplicate", HistorySession);
};

const transferTheSession = (sessionId, operatorId) => {
  const TransferSession = new FormData();

  TransferSession.set("sessionId", sessionId);
  TransferSession.set("userId", operatorId);

  // return axiosInstance.post("sales-session/transfer", TransferSession);
  return axiosInstance.post("sales-session/request-for-transfer-session", TransferSession);
};

const responseForTransferSession = (sessionId, status) => {
  const AssistantSession = new FormData();

  AssistantSession.set("sessionId", sessionId);
  AssistantSession.set("status", status);

  return axiosInstance.post("sales-session/response-for-transfer-session", AssistantSession);
};

const addAssistantToSession = (sessionId, operatorId) => {
  const AssistantSession = new FormData();

  AssistantSession.set("sessionId", sessionId);
  AssistantSession.set("userId", operatorId);

  // return axiosInstance.post("sales-session/add-assistant", AssistantSession);
  return axiosInstance.post("sales-session/request-for-add-to-session", AssistantSession);
};

const responseForAddToAssistant = (sessionId, status) => {
  const AssistantSession = new FormData();

  AssistantSession.set("sessionId", sessionId);
  AssistantSession.set("status", status);

  return axiosInstance.post("sales-session/response-for-add-to-session", AssistantSession);
};

const removeAssistantFromSession = (sessionId, operatorId) => {
  const AssistantSession = new FormData();

  AssistantSession.set("sessionId", sessionId);
  AssistantSession.set("userId", operatorId);

  return axiosInstance.post('sales-session/remove-partner-from-session', AssistantSession);
}

const removeViewedInSession = sessionId => {
  const SessionViewed = new FormData();

  SessionViewed.set("sessionId", sessionId);

  return axiosInstance.post("sales-session/remove-viewed", SessionViewed);
};

const updateUserSalesFilters = (filtersKeys = []) => {
  const UpdateFilters = new FormData();

  UpdateFilters.set("filters", JSON.stringify(filtersKeys));

  return axiosInstance.post("user/update-sales-filters", UpdateFilters);
};

const changeDefaultContactTel = telId => {
  const DefaultTel = new FormData();

  return axiosInstance.post("callers/set-default-number/" + telId, DefaultTel);
};

const changeDefaultContactEmail = emailId => {
  const DefaultEmail = new FormData();

  return axiosInstance.post("callers/set-default-email/" + emailId, DefaultEmail);
};

const changeClientInSession = (sessionId, newClientId) => {
  const ChangeClientSession = new FormData();

  ChangeClientSession.set("sessionId", sessionId);
  ChangeClientSession.set("callerId", newClientId);

  return axiosInstance.post("sales-session/change-caller", ChangeClientSession);
};

const updateGalleryUrlInSession = (newUrl, sessionId) => {
  const GalleryUrl = new FormData();

  GalleryUrl.set("url", newUrl);
  GalleryUrl.set("sessionId", sessionId);

  return axiosInstance.post("sales-session/add-url", GalleryUrl);
};

const getClientSessionCounts = (id) => {
  const SessionCounts = new FormData();

  SessionCounts.set('id', id);

  return axiosInstance.post('callers/sales-session-counts', SessionCounts);
}

const getGirlBookings = (girlDivaId, offset = 0, dateRange) => {
  const GirlBookings = new FormData();

  GirlBookings.set('girlId', girlDivaId);
  GirlBookings.set('limit', LIMIT);
  GirlBookings.set('offset', offset);

  if (dateRange) {
    GirlBookings.set('dateRange', JSON.stringify(dateRange));

    // return new Promise((res, rej) => { setTimeout(() => { res({ data: [
    //   { callerId: 4, close_confirmed: 0, date: "2020-08-06 10:20:00", date_created: "2020-08-06 10:17:10", duration: "30 minutes", id: 263, is_success: 1, profile_id: girlDivaId, rate: "100", requirements: [], session_id: 790, status: 1, type: "incall", usersIds: [1], },
    //   { callerId: 4, close_confirmed: 0, date: "2020-08-06 10:20:00", date_created: "2020-08-06 10:17:10", duration: "1 hour", id: 264, is_success: 1, profile_id: girlDivaId, rate: "100", requirements: [], session_id: 790, status: 1, type: "outcall", usersIds: [1], },
    // ], }); }, 500); });
  }

  return axiosInstance.post('callers/get-girl-bookings', GirlBookings);
}

const getIsGirlHasBookingsForToday = (girlIds, userTimezone) => {
  const GirlBookings = new FormData();

  const from = getDateByTimezoneOffset(userTimezone).setHours(3, 0, 0, 0) -
    (userTimezone - (new Date().getTimezoneOffset() * (-1))) * 60000;
  const to = from + 86400000 - 1;

  GirlBookings.set('girlIds', JSON.stringify(girlIds));
  GirlBookings.set('dateRange', JSON.stringify([from, to]));

  // return new Promise((res, rej) => { // FIXME: mock
  //   setTimeout(() => {
  //     const result = {};

  //     girlIds.forEach(id => {
  //       result[id] = true;
  //     });

  //     res({
  //       data: result,
  //     });
  //   }, 500);
  // });

  return axiosInstance.post('callers/get-is-girl-have-bookings-for-today', GirlBookings);
}

const removeUserFromRoom = (chatId, userId) => {
  const RemoveUserData = new FormData();

  RemoveUserData.set("chatId", chatId);
  RemoveUserData.set("userId", userId);

  return axiosInstance.post("chat/remove-user", RemoveUserData);
};

const addUsersFromRoom = (chatId, usersIds) => {
  const AddUserData = new FormData();

  AddUserData.set("chatId", chatId);
  AddUserData.set("usersIds", JSON.stringify(usersIds));

  return axiosInstance.post("chat/add-users", AddUserData);
};

const leaveRoom = chatId => {
  const LeaveFromRoomData = new FormData();

  LeaveFromRoomData.set("chatId", chatId);

  return axiosInstance.post("chat/leave", LeaveFromRoomData);
};

const removeRoomForAll = chatId => {
  const RemoveRoomData = new FormData();

  RemoveRoomData.set("chatId", chatId);

  return axiosInstance.post("chat/delete", RemoveRoomData);
};

const updateRoom = async ({ chatId, roomName, usersIds, photo }) => {
  const RoomInfoData = new FormData();

  RoomInfoData.set("chatId", chatId);
  RoomInfoData.set("roomName", roomName);
  RoomInfoData.set("usersIds", JSON.stringify(usersIds));
  photo && RoomInfoData.set("photo", photo || "");

  return axiosInstance.post("chat/edit", RoomInfoData);
};

const fetchCallsLog = (offset = 0) => {
  const Calls = new FormData();

  Calls.set("offset", offset);

  return axiosInstance.post("interactions/get-calls-by-limit", Calls);
};

const getUserNotifications = (offset = 0) => {
  const Notifications = new FormData();

  Notifications.set("offset", offset);

  return axiosInstance.post("notifications/get-notifications-for-user", Notifications);
};

const readUserNotification = (notificationId) => {
  const ReadNotification = new FormData();

  ReadNotification.set("notificationId", notificationId);

  return axiosInstance.post("notifications/set-read", ReadNotification);
}

const readAllUserNotifications = () => {
  return axiosInstance.post('notifications/set-all-read');
}

const getUserNotificationsCount = () => {
  return axiosInstance.post('notifications/get-count-of-unread-notifications');
}

const cancelConferenceInvitation = conferenceId => {
  return axiosInstance.get("conference/cancel-invitation", {
    params: { conferenceId }
  });
};

const leaveConference = callSid => {
  return axiosInstance.get("conference/leave-conference", { params: { callSid } });
};

const inviteParticipantsToConference = (confId, participantIds) => {
  const Participants = new FormData();

  Participants.set("userIds", JSON.stringify(participantIds));
  Participants.set("interactionId", confId);

  return axiosInstance.post("conference/conference-invite", Participants);
};

const getAllConferences = () => axiosInstance.get("conference/my-active-confs");

//----------------settings-------------------------------------------------------->
const changeSettingsUserMode = ({ sms, call, is_call_active, is_sms_active }) => {
  const Mode = new FormData();

  Mode.set("sms", JSON.stringify(sms));
  Mode.set("call", JSON.stringify(call));
  Mode.set("is_call_active", JSON.stringify(is_call_active));
  Mode.set("is_sms_active", JSON.stringify(is_sms_active));

  return axiosInstance.post("user/set-user-mode", Mode);
};

const getSettingsAllNumbers = () => axiosInstance.get("user/get-all-numbers");

const setSettingsPhoneNumberAsDefault = (id, callerType) => {
  const PhoneNumber = new FormData();

  PhoneNumber.set("numberId", id);
  PhoneNumber.set("callerType", callerType);

  return axiosInstance.post("user/change-user-number", PhoneNumber);
};

//----------------settings-------------------------------------------------------->

const pinMsg = (msgId, chatId) => {
  const PinMsg = new FormData();

  PinMsg.set("interectionId", msgId);
  PinMsg.set("conversationId", chatId);

  return axiosInstance.post("message/pinn", PinMsg);
};

const unpinMsg = chatId => {
  const PinMsg = new FormData();

  PinMsg.set("conversationId", chatId);

  return axiosInstance.post("message/unpinn", PinMsg);
};

const pinClientMsg = (messageId, callerId) => {
  const PinMsg = new FormData();

  PinMsg.set("messageId", messageId);
  PinMsg.set("callerId", callerId);

  return axiosInstance.post("message/pinn-caller", PinMsg);
};

const deletePinnedMessage = id => {
  const PinMsg = new FormData();

  PinMsg.set("id", id);

  const config = {
    baseURL: baseUrlV2,
  }

  return axiosInstance.post("interactions/delete-pinned-message", PinMsg, config);
};

const sendTyping = (chatId, isTyping, text) => {
  const [id, type] = chatId.split("_").map(elem => +elem);

  const Typing = new FormData();

  Typing.set("id", id);
  Typing.set("type", type);
  Typing.set("typing", isTyping);
  typeof text === 'string' && Typing.set("text", text);

  axiosInstance
    .post("chats/start-typing", Typing)
    .then(console.log("typing ping sent."))
    .catch(console.error);
};

const clearAllMissedForMe = () => axiosInstance.post("calls/hide-missed-calls");

const createNewChat = tel => {
  const Tel = new FormData();

  Tel.set("tel", tel);

  return axiosInstance.post("callers/get-number-for-chats", Tel);
};

const removeMissedContactCallForMe = callerId => {
  const missedCall = new FormData();

  missedCall.set("callerId", callerId);

  return axiosInstance.post("caller/hide-missed", missedCall);
};

const removeMissedOperatorCallForMe = userId => {
  const missedCall = new FormData();

  missedCall.set("userId", userId);

  return axiosInstance.post("user/hide-missed", missedCall);
};

const getCountMessageByType = (type) => axiosInstance.get(`callers/get-count-message?type=${type}`);

const updateUserInfo = async (info) => {
  const User = new FormData();
  let image = null;

  User.set("username", info.username);
  User.set("email", info.email);
  User.set('file', image);

  // if (info.image === 'delete') {
  User.set('file', info.image || "");
  // }
  // else if (info.image) {
  //   await new Promise(
  //     (res, rej) =>
  //       new Compressor(info.image, {
  //         quality: 0.6,
  //         success(result) {
  //           console.log("result", result);
  //           User.set('file', result);
  //           res(result);
  //         },
  //         error(err) {
  //           console.log("error image", err);
  //         }
  //       })
  //   );
  // }

  return axiosInstance.post('user/change-user-data', User);
};

const getContactReminders = (callerId, page = null) => {
  const ChatInfo = new FormData();

  ChatInfo.set('limit', LIMIT);
  ChatInfo.set('callerId', callerId);

  page && ChatInfo.set('page', page);

  return axiosInstance.post('messages/get-all-reminders', ChatInfo);
};

const addGirlToAvailableByMsgId = (msgId) => {
  const Msg = new FormData();

  Msg.set("interactionId", msgId);

  return axiosInstance.post("callers/add-girl-to-available-by-message-id", Msg);
}

const addGirlToAvailable = (girlId) => {
  const Msg = new FormData();

  Msg.set("callerId", girlId);

  return axiosInstance.post("callers/add-girl-to-available", Msg);
}

const removeGirlFromAvailableByMsgId = (msgId) => {
  const Msg = new FormData();

  Msg.set("interactionId", msgId);

  return axiosInstance.post("callers/remove-girl-from-available-by-message-id", Msg);
}

const removeGirlFromAvailable = (girlId) => {
  const Msg = new FormData();

  Msg.set("callerId", girlId);

  return axiosInstance.post("callers/remove-girl-from-available", Msg);
}

const changeGirlsStatus = (status, girlId) => {
  const Msg = new FormData();

  Msg.set("status", status)
  Msg.set("callerId", girlId);

  return axiosInstance.post("callers/change-girls-status", Msg);
}

const addMessageToAvailableGlossary = (msgId, type) => {
  const Msg = new FormData();

  Msg.set("interactionId", msgId);
  Msg.set("type", type);

  return axiosInstance.post("callers/add-message-to-glossary", Msg);
}

const removeMessageFromAvailableGlossary = (glossaryId, msgId) => {
  const Msg = new FormData();

  Msg.set("glossaryId", glossaryId);
  Msg.set("interactionId", msgId);

  return axiosInstance.post("callers/delete-message-from-glossary", Msg);
}

const getGirlsByDivaId = (id, id_new) => {
  const Ids = new FormData();

  Ids.set("id", id);
  Ids.set("id_new", id_new);

  return axiosInstance.post("contacts/get-by-diva-ids", Ids);
}

const getGirlsByContactUid = (uids) => {
  const Ids = new FormData();

  Ids.set("uids", JSON.stringify(uids));

  return axiosInstance.post("callers/get-caller-by-uid", Ids);
}

const getAllWebmasterTasks = (offset = '') => {
  const Chat = new FormData();

  Chat.set("offset", offset);

  return axiosInstance.post("web-master/get-all-tasks", Chat);
}

const addWebmasterTask = (interactionId) => {
  const Msg = new FormData();

  Msg.set("interactionId", interactionId);

  return axiosInstance.post("web-master/add-task", Msg);
}

const completeWebmasterTask = (taskId) => {
  const Task = new FormData();

  Task.set("taskId", taskId);

  return axiosInstance.post("web-master/finish-task", Task);
};

const setContactAudioStatus = (status, contactId) => {
  const Status = new FormData();

  Status.set('status', status);
  Status.set('callerId', contactId);

  return axiosInstance.post('callers/add-important-interaction-caller', Status);
};

const setRoomAudioStatus = (status, roomId) => {
  const Status = new FormData();

  Status.set('status', status);
  Status.set('chatId', roomId);

  return axiosInstance.post('chat/add-important-interaction', Status);
};

const getContactDateMsgContext = (contextDate, contactId, page = null) => {
  const Chat = new FormData();

  Chat.set("date", contextDate);
  Chat.set("callerId", contactId);
  Chat.set("limit", LIMIT);

  page && Chat.set("page", page);

  return axiosInstance.post("interactions/get-messages-by-date", Chat);
}

const getContactChatOfHistorySession = (sessionId, contactId, page = null) => {
  const Chat = new FormData();

  Chat.set("sessionId", sessionId);
  Chat.set("limit", LIMIT);
  Chat.set("callerId", contactId);

  page && Chat.set("page", page);

  return axiosInstance.post("interactions/get-interactions-by-session-id", Chat);
}

const cleanContactsUnreadCount = (activeTab) => {
  const Contact = new FormData();

  Contact.set("type", activeTab);

  return axiosInstance.post("callers/set-all-read", Contact);
}

const mergeContactCards = (newCard, deletingCardId) => {
  const Card = new FormData();

  Card.set("callerIdForDelete", deletingCardId)
  Card.set("callerNew", JSON.stringify(newCard));
  Card.set("callerIdNew", newCard.id);

  return axiosInstance.post("callers/merge-contact", Card);
};

// DIVA API

const getDivaGirls = (params) => {
  const newParams = {};
  const config = {};
  const setParam = (key, value) => {
    newParams[key] = value;
  }

  if (!params.part) {
    params.part = 1;
  }
  if (!params.limit) {
    params.limit = DIVA_GIRLS_LIMIT;
  }
  setParam('format', 'json');
  params['same-params'] && setParam('same-params', params['same-params']);
  params['filterby'] && setParam('filterby', params['filterby']);
  params['host-profile'] && setParam('host-profile', params['host-profile']);
  params['sortby'] && setParam('sortby', params['sortby']);
  !params['includeoff'] && setParam('includeoff', params['includeoff']);
  !params['include-banned'] && setParam('include-banned', params['include-banned']);
  !params['on-holidays'] && setParam('on-holidays', params['on-holidays']);
  params['filter-is_available_now'] && setParam('filter-is_available_now', params['filter-is_available_now']);
  params['search'] && setParam('search', params['search']);
  params['bestmatch'] && setParam('bestmatch', params['bestmatch']);
  params['limit'] && setParam('limit', params['limit']);
  params['part'] && setParam('part', params['part']);
  params['format'] && setParam('format', params['format']);
  params['filter-age_filter'] && setParam('filter-age_filter', params['filter-age_filter']);
  params['filter-height_filter'] && setParam('filter-height_filter', params['filter-height_filter']);
  params['filter-breast_filter'] && setParam('filter-breast_filter', params['filter-breast_filter']);
  params['filter-ethnicity'] && setParam('filter-ethnicity', params['filter-ethnicity']);
  params['filter-hair'] && setParam('filter-hair', params['filter-hair']);
  params['filter-location'] && setParam('filter-location', params['filter-location']);
  params['filter-language'] && setParam('filter-language', params['filter-language']);
  params['filter-price_filter'] && setParam('filter-price_filter', params['filter-price_filter']);
  params['filter-dress_filter'] && setParam('filter-dress_filter', params['filter-dress_filter']);
  params['filter-nationality'] && setParam('filter-nationality', params['filter-nationality']);
  params['filter-city'] && setParam('filter-city', params['filter-city']);
  params['filter-services'] && setParam('filter-services', params['filter-services']);
  params['filter-profileid'] && setParam('filter-profileid', params['filter-profileid']);
  params['filter-ids'] && setParam('filter-ids', params['filter-ids']);
  params['opt-with-review'] && setParam('opt-with-review', params['opt-with-review']);
  params['isPreviouslyBooked'] && setParam('isPreviouslyBooked', params['isPreviouslyBooked']);
  config.params = newParams;

  if (params.cancelToken) {
    config.cancelToken = params.cancelToken;
  }

  return axiosInstance.get('diva/girls', config);
};

const getPrivatePhotos = (profileId, part = 0) => {
  const params = {};

  params.limit = DIVA_GIRLS_LIMIT;
  params.part = part;
  params.format = 'json';
  params.girlId = profileId;

  return axiosInstance.get('diva/private-photo', { params });
}

const getPrivateVideos = (profileId, part = 0) => {
  const params = {};

  params.limit = DIVA_GIRLS_LIMIT;
  params.part = part;
  params.format = 'json';
  params.girlId = profileId;

  return axiosInstance.get('diva/private-video', { params });
}

const getDivaFilters = ({ part = 1, limit = DIVA_GIRLS_LIMIT, ...params }) => {
  const config = {
    params: {
      limit,
      part,
      format: 'json',
      'sort-name': 'asc',
      ...params
    }
  };

  return axiosInstance.get('diva/filters', config);
};

const getAdditionalFiltersFromDiva = () => {
  return axiosInstance.get('diva/get-sales-filters-with-value');
}

// Instead of sessionId may be also galleryId (for default session)
// profileIds may be a one (Number) or several ids (Array)
const addProfilesToGallery = (sessionId, profileIds) => {
  return axiosInstance.get('diva/add-to-gallery', {
    params: {
      sessid: sessionId,
      list: profileIds,
    }
  });
};

const removeProfileFromGallery = (sessionId, profile) => {
  return axiosInstance.get('diva/remove-from-gallery', {
    params: {
      sessid: sessionId,
      list: profile
    }
  });
};

const getGalleryUrlBySessionId = (sessionId) => {
  return axiosInstance.get('diva/gallery', {
    params: {
      sessid: sessionId
    }
  });
};

const getDivaGirlsByContactsIds = (girlIds) => {
  return axiosInstance.get('diva/get-diva-girls-by-callers-id', {
    params: {
      callerIds: Array.isArray(girlIds) ? JSON.stringify(girlIds) : girlIds
    }
  })
}

const sendMailing = (message, contacts) => {
  const Mailing = new FormData();

  Mailing.set("To", JSON.stringify(contacts.id));
  Mailing.set("message", message.text || "");
  Mailing.set("shortcuts", message.shortcuts);

  return axiosInstance.post("messages/send-mailing", Mailing);
};

const getMailingHistory = (limit = 20, offset = 0, query = '') =>
  axiosInstance.get(`messages/get-mailing?limit=${limit}&offset=${offset}&query=${query}`);

const getContactsForMerge = (offset = 0) => {
  const config = {
    params: {
      limit: CONTACTS_LIMIT,
      offset
    }
  };

  return axiosInstance.get('callers/get-merge-contacts', config);
};

// Not For
const getClientNotFor = caller_id =>
  axiosInstance.get('caller-not-for/view', { params: { caller_id } });

const addClientNotFor = (contact_id, contact_ignore_id) => {
  const NotForData = new FormData();

  NotForData.set('caller_id', String(contact_id));
  NotForData.set('caller_ignore_id', String(contact_ignore_id));

  return axiosInstance.post('caller-not-for/create', NotForData);
};

const removeClientNotFor = (contact_id, contact_ignore_id) => {
  const RemoveData = new FormData();

  RemoveData.set('caller_id', String(contact_id));
  RemoveData.set('caller_ignore_id', String(contact_ignore_id));

  return axiosInstance.post('caller-not-for/delete', RemoveData);
};

const getPhoneLabels = () => axiosInstance.get('callers/get-contacts-labels');

const getLinksLabels = () => axiosInstance.get('callers/get-links-labels');

const getNightBotReport = () => axiosInstance.get('night-bot/index');

const deleteNightBotReportSession = (session_id) => {
  const DeleteData = new FormData();

  DeleteData.set('session_id', String(session_id));

  return axiosInstance.post('night-bot/view', DeleteData);
}

const redirectIncomingCall = (call_id, user_redirect_id = null) => {
  const RedirectData = new FormData();

  RedirectData.set('call_id', String(call_id));
  user_redirect_id && RedirectData.set('user_redirect_id', String(user_redirect_id));

  return axiosInstance.post('calls/receive-redirect', RedirectData);
}

const getGirlsMsgCountToday = userTimezone => {
  const MsgCountData = new FormData();

  const currentTimeByTimeZone = getDateByTimezoneOffset(userTimezone);
  const dateFromByTimeZone = currentTimeByTimeZone.getHours() < 4
    ? new Date(currentTimeByTimeZone.setDate(currentTimeByTimeZone.getDate() - 1))
    : currentTimeByTimeZone;

  const timeFrom = dateFromByTimeZone.setHours(4, 0, 0, 0) -
    (userTimezone - (new Date().getTimezoneOffset() * (-1))) * 60000;

  MsgCountData.set('timeFrom', timeFrom);

  return axiosInstance.post('interactions/girls-count', MsgCountData);
};

const sortProfileReviews = (profileId, sortBy) => {
  const config = {
    params: {
      profileId,
      sortBy,
    }
  };

  return axiosInstance.get('diva/sort-review', config);
};

const searchProfileReviews = (profileId, text) => {
  const config = {
    params: {
      profileId,
      text,
    }
  };

  return axiosInstance.get('diva/search-by-text-review', config);
};

const editProfileReview = (reviewId, text, is_published) => {
  const EditReviewData = new FormData();

  EditReviewData.set('reviewId', reviewId);
  text && EditReviewData.set('text', text);
  (is_published !== null) && EditReviewData.set('is_published', is_published);

  return axiosInstance.post('diva/edit-review', EditReviewData);
};

const deleteProfileReview = reviewId => {
  const DeletedReviewId = new FormData();

  DeletedReviewId.set("reviewId", reviewId);

  return axiosInstance.post('diva/delete-review', DeletedReviewId);

};

const changeUserTimeFormat = hour12 => {
  const Hour12 = new FormData();

  hour12 && Hour12.set("isHour12", hour12);

  return axiosInstance.post('user/change-time-format', Hour12);
};

const takeFutureBooking = (sessionId, userId) => {
  const FutureBookingData = new FormData();

  FutureBookingData.set("sessionId", sessionId);
  FutureBookingData.set("userId", userId);


  return axiosInstance.post('sales-session/take-future-booking', FutureBookingData);
};

const getContactScheduledMsgs = (contactId, page = null) => {
  const config = {
    params: {
      callerId: contactId,
      limit: LIMIT
    }
  };

  if (page) {
    config.params.page = page
  }

  return axiosInstance.get('schedule-messages', config);
}

const getScheduledMsgsCount = (contactId) => axiosInstance.get('/schedule-messages/get-total?callerId=' + contactId);

const createScheduledMsg = (msg) => {
  const Msg = new FormData();

  Msg.set('callerId', msg.contactId);
  Msg.set('body', msg.body);
  Msg.set('date', msg.date);
  msg.bookingId && Msg.set('bookingId', msg.bookingId);

  return axiosInstance.post('schedule-messages/add', Msg);
}

const updateScheduledMsg = (msg) => {
  const Msg = new FormData();

  Msg.set('id', msg.id);
  msg.body && Msg.set('body', msg.body);
  msg.date && Msg.set('date', msg.date);
  msg.contactId && Msg.set('callerId', msg.contactId);

  return axiosInstance.post('schedule-messages/edit', Msg);
}

const deleteScheduledMsg = (msgId) => {
  const Msg = new FormData();

  Msg.set('id', msgId);

  return axiosInstance.post('schedule-messages/delete', Msg);
};
let cancelGetSystemMsgs;

const getContactServiceMsgs = (contactId, page = null, withCancel = true) => {
  if (cancelGetSystemMsgs) {
    cancelGetSystemMsgs('Request "getSystemMsgs" canceled');
  }

  const config = {
    params: {
      limit: LIMIT,
    },
    cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      if (withCancel) {
        cancelGetSystemMsgs = c;
      }
    })
  };

  if (page) {
    config.params.page = page;
  }

  return axiosInstance.get('interactions/caller-system/' + contactId, config);
};

const getServiceMsgsCount = (contactId) => {
  const config = {
    params: {
      callerId: contactId
    }
  };

  return axiosInstance.get('interactions/caller-system', config)
};

//WebRTC
const offerStartCall = (operatorId) => {
  const Offer = new FormData();

  Offer.set('operatorId', operatorId);

  return axiosInstance.post('web-rtc/initiate-incoming-call', Offer);
}

const answerStartCall = (callId) => {
  const Answer = new FormData();

  Answer.set('interactionId', callId);

  return axiosInstance.post('web-rtc/pick-up', Answer);
}

const sendLocalDescription = (operatorId, description, type) => {
  const Answer = new FormData();

  Answer.set('operatorId', operatorId);
  Answer.set('description', JSON.stringify(description));
  Answer.set('type', type);

  return axiosInstance.post('web-rtc/send-local-description', Answer);
}

const sendIceCandidate = (operatorId, candidate) => {
  const IceCandidate = new FormData();

  IceCandidate.set('operatorId', operatorId);
  IceCandidate.set('candidate', JSON.stringify(candidate));

  return axiosInstance.post('web-rtc/init-connections', IceCandidate);
}

const dismissWebrtcCall = (callId) => {
  const Call = new FormData();

  Call.set('interactionId', callId);

  return axiosInstance.post('web-rtc/cancel', Call);
}

const hangUpWebrtcCall = (activeCallId) => {
  const Call = new FormData();

  Call.set('interactionId', activeCallId);

  return axiosInstance.post('web-rtc/hang-up', Call);
}

const sendConnectionStatus = (callId, status) => {
  const ConnectionStatus = new FormData();

  ConnectionStatus.set('interactionId', callId);
  ConnectionStatus.set('status', status);

  return axiosInstance.post('web-rtc/check-connection', ConnectionStatus);
}

//WebRTC conferences
const getWebrtcConferences = () => axiosInstance.get("web-rtc-conference/get-all");

const joinToWebrtcConference = (conferenceId) => {
  const Conference = new FormData();

  Conference.set('conferenceId', conferenceId);

  return axiosInstance.post('web-rtc-conference/join', Conference);
}

const leaveWebrtcConference = () => axiosInstance.post('web-rtc-conference/leave');

const inviteParticipantsToWebrtcConference = (conferenceId, operatorIds) => {
  const ConferenceInvite = new FormData();

  ConferenceInvite.set('conferenceId', conferenceId);
  ConferenceInvite.set('operatorIds', JSON.stringify(operatorIds));

  return axiosInstance.post('web-rtc-conference/invite', ConferenceInvite);
}

const changeWebrtcConferenceStatus = (conferenceId, isClosed) => {
  const ConferenceStatus = new FormData();

  ConferenceStatus.set('conferenceId', conferenceId);
  ConferenceStatus.set('is_closed', isClosed ? 1 : 0);

  return axiosInstance.post('web-rtc-conference/change-conference-status', ConferenceStatus);
}

const changeParticipantStatus = (participantId, status) => {
  const ConferenceParticipantStatus = new FormData();

  ConferenceParticipantStatus.set('activeParticipantId', participantId);
  ConferenceParticipantStatus.set('status', status);

  return axiosInstance.post('web-rtc-conference/change-participant-connection-status', ConferenceParticipantStatus);
}

const acceptWebrtcConference = (conferenceId) => {
  const Conference = new FormData();

  Conference.set('conferenceId', conferenceId);

  return axiosInstance.post('web-rtc-conference/accept-invitation', Conference);
}

const rejectWebrtcConference = (conferenceId) => {
  const Conference = new FormData();

  Conference.set('conferenceId', conferenceId);

  return axiosInstance.post('web-rtc-conference/reject-invitation', Conference);
};

const webrtcParticipantMute = (isMute) => {
  const Participant = new FormData();

  Participant.set('is_muted', isMute ? 1 : 0);

  return axiosInstance.post('web-rtc-conference/mute', Participant);
};

const webrtcConferenceMute = (isMute) => {
  const Participant = new FormData();

  Participant.set('is_muted_conference', isMute ? 1 : 0);

  return axiosInstance.post('web-rtc-conference/mute-conference', Participant);
};

const getMinifiedContactsWithOneEmail = (config = {}) => {
  // Returns minified contacts where each email has its own contact.
  // If the contact has two emails, two objects will be returned

  const apiConfig = {
    params: {
      limit: MAIL_SEARCH_CONTACTS_LIMIT,
    }
  };

  if (config.part) {
    apiConfig.params.part = config.part;
  }

  if (config.search) {
    apiConfig.params.query = config.search;
  }

  return axiosInstance.get('callers/search-for-email', apiConfig);
}


const getContactsForAdrBook = (config = {}) => {
  const apiConfig = {
    params: {
      limit: ADR_BOOK_LIMIT,
    }
  };

  if (config.sync) {
    apiConfig.params.sync = config.sync;
  }
  if (config.offset) {
    apiConfig.params.offset = config.offset;
  }
  if (config.contactType) {
    apiConfig.params.contactType = config.contactType;
  }
  if (config.search) {
    apiConfig.params.search = config.search;
  }
  if (config.includeUnsaved) {
    apiConfig.params.includeUnsaved = config.includeUnsaved;
  }
  if (config.activeTags) {
    apiConfig.params.tagIds = JSON.stringify(Object.keys(config.activeTags));
  }
  if (config.sortBy) {
    apiConfig.params.sortBy = config.sortBy;
  }

  return axiosInstance.get("callers/get-for-address-book", apiConfig);
};

const getAdrBookTags = (contactType) => {
  const apiConfig = {
    params: {}
  }
  if (contactType) apiConfig.params.contactType = contactType;

  return axiosInstance.get("tags/get-for-address-book", apiConfig);
}

const getContactNotes = (callerId, offset = 0, limit = 20) => {
  const apiConfig = {
    params: {
      limit,
      offset,
      callerId,
    }
  }

  return axiosInstance.get('callers-notes', apiConfig);
}

const addFeedback = ({
  clientId,
  divaId,
  type,
  date,
  time,
  duration,
  sourceId,
  sourceType,
  text,
}) => {
  const feedback = new FormData();

  feedback.set('caller_id', clientId);
  feedback.set('diva_id', divaId);
  feedback.set('type', type);
  feedback.set('booking_date', date);
  feedback.set('booking_time', time);
  feedback.set('booking_duration', duration);
  feedback.set('source_id', sourceId);
  feedback.set('source_type', sourceType);
  feedback.set('body', text);

  return axiosInstance.post('callers-notes/add', feedback);
}

const editContactNote = props => {
  const Note = new FormData();

  Note.set('id', props.noteId);
  Note.set('body', props.text);
  Note.set('type', props.type);
  Note.set('diva_id', props.divaId);
  Note.set('source', props.sourceId);
  Note.set('booking_date', props.date);
  Note.set('booking_time', props.time);
  Note.set('caller_id', props.clientId);
  Note.set('source_type', props.sourceType);
  Note.set('booking_duration', props.duration);
  Note.set('date_created', props.date_created);

  return axiosInstance.post('callers-notes/edit', Note);
}

const deleteContactNote = (noteId) => {
  const Note = new FormData();

  Note.set('id', noteId);

  return axiosInstance.post('callers-notes/delete', Note);
}

const getEscortsForFeedback = ({
  query = '',
  offset = 0,
  limit = 20,
  cancelToken,   // optional
}) => {
  const config = {
    params: { query, offset, limit },
    cancelToken,
  };

  return axiosInstance.get('callers/get-for-reviews', config);
}


const getOperatorsForFeedback = ({ query = '', offset = 0, limit = 20, cancelToken }) => {
  const config = {
    params: { query, offset, limit },
    cancelToken,
  };

  return axiosInstance.get('/users/search', config);
};

const getCancelBookingReasons = () => axiosInstance.get('booking/get-reasons');

const searchContactsByMsg = (type, msg, cancelToken) => {
  const Msg = new FormData();

  Msg.set('query', msg);
  Msg.set('type', type);

  const config = {};

  if (cancelToken) {
    config.cancelToken = cancelToken;
  }

  return axiosInstance.post('callers/search-by-message', Msg, config);
};

const getGirlsWithStatus = (status) => {
  const config = {
    params: {
      status
    }
  };

  return axiosInstance.get('callers/get-girls-with-status', config);
};

const syncContacts = () => {
  return axiosInstance.get('callers/sync-contacts');
}

// const getEmails = (config = {}) => {
//   const apiConfig = {
//     params: {
//       type: config.type,
//       limit: config.limit,
//       offset: config.offset,
//     }
//   }

//   if (config.mode) {
//     apiConfig.params.messageType = config.mode;
//   }

//   return axiosInstance.get('mail/get-messages', apiConfig);
// }

const getEmails = ({ offset, limit, type, mode, sortBy } = {}) => {
  const params = {
    offset,
    limit,
    folder: type,
    sortBy,
  }

  if (mode) {
    params.messageType = mode;
  }

  return axiosInstance.get('mail/get-mail-conversations', { params });
}

const getFilteredBySearchQueryEmails = ({ offset, limit, search } = {}) => {
  const params = {
    offset,
    limit,
    search,
  };

  return axiosInstance.get('mail-search/search', { params });
}

const getMailChatMessages = (conversationId, folder) => {
  const apiConfig = {
    params: {
      conversationId,
      folder,
    }
  }

  return axiosInstance.get('mail/get-mail-conversation', apiConfig);
}

const getCountOfUnreadEmails = () => {
  return axiosInstance.get('mail/get-count-unread-messages');
}

const sendEmailMessage = ({ config, isBeacon }) => {
  const Email = new FormData();

  Email.set('body', config.body);
  Email.set('subject', config.subject);
  Email.set('recipient', config.recipient);
  Email.set('isDraft', Number(!!config.isDraft));
  config.draftId && Email.set('draftId', config.draftId);
  config.delay && Email.set('timeDelay', config.delay);

  // Beacon is for send unsaved draft on window unload
  if (isBeacon) {
    const { baseURL } = axiosInstance.defaults;

    return navigator.sendBeacon(`${baseURL}mail/send-message`, Email);
  }

  // Normal request by default
  return axiosInstance.post('mail/send-message', Email);
}

const sendEmailReply = ({ config, isBeacon }) => {
  const Reply = new FormData();

  Reply.set('body', config.body || '');
  Reply.set('replyOnId', config.replyOnId);
  Reply.set('isDraft', Number(!!config.isDraft));
  config.draftId && Reply.set('draftId', config.draftId);
  config.delay && Reply.set('timeDelay', config.delay);

  // Beacon is for send unsaved draft on window unload
  if (isBeacon) {
    const { baseURL } = axiosInstance.defaults;

    return navigator.sendBeacon(`${baseURL}mail/reply`, Reply);
  }

  // Normal request by default
  return axiosInstance.post('mail/reply', Reply);
}

const deleteMailsToTrash = ({ msgId, conversationsIds, limit, mode, offset, sortBy, folder }) => {
  const params = {};

  if (msgId) {
    params.id = msgId;
  } else if (conversationsIds.length) {
    params.limit = limit;
    params.offset = offset;
    params.sortBy = sortBy;
    params.folder = folder;
    params.messageType = mode;
    params.conversationId = JSON.stringify(conversationsIds);
  }

  return axiosInstance.get('mail/delete-message', { params, baseURL: baseUrlV2 });
}

const deleteMailsPermanently = ({ msgId, conversationsIds, limit, offset, sortBy, mode, folder }) => {
  const params = {};

  if (msgId) {
    params.id = msgId;
  } else if (conversationsIds.length) {
    params.limit = limit;
    params.offset = offset;
    params.sortBy = sortBy;
    params.folder = folder;
    params.messageType = mode;
    params.conversationId = JSON.stringify(conversationsIds);
  }

  return axiosInstance.get('mail/permanently-delete', { params, baseURL: baseUrlV2 });
}

const deleteMailsFromSearchList = ({ conversationsIds, limit, offset, search }) => {
  const params = {};

  if (conversationsIds.length) {
    params.limit = limit;
    params.offset = offset;
    params.search = search;
    params.conversationId = JSON.stringify(conversationsIds);
  }

  return axiosInstance.get('mail-search/delete', { params });
};

const moveMailsToFolder = ({ conversationsIds, to, limit, offset, sortBy, mode, folder }) => {
  const params = {
    to,
    limit,
    offset,
    sortBy,
    folder,
    from: folder,
    messageType: mode,
    conversationIds: JSON.stringify(conversationsIds),
  };

  return axiosInstance.get('mail/move-to-folder', { params });
};

const archiveMails = ({ conversationsIds, limit, offset, sortBy, mode, folder }) => {
  const params = {
    limit,
    offset,
    sortBy,
    folder,
    messageType: mode,
    conversationIds: JSON.stringify(conversationsIds),
  };

  return axiosInstance.get('mail/archive', { params });
};

const markMailsNotSpam = ({ conversationId }) => {
  const params = {
    conversationId,
  };

  return axiosInstance.get('mail/mark-un-spam', { params });
}

const setIsMailStarred = ({ conversationId, isStarred }) => {
  const Data = new FormData();

  Data.set('conversationId', conversationId);
  Data.set('isStarred', isStarred ? 1 : 0);

  return axiosInstance.post('/mail/set-starred', Data);
}

const undeleteMails = ({ msgId, conversationId }) => {
  const params = {};

  if (msgId) {
    params.id = msgId;
  } else if (conversationId) {
    params.conversationId = conversationId;
  }

  return axiosInstance.get('mail/undelete', { params });
}

const deleteMailDraft = (draftId) => {
  const params = {
    id: draftId,
  };

  return axiosInstance.get('mail/discard-draft', { params });
}

const uploadMailAttachments = ({ uploadFiles, draftId, replyOnId }) => {
  const Data = new FormData();

  uploadFiles.forEach(file => Data.append('files[]', file));
  Data.set('draftId', draftId);
  replyOnId && Data.set('replyOnId', replyOnId);

  return axiosInstance.post('mail-attachment/attach-file-to-draft', Data);
}

const deleteMailAttachment = ({ delId }) => {
  const params = {
    id: delId,
  };

  return axiosInstance.delete('mail-attachment/delete', { params });
}

const cancelDelayedMail = ({ id }) => {
  const Data = new FormData();

  Data.set('uuid', id);

  return axiosInstance.post('mail/cancel-sending', Data);
}

const cancelDeleteMails = ({ id, conversationsIds, limit, offset, sortBy, mode, folder }) => {
  const params = {};

  if (id) {
    params.id = id;
  } else if (conversationsIds.length) {
    params.limit = limit;
    params.offset = offset;
    params.sortBy = sortBy;
    params.folder = folder;
    params.messageType = mode;
    params.conversationId = JSON.stringify(conversationsIds);
  }

  return axiosInstance.get('mail/cancel-delete', { params, baseURL: baseUrlV2 });
};

const cancelDeleteMailsFromSearchList = ({ conversationsIds, limit, offset, search }) => {
  const params = {};

  if (conversationsIds.length) {
    params.limit = limit;
    params.offset = offset;
    params.search = search;
    params.conversationId = JSON.stringify(conversationsIds);
  }

  return axiosInstance.get('mail-search/cancel-delete', { params });
};

const cancelMoveTo = ({ conversationsIds, to, limit, offset, sortBy, mode, folder }) => {
  const params = {
    to,
    limit,
    offset,
    sortBy,
    folder,
    messageType: mode,
    conversationIds: JSON.stringify(conversationsIds),
  };

  return axiosInstance.get('mail/cancel-move-to', { params });
};

const cancelArchiveMails = ({ mailMovesIds, limit, offset, sortBy, mode, folder }) => {
  const params = {
    limit,
    offset,
    sortBy,
    folder,
    messageType: mode,
    mailMovesIds: JSON.stringify(mailMovesIds),
  };

  return axiosInstance.get('mail/cancel-archive', { params });
};

const deleteMailAttsBeacon = ({ draftId }) => {
  const { baseURL } = axiosInstance.defaults;

  navigator.sendBeacon(`${baseURL}mail-attachment/delete?id=${draftId}`);
}

const banMedia = (caller_id, ban) => {
  const BanData = new FormData();

  BanData.set('caller_id', caller_id);
  BanData.set('ban', ban);

  return axiosInstance.post('callers/ban-media', BanData);
}

const changeOperator = (user_id, session_id) => {
  const Operator = new FormData();

  Operator.set('user_id', user_id);
  Operator.set('session_id', session_id);

  return axiosInstance.post('booking/change-user', Operator);
}

const deleteTelegramMsg = id => {
  const Data = new FormData();

  Data.set('message_id', id);

  return axiosInstance.post('message/delete-telegram-message', Data);
}

const getSessionLog = (sessionId) => {
  const SessionLog = new FormData();

  SessionLog.set('sessionId', sessionId);

  return axiosInstance.post('sales-session/get-session-log', SessionLog);
}

const changeProfileRates = ({ profileId, pricesDefault, pricesMember, pricesSecret }) => {
  const Prices = new FormData();

  Prices.set('id', profileId);
  Prices.set('prices', JSON.stringify(pricesDefault));
  Prices.set('prices_member', JSON.stringify(pricesMember));
  Prices.set('prices_secret', JSON.stringify(pricesSecret));

  return axiosInstance.post('diva/change-rates', Prices);
}

const getBookingsByFilters = (date, type, caller_type, is_prebooking, search) => {
  const config = {
    params: {
      date,
      type,
      caller_type,
      is_prebooking,
      search,
    }
  }
  return axiosInstance.get('sales-sessions/bookings-by-date', config);
}


//----------------dialpad start-------------------------------------------------------->
const getDefaultCallersList = (offset = 0, limit = CONTACTS_LIMIT) => {
  const params = {
    limit,
    offset,
  }

  return axiosInstance.get('dialpad/get-default-callers-list', { params, baseURL: baseUrlV2 });
}
const getRecentCallsList = (offset = 0, limit = CONTACTS_LIMIT) => {
 const params = {
    limit,
    offset,
 }
  
  return axiosInstance.get('dialpad/get-recent-contacts', { params, baseURL: baseUrlV2 });
}

const searchContactsDialpad = ( query, offset = 0, limit = CONTACTS_LIMIT, cancelToken) => {
  const SearchData = new FormData();

  SearchData.set("query", query);
  SearchData.set("limit", limit);
  SearchData.set("offset", offset);

  const config = {
    baseURL: baseUrlV2
  };

  if (cancelToken) {
    config.cancelToken = cancelToken;
  }

  return axiosInstance.post("dialpad/search-by-callers", SearchData, config);
};

//----------------dialpad end-------------------------------------------------------->
/* Cronjobs API */

const getAllRequirements = () => axiosInstanceCronjobs.get('requirements');

const getCounters = () => axiosInstanceCronjobs.get('statistics/counters');

const getActiveChatContacts = (type, offset = 0) => {
  const config = {
    params: {
      limit: CONTACTS_LIMIT,
      type,
      offset,
    }
  };

  return axiosInstanceCronjobs.get('callers/active-chats', config);
};

const getContactList = (type, offset = 0, limit) => {
  const config = {
    params: {
      limit: limit || CONTACTS_LIMIT,
      type,
      offset
    }
  };

  return axiosInstanceCronjobs.get('callers/callers-by-type', config);
};

const getAllCalls = () => axiosInstanceCronjobs.get('calls/queues');

const getOperatorsList = () => axiosInstanceCronjobs.get('users');

const addGirlsIdsThatLinksIsSendInChat = (sessionId, girlId) => {
  const Session = new FormData();

  Session.set("sessionId", sessionId);

  Session.set("girlId", girlId);

  return axios.post("sales-session/add-message-girl-id", Session);
};

const API = {
  changeOperator,
  getSessionLog,
  banMedia,
  loginUser,
  logoutUser,
  updateUserTimezone,
  getAllCalls,
  getConnectedContacts,
  removeMissedCallForAll,
  putCallOnHold,
  hangUpCallOnHold,
  createTransferredCall,
  getAllVoicemails,
  getInteractions,
  sendMessage,
  markMessageAsUnread,
  markContactChatAsRead,
  setMessageReminder,
  getConversationMedia,
  getContactLog,
  getContactList,
  getActiveDivaProfiles,
  getContactsById,
  getAllContactsList,
  saveContact,
  removeContact,
  toggleContactPin,
  toggleContactMark,
  toggleContactBlock,
  getFilteredContacts,
  getActiveChatContacts,
  toggleContactActiveChat,
  searchTags,
  getTopTags,
  addContactTag,
  removeContactTag,
  getAllTemplates,
  getMsgShortcuts,
  getOperatorsList,
  changeBusyStatus,
  changeUserAvatar,
  getUserRingtones,
  updateUserRingtone,
  getChatInteractions,
  sendMessageToChat,
  updateChatMsg,
  removeChatMsg,
  markChatAsRead,
  sendDeliveredStatus,
  sendTyping,
  // getPrivateRooms,
  getRoomsById,
  getRoomsList,
  searchRooms,
  saveRoom,
  getChatRooms,
  getTwilioToken,
  searchContacts,
  searchMessageInChat,
  searchContactMessage,
  getMessageContext,
  getAllSessions,
  getSessionById,
  createBooking,
  getContactUnfinishedBookings,
  getBooking,
  getBookingsByFilters,
  getBookingsByCallerId,
  getBookingsDividedByDate,
  getContactsForBookings,
  updateBooking,
  deleteBooking,
  updateBookingsRequirementChecked,
  getOverdueBookings,
  addBookingRequest,
  updateBookingRequest,
  deleteBookingRequest,
  getContactRequests,
  getContactRequestsCount,
  getSuccessfulSessions,
  createSession,
  closeSession,
  deleteSession,
  updateComparedIds,
  removeComparedId,
  addBufferId,
  removeBufferedId,
  updateActiveFilters,
  updateAdditionalFilters,
  addBookedId,
  removeBookedId,
  getSessionsHistory,
  toggleHistorySessionSuccess,
  continueClosedSession,
  duplicateClosedSession,
  transferTheSession,
  responseForTransferSession,
  addAssistantToSession,
  responseForAddToAssistant,
  removeAssistantFromSession,
  removeViewedInSession,
  updateUserSalesFilters,
  lockContact,
  getUnreadChats,
  markVoicemailAsListened,
  changeDefaultContactTel,
  changeDefaultContactEmail,
  changeClientInSession,
  updateGalleryUrlInSession,
  getClientSessionCounts,
  getGirlBookings,
  getIsGirlHasBookingsForToday,
  getChatMessageContext,
  removeUserFromRoom,
  leaveRoom,
  removeRoomForAll,
  addUsersFromRoom,
  updateRoom,
  addProposedId,
  removeProposedId,
  createWarmTransferCall,
  getChatConversationMedia,
  getGirlsChatInteraction,
  fetchCallsLog,
  cancelConferenceInvitation,
  getAgentGirls,
  deleteGirlFromAgent,
  addGirlToAgent,
  searchGirls,
  leaveConference,
  getUserNotifications,
  readUserNotification,
  readAllUserNotifications,
  getUserNotificationsCount,
  changeSettingsUserMode,
  getSettingsAllNumbers,
  setSettingsPhoneNumberAsDefault,
  pinMsg,
  unpinMsg,
  clearAllMissedForMe,
  createNewChat,
  inviteParticipantsToConference,
  removeMissedContactCallForMe,
  removeMissedOperatorCallForMe,
  pinClientMsg,
  getAllMessageCount,
  updateUserInfo,
  getAllConferences,
  getContactReminders,
  addGirlToAvailableByMsgId,
  addGirlToAvailable,
  removeGirlFromAvailableByMsgId,
  removeGirlFromAvailable,
  changeGirlsStatus,
  addMessageToAvailableGlossary,
  removeMessageFromAvailableGlossary,
  getGirlsByDivaId,
  getAllWebmasterTasks,
  addWebmasterTask,
  completeWebmasterTask,
  removeMessageReminder,
  setContactAudioStatus,
  setRoomAudioStatus,
  getContactDateMsgContext,
  getContactChatOfHistorySession,
  cleanContactsUnreadCount,
  mergeContactCards,
  getIsIAmLoggedIn,
  getDivaGirls,
  getPrivatePhotos,
  getPrivateVideos,
  getDivaFilters,
  getAdditionalFiltersFromDiva,
  getNightBotReport,
  deleteNightBotReportSession,
  addProfilesToGallery,
  removeProfileFromGallery,
  getGalleryUrlBySessionId,
  sendMailing,
  getMailingHistory,
  getTimezones,
  getClientNotFor,
  addClientNotFor,
  removeClientNotFor,
  getPhoneLabels,
  getLinksLabels,
  getContactsForMerge,
  getGirlsByContactUid,
  getClientPrevBookedProfiles,
  changeContactDefaultChannel,
  redirectIncomingCall,
  getGirlsMsgCountToday,
  sortProfileReviews,
  searchProfileReviews,
  editProfileReview,
  deleteProfileReview,
  changeUserTimeFormat,
  takeFutureBooking,
  getContactScheduledMsgs,
  getScheduledMsgsCount,
  createScheduledMsg,
  updateScheduledMsg,
  deleteScheduledMsg,
  getContactServiceMsgs,
  getServiceMsgsCount,
  offerStartCall,
  answerStartCall,
  sendLocalDescription,
  sendIceCandidate,
  dismissWebrtcCall,
  hangUpWebrtcCall,
  getWebrtcConferences,
  joinToWebrtcConference,
  leaveWebrtcConference,
  inviteParticipantsToWebrtcConference,
  changeWebrtcConferenceStatus,
  changeParticipantStatus,
  acceptWebrtcConference,
  rejectWebrtcConference,
  webrtcParticipantMute,
  webrtcConferenceMute,
  sendConnectionStatus,
  getDivaGirlsByContactsIds,
  getContactsForAdrBook,
  getNotForContacts,
  getAdrBookTags,
  getContactNotes,
  addFeedback,
  editContactNote,
  deleteContactNote,
  getEscortsForFeedback,
  getOperatorsForFeedback,
  getCancelBookingReasons,
  searchContactsByMsg,
  getGirlsWithStatus,
  syncContacts,
  getEmails,
  getMailChatMessages,
  getCountOfUnreadEmails,
  sendEmailMessage,
  deleteTelegramMsg,
  sendEmailReply,
  deleteMailsToTrash,
  deleteMailsPermanently,
  deleteMailsFromSearchList,
  moveMailsToFolder,
  archiveMails,
  markMailsNotSpam,
  undeleteMails,
  deleteMailDraft,
  uploadMailAttachments,
  deleteMailAttachment,
  cancelDelayedMail,
  cancelDeleteMails,
  cancelDeleteMailsFromSearchList,
  cancelMoveTo,
  cancelArchiveMails,
  deleteMailAttsBeacon,
  changeProfileRates,
  getAllRequirements,
  getCounters,
  sendVerificationCode,
  resendVerificationCode,
  getDefaultCallersList,
  getRecentCallsList,
  setIsMailStarred,
  searchContactsDialpad,
  getMinifiedContactsWithOneEmail,
  deletePinnedMessage,
  getFilteredBySearchQueryEmails,
  addProfilesToSessionBuffer,
  addGirlsIdsThatLinksIsSendInChat,
};

export default API;
