import React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { v4 as uuid } from 'uuid';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';

import './ContactAddressTab.scss';
import FormTextTextarea from 'components/FormTextTextarea/FormTextarea';

const ContactAddressTab = props => {

  const { 
    editedContact,
  } = props;

  const onBlur = (e, input, removeEmptyField) => {
    input.onBlur && input.onBlur(e);
    removeEmptyField();
  };

  const onAddAddress = (fields) => {
    fields.push({ 
      address: '', 
      caller_id: editedContact.id,
      uuid: uuid(),
    })
  }

  return (
    <div className="contact-address-tab">
      <FieldArray name="addresses">
        {({ fields, meta }) => {
          const isDisabledAddBtn = fields.value?.length !== 0 && fields.value?.some(val => !val.address);
          return <>
            {fields.map((name, index) => (
              <Field
                key={name}
                name={`${name}.address`}
                className="contact-address-tab__input"
                render={(props) => {
                  const removeEmptyField = () => !fields.value[index].address && fields.remove(index);
                  return (
                    <div className={classModifier('contact-address-tab__input-wrap', 'address')}>
                      <FormTextTextarea
                        {...props}
                        autosize
                        inputProps={{
                          placeholder: "Address",
                          onBlur: (e) => onBlur(e, props.input, removeEmptyField)
                        }}
                      />
  
                      <ICONS.mapFill className="contact-address-tab__map-icon-wrap" width="12px"/>
                    </div>
                  )
                }
              }
              />
            ))}

            <div className="contact-address-tab__btn-wrapper">
              <button 
                type="button"
                className="contact-address-tab__btn-add"
                disabled={isDisabledAddBtn}
                onClick={() => onAddAddress(fields)}
              >
                <ICONS.plusCircle className="contact-address-tab__btn-add-icon" />
              </button>
            </div>
          </>
        }}
      </FieldArray>
    </div>
  )
};

export default ContactAddressTab;