import React, { useRef, useState } from 'react';

import './AdrBookPage.scss';
import AdrBookTags from 'containers/AdrBookTags/AdrBookTags';
import AdrBookHeader from 'containers/AdrBookHeader/AdrBookHeader';
import AdrBookListWrap from 'containers/AdrBookListWrap/AdrBookListWrap';
import AdrBookContactFormWrap from 'containers/AdrBookContactFormWrap/AdrBookContactFormWrap';


const AdrBookPage = () => { 
  const [undoDataProps, setUndoData] = useState({});
  const callbacksRef = useRef(null);

  const setPrevState = () => {
    if(callbacksRef?.current){
      return callbacksRef.current.setPrevState();
    }
  };

  const saveContact = () => {
    if(callbacksRef?.current){
      return callbacksRef.current.saveContact();
    }
  }
  
  return (
    <div className="adr-book-page">
      <AdrBookHeader 
        setPrevState={setPrevState}
        undoDataProps={undoDataProps} 
        saveContact={saveContact} 
      />

      <main className="adr-book-page__main">
        <AdrBookTags />

        <AdrBookListWrap />

        <AdrBookContactFormWrap 
          setUndoData={setUndoData} 
          callbacksRef={callbacksRef}
        />
      </main>
    </div>
  )
};

export default AdrBookPage;