import React, { Component, forwardRef } from 'react';
import { VariableSizeList as List } from 'react-window';

import "./AdrBookList.scss";
import AutoSizer from 'react-virtualized-auto-sizer';
import AdrBookListScrollWrapper from './AdrBookListScrollWrapper';
import Spinner from 'components/UI/Spinner/Spinner';


class AdrBookList extends Component {
  expectedListLength = this.props.limit;

  componentDidMount() {
    this.expectedListLength = this.props.list.length >= this.expectedListLength
      ? this.props.list.length
      : this.expectedListLength;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mode !== this.props.mode) {
      this.expectedListLength = this.props.list.length >= this.props.limit
        ? this.props.list.length
        : this.props.limit;
      console.log('limit', this.expectedListLength)

      return;
    }
    // if we remove contact
    if (prevProps.list.length - 1 === this.props.list.length) {
      return this.expectedListLength -= 1;
    }
    // if we add new contact
    if (prevProps.list.length + 1 === this.props.list.length) {
      return this.expectedListLength += 1;
    }
  }

  handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    const scrollBottom = scrollHeight - scrollTop - clientHeight;

    if (scrollBottom < 1500 && this.expectedListLength === this.props.list.length) {
      this.expectedListLength += this.props.limit;
      this.props.loadMore && this.props.loadMore(this.props.list.length);    // pass offset to loadMore func
    }
  };

  renderListItem = ({ index, style }) => {
    const ListItem = this.props.listItem;

    return (
      <ListItem
        style={style}
        id={this.props.list[index]}
      />
    )
  }

  outerListWrap = forwardRef((props, ref) => (
    <AdrBookListScrollWrapper
      {...props}
      forwardedRef={ref}
      customOnScroll={this.handleScroll} />
  ));

  innerListWrap = forwardRef(({ style, ...rest }, ref) => (
    <div
      ref={ref}
      style={{
        ...style,
        paddingTop: 5
      }}
      {...rest}
    />
  ));

  render() {
    console.log('AdrBookList Render');

    if (this.props.pending || !this.props.list.length) {
      return (
        <div className="adr-book-list adr-book-list--centered">
          {this.props.pending
            ? <Spinner spinnerSize='40px' />
            : "(no items)"
          }
        </div>
      )
    }

    return (
      <div className="adr-book-list">
        <AutoSizer>
          {({ width, height }) => (
            <List
              outerElementType={this.outerListWrap}
              innerElementType={this.innerListWrap}
              itemCount={this.props.list.length}
              itemSize={() => 50}
              height={height}
              width={width}
              className="adr-book-list__list"
            >
              {this.renderListItem}
            </List>
          )}
        </AutoSizer>
      </div>
    );
  }
}

export default AdrBookList;