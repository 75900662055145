import React, { useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";
import ReactSelect from "react-select";

import { classModifier } from "utils";
import { useDidMount, useDidUpdate } from "hooks";
import { updateProfileFilters } from 'redux/ducks/sessions';
import { getAdditionalFiltersFromDiva } from "redux/ducks/divaGirls";
import { ADDITIONAL_SESSION_FILTERS } from "config/constants";

const GalleryHeaderProfileFilters = (props) => {
  const {
    activeSession,
    canIEditSession,
    additionalFilters,
    divaAdditionalFilters,
  } = props;

  const values = useMemo(() => ({
    city: additionalFilters[ADDITIONAL_SESSION_FILTERS.city] || null,
    location: additionalFilters[ADDITIONAL_SESSION_FILTERS.location] || null,
    services: additionalFilters[ADDITIONAL_SESSION_FILTERS.services] || null,
    price: additionalFilters[ADDITIONAL_SESSION_FILTERS.price] || null,
    age: additionalFilters[ADDITIONAL_SESSION_FILTERS.age] || null,
    height: additionalFilters[ADDITIONAL_SESSION_FILTERS.height] || null,
    breast: additionalFilters[ADDITIONAL_SESSION_FILTERS.breast] || null,
    hair: additionalFilters[ADDITIONAL_SESSION_FILTERS.hair] || null,
    dress: additionalFilters[ADDITIONAL_SESSION_FILTERS.dress] || null,
    nationality: additionalFilters[ADDITIONAL_SESSION_FILTERS.nationality] || null,
    ethnicity: additionalFilters[ADDITIONAL_SESSION_FILTERS.ethnicity] || null,
    language: additionalFilters[ADDITIONAL_SESSION_FILTERS.language] || null,
  }), [activeSession, additionalFilters]);

  const [selectedValues, setSelectedValues] = useState(values);

  useDidMount(() => {
    props.getAdditionalFiltersFromDiva();
  })

  useDidUpdate(() => {
    setSelectedValues(values);
  }, [activeSession, additionalFilters]);

  const selectValue = useCallback((name, value) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const updateProfileFilters = (selectedValues) => {
    props.updateProfileFilters(selectedValues, activeSession, additionalFilters, canIEditSession);
  };

  const getOptions = values => values?.map(value => ({ label: value, value }));
  const getValue = (selectedValues) =>  selectedValues ? getOptions(selectedValues) : null;

  const options = useMemo(() => {
    const resultOptions = {};

    for (const key in divaAdditionalFilters) {
      resultOptions[key] = getOptions(divaAdditionalFilters[key]);
    }

    return resultOptions;
  }, [divaAdditionalFilters]);
  
  return (
    <div className="gallery-header-filters__main">
      {filtersConfig.map((group, idx) => (
        <div
          key={idx}
          className={classModifier('gallery-header-filters__group', `row-${idx + 1}`)}
        >
          {group.map((filter) => (
            <div key={filter.name} className="gallery-header-filters__filter">
              <span className="gallery-header-filters__filter-title">{filter.label}</span>
    
              <ReactSelect
                placeholder="select"
                className="react-select"
                classNamePrefix="react-select"
                isMulti
                isLoading={!divaAdditionalFilters}
                value={getValue(selectedValues[filter.name])}
                options={options[filter.optionName]}
                isSearchable={false} 
                onChange={(options) => {
                  selectValue(filter.name, options.map((option) => option.value));
                  updateProfileFilters({ 
                    ...selectedValues, 
                    [filter.name]: options.map((option) => option.value) 
                  });
                }}
                {...filter.selectProps}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

const mSTP = state => ({
  divaAdditionalFilters: state.divaGirls.divaAdditionalFilters,
})

const mDTP = {
  updateProfileFilters,
  getAdditionalFiltersFromDiva,
}

export default connect(mSTP, mDTP)(GalleryHeaderProfileFilters);

const filtersConfig = [
  [
    { label: 'City', name: 'city', optionName: 'city', selectProps: { isSearchable: true } },
    { label: 'Location', name: 'location', optionName: 'location', selectProps: { isSearchable: true } },
  ],
  [
    { label: 'Rate', name: 'price', optionName: 'price_filter' },
    { label: 'Age', name: 'age', optionName: 'age_filter' }
  ],
  [
    { label: 'Height', name: 'height', optionName: 'height_filter' },
    { label: 'Breast', name: 'breast', optionName: 'breast_filter' },
    { label: 'Hair', name: 'hair', optionName: 'hair' },
  ],
  [
    { label: 'Dress', name: 'dress', optionName: 'dress_filter' },
    { label: 'Ethnicity', name: 'ethnicity', optionName: 'ethnicity' },
    { label: 'Nationality', name: 'nationality', optionName: 'nationality', selectProps: { isSearchable: true } },
  ],
  [
    { label: 'Language', name: 'language', optionName: 'language', selectProps: { isSearchable: true } },
    { label: 'Services', name: 'services', optionName: 'services', selectProps: { isSearchable: true } },
  ]
];