import React, { useEffect, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';

import { selectUserTimezone } from 'redux/selectors/selectors';
import { classModifier, getDateByTimezoneOffset } from 'utils';
import API from 'api/api';
import ICONS from 'assets/icons';
import { useDidUpdate } from 'hooks';

import SearchInput from 'components/SearchInput/SearchInput';
import CustomScrollbarThumb from 'components/UI/CustomScrollbarThumb/CustomScrollbarThumb';
import CustomScrollbarTrack from 'components/UI/CustomScrollbarTrack/CustomScrollbarTrack';
import Spinner from 'components/UI/Spinner/Spinner';
import BookingListItem from './BookingListItem';
import './BookingsList.scss'

const BookingsList = () => {
  const initialFilteredBookings = {
    bookings: null,
    isActiveOut: false,
    isActiveAgent: false,
    isActivePre: false,
    isActiveSearch: false,
  };

  const [query, setQuery] = useState('');
  const [bookings, setBookings] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState(initialFilteredBookings);

  const [isPending, setPending] = useState(true);

  const userTimezone = useSelector((state) => selectUserTimezone(state));

  const getTodayByTimezone = () =>
    getDateByTimezoneOffset(userTimezone).setHours(0, 0, 0, 0) -
    (userTimezone - new Date().getTimezoneOffset() * -1) * 60000;

  const [date, setDate] = useState(getTodayByTimezone());

  useDidUpdate(() => {
    setDate(getTodayByTimezone());
  }, [userTimezone]);

  useEffect(() => {
    setPending(true);

    API.getBookingsByFilters(date)
      .then(res => setBookings(res.data))
      .catch(console.log)
      .finally(() => setPending(false));
  }, [date]);

  const getBookingsByFilters = ({
    activeButtons,
    type,
    caller_type,
    isPreBooking,
    search,
  }) => {
    setPending(true);

    API.getBookingsByFilters(date, type, caller_type, isPreBooking, search)
      .then((res) =>
        setFilteredBookings((prev) => ({
          ...prev,
          bookings: res.data,
          [activeButtons]: !prev[activeButtons],
        }))
      )
      .catch(console.log)
      .finally(() => setPending(false));
  };

  const cancelFilters = () => {
    setQuery('');
    setFilteredBookings(initialFilteredBookings);
  }

  const getBookingsItem = (someBookings) =>
    someBookings.length ? (
      someBookings.map((booking) => (
        <BookingListItem
          booking={booking}
          userTimezone={userTimezone}
          key={booking.id}
        />
      ))
    ) : (
      <div className="bookings-list__no-items">no items</div>
    );

  const {isActiveOut,isActiveAgent, isActivePre, isActiveSearch} = filteredBookings;

  const isFiltered = isActiveOut || isActiveAgent || isActivePre || isActiveSearch;

  return (
    <div className="bookings-list">
      <div className="bookings-list__header">
        <div className="bookings-list__header-top">
          <div className="bookings-list__buttons">
            <button
              className={classModifier("bookings-list__button", [
                isActiveOut && "active",
              ])}
              onClick={() =>
                getBookingsByFilters({
                  activeButtons: "isActiveOut",
                  type: isActiveOut ? null : "outcall",
                  caller_type: isActiveAgent ? 3 : null,
                  isPreBooking: isActivePre ? 1 : null,
                })
              }
              disabled={isPending}
            >
              <ICONS.out className="bookings-list__icon bookings-list__icon-out-bookings" />
            </button>
            {/* <button 
              className="bookings-list__button" 
              onClick={() => {}}
              disabled={isPending}  
            >
              <ICONS.duoGirls className="bookings-list__icon"/>
            </button> */}
            <button
              className={classModifier("bookings-list__button", [
                isActivePre && "active",
              ])}
              onClick={() =>
                getBookingsByFilters({
                  activeButtons: "isActivePre",
                  isPreBooking: isActivePre ? null : 1,
                  caller_type: isActiveAgent ? 3 : null,
                  type: isActiveOut ? "outcall" : null,
                })
              }
              disabled={isPending}
            >
              <ICONS.p className="bookings-list__icon bookings-list__icon-pre-bookings" />
            </button>
            <button
              className={classModifier("bookings-list__button", [
                isActiveAgent && "active",
              ])}
              onClick={() =>
                getBookingsByFilters({
                  activeButtons: "isActiveAgent",
                  caller_type: isActiveAgent ? null : 3,
                  isPreBooking: isActivePre ? 1 : null,
                  type: isActiveOut ? "outcall" : null,
                })
              }
              disabled={isPending}
            >
              <ICONS.a className="bookings-list__icon bookings-list__icon-agent-bookings" />
            </button>
          </div>

          <div className="bookings-list__counter-box">
            <p className="bookings-list__counter">
              {filteredBookings.bookings?.length ?? bookings.length}
            </p>
            {isFiltered && (
              <button
                className="bookings-list__cancel"
                onClick={cancelFilters}
                disabled={isPending}
              >
                <ICONS.close className="bookings-list__icon-cancel" />
              </button>
            )}
          </div>
        </div>

        <div className="bookings-list__search-box">
          <SearchInput
            query={query}
            placeholder="Search booking"
            inputClassName="input bookings-list__search-input"
            setQuery={setQuery}
            startSearch={(search) => {
              setFilteredBookings(initialFilteredBookings);
              getBookingsByFilters({
                activeButtons: "isActiveSearch",
                search,
              });
            }}
            stopSearch={() => cancelFilters()}
            showSearchBtn
            showClearBtn
          />
        </div>
      </div>

      <div className="bookings-list__list-wrapper">
        {isPending ? (
          <div className="bookings-list bookings-list__spinner-wrap">
            <Spinner spinnerSize={30} />
          </div>
        ) : (
          <Scrollbars
            renderThumbVertical={CustomScrollbarThumb}
            renderTrackVertical={CustomScrollbarTrack}
          >
            <div className="bookings-list__list">
              {getBookingsItem(isFiltered ? filteredBookings?.bookings : bookings)}
            </div>
          </Scrollbars>
        )}
      </div>
    </div>
  );
}

export default BookingsList;