import ICONS from 'assets/icons';
import { classModifier } from 'utils';

import Spinner from 'components/UI/Spinner/Spinner';

const MailHeaderActionButton = (props) => {
  const {
    title,
    pending,
    content,
    disabled,
    iconName,
    handleClick,
    type = 'button',
    isDisplay = true,
    classNameModifier,
    iconClassNameModifier,
  } = props;

  const Icon = ICONS[iconName];

  return (
    <button
      className={classModifier('mail-header__action-btn', [
        !isDisplay && 'hidden',
        classNameModifier && classNameModifier,
      ])}
      type={type}
      title={title}
      onClick={handleClick}
      disabled={disabled}
    >
      {content}
      {pending
        ? <Spinner spinnerSize={16} className="mail-header__action-btn-icon" />
        : (
          <Icon className={classModifier('mail-header__action-btn-icon', [
            iconClassNameModifier && iconClassNameModifier,
          ])}
          />
        )
      }
    </button>
  );
};

export default MailHeaderActionButton;
