import { memo, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { components } from 'react-select';

import API from 'api/api';
import ICONS from 'assets/icons';
import { classModifier, debounce, validateFormValues } from 'utils';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';

import './MailComposerEmailField.scss';
import MailContactItem from 'components/MailContactItem/MailContactItem';

const MailComposerEmailField = (props) => {
  const {
    name,
    emails,
    setEmails,
    serverErrors,
    emailInputValueError,
    setEmailInputValueError,
    meta: { error, touched, submitFailed }
  } = props;

  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const dispatch = useDispatch();

  const isClientError = error && touched && !focused && submitFailed;
  const isServerError = serverErrors && serverErrors[name];

  const [labelId] = useState('id-' + Math.random().toString(32).slice(2));

  const loadOptions = (inputValue, callback) => {
    if (inputValue) {
      API.getMinifiedContactsWithOneEmail({ search: inputValue })
        .then(res => {
          const options = res.data.map((item) => ({
              ...item,
              label: `${item.fn}: ${item.email}`,
              value: item.email,
              isCustom: true,
            })
          );

          callback(options);
        })
        .catch(console.error);
    }
  }

  const debouncedLoadOptions = useMemo(() => debounce(loadOptions, 700), []);

  const onCreateHandler = (value) => {
    if (!validateFormValues.isEmail(value)) {
      setEmailInputValueError('Incorrect email');
      setInputValue(value);
    } else {
      setEmails([value]);
      setEmailInputValueError(null);
      setInputValue('');
    }
  }

  const onChangeHandler = ({ action, removedValue, option }) => {
    if (action === 'remove-value') {
      const getUpdatedEmails = (prevState) => {
        const index = prevState.findIndex((email) => email === removedValue.value);

        return [...prevState.slice(0, index), ...prevState.slice(index + 1)];
      }

      setEmails(getUpdatedEmails);
    } else if (action === 'select-option') {
      onCreateHandler(option.value);
    }
  }

  const onInputChangeHandler = (newValue, actionMeta) => {
    if (actionMeta.action === 'input-change') {
      setInputValue(newValue);
      if (newValue === '') setEmailInputValueError(null);
    }
    if (actionMeta.action === 'input-blur') {
      setEmailInputValueError(null);
      setFocused(false);
    }
    if (actionMeta.action === 'input-blur' && actionMeta.prevInputValue) {
      onCreateHandler(actionMeta.prevInputValue);
    }
  }

  const getEmailsValue = (emails) => {
    return emails.map((email) => ({ label: email, value: email }));
  };

  const renderError = (errorMsg) => (
    <div className="form-text-input__error">
      {errorMsg}
    </div>
  )

  const formatOptionLabel = (option) => {
    const { label, isCustom } = option;

    return (
      isCustom
        ? <MailContactItem item={option} />
        : <div className="mail-composer__mail-create-item">
            {label}
          </div>
    );
  };

  return (
    <div
      className={classModifier('form-text-field', [
        (emailInputValueError || isClientError || isServerError) && 'email-error'
      ])}
    >
      <label
        className="mail-composer__label"
        htmlFor={labelId}
      >
        To
      </label>


      <div className="mail-composer__mail-select-wrap">
        <div className={classModifier("mail-composer__mail-select", focused && "focused")}>
          <AsyncCreatableSelect
            isMulti
            isClearable={false}
            inputId={labelId}
            maxMenuHeight={185}
            placeholder="Email"
            className="react-select"
            classNamePrefix="react-select"
            loadingMessage={() => null}
            noOptionsMessage={() => null}
            loadOptions={debouncedLoadOptions}
            inputValue={inputValue}
            value={getEmailsValue(emails)}
            formatCreateLabel={(inputValue) => `Send to: ${inputValue}`}
            formatOptionLabel={formatOptionLabel}
            onFocus={() => setFocused(true)}
            onChange={(emails, action) => onChangeHandler(action)}
            onCreateOption={onCreateHandler}
            onInputChange={onInputChangeHandler}
            components={{
              IndicatorSeparator: null,
              DropdownIndicator: null,
              LoadingIndicator: null,
              MultiValueRemove,
            }}
          />

          {!!emails.length && (
            <button
              type="button"
              className="mail-composer__mail-clear-btn"
              onClick={() => {
                setEmails([]);
                setInputValue('');
              }}
            >
              <ICONS.close className="mail-composer__mail-clear-btn-icon" />
            </button>
          )}
        </div>

        <ICONS.adrBook
          className="mail-composer__mail-contacts-btn"
          onClick={() => {
            dispatch(openModal(MODAL_TYPES.mailContactSearch, { onClick: (email) => setEmails([email]) }));
          }}
        />

      </div>

        {emailInputValueError || isClientError
          ? renderError(emailInputValueError || error)
          : isServerError
            ? renderError(serverErrors[name])
            : null
        }
    </div>
  )
};

const MultiValueRemove = (props) => {
  return (
      <components.MultiValueRemove {...props}>
        <ICONS.close />
      </components.MultiValueRemove>
  );
};

export default memo(MailComposerEmailField);
