import React, { useState, useEffect } from 'react';

import './SidebarContactsFilters.scss';
import { ALL_FILTER } from 'redux/ducks/contacts';
import { usePrevious } from 'hooks';
import { classModifier } from 'utils';

import ICONS from 'assets/icons';
import SidebarContactsSelectFilter from './SidebarContactsSelectFilter';
import SearchInput from 'components/SearchInput/SearchInput';

const SidebarContactsFilters = ({
  initialQuery,
  activeTab,
  activeFilter,
  searchSource,
  ...props
}) => {
  const [query, setQuery] = useState(initialQuery || '');
  const prevActiveTab = usePrevious(activeTab);

  useEffect(() => {
    if ((query && activeFilter !== ALL_FILTER) || (activeTab !== prevActiveTab)) {
      setQuery(initialQuery);
    }
  }, [activeTab, activeFilter]);

  const isContactsSearch = searchSource === 'contacts';
  const classPrefix = 'sidebar-contacts-filters';

  return (
    <div className={classPrefix}>
      <div className={`${classPrefix}__input-wrap`}>
        <SearchInput
          inputClassName="input"
          classPrefix={classPrefix}
          startSearch={props.startSearch}
          stopSearch={props.stopSearch}
          query={query}
          setQuery={setQuery}
          showSearchBtn={props.showSearchBtn}
          showClearBtn={props.showClearBtn}
        />

        {props.showSearchSourceToggle &&
          <button
            className={`${classPrefix}__toggle-btn`}
            onClick={() => props.toggleSearchSource(activeTab)}
          >
            {activeTab === "clients" &&
              <>
                <div className={`${classPrefix}__toggle-btn-icon-wrap`}>
                  {isContactsSearch
                    ? <ICONS.comments className={`${classPrefix}__toggle-btn-icon`} />
                    : <ICONS.users className={`${classPrefix}__toggle-btn-icon`} />
                  }
                </div>
              
                <div className={classModifier(`${classPrefix}__toggle-btn-icon-wrap`, 'active')}>
                  {isContactsSearch
                    ? <ICONS.users className={`${classPrefix}__toggle-btn-icon`} />
                    : <ICONS.comments className={`${classPrefix}__toggle-btn-icon`} />
                  }
                </div>
              </>
            }
          </button>
        }
      </div>

      <SidebarContactsSelectFilter
        isQuery={!!query.length}
        setQuery={setQuery}
        filters={props.filters}
        defaultFilter={activeFilter}
      />
    </div>
  );
}

export default React.memo(SidebarContactsFilters);
