import React from "react";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";

import { selectPropertyOfActiveSession } from "redux/selectors/selectors";

import "./RecentlyViewedProfiles.scss";
import RecentlyViewedProfile from "./RecentlyViewedProfile";

const RecentlyViewedProfiles = ({ recentlyViewedIds, hostProfile }) => {
  const profilesCount = ~recentlyViewedIds.indexOf(hostProfile)
    ? recentlyViewedIds.length - 1
    : recentlyViewedIds.length;

  return (
    <div className="recently-viewed-profiles">
      <h2 className="recently-viewed-profiles__title">
        Recently Viewed Profiles ({profilesCount})
      </h2>

      <ul className="recently-viewed-profiles__items">
        <div className="recently-viewed-profiles__scrollbar-wrap">
          <Scrollbars renderThumbVertical={() => <div className="recently-viewed-profiles__scrollbar-thumb" />} >

            {recentlyViewedIds.map(id => (id !== hostProfile &&
              <RecentlyViewedProfile
                id={id}
                key={id}
                hostProfile={hostProfile}
              />
            ))}
          </Scrollbars>
        </div>
      </ul>
    </div>
  );
};

const mapStateToProps = state => ({
  recentlyViewedIds: selectPropertyOfActiveSession(state, "recentlyViewedIds"),
  hostProfile: selectPropertyOfActiveSession(state, "comparedIds")[0]
});

export default connect(mapStateToProps)(RecentlyViewedProfiles);