import formatDateToTime from "./formatDateToTime";
import getDateByTimezoneOffset from "./getDateByTimezoneOffset";
import getShortDate from "./getShortDate";

export default (utcDate, userTimezone, userHour12) => {
  const dateByTimezone = getDateByTimezoneOffset(userTimezone, utcDate);
  const lastActiveByTimezone = getShortDate(dateByTimezone);

  const d = getDateByTimezoneOffset(userTimezone);
  const todayByTimezone = getShortDate(getDateByTimezoneOffset(userTimezone));
  const yesterdayByTimezone = getShortDate(new Date(d.setDate(d.getDate() - 1)));

  const currentYear = d.getFullYear();
  const interactionYear = new Date(lastActiveByTimezone).getFullYear();

  if (lastActiveByTimezone === todayByTimezone) {
    return formatDateToTime(dateByTimezone, userHour12, false);
  }
  else if (lastActiveByTimezone === yesterdayByTimezone) {
    return "Ystd";
  }
  else if (interactionYear !== currentYear) {
    return dateByTimezone.toLocaleDateString('ru-RU', { year: '2-digit', day: 'numeric', month: 'numeric' });
  }
  else {
    return dateByTimezone.toLocaleDateString('en-US', { day: 'numeric', month: 'short' });
  }
};