import React, { useState } from 'react';

import './Label.scss';

export default function Label({ label, children, className }) {
  const [labelId] = useState('id-' + Math.random().toString(32).slice(2));

  return (
    <>
      <label 
        className={className ? `label ${className}` : 'label'}
        htmlFor={labelId}
        >
          {label}
        </label>

      {typeof children === 'function'
        ? children(labelId)
        : children
      }
    </>
  )
}
