import { Component } from 'react';
import { connect } from 'react-redux';
import { Device } from 'twilio-client';

import { openModal, closeModal, MODAL_TYPES } from 'redux/ducks/activeWindows';
import { loadTwilioDevice, setConnection, getToken } from 'redux/ducks/twilio';
import { finishCall, toggleMuteActiveCall } from 'redux/ducks/calls';


class TwilioSetup extends Component {
  componentDidMount() {
    getToken()
      .then(token => {
        this.setupTwilioClient(token);
      })
      .catch(err => {
        console.log('Twilio Error:', err);
        window.location = '/client/login';
      })
  }

  shouldComponentUpdate() {
    return false;
  }

  setupTwilioClient = (token) => {
    // const Twilio = window.Twilio;
    const device = new Device();

    device.setup(token, {
      debug: true,
      allowIncomingWhileBusy: true,
      closeProtection: "You have active connection!",
      enableRingingState: true,
      // region: "ie1"
    });

    device.audio.incoming(false);

    this.props.loadTwilioDevice(device);

    device.on('ready', (device) => {
      console.log('Twilio.Device Ready!');
    });

    device.on('error', (error) => {
      console.log('Twilio.Device Error: ' + error.message);
      console.log(error);
      if (error.code === 31205) {
        getToken()
          .then(token => {
            this.setupTwilioClient(token);
          })
          .catch(err => {
            console.log('Twilio Error:', err);
            window.location = '/client/login';
          })
      }
      else if (error.message.startsWith("Error occurred while accessing microphone")) {
        this.props.openModal(MODAL_TYPES.error, { text: 'Please turn on your microphone' });
      }
      else if (error.message.startsWith("ICE negotiation with Twilio failed")) {
        this.props.openModal(MODAL_TYPES.error, { text: 'Poor connection' });
      }
      else if (error.message) {
        this.props.openModal(MODAL_TYPES.error, { text: error.message });
      }
    });

    device.on('offline', (device) => {
      // getToken(localStorage.connectToken)
      //   .then(token => this.setupTwilioClient(token))
      //   .catch(err => console.log(err))
    });

    device.on('connect', (connection) => {
      console.log('Successfully established call!');

      this.props.setConnection(connection);

      connection.on('mute', (bool, con) => {
        console.log("MUTE-connect", bool);
        this.props.toggleMuteActiveCall(bool);
      })
    });

    device.on('disconnect', (connection) => {
      console.log('Call ended.', connection);
      const isOutgoingCallModal = this.props.activeModals.find(modal => modal.type === MODAL_TYPES.call);
      const isIncomingCallModal = this.props.activeModals.find(modal => modal.type === MODAL_TYPES.incomingCall);

      if (isOutgoingCallModal) {
        this.props.closeModal(MODAL_TYPES.call);
      }
      if (isIncomingCallModal) {
        this.props.closeModal(MODAL_TYPES.incomingCall);
      }
      // if (this.props.activeCall) {
      this.props.finishCall();
      // }

    });

    device.on('cancel', (connection) => {
      console.log('Client hang up.', connection);

      this.props.finishCall();
      this.props.closeModal(MODAL_TYPES.incomingCall);
    });

    device.on('incoming', (connection) => {
      console.log('Incoming connection from ' + connection.parameters.From);

      if (this.props.connection) {
        return console.log('NEW missed CALL_______');
      }

      this.props.setConnection(connection);
      // this.props.openModal(MODAL_TYPES.incomingCall);

      connection.on('reject', () => {
        console.log('Reject Incoming connection : ');
        const isIncomingCallModal = this.props.activeModals.find(modal => modal.type === MODAL_TYPES.incomingCall);

        this.props.finishCall();

        if (isIncomingCallModal) {
          this.props.closeModal(MODAL_TYPES.incomingCall);
        }
      });

      connection.on('accept', () => {
        console.log('Accept Incoming Conneciton: ');

        this.props.closeModal(MODAL_TYPES.incomingCall);
      });
    });
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  connection: state.twilio.connection,
  activeCall: state.calls.activeCall,
  activeModals: state.activeWindows.activeModals,
})

const mapDispatchToProps = {
  loadTwilioDevice,
  setConnection,
  getToken,
  finishCall,
  openModal,
  closeModal,
  toggleMuteActiveCall
}

export default connect(mapStateToProps, mapDispatchToProps)(TwilioSetup);