import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { connect, useDispatch, useSelector } from 'react-redux';

import ICONS from 'assets/icons';
import {
  getContactAvatar,
  formatDateToTime,
  getDefaultField,
  getDateByTimezoneOffset,
  isEmptyObj,
  classModifier,
} from 'utils';
import { useToggle } from 'hooks';
import ContactInteractionMenu from '../InteractionMenu/ContactInteractionMenu';
import { deletePinnedMessage } from 'redux/ducks/clientChats';
import { CHAT_TYPES } from 'config/constants';

import API from 'api/api';
import SvgIcon from '../../../../../components/SvgIcon/SvgIcon';
import InteractionAttachments from '../../ChatAttachments/InteractionAttachments';
import RoomInteractionMenu from '../InteractionMenu/RoomInteractionMenu';
import MsgText from './MsgText';
import ScheduledMsgInteractionMenu from '../InteractionMenu/ScheduledMsgInteractionMenu';
import AttachmentRepliedMessage from '../../ChatAttachments/AttachmentRepliedMessage';

// import ParsedGirlList from '../../ParsedGirlList/ParsedGirlList';

const OutgoingMessage = ({ interaction, search, activeRecipient, ...props }) => {
  // const [parsedGirlsList, setParsedGirlsList] = useState({
  //   query: '',
  //   list: [],
  //   isOpen: false,
  // });

  const dispatch = useDispatch();
  const isMsgAlreadyPinned = useSelector(
    (state) => 
    interaction?.caller?.type === CHAT_TYPES.CLIENT
    ? state.clientChats.pinnedMsgs.find(el => el.id === interaction.id)
    : state.girlChats.pinnedMsgs.find(el => el.id === interaction.id)
  );

  const [menu, toggleMenu] = useToggle(false);
  const [isAnimate, setIsAnimate] = useToggle(false);

  const msgRef = useRef();

  const isFromTelegram = interaction.type === 10;
  const isScheduledMsg = interaction.type === 11;

  const isContextMsg = interaction.id === +props.contextMsgId; // cause props.contextMsgId is string

  useEffect(() => {
    if (isContextMsg) {
      setIsAnimate();

      setTimeout(() => setIsAnimate(false), 2000);
    }
  }, [isContextMsg]);

  const showInteractionMenu = () => {
    if (props.isEdited || interaction.is_deleted) {
      return null;
    }

    if (interaction.chatId) {
      return <RoomInteractionMenu
        isOpen={menu}
        closeMenu={toggleMenu}
        interaction={interaction}
        isMine={true}
        editMsg={props.editMsg}
        shareMsg={props.shareMsg}
      />
    }

    return isScheduledMsg
      ? (
        <ScheduledMsgInteractionMenu
          isOpen={menu}
          closeMenu={toggleMenu}
          interaction={interaction}
          userTimezone={props.userTimezone}
          editMsg={props.editMsg}
          openModal={props.openModal}
        />
      )
      : (
        <ContactInteractionMenu
          isOpen={menu}
          closeMenu={toggleMenu}
          interaction={interaction}
          search={search}
          replyMsg={props.replyMsg}
          userTimezone={props.userTimezone}
          activeChannel={activeRecipient.default_channel}
        />
      )
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'pending': {
        return <ICONS.check className="interaction__status-icon interaction__status-icon--pending" title="pending" />;
      }

      case 'sent': {
        return <ICONS.check className="interaction__status-icon interaction__status-icon--sent" title="sent" />;
      }

      case 'delivered': {
        return (
          <>
            <ICONS.check className="interaction__status-icon interaction__status-icon--delivered" title="delivered" />
            <ICONS.check className="interaction__status-icon interaction__status-icon--delivered" title="delivered" />
          </>
        );
      }

      // case 'viewed': {
      //   return <SvgIcon icon="eye" title="viewed" fill="#0092F2" width="12px" height="12px" />;
      // }

      case 'undelivered':
      case 'failed': {
        return <ICONS.undo className="interaction__status-icon interaction__status-icon--failed" title="failed" />;
      }

      default:
        return <ICONS.check className="interaction__status-icon interaction__status-icon--pending" title="pending" />;
    }
  };

  const getStatus = () => {
    const isInteractionStatusError = interaction.status === 'failed' || interaction.status === 'undelivered';

    if (interaction.status) {
      return (
        <span
          className={classModifier('interaction__status', [
            isInteractionStatusError && 'error',
            interaction.is_deleted && 'deleted'
          ])}
        >
          {!interaction.is_deleted && getStatusIcon(interaction.status)}
          {isInteractionStatusError && 'error'}
          {interaction.is_deleted && 'Removed'}
        </span>
      );
    }
  };

  const getMsgSender = () => {
    let sender;
    let isIAmSender = false;

    if (interaction.user_id === props.profileId) {
      sender = interaction.user;
      isIAmSender = true;
    }
    else if (interaction.senderId === props.profileId) {
      sender = interaction.sender;
      isIAmSender = true;
    }
    else {
      sender = interaction.user || {};
    }

    return {
      sender,
      isIAmSender
    };
  };

  const renderHeader = () => {
    const { isIAmSender, sender } = getMsgSender();

    return isIAmSender ? (
      <span className={classModifier('interaction__name', ['out', 'is-sender'])}>
        You
        {isFromTelegram &&
          <ICONS.telegram />
        }
      </span>
    ):(
      <Fragment>
        <div className={classModifier('interaction__ava', 'reverse')}>
          <img src={getContactAvatar(sender)} alt="ava" />
          {isFromTelegram &&
            <ICONS.telegram className={classModifier('interaction__ava-telegram-icon', 'reverse')}/>
          }
        </div>

        <span className={classModifier('interaction__name', 'out')}>
          {(isFromTelegram && isEmptyObj(sender)) ? 'System Bot' : sender.username}
        </span>
      </Fragment>
    );
  };

  const searchByGirlName = useCallback((e) => {
    const text = e.currentTarget.getAttribute('data-value');

    API.searchContacts(2, text)
      .then(res => {
        props.addNewArrayGirlsToState(res.data);

        // setParsedGirlsList({ query: text, list: res.data, isOpen: true });
      });
  }, []);

  const getMsgContext = (e) => {
    if (!search) {
      return;
    }

    if (!props.contextMsgId) {                          // if we not in Msg Context
      return e.target.className !== 'lazy-load__image'
        ? props.getMessageContext(interaction.id, props.isGlobalSearch ? interaction.caller : null, search)
        : null;
    }
  };

  const setContextRef = ref => {
    if (isContextMsg) {
      return props.contextMsgRef.current = ref;
    }
  };

  const isSentOnNotDefaultNumber = interaction.channel && getDefaultField(activeRecipient.tels, 'tel') !== interaction.channel;
  // console.log('outgoing Render', props.parsedMsgText);

  const getInteractionDate = () => {
    if (isScheduledMsg) {
      return (
        <time>
          {getDateByTimezoneOffset(props.userTimezone, interaction.date).toLocaleString('eu', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour12: props.userHour12,
            hour: '2-digit',
            minute: '2-digit'
          })
          }
        </time>
      )
    }

    return formatDateToTime(
      getDateByTimezoneOffset(props.userTimezone, interaction.dateCreated),
      props.userHour12
    );
  }

  return (
    <>
      <li
        className={classModifier('interaction', [
            'out',
            interaction.isNew && 'new',
            interaction.is_deleted && 'deleted',
        ])}
        // style={isContextMsg ? { border: "1px solid #0092F2" } : null}
        data-isanimate={isAnimate}
        onClick={getMsgContext}
        ref={setContextRef}
        // if we will use below {() => toggleMenu()} it will rerender this interaction
        onMouseEnter={() => toggleMenu(true)}
        onMouseLeave={() => toggleMenu(false)}
      >
        <header className="interaction__header" ref={msgRef}>
          <div className="interaction__header-info interaction__header-info--reverse">
            {renderHeader()}

            {/*{!!interaction.edited && <span className="interaction__edited">edited</span>}*/}

            <span className="interaction__time">
              {isScheduledMsg && 'Will be sent at: '}
              {getInteractionDate()}
            </span>

            {getStatus()}

            {isSentOnNotDefaultNumber &&
              <span className="interaction__channel">
                {`(to: ${interaction.channel})`}
              </span>
            }

            {showInteractionMenu()}

            {isMsgAlreadyPinned &&
              <button
                className="interaction__btn-pinned"
                onClick={() => dispatch(deletePinnedMessage(isMsgAlreadyPinned.id, interaction.caller.type))}
              >
                <ICONS.pin className="interaction__icon-pinned"/>
              </button>
            }
          </div>
        </header>

        <div
          className={classModifier('interaction__body-wrap', [
            interaction.is_deleted && 'deleted',
          ])}
        >
          {interaction.attachments?.repliedMessage &&
            <div className="interaction__replied-msg">
              <AttachmentRepliedMessage
                profileId={props.profileId}
                userHour12={props.userHour12}
                interaction={interaction.attachments.repliedMessage}
                userTimezone={props.userTimezone}
                getMessageContext={props.getMessageContext}
              />
            </div>
          }

          {(interaction.body || !interaction.attachments) &&
            <div className="interaction__text-wrap">
              <MsgText
                highlightedText={search}
                interaction={interaction}
                onGirlNameClick={searchByGirlName}
              />
            </div>
          }

          {interaction.attachments &&
            <InteractionAttachments
              attachments={interaction.attachments}
              profileId={props.profileId}
              userTimezone={props.userTimezone}
              userHour12={props.userHour12}
            />
          }
        </div>
      </li>
      {/* {parsedGirlsList.isOpen &&
        <ParsedGirlList
          msgRef={msgRef}
          activeRecipient={activeRecipient}
          addNewArrayGirlsToState={props.addNewArrayGirlsToState}
          profileId={props.profileId}
          parsedGirlsList={parsedGirlsList}
          updateActiveContact={props.updateActiveContact}
          setParsedGirlsList={setParsedGirlsList}
        />
      } */}
    </>
  );
};

export default OutgoingMessage;