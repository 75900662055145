import { connect, useDispatch } from 'react-redux';

import { classModifier, getDateByTimezoneOffset } from 'utils';
import { selectUserTimezone } from 'redux/selectors/selectors';

import './FeedbackItem.scss';
import ICONS from 'assets/icons';
import { closeModal, MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';

const FeedbackItem = ({ item, onDelete, setFeedbackList, userTimezone, operator }) => {

  const dispatch = useDispatch();

  const formatDate = () => {
    const dateByTimezone = getDateByTimezoneOffset(
      userTimezone,
      item.date_created,
    );
    return dateByTimezone.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  }

  const getTypeLabelModifier = () => {
    switch (item.type) {
      case 2:
        return 'positive';
      case 1:
        return 'negative';
      default:   // (case 0) or other
        return 'neutral';
    }
  }

  const handleEdit = () => {
    dispatch(openModal(MODAL_TYPES.feedback, {
      initValues: item,
      onCancel: () => closeModal(MODAL_TYPES.feedback),
      setFeedbackList,
      isEdit: true
    }));
  }

  return (
    <div className='feedback-item'>
      <div className='feedback-item__header'>
        {item.divaName &&   // "divaName" check for v1 compatibility
          <p className='feedback-item__escort-name'>{item.divaName}</p>
        }

        <p className='feedback-item__date'>{formatDate()}</p>

        <p className='feedback-item__added-by'>added by: {operator.username}</p>

        <div className='feedback-item__actions'>
          <span
            className={classModifier('feedback-item__type-label', [
              getTypeLabelModifier(),
            ])}
          />

          <button
            onClick={handleEdit}
            className={classModifier('feedback-item__action-button', 'edit')}
            type='button'
            title='Edit'
          >
            <ICONS.pencil />
          </button>

          <button
            className={classModifier('feedback-item__action-button', 'delete')}
            onClick={onDelete}
            type='button'
            title='Delete'
          >
            <ICONS.trash />
          </button>
        </div>
      </div>

      <p className='feedback-item__text'>{item.body}</p>
    </div>
  );
}

const mSTP = (state, { item }) => ({
  userTimezone: selectUserTimezone(state),
  operator: state.operators.entities[item.user_id] || state.user,
});

export default connect(mSTP)(FeedbackItem);
