import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { markMessageAsUnread, setMessageReminder, deleteTelegramMsgRequest } from 'redux/ducks/clientChats';
import { pinClientMsg } from 'redux/ducks/clientChats';
import { copyToClipboard, getDateByTimezoneOffset, parseMsgText } from 'utils';
import { openModal, MODAL_TYPES } from 'redux/ducks/activeWindows';
import { INTERACTION_TYPES } from 'config/constants';

import InteractionMenu from './InteractionMenu';
import ICONS from 'assets/icons';

const deleteMsgConfirmText = "Are you sure you want to delete this message?";

const ContactInteractionMenu = (props) => {
  const {
    interaction,
    activeChannel,
    arrayContactPinnedMsgs,
  } = props;

  const msgRef = useRef(null);

  const { INCOMING_MSG_TELEGRAM, OUTGOING_MSG_TELEGRAM } = INTERACTION_TYPES;

  const remindWithTooltip = (time) => {
    return props.setMessageReminder(interaction, time)
  }

  const isMsgAlreadyPinned = arrayContactPinnedMsgs.some(el => el.id === interaction.id);

  const items = useMemo(() => {
    const itemsArray = [
      {
        component: (props) =>
          <>
            <ICONS.copy className='ii-menu__icon' />
            <textarea
              ref={msgRef}
              className="interaction__textarea--hidden"
              readOnly="readonly"
              value={parseMsgText(interaction.body, true)}
            />
          </>,
        action: async (e) => copyToClipboard(e, interaction.body, msgRef.current),
        tooltipOnHover: 'Copy text',
      },
    ];

    if (!isMsgAlreadyPinned && arrayContactPinnedMsgs.length < 3) {
      itemsArray.push({
        icon: <ICONS.pin className='ii-menu__icon' />,
        tooltipOnHover: 'Pin message',
        action: () => props.pinClientMsg(interaction, interaction.caller_id),
      })
    }
    if (!isMsgAlreadyPinned && arrayContactPinnedMsgs.length === 3) {
      itemsArray.push({
        icon: <ICONS.pin className='ii-menu__icon ii-menu__icon--limit-pin-msg' />,
        tooltipOnHover: 'Maximum of pinned messages',
        classMods: 'limit-pin-msg'
      })
    }

    if (interaction.type === 4 && !props.search && !!interaction.isRead) {
      itemsArray.push({
        icon: <ICONS.mail className='ii-menu__icon' />,
        tooltipOnHover: 'Unread message',
        // action: () => props.markMessageAsUnread(interaction),
        // tooltipAfterAction: 'Success unread'
      })
    }

    itemsArray.push({
      icon: <ICONS.bell className='ii-menu__icon' />,
      tooltipOnHover: 'Remind ',
      // action: () => props.setMessageReminder(interaction, new Date().getTime() + 5 * 60 * 1000),
      subMenu: [
        {
          title: '15 min',
          // action: () => props.setMessageReminder(interaction, new Date().getTime() + 15 * 60 * 1000)
        },
        {
          title: 'In hour',
          // action: () => props.setMessageReminder(interaction, new Date().getTime() + 3600 * 1000)
        },
        {
          title: 'In 3 hours',
          // action: () => props.setMessageReminder(interaction, new Date().getTime() + 3600 * 3000)
        },
        {
          title: 'Custom',
          // action: async () => props.openModal(
          //   MODAL_TYPES.timePicker,
          //   {
          //     action: remindWithTooltip,
          //     isShowTimeMenu: true,
          //     minDate: +getDateByTimezoneOffset(props.userTimezone) + 900000,
          //     userTimezone: props.userTimezone,
          //     onClose: props.closeMenu
          //   }
          // )
        },
      ]
    });

    if ([INCOMING_MSG_TELEGRAM, OUTGOING_MSG_TELEGRAM].includes(interaction.type) && activeChannel === 'telegram') {
      itemsArray.push({
        icon: <ICONS.reply className='ii-menu__icon' />,
        tooltipOnHover: 'Reply',
        // action: async () => props.replyMsg(interaction),
      })

      if (interaction.is_deletable) {
        itemsArray.push({
          icon: <ICONS.trash className='ii-menu__icon' />,
          tooltipOnHover: 'Remove',
          classMods: "danger",
          action: async () => {
            if (window.confirm(deleteMsgConfirmText)) {
                props.deleteTelegramMsgRequest(interaction.id);
            }
          },
        })
      }
    }

    return itemsArray;
  }
  , [activeChannel, arrayContactPinnedMsgs]);

  // console.log('ContactInteractionMenu Render', items);
  return (
    <InteractionMenu
      isOpen={props.isOpen}
      menuItems={items}
      closeMenu={props.closeMenu}
    />
  );
}

const mapStateToProps = (state, ownProps) => ({ 
  arrayContactPinnedMsgs: (
    ownProps.interaction.caller.type === 1 
    ? state.clientChats.pinnedMsgs 
    : state.girlChats.pinnedMsgs
  ),
})

const mapDispatchToProps = {
  markMessageAsUnread,
  setMessageReminder,
  pinClientMsg,
  openModal,
  deleteTelegramMsgRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactInteractionMenu);
