
import { useToggle } from 'hooks';

import { classModifier } from 'utils';

import './MainPage.scss';
import CallsSection from 'containers/CallsSection/CallsSection';
import SidebarClientsChatList from 'containers/SidebarChatLists/SidebarClientsChatList/SidebarClientsChatList';
import SidebarGirlsChatList from 'containers/SidebarChatLists/SidebarGirlsChatList/SidebarGirlsChatList';
import ClientChat from 'containers/Chat/ClientChat';
import GirlChat from 'containers/Chat/GirlChat';
import SidebarOperatorsList from 'containers/SidebarChatLists/SidebarOperatorsList/SidebarOperatorsList';
import PublicChat from 'containers/Chat/PublicChat/PublicChat';
// import SidebarContacts from 'containers/SidebarContacts/SidebarContacts';

const MainPage = () => {
  const [isShowCallSection, toggleCallSection] = useToggle(false);
  
  return (
    <main className="main-page">
      <div
        className={classModifier("main-page__calls", [isShowCallSection && "show"])}
      >
        <CallsSection
          isShowCallSection={isShowCallSection}
          toggleCallSection={toggleCallSection}
        />
      </div>

      <div className="main-page__main">
        <div className="main-page__sidebar">
          <div className="main-page__contacts">
              <SidebarClientsChatList />
          </div>

          <div className="divider main-page__divider"/>

          <div className="main-page__sessions">
            <span className="placeholder">sessions</span>
          </div>
        </div>

        <div className="main-page__chats">
          <ClientChat />

          <PublicChat />

          <GirlChat />
        </div>

        <div className="main-page__sidebar">
          <div className="main-page__contacts">
            <SidebarGirlsChatList />
          </div>

          <div className="divider main-page__divider"/>

          <div className="main-page__sessions">
            <SidebarOperatorsList />
          </div>
        </div>
      </div>
    </main>
  );
};

export default MainPage;