import React from 'react'

import {
  classModifier,
  getContactAvatar,
  getDefaultField,
  getDateByTimezoneOffset,
  getShortDate,
} from 'utils';
import ICONS from 'assets/icons';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';

const DialpadRecentCallsItem = ({ 
  setQuery, 
  item, 
  setContact, 
  username,
  userTimezone,
  userHour12,
}) => {

  if (!item.caller?.tels?.length) {
    return null
  }

  const dateRecentCall = (item) => {
    const dateCreated = getShortDate(getDateByTimezoneOffset(userTimezone, item.date_created));
    const todayByTimezone = getShortDate(getDateByTimezoneOffset(userTimezone));

    const objDateFormat = {
      hour: '2-digit',
      minute: '2-digit',
      userHour12
    };

    if (todayByTimezone !== dateCreated) {
      objDateFormat.day = 'numeric'
      objDateFormat.month = '2-digit'
    };
    
    return (
      new Date(getDateByTimezoneOffset(userTimezone, item.date_created))
        .toLocaleString('en-GB', objDateFormat)
    );
  }

  const status = {
    0: 'online',
    1: 'busy',
    2: 'away',
    3: 'offline',
  }

  return (
    <li
      className='dialpad-list__item dialpad-list__item--resent-calls'
      onClick={() => {
        setContact(item.caller);
        setQuery(getDefaultField(item.caller.tels, 'tel'));
      }}
    >
      <div className="dialpad-list__avatar-box">
        <LazyLoadImage
          src={item.caller.avatar || getContactAvatar(item.caller)}
          alt="ava"
          className="dialpad-list__avatar"
        />
      </div>
      <div className="dialpad-list__text-box">
        <p className="dialpad-list__contact-name dialpad-list__contact-name--recent">
          {item.caller.fn}
        </p>

        <div className="dialpad-list__info">
          <div className="dialpad-list__date-box">
            <ICONS.incomingCalls className="dialpad-list__icon-incoming"/>

            <p>
              {dateRecentCall(item)}
            </p>
          </div>
          
          {item.operator?.name &&
            <p className={
              classModifier('dialpad-list__operator-name', [status[item.operator.status]])
            }>
              {username === item.operator.name ? 'you' : item.operator.name}
            </p>
          }
        </div>
      </div>
    </li>
    );
}

export default DialpadRecentCallsItem;