import { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";

import { debounce } from "utils";
import { ADDITIONAL_SESSION_FILTERS } from "config/constants";
import {
  selectActiveSessionId,
  selectCanIEditSession,
  selectPropertyOfActiveSession,
} from "redux/selectors/selectors";
import { updateAdditionalTitleFilters } from 'redux/ducks/sessions';

import './GallerySearch.scss';
import ICONS from 'assets/icons';


const GallerySearch = ({
  additionalFilters,
  activeSessionId,
  canIEditSession,
  updateAdditionalTitleFilters,
}) => {
  const [text, setText] = useState('');

  useEffect(() => {
    setText(additionalFilters[ADDITIONAL_SESSION_FILTERS.search || '']);
  }, [activeSessionId]);

  useEffect(() => {
    if (!additionalFilters.search) {
      setText('');
    };
  }, [additionalFilters.search]);

  const handleSearch = (query, sessionId, additionalFilters, canIEditSession) => {
    updateAdditionalTitleFilters(
      ADDITIONAL_SESSION_FILTERS.search,
      query,
      sessionId,
      additionalFilters,
      canIEditSession,
      !query,
    );
  };

  const handleSearchDebounce = useCallback(debounce(handleSearch, 700), []);

  const handleTextChange = e => {
    setText(e.target.value);
    handleSearchDebounce(e.target.value, activeSessionId, additionalFilters, canIEditSession);
  };

  const handleClearClick = () => {
    handleSearch('', activeSessionId, additionalFilters, canIEditSession);
    setText('');
  };
  
  return (
    <label className="gallery-search">
      <input
        className="gallery-search__text-input"
        placeholder="Search"
        value={text}
        onChange={handleTextChange}
      />

      {text &&
        <button
          className="gallery-search__clear-button"
          onClick={handleClearClick}
        >
          <ICONS.close />
        </button>
      }
      {!text &&
        <ICONS.search className="gallery-search__search-icon"/>
      }
    </label>
  );
};

const mSTP = state => ({
  additionalFilters: selectPropertyOfActiveSession(state, 'additionalFilters'),
  activeSessionId: selectActiveSessionId(state),
  canIEditSession: selectCanIEditSession(state),
});

const mDTP = {
  updateAdditionalTitleFilters,
};

export default connect(mSTP, mDTP)(GallerySearch);
