import React, { memo, useState } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { getTopTags, searchTags } from 'redux/ducks/contacts';


const colourStyles = {
  valueContainer: (provided) => ({
    ...provided,
    padding: '6px',
    minHeight: '94px',
    alignItems: 'flex-start'
  }),

  multiValue: (provided) => ({
    ...provided,
    borderRadius: '6px',
    backgroundColor: '#F6F6F8',
    margin: '3px',
  }),

  multiValueLabel: (provided) => ({
    ...provided,
    padding: '4px 5px',
    fontSize: '11px',
    fontFamily: 'Roboto',
  }),

  multiValueRemove: (provided) => ({
    ...provided,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#dae0e6',
      borderRadius: '0 7px 7px 0',
      color: '#404040',
    }
  }),

  menu: (provided) => ({ ...provided, fontSize: '11px', zIndex: '10' }),

  placeholder: (provided) => ({ ...provided, fontSize: '14px', marginTop: '3px' }),

  input: (provided) => ({ ...provided, fontSize: '14px' }),

  control: (provided) => ({ ...provided, border: 'none', borderRadius: '6px', }),
};

const TagsField = (props) => {
  const {
    values,
    saveContact,
    isEditedTags,
    editedContactTags,
    setEditedContactTags,
    newContactTags,
    setNewContactTags,
    isNewContactCreation,
  } = props;

  const [topOptions, setTopOptions] = useState(() => []);

  const loadOptions = (inputValue, callback) => {
    if (!topOptions.length) {
      getTopTags()
        .then(tags => {
          setTopOptions(tags);
          callback(tags);
        })
        .catch(console.error);
    }
    else {
      searchTags(inputValue)
        .then(tags => callback(tags))
        .catch(console.error);
    }
  };

  const onChangeHandler = ({ action, removedValue, option }) => {
    if (action === 'remove-value') {
      const getUpdatedTags = (prevState) => {
        const index = prevState.findIndex((tag) => tag === removedValue.label)

        return [...prevState.slice(0, index), ...prevState.slice(index + 1)]
      }

      isNewContactCreation
        ? setNewContactTags(getUpdatedTags)
        : setEditedContactTags(getUpdatedTags);
    } 
    else if (action === 'select-option') {
      isNewContactCreation
        ? setNewContactTags((prevState) => [...prevState, option.label])
        : setEditedContactTags((prevState) => [...prevState, option.label ]);
    }
  };

  const getTagsValue = (tags) => {
    return tags.map((tag) => ({ label: tag, value: tag }));
  };

  const handleBlur = () => isEditedTags && saveContact(values);

  return (
    <AsyncCreatableSelect
      isMulti
      isClearable={false}
      defaultOptions
      loadOptions={loadOptions}
      value={getTagsValue(isNewContactCreation ? newContactTags : editedContactTags)}
      getOptionLabel={(option) => option.label || option.title}
      getOptionValue={(option) => option.value || option.title}
      onChange={(tags, action) => {
        onChangeHandler(action)
      }}
      onBlur={handleBlur}
      onCreateOption={(tag) => {
        isNewContactCreation
          ? setNewContactTags((state) => ([...state, tag]))
          : setEditedContactTags((state) => ([...state, tag]));
      }}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator: null,
        LoadingIndicator: null,
      }}
      maxMenuHeight={115}
      styles={colourStyles}
      placeholder='Add tags...'
      className='tags-field-container'
      classNamePrefix='tags-field'
    />
  )
}

export default memo(TagsField);
