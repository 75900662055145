import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';

import { selectUserTimezone } from 'redux/selectors/selectors';
import { getDateByTimezoneOffset } from 'utils';
import './TimeSelect.scss';

const TimeSelect = props => {
  const {
    timeIntervals = 30, // in minutes
    minTime,
    currentOption,
    onChange,
    userHour12,
    userTimezone,
    initDate,
  } = props;

  const [timeOptions, setTimeOptions] = useState([]);

  useEffect(() => {
    const updatedTimeOptions = [];
    const oneDay = 60 * 24;
    let minutes = 0;

    while (minutes < oneDay) {
      const mins = minutes % 60;
      const hours = Math.floor(minutes / 60);

      const [time24, time12] = getTimeWithTwoFormats(hours, mins);

      const isCurrentTimeMoreThanMinTime = isSecondTimeMoreThanFirst(minTime || ':', time24);
      const canAddTime = !minTime || minTime === time24 || isCurrentTimeMoreThanMinTime;

      if (canAddTime) {
        updatedTimeOptions.push({ value: time24, label: userHour12 ? time12 : time24 });
      }
      minutes += timeIntervals;
    }

    // Set current time by default
    if (!currentOption) {
      const date = getDateByTimezoneOffset(userTimezone, initDate);

      const minutesToDate = date.getHours() * 60 + date.getMinutes();
      const minutesToNextPoint = Math.ceil(minutesToDate / timeIntervals) * timeIntervals;

      const [time24, time12] = getTimeWithTwoFormats(Math.floor(minutesToNextPoint / 60), minutesToNextPoint % 60);
      const minTimeStr = minTime || '00:00';

      if (time24 >= minTimeStr && time24 <= '23:59') {
        onChange({ value: time24, label: userHour12 ? time12 : time24 });
      } else if (updatedTimeOptions.length) {
        const newIndex = time24 < minTimeStr ? 0 : updatedTimeOptions.length - 1;
        onChange(updatedTimeOptions[newIndex]);
      }
    }

    if (currentOption && minTime && isSecondTimeMoreThanFirst(currentOption.value, minTime)) {
      onChange(updatedTimeOptions[0]);
    }

    setTimeOptions(updatedTimeOptions);
  }, [minTime, currentOption, userHour12]);

  return (
    <div className='time-select'>
      <Select
        className="react-select"
        classNamePrefix="react-select"
        options={timeOptions}
        value={currentOption}
        onChange={onChange}
        placeholder='Select'
        isSearchable={false}
        noOptionsMessage={() => null}
        components={{
          IndicatorSeparator: null,
        }}
      />
    </div>
  );
};

const getTimeWithTwoFormats = (hours, mins) => {
  const amPm = hours < 12 ? " AM" : " PM";

  let amPmHour = hours > 12 ? hours - 12 : hours;
  if (amPmHour == 0) {
    amPmHour = 12;
  }

  mins = String(mins).padStart(2, '0');
  hours = String(hours).padStart(2, '0');
  amPmHour = String(amPmHour).padStart(2, '0');

  const time24 = hours + ':' + mins;
  const time12 = amPmHour + ':' + mins + amPm;

  return [time24, time12];
}

const isSecondTimeMoreThanFirst = (firstTime, secondTime) => {
  const [firstHours, firstMinutes] = firstTime.split(':').map(str => +str);
  const [secondHours, secondMinutes] = secondTime.split(':').map(str => +str);

  if (secondHours < firstHours) {
    return false;
  }
  if (secondHours > firstHours) {
    return true;
  }

  return secondMinutes > firstMinutes;
};

const mapStateToProps = state => ({
  userHour12: state.user.hour12,
  userTimezone: selectUserTimezone(state),
});

export default connect(mapStateToProps)(TimeSelect);
