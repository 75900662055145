import React from 'react'

import { classModifier, formatTime } from 'utils';

import ICONS from 'assets/icons';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import VideoThumbnail from 'components/VideoThumbnail/VideoThumbnail';

const PinnedMsgAttachments = ({attachments, ...props}) => {
  const {
    images = [],
    videos = [],
    voiceMsg,
  } = attachments;

  const arrayImages = props.isMenuOpen ? images : images.slice(0,6);
  const arrayVideos = props.isMenuOpen ? videos : videos.slice(0, 6 - (images.length));

  const mediaLength = images.length + videos.length;


  if(!voiceMsg && !mediaLength) {
    return null
  }

  return (
    <>
      {voiceMsg?.duration ? (
        <div className="pinned-msgs-attachments__voicemail">
          <div className="pinned-msgs-attachments__voicemail-box">
            <div className="pinned-msgs-attachments__voicemail-text">
              voicemail
              <div className="pinned-msgs-attachments__voicemail-time">
                ({formatTime(voiceMsg?.duration * 1000)})
              </div>
            </div>

            <ICONS.phoneVoicemail className='pinned-msgs-attachments__voicemail-icon'/>
          </div>
        </div>
      ) : (
        <div
          className={classModifier('pinned-msgs-attachments', [
            !props.isMenuOpen && mediaLength === 2 && 'mod2',
            !props.isMenuOpen && mediaLength > 2 && mediaLength <= 4 && 'mod4',
            !props.isMenuOpen && mediaLength > 4 && 'mod6',
            props.isMenuOpen && 'open',
            props.isMenuOpen && mediaLength === 1 && 'modopen1',
          ])}
        >
          {arrayImages.map((image, idx) => (
            <div key={idx} className="pinned-msgs-attachments__item">
              <div className="pinned-msgs-attachments__image-wrapper">
                <LazyLoadImage
                  src={image.url}
                  className="pinned-msgs-attachments__image"
                  alt="msg img"
                  spinnerSize={20}
                />
              </div>

              {props.isMenuOpen && image.fileName && 
                <p className="pinned-msgs-attachments__image-name">
                  {image.fileName}
                </p>
              }
            </div>
          ))}

          {arrayVideos.map((video, idx) => (
            <div key={idx} className="pinned-msgs-attachments__item">
              <div
                className="pinned-msgs-attachments__image-wrapper">
                <VideoThumbnail
                  spinnerSize={40}
                  src={video.url}
                />
              </div>

              {props.isMenuOpen && video.fileName  &&
                <p className="pinned-msgs-attachments__image-name">
                  {video.fileName}
                </p>
              }
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default PinnedMsgAttachments;