import { memo } from 'react';
import classNames from 'classnames';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';
import { useDebounce, useDidUpdate } from 'hooks';

import './SearchInput.scss'

const SearchInput = (props) => {
  const {
    query,
    classPrefix,
    onChange,
    setQuery,
    stopSearch,
    startSearch,
    inputRef,
    isDisabled,
    showSearchBtn,
    showClearBtn,
    maxLength = "20",
    disableDefaultStyles,
    inputClassName,
    inputWrapClassname,
    minSearchLength = 1,
    ...inputProps
  } = props;

  useDidUpdate(() => {
    if (query.length === 0) {
      stopSearch('');
    }
  }, [query]);

  const handleSearch = () => {
    if (query.length > minSearchLength) {
      startSearch(query);
    }
  };

  const onInputChange = (e) => {
    if (onChange) {
      onChange(e);
    }
    setQuery(e.target.value)
  };

  useDebounce(handleSearch, 700, query);

  const renderSearchClearBtn = () => {
    if (showSearchBtn && query.length < 1) {
      return (
        <button className="search-input__btn">
          <ICONS.search className={classModifier('search-input__icon', 'search')} />
        </button>
      );
    }

    if (showClearBtn && query.length > 1) {
      return (
        <button className="search-input__btn" onClick={() => setQuery('')}>
          <ICONS.close className={classModifier('search-input__icon', 'clear')} />
        </button>
      );
    }
  };
   
  const resultClassName = classNames(inputClassName, 'search-input__input');

  return (
    <div className={classNames(inputWrapClassname, 'search-input')}>
      <input
        autoComplete="off"
        className={resultClassName}
        maxLength={maxLength}
        placeholder="Search"
        disabled={isDisabled}
        tabIndex="0"
        type="text"
        value={query}
        ref={inputRef}
        onChange={onInputChange}
        autoFocus={props.autoFocus}
        {...inputProps}
      />

      {renderSearchClearBtn()}
    </div>
  );
};

export default memo(SearchInput);
