import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import {
  getAllContactsList,
  updateContactsList,
  toggleContactPin,
  toggleContactMark,
  CONTACTS_LIMIT,
  toggleContactBlock,
  removeContactFilter,
  getMarkedContacts,
  getContactsWithUnread,
  getRelatedContacts,
  getActiveChats,
  getOnlyHotels,
  getOnlyAgents,
  getNotTextedForLong,
  ALL_FILTER,
  UNREAD_FILTER,
  MARKED_FILTER,
  RELATED_FILTER,
  ACTIVE_CHATS,
  toggleSearchSource,
} from 'redux/ducks/contacts';
import { CONTACT_TABS } from 'config/constants';
import API from 'api/api';
import { usePrevious, useDidUpdate, useDidMount, useCancelToken } from 'hooks';
import { selectClientsList } from 'redux/selectors/selectors';
import { openModal, MODAL_TYPES } from 'redux/ducks/activeWindows';
import { updateSearchContactsByType, stopContactsSearchByType, searchContactsByType } from 'redux/ducks/contacts';
import LS from 'utils/localStorageAPI';

import './SidebarClientsChatList.scss';
import SidebarContactsFilters from '../components/SidebarContactsFilters/SidebarContactsFilters';
import SidebarContactsList from '../components/SidebarContactsList/SidebarContactsList';
import ContactItem from '../components/SidebarContactsList/ContactItem';
import Contextmenu from 'components/ContextMenu/ContextMenu';
import SidebarHeader from '../components/SidebarHeader/SidebarHeader';
// import ChatListSearchFilterZone from '../ChatListSearchFilterZone/ChatListSearchFilterZone';
// import SearchContacts from '../../../../components/SearchContacts/SearchContacts';

const SidebarClientsChatList = ({ openModal, clientUnreadCount, clientsSearchSource, ...props }) => {
  // const [searchContacts, setSearchContacts] = useState({
  //   query: '',
  //   list: [],
  //   isOpen: false,
  // });
  // const [donorContact, setDonorContact] = useState(null);

  const prevClientsSearchSource = usePrevious(clientsSearchSource);

  // To cancel axios http request
  const { newCancelToken, cancelPrevRequest } = useCancelToken();

  useDidMount(() => {
    if (props.searchClients) {
      props.searchContactsByType(
        props.searchClients,
        CONTACT_TABS.CLIENTS,
        clientsSearchSource,
        newCancelToken(),
      );
    }

    if (props.clientsActiveFilter !== ALL_FILTER) {
      getClientListRequest()();
    }
  });

  useDidUpdate(() => {
    startSearch(props.searchClients);
  }, [clientsSearchSource]);

  useDidUpdate(() => {
    LS.setItem('clientsSearch', props.searchClients)
  }, [props.searchClients]);

  useDidUpdate(() => {
    LS.setItem('clientsActiveFilter', props.clientsActiveFilter);
  }, [props.clientsActiveFilter]);

  const getClientListRequest = () => {
    if (props.searchClients) {
      return props.updateSearchContactsByType;
    }
    else if (props.clientsActiveFilter === MARKED_FILTER) {
      return props.getMarkedContacts;
    }
    else if (props.clientsActiveFilter === UNREAD_FILTER) {
      return props.getContactsWithUnread;
    }
    else if (props.clientsActiveFilter === RELATED_FILTER) {
      return props.getRelatedContacts;
    }
    else if (props.clientsActiveFilter === ACTIVE_CHATS) {
      return props.getActiveChats;
    }
    return props.updateContactsList;
  };

  const blockContact = (contact) => {
    // if (contact.type === 9) return;             // Saved msg

    if (contact.blocked) {
      return props.toggleContactBlock(contact);
    }

    // return openModal(MODAL_TYPES.blockForm, { contact: contact.id }); //FIXME v2
  };

  const generateContextItems = (contact) => {
    const items = props.clientsActiveFilter !== ACTIVE_CHATS
      ? [
        {
          text: `${contact.pinned ? 'Unp' : 'P'}in contact`,
          action: () => props.toggleContactPin(contact),
        },
        {
          text: `${contact.marked ? 'Unm' : 'M'}ark contact`,
          action: () => props.toggleContactMark(contact),
        },
        {
          text: `${contact.blocked ? 'Un' : 'B'}lock contact`,
          action: () => blockContact(contact),
        },
        // { //TODO v2
        //   text: 'Merge contact to ...',
        //   action: () => {
        //     setSearchContacts({ ...searchContacts, isOpen: true })
        //     setDonorContact(contact);
        //   },
        // },
        {
          text: `${contact.is_active_chat ? 'Remove from' : 'Add to'} active chats`,
          action: () => API.toggleContactActiveChat(contact),
        },
      ]
      : [
        {
          text: "Remove from active chats",
          action: () => API.toggleContactActiveChat(contact),
        },
      ];

    items.push();

    return items;
  };

  const getFiltersByType = useCallback(() => {
    const filters = [
      { name: ALL_FILTER, action: () => props.removeContactFilter('clients') },
      { name: MARKED_FILTER, action: () => props.getMarkedContacts('clients') },
      { name: UNREAD_FILTER, action: () => props.getContactsWithUnread('clients') },
      { name: RELATED_FILTER, action: () => props.getRelatedContacts('clients') },
      { name: ACTIVE_CHATS, action: () => props.getActiveChats('clients') },
    ];

    return filters;
  }, []);

  const stopSearch = useCallback(() => {
    cancelPrevRequest();
    props.stopContactsSearchByType('clients');
  }, []);

  const startSearch = useCallback((query) => {
    if (query !== props.searchClients || prevClientsSearchSource !== clientsSearchSource) {
      cancelPrevRequest();

      return props.searchContactsByType(query, 'clients', clientsSearchSource, newCancelToken());
    }
  }, [clientsSearchSource]);

  return (
    <div className="sidebar-sections">
      <div className="sidebar-sections__header">
        <SidebarHeader type='Clients' />
      </div>

      <div className="sidebar-sections__filters">
        <SidebarContactsFilters
          initialQuery={props.searchClients}
          activeTab='clients'
          startSearch={startSearch}
          stopSearch={stopSearch}
          filters={getFiltersByType()}
          activeFilter={props.activeFilter}
          defaultFilter={ALL_FILTER}
          showSearchSourceToggle
          showSearchBtn
          showClearBtn
          searchSource={clientsSearchSource}
          toggleSearchSource={props.toggleSearchSource}
        />
      </div>

      <div className="sidebar-sections__list">
        <SidebarContactsList
          list={props.clients}
          pending={props.clientsPending}
          search={props.searchClients}
          filter={props.clientsActiveFilter}
          updateList={getClientListRequest()}
          limit={CONTACTS_LIMIT}
          type={1}

          listItem={ContactItem}
          contextMenu={Contextmenu}
          generateContextItems={generateContextItems}

          searchSource={clientsSearchSource}
        />
      </div>

      {/* {searchContacts.isOpen &&
        <SearchContacts
          onClickAction={(acceptorContact) => openModal(MODAL_TYPES.mergeContacts, { donorContact, acceptorContact })}
          // onClickAction={(contactToMerge) => openModal(MODAL_TYPES.mergeContacts, { donorId: contactId, acceptorId: contactToMerge.id })}
          setSearchContacts={setSearchContacts}
          classPrefix="search-contacts"
          ignoredIds={[donorContact.id]}
          isPopup
          closeModal={() => setSearchContacts({ ...searchContacts, isOpen: false })}
          searchContacts={searchContacts}
        />
      } */}
    </div>
  );
};


const mapStateToProps = state => ({
  clients: selectClientsList(state, 1),
  clientsPending: state.contacts.clients.pending,
  clientsActiveFilter: state.contacts.clients.activeFilter,
  searchClients: state.contacts.clients.search,
  clientsTotalCount: state.contacts.clients.totalCount,
  clientUnreadCount: state.contacts.clients.unreadCount,
  activeFilter: state.contacts.clients.activeFilter,
  clientsSearchSource: state.contacts.clients.searchSource,
});

const mapDispatchToProps = {
  getAllContactsList,
  updateContactsList,
  updateSearchContactsByType,
  toggleContactPin,
  toggleContactMark,
  getContactsWithUnread,
  getMarkedContacts,
  getRelatedContacts,
  getActiveChats,
  toggleContactBlock,
  stopContactsSearchByType,
  searchContactsByType,
  removeContactFilter,
  getOnlyHotels,
  getOnlyAgents,
  getNotTextedForLong,
  openModal,
  toggleSearchSource,
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarClientsChatList);