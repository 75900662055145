import React from 'react';
import { useDropzone } from 'react-dropzone';


const ImgInput = ({ onChangeHandler, ...props }) => {
  const { getInputProps } = useDropzone();

  const changeContactAvatar = (e) => {
    onChangeHandler(e.target.files[0]);

    console.log(e.target.files[0]);
  };

  return (
    <input
      {...props}
      {...getInputProps()}
      name="image"
      type="file"
      onChange={changeContactAvatar}
    />
  );
};

export default ImgInput;
