import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import API from 'api/api';
import ICONS from 'assets/icons';
import { selectObjectOperators } from 'redux/selectors/selectors';
import { classModifier, convertNumberToSeconds } from 'utils';
import { useDidMount, useToggle, useWillUnmount } from 'hooks';

import './SystemMsg.scss';
import TimeAgo from 'components/TimeAgo';
import Spinner from 'components/UI/Spinner/Spinner';
import AttachmentBooking from '../../../ChatAttachments/AttachmentBooking';
import AttachmentEmail from '../../../ChatAttachments/AttachmentEmail';

const SystemMsg = (props) => {
  const {
    userId,
    interaction,
    subjectOperator,
    objectOperators,
    returnOnlyMsgString = false,
  } = props;

  const chatUpdate = interaction.attachments && interaction.attachments.chatUpdate;

  const isBooking = chatUpdate && (
    chatUpdate.action === "newBooking" ||
    chatUpdate.action === "futureBookingActive"
  );

  const [session, setSession] = useState({});
  const [pending, togglePending] = useToggle(true);

  let isUnmount = useRef(false);

  useDidMount(() => {
    if (
      chatUpdate && 
      chatUpdate.sessionId && 
      (chatUpdate.action === 'futureBookingActive' || chatUpdate.action === 'newBooking')
    ) {
      API.getSessionById(chatUpdate.sessionId)
        .then(res => !isUnmount.current && setSession(res.data))
        .catch(console.error)
        .finally(() => togglePending(false));
    }
    else {
      togglePending(false);
    }
  });

  useWillUnmount(() => isUnmount.current = true);

  const convertCallDuration = (duration) => {
    if (duration === null) return '';

    const timeString = convertNumberToSeconds(+duration)
      .split(':')
      .filter((item, index) => (index === 0 && item !== '00') || index > 0)
      .join(':');

    if (timeString[0] === '0') return `(${timeString.slice(1)})`;

    return `(${timeString})`;
  };

  const renderSystemMsg = () => {
    if (interaction.type === 1) {  // incoming call
      return {
        timeAgo: interaction.dateCreated,
        text: `Incoming Call ${convertCallDuration(interaction.duration)}`,
        icon: <ICONS.incCall className={classModifier('system-msg__icon', 'inc-call')} />,
      };
    }

    if (interaction.type === 2) {  // outgoing call
      return {
        timeAgo: interaction.dateCreated,
        text: `Outgoing Call ${convertCallDuration(interaction.duration)}`,
        icon: <ICONS.outCall className={classModifier('system-msg__icon', 'out-call')} />,
      };
    }

    if (!interaction.attachments) {
      return { text: interaction.body };
    }

    switch (chatUpdate.action) {
      case 'edit': {
        const selectEdited = () => {
          let stringEditedMeta = null;

          if (chatUpdate.edit.title) {
            stringEditedMeta = `room title to ${chatUpdate.edit.title}.`;
          }
          if (chatUpdate.edit.photo) {
            if (stringEditedMeta) {
              stringEditedMeta = stringEditedMeta + ' Room photo was updated.';
            }
            else {
              stringEditedMeta = 'room photo';
            }
          }
          return stringEditedMeta;
        };

        return { text: `${(subjectOperator && subjectOperator.username) || `You`} changed ${selectEdited()}` };
      }

      case 'removeUsers': {
        const operatorsStr = objectOperators.map(operator => operator.username).join(', ');

        return {
          text: `${(subjectOperator?.username) || `You`} removed  ${(operatorsStr) || 'You'} from room.`
        };
      }

      case 'leave': {
        const nameLeaved = (subjectOperator && subjectOperator.username) || 'You';

        return { text: `${nameLeaved} leaved chat room.` };
      }

      case 'addUser': {
        const addedOperators = objectOperators
          .map(operator => {
            if (operator === undefined) {
              return 'You';
            }
            return operator.username;
          })
          .join(', ');

        return {
          text: `Users ${addedOperators} was added to chat by ${(subjectOperator && subjectOperator.username) || `You`} `
        };
      }

      case 'chatCreated': {
        return { text: `${(subjectOperator && subjectOperator.username) || `You`} create chat.` };
      }

      case 'updateNumber': {
        const chatSource = chatUpdate.edit.title.split(' ').pop();

        let icon;
        if (chatSource === 'Telegram') {
          icon = <ICONS.telegram className={classModifier('system-msg__icon', 'telegram')} />
        }
        else if (chatSource === 'SMS') {
          icon = <ICONS.comments className={classModifier('system-msg__icon', 'sms')} />
        }

        let msg = ''
        if ((subjectOperator === undefined) && interaction.attachments.chatUpdate.userId) {
          msg = `You ${chatUpdate.edit.title}`
        }
        else if (subjectOperator && subjectOperator.username) {
          msg = `${subjectOperator.username} ${chatUpdate.edit.title}`
        }
        else {
          msg = `${chatUpdate.edit.title}`;
        }

        return {
          text: msg,
          icon,
        }
      }
      case 'session_delete':
      case 'session_start':
      case 'session_close': {
        // const isSessionStart = chatUpdate.action === 'session_start';

        const getActionTitle = () => {
          switch (chatUpdate.action) {
            case 'session_start':
              return 'Started'
            case 'session_close':
              return 'Closed'
            case 'session_delete':
              return 'Removed'
            default:
              return 'unknown action';
          }
        }
        let operatorName = '';

        if (!subjectOperator && !chatUpdate.userId) {
          operatorName = 'Unknown operator';
        }
        else if (!subjectOperator) {
          operatorName = 'You';
        }
        else {
          operatorName = subjectOperator.username;
        }

        return {
          text: 'Session ' + getActionTitle() + ` by ${operatorName}`,
          timeAgo: interaction.dateCreated,
          icon: <ICONS.puzzle className={classModifier('system-msg__icon', `${chatUpdate.action.slice(8)}-session`)} />
        }
      }
      case "session_assistance":
      case "session_assistance_delete":
      case "session_transfer": {
        const [who, whom] = objectOperators.map((operator, index) => {
          if (!operator) {
            return +chatUpdate.usersIds[index] === userId
              ? 'You'
              : 'DELETED_OPERATOR';
          }
          return operator.username;
        });

        const getMsg = () => {
          switch (chatUpdate.action) {
            case "session_assistance":
              return `${who} added ${whom} to session`;
            case "session_assistance_delete":
              return`${who} deleted ${whom} from session`;
            case "session_transfer":
              return `${who} transferred session to ${whom}`;
          }
        }

        return {
          text: getMsg(),
          timeAgo: interaction.dateCreated,
          icon: <ICONS.puzzle className={classModifier('system-msg__icon', 'transfer-session')} />
        }
      }

      case "newBooking": {
        if (returnOnlyMsgString) {
          return { text: `New booking was created by ${(subjectOperator && subjectOperator.username) || `You`}` }
        }

        const booking = session.bookings && session.bookings.find(booking => booking.id === chatUpdate.bookingId);

        const attachment = (
          <AttachmentBooking
            chatUpdate={chatUpdate}
            subjectOperator={subjectOperator}
            userTimezone={props.userTimezone}
            userHour12={props.userHour12}
            session={session}
            booking={booking}
          />
        )
        const icon = (
          <ICONS.booking
            className={classModifier('system-msg__icon', booking ? 'booking' : 'booking-removed')}
          />
        )

        return {
          icon,
          timeAgo: booking?.date_created,
          attachment: booking ? attachment : null,
          text: booking ? 'Booked' : 'Booking was removed',
        }
      }

      case "futureBookingActive": {
        if (returnOnlyMsgString) {
          return { text: "Future booking is available" }
        }

        const booking = session.bookings && session.bookings.find(booking => booking.id === chatUpdate.bookingId);
        const attachment = (
          <AttachmentBooking
            chatUpdate={chatUpdate}
            subjectOperator={subjectOperator}
            userTimezone={props.userTimezone}
            userHour12={props.userHour12}
            session={session}
            booking={booking}
            isFutureBookingAvailable
          />
        )

        return { attachment, timeAgo: booking?.date_created };
      }

      case "newReminder": {
        return { text: `${(subjectOperator && subjectOperator.username) || `You`} create new msgs reminder` };
      }

      case "newMail": {
        const { mail } = chatUpdate;

        return {
          timeAgo: mail.created_at,
          attachment: <AttachmentEmail email={mail} />,
          icon: <ICONS.mailLines className={classModifier('system-msg__icon', 'mail')} />,
        }
      }

      default:
        return { text: interaction.body };
    }
  };
  
  const sysMsg = renderSystemMsg();

  if (returnOnlyMsgString) {
    return renderSystemMsg();
  }

  return (
    <div className="system-msg">
      {(pending && isBooking) 
        ? (
          <div className='system-msg__booking-pending'>
            <Spinner spinnerSize={30} />
          </div>
        ):(
        <>
            {(sysMsg.text || sysMsg.timeAgo) &&
            <div className={classModifier('system-msg__title', sysMsg.attachment && 'with-attachment')}>
              {(sysMsg.text || sysMsg.timeAgo) &&
                <p className="system-msg__text">
                  {sysMsg.timeAgo &&
                    <TimeAgo
                      className='system-msg__timeago'
                      minPeriod={60}
                      date={sysMsg.timeAgo}
                    />
                  }

                  {sysMsg.text}
                </p>
              }

              {sysMsg.icon}
            </div>
          }

          {sysMsg.attachment &&
            <div className="system-msg__attachment">{sysMsg.attachment}</div>
          }
        </>
        )
      }
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  userId: state.user.id,
  subjectOperator: ownProps.interaction.attachments
    ? state.operators.entities[ownProps.interaction.attachments.chatUpdate.userId]
    : null,
  objectOperators: ownProps.interaction.attachments
    ? selectObjectOperators(state, ownProps.interaction.attachments.chatUpdate.usersIds)
    : null,
});

SystemMsg.propTypes = {
  interaction: PropTypes.object,
  subjectOperator: PropTypes.object,
  objectOperators: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
};

export default connect(mapStateToProps)(SystemMsg);