import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Scrollbars } from 'react-custom-scrollbars';

import { classModifier } from 'utils';
import {
  getEmails,
  LIST_TYPES,
  updateSearch,
  setEmailsOffset,
  setActiveFolder,
  MAIL_LIST_LIMIT,
  clearActiveFolder,
  clearSelectedMails,
  setActiveFolderTabIndex,
  setActiveSortFilter,
  MAIL_LIST_SORT_OPTIONS,
  getFilteredBySearchQueryEmails,
} from 'redux/ducks/mail';

import './MailList.scss';
import Spinner from 'components/UI/Spinner/Spinner';
import MailListItem from '../MailListItem/MailListItem';
import MailPagination from '../MailPagination/MailPagination';
import {useWillUnmount} from "../../../../hooks";

const MailList = ({
  ids,
  tabs = [],
  type,
  offset,
  search,
  option,
  pending,
  entities,
  totalCount,
  getEmails,
  updateSearch,
  setActiveFolder,
  activeFolderTabIndex,
  clearActiveFolder,
  setActiveSortFilter,
  setActiveFolderTabIndex,
  setEmailsOffset,
  clearSelectedMails,
  getFilteredBySearchQueryEmails,
}) => {
  const scrollbarsRef = useRef();

  const [firstPending, setFirstPending] = useState(true);

  const isSearchType = type === LIST_TYPES.search;

  useEffect(() => {
    setActiveFolder(type);

    return () => {
      clearActiveFolder();
      clearSelectedMails();
      setActiveFolderTabIndex(0);
      setActiveSortFilter(MAIL_LIST_SORT_OPTIONS[0].value);
    };
  }, []);

  useEffect(() => {
    if (!isSearchType && search) return;

    handleGetOffset(0)
      .then(() => setFirstPending(false));
  }, [activeFolderTabIndex, option, search]);

  useWillUnmount(() => {
    if (isSearchType) {
      updateSearch('');
    }
  });

  const handleGetOffset = async (offset) => {
    if (isSearchType && search) {
      await getFilteredBySearchQueryEmails({
        type,
        offset,
        search,
        limit: MAIL_LIST_LIMIT,
      });
    } else {
      await getEmails({
        type,
        offset,
        mode: tabs[activeFolderTabIndex]?.mode,
        limit: MAIL_LIST_LIMIT,
        sortBy: option,
      });
    }

    clearSelectedMails();
    setEmailsOffset(offset);
    scrollConversationsToTop();
  };

  const scrollConversationsToTop = () => {
    if (scrollbarsRef.current) {
      scrollbarsRef.current.scrollToTop();
    }
  };

  if (firstPending) {
    return (
      <div className="mail-list">
        <Spinner spinnerSize={75} className={"mail-list__spinner"} />
      </div>
    );
  }

  return (
    <div className={classModifier('mail-list', pending && 'pending')}>
      <div className="mail-list__header">
        {tabs.length > 0 &&
          <ul className="mail-list__tabs">
            {tabs.map((tab, idx) =>
              <li
                key={tab.name}
                className={classModifier('mail-list__tab', [
                  activeFolderTabIndex === idx && 'selected'
                ])}
                onClick={() => {
                  setActiveFolderTabIndex(idx);
                  clearSelectedMails();
                }}
              >
                {tab.name}
              </li>
            )}
          </ul>
        }

        <div className="mail-list__pagination-sort-wrap">
          {totalCount > 0 &&
            <MailPagination
              offset={offset}
              pageSize={MAIL_LIST_LIMIT}
              totalCount={totalCount}
              onGetOffset={handleGetOffset}
            />
          }

          {!isSearchType && (
            <div className="mail-list__sort">
              <Select
                className="react-select"
                classNamePrefix="react-select"
                options={MAIL_LIST_SORT_OPTIONS}
                defaultValue={MAIL_LIST_SORT_OPTIONS[0]}
                getOptionValue={option => option.value}
                getOptionLabel={option => option.label}
                onChange={option => setActiveSortFilter(option.value)}
                isSearchable={false}
                components={{
                  IndicatorSeparator: null,
                }}
              />
            </div>
          )}
        </div>
      </div>

      {!ids.length && !pending &&
        <p className="mail-list__no-items">No emails</p>
      }

      {ids.length > 0 &&
        <Scrollbars
          autoHide
          autoHideTimeout={800}
          ref={scrollbarsRef}
          style={{ width: 'auto', height: 'auto' }}
          className="mail-list__scrollbars"
        >
          <ul className="mail-list__conversations">
            {ids.map(id => (
              <li key={id}>
                <MailListItem
                  type={type}
                  item={entities[id]}
                />
              </li>
            ))}
          </ul>
        </Scrollbars>
      }
    </div>
  )
}

const mSTP = (state, ownProps) => ({
  ids: state.mail[ownProps.type].ids,
  entities: state.mail[ownProps.type].entities,
  offset: state.mail.offset,
  search: state.mail.mailList.search,
  pending: state.mail.mailList.mailListPending,
  totalCount: state.mail.totalCount,
  activeFolderTabIndex: state.mail.mailList.activeFolderTabIndex,
  option: state.mail.mailList.activeSortFilter,
});

const mDTP = ({
  getEmails,
  updateSearch,
  setEmailsOffset,
  setActiveFolder,
  clearActiveFolder,
  clearSelectedMails,
  setActiveFolderTabIndex,
  setActiveSortFilter,
  getFilteredBySearchQueryEmails,
});

export default connect(mSTP, mDTP)(MailList);
