import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

import { classModifier } from 'utils';

import './FormTextarea.scss';

const FormTextTextarea = (props) => {
  const {
    inputProps = {},
    inputRef,
    autosize,
    className,
    serverErrors,
    inputClassName,
    isErrorRelative,
    errorInputClassName,
    input: finalFormInputProps,
    meta: { error, touched, active, submitFailed }
  } = props;

  const textareaRef = useRef(null);

  const inputHeightAutosize = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.style.height = '';
      inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
    }
    else {
      textareaRef.current.style.height = '';
      textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
    }
  };

  useEffect(() => {
    autosize && inputHeightAutosize();
  }, [finalFormInputProps.value])

  const renderError = (errorMsg) => (
    <div className={classModifier('form-text-input__error form-textarea__error',
      isErrorRelative && 'relative')} >
      {errorMsg}
    </div>
  )

  const isClientError = error && touched && !active && submitFailed;
  const isServerError = serverErrors && serverErrors[finalFormInputProps.name];
  const errorInputClass = (isClientError || isServerError)
    ? classNames(errorInputClassName, 'input--error')
    : '';

  return (
    <div className={classNames('form-text-input form-textarea', className)}>
      <textarea
        {...finalFormInputProps}
        ref={inputRef ? inputRef : textareaRef}
        className={classNames('input', inputClassName, errorInputClass)}
        {...inputProps}
      />

      {isClientError
        ? renderError(error)
        : isServerError
          ? renderError(serverErrors[finalFormInputProps.name])
          : null
      }
    </div>
  )
}

export default FormTextTextarea;
