import React from 'react';
import { connect } from 'react-redux';
import ReactTimeAgo from 'react-timeago';

import { selectUserTimezone } from 'redux/selectors/selectors';
import { getDateByTimezoneOffset } from 'utils';

const TimeAgo = (props) => {
  const {
    userTimezone,
    date,
    className,
    minPeriod = 60,
  } = props;

  const getDate = () => {
    const localTimezoneOffset = -new Date().getTimezoneOffset();
    const dateByTimezoneOffset = getDateByTimezoneOffset(userTimezone, date);

    if (userTimezone === localTimezoneOffset) {
      return dateByTimezoneOffset;
    }
    const neededOffset = (userTimezone - localTimezoneOffset) * 60000;

    return new Date(dateByTimezoneOffset - neededOffset);
  }

  return (
    <ReactTimeAgo
      className={className}
      minPeriod={minPeriod}
      date={getDate()}
    />
  )
}

const mapStateToProps = (state) => ({
  userTimezone: selectUserTimezone(state),
});

export default connect(mapStateToProps)(TimeAgo);