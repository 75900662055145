import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  getContactAvatar,
  classModifier,
  parseMsgText,
  isEmptyObj,
  showTimelineDate,
  showContactName,
} from 'utils';
import { selectContactById, selectIsContactActive, selectRelatedOperator, selectUserTimezone } from 'redux/selectors/selectors';
import { useToggle } from 'hooks';
import { CONTACT_TYPES, INTERACTION_TYPES, ROUTES } from 'config/constants';
import { startSearchContactsMsgsInChat, updateActiveContact } from 'redux/ducks/clientChats';

import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
// import { EditContactFormNew } from 'containers/Dialogs/Dialogs'; //TODO v2
import BusyStatus from './BusyStatus/BusyStatus';
// import SystemMsg from '../../../Chat/components/ChatTimeline/interactions/SystemMsg'; //TODO v2
import BookingIcon from 'components/BookingIcon'; //TODO v2
import CallButton from 'components/CallButton/CallButton';
import TimeAgo from 'components/TimeAgo';
import Spinner from 'components/UI/Spinner/Spinner';
import ICONS from 'assets/icons';
import SystemMsg from 'containers/Chat/components/ChatTimeline/interactions/SystemMsg/SystemMsg';


const ContactItem = (props) => {
  const {
    contact = {},
    style,
    isActive,
    isBusy,
  } = props;

  const { INCOMING_MSG, INCOMING_CALL, INCOMING_MSG_TELEGRAM, INCOMING_EMAIL } = INTERACTION_TYPES;

  const isLastMsgFormTelegram = !!contact.interactionObject && (
    contact.interactionObject.type === 9 ||  contact.interactionObject.type === 10
  );

  const [isBusyTooltipShowed, setBusyTooltipShowing] = useToggle(false);

  const handleContactClick = (e) => {
    e.preventDefault();

    props.updateActiveContact(contact);

    const chatSearch = props.type === CONTACT_TYPES.CLIENT
      ? props.clientsChatSearch
      : props.girlsChatSearch;

    // if we search by msgs (global) and search contact is active (opened chat with it),
    // we need to start search inside the contactsChat
    if (isActive && props.searchSource === 'msgs' && !!props.search && chatSearch !== props.search) {
      props.startSearchContactsMsgsInChat(props.type, props.search);
    }
  };

  const onContextMenu = (e) => {
    e.preventDefault();

    return props.isContextMenu
      ? props.openContextMenu([e.clientX, e.clientY], contact)
      : null;
  };

  // Show count when description is closed
  const showUnreadCount = () => {
    const isRelatedUser = !!contact.relatedUserId && contact.relatedUserId !== props.userId;

    if (contact.unreadCount > 0) {
      return (
        <span className={
          contact.withImportantUnread
            ? 'contact-item__count contact-item__count--important'
            : classModifier('contact-item__count', isRelatedUser && 'related')
        }>
          {contact.unreadCount >= 100 ? '99+' : contact.unreadCount}
        </span>
      )
    }
  }

  const busyStatus = isBusy
    ? <span className="contact-item__busy"
        onMouseEnter={() => setBusyTooltipShowing()}
        onMouseLeave={() => setBusyTooltipShowing()}
      ></span>
    : null;

  const renderRelatedOperator = () => {
    if (!contact.relatedUserId) {
      return null;
    }
    const name = contact.relatedUserId === props.userId
      ? 'You'
      : props.relatedOperator && props.relatedOperator.username;

    return <span className="contact-item__related">{name}</span>;
  };

  const renderLastInteraction = () => {
    let lastInteraction = '';

    const interactionObject = props.searchSource === 'msgs' && !!contact.interactionObject_searched && !!props.search
      ? contact.interactionObject_searched
      : contact.interactionObject;

    switch (interactionObject.type) {
      case 1: {
        lastInteraction = 'Incoming call';
        break;
      }
      case 2: {
        lastInteraction = 'Outgoing call';
        break;
      }
      case 3: {
        lastInteraction = interactionObject.message;
      }
      case 4: {
        if (interactionObject.user_id === props.userId) {
          lastInteraction = 'You: ' + interactionObject.message;
          break;
        }
        else {
          lastInteraction = interactionObject.message;
          break;
        }
      }
      case 8: {
        // // we don't need parse msg on attachment if it's system msg
        return <SystemMsg
          interaction={interactionObject}
          returnOnlyMsgString={true}
          userHour12={props.userHour12}
        />
      }
      case 9: {
        if (interactionObject.attachments?.hasOwnProperty('voiceMsg')) {
          lastInteraction = 'Incoming voice message'
          break;
        }
        if (
          interactionObject.attachments?.hasOwnProperty('images') &&
          interactionObject.attachments?.hasOwnProperty('videos')
        ) {
          lastInteraction = 'Incoming media'
          break;
        }
        if (interactionObject.attachments?.hasOwnProperty('videos')) {
          lastInteraction = 'Incoming video'
          break;
        }
        if (interactionObject.attachments?.hasOwnProperty('images')) {
          lastInteraction = 'Incoming image'
          break;
        }
        else {
          lastInteraction = interactionObject.message;
          break;
        }
      }
      case 10: {
        if (interactionObject.attachments?.hasOwnProperty('voiceMsg')) {
          lastInteraction = 'Outgoing voice message'
          break;
        }
        if (
          interactionObject.attachments?.hasOwnProperty('images') &&
          interactionObject.attachments?.hasOwnProperty('videos')
        ) {
          lastInteraction = 'Outgoing media'
          break;
        }
        if (interactionObject.attachments?.hasOwnProperty('videos')) {
          lastInteraction = 'Outgoing video'
          break;
        }
        if (interactionObject.attachments?.hasOwnProperty('images')) {
          lastInteraction = 'Outgoing image'
          break;
        }
        else {
          lastInteraction = interactionObject.message;
          break;
        }
      }
      case 12: {
        lastInteraction = 'Incoming email'
        break;
      }
      default:
        lastInteraction = interactionObject.message;
    }

    return parseMsgText(lastInteraction, true);
  };

  if (isEmptyObj(contact)) {
    return (
      <div className="contact-item contact-item--pending">
        <Spinner spinnerSize="32px" />
      </div>
    )
  }

  return (
    <div
      style={style}
      className={classModifier('contact-item', [
        contact.marked && !props.isSimpleView && "marked",
        contact.availability && contact.availability === 'off today'
          ? 'off-today'
          : contact.availability,
        contact.agentId && "with-agent",
        isActive && 'active',
        !!contact.unreadCount && 'unread-msgs',
        contact.type === 2 && "girl"
      ])}
      onContextMenu={onContextMenu}
      onClick={handleContactClick}
    >
      <div
        className={`contact-item__img-wrap ${contact.blocked ? "blocked" : ""} ${
          contact.is_active_chat ? "contact-item__img-wrap--active" : ""
        }`}
      >
        <LazyLoadImage src={getContactAvatar(contact)} alt="ava" className="contact-item__img" />
        {busyStatus}

        {isLastMsgFormTelegram && 
          <ICONS.telegram className="contact-item__telegram-icon" />
        }
      </div>

      <div className="contact-item__content-wrap">
        <div className="contact-item__info">
          {/* <BookingIcon //TODO v2
            classPrefix="contact-item__icon"
            booking={contact.closestBooking}
            userTimezone={props.userTimezone}
          /> */}
          <h4 className="contact-item__name">

            {/* <ICONS.info className="contact-item__icon-info" /> */}
            
            {showContactName(contact.fn, 'contact-item__ex')}

            {contact.type === CONTACT_TYPES.GIRL &&
              <>
                <span className="contact-item__rates">
                  {!!contact.rates && contact.rates}
                </span >

                {!!contact.rating &&
                  <span className="chat-header__rating">
                    ({contact.rating})
                  </span>
                }
              </>
            }
          </h4>

          <span className="contact-item__time">
            {props.isFromListOfGirlsWithStatus
              ? contact.statusUpdatedDate && <TimeAgo minPeriod={60} date={contact.statusUpdatedDate} />
              : contact.last_interaction && showTimelineDate(contact.last_interaction, props.userTimezone, props.userHour12)
            }
          </span>
        </div>

        <div className="contact-item__bottom">
          {contact.draft_message && 
            <ICONS.pencil className="contact-item__draft-icon" title="draft" />
          }

          <div
            className={`contact-item__last-msg  ${contact.interactionObject
              ? ([INCOMING_MSG, INCOMING_CALL, INCOMING_MSG_TELEGRAM, INCOMING_EMAIL].includes(contact.interactionObject.type)
                && 'contact-item__last-msg--operator')
              : ''
              }`
            }
          >
            {(contact.interactionObject || contact.interactionObject_searched) &&
              renderLastInteraction()
            }
          </div>

          {renderRelatedOperator()}

          {contact.isScheduledMsg &&
            <ICONS.checkbox className="contact-item__schedule-msg-icon"/>
          }
        </div>

        {!props.isSimpleView && contact.pinned &&
          <ICONS.pin className="contact-item__pinned-icon" title="pinned" />
        }

        {showUnreadCount()}

        {isBusyTooltipShowed &&
          <BusyStatus id={props.id} isContact />
        }
      </div>
    </div >
  );
};

const mapStateToProps = (state, ownProps) => ({
  contact: selectContactById(state, ownProps.id),
  isActive: selectIsContactActive(state, ownProps.type, ownProps.id),
  userId: state.user.id,
  userTimezone: selectUserTimezone(state),
  // agent: selectAgentContactById(state, ownProps.id),
  relatedOperator: selectRelatedOperator(state, ownProps.id),
  userHour12: state.user.hour12,
});

const mapDispatchToProps = {
  updateActiveContact,
  startSearchContactsMsgsInChat,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactItem);