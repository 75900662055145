import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ICONS from 'assets/icons';
import { getContactAvatar, getDateByTimezoneOffset, classModifier, getHighlightedText } from 'utils';
import { selectActiveFolderTab, selectUserTimezone } from 'redux/selectors/selectors';
import {
  LIST_TYPES,
  MAIL_LIST_LIMIT,
  setIsMailStarred,
  setMailListPending,
  deleteMailsToTrash,
  toggleIsMailSelected,
  deleteMailsPermanently,
  deleteMailsFromSearchList,
} from 'redux/ducks/mail';

import './MailListItem.scss';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import Spinner from 'components/UI/Spinner/Spinner';


const MailListItem = (props) => {
  const {
    item,
    type,
    offset,
    search,
    sortBy,
    userHour12,
    userTimezone,
    activeFolderTab,
    setIsMailStarred,
    deleteMailsToTrash,
    setMailListPending,
    toggleIsMailSelected,
    deleteMailsPermanently,
    selectedConversationsIds,
    deleteMailsFromSearchList,
  } = props;

  const navigate = useNavigate();

  const [isMouseWithin, setIsMouseWithin] = useState(false);
  const [pending, setPending] = useState({ delPending: false, starredPending: false });

  const isSent = type === LIST_TYPES.sent;
  const isDrafts = type === LIST_TYPES.drafts;
  const isSpam = type === LIST_TYPES.spam;
  const isTrash = type === LIST_TYPES.trash;
  const isSearch = type === LIST_TYPES.search;
  const isOutgoingLastMsg = item.user?.id || isSent || isDrafts;
  const { conversationId, draft } = item;

  const somePending = Object.values(pending).includes(true);
  const isShowActions = isMouseWithin || somePending;
  const isPermanentlyDelete = isSpam || isTrash || draft;
  const isMailSelected = selectedConversationsIds.includes(conversationId);

  const getDeletionConfig = () => {
    const config = {};

    if (isSearch) {
      config.search = search;
    } else {
      config.folder = type;
      config.mode = activeFolderTab;
      config.sortBy = sortBy;
    }

    config.offset = offset;
    config.limit = MAIL_LIST_LIMIT;
    config.conversationsIds = [conversationId];

    return config;
  };

  const handleMouseEnter = () => {
    setIsMouseWithin(true);
  }
  
  const handleMouseLeave = () => {
    setIsMouseWithin(false);
  }

  const handleClick = () => {
    if (somePending) {
      return;
    }

    // If only one draft is present in conversation and nothing else
    if (draft) {
      const { id, to, subject, body, attachments } = draft;

      return navigate("../compose", {
        state: {
          draft: { id, email: to, subject, message: body, attachments },
        },
      });
    }

    // If conversation contains messages other than draft
    navigate(`./email/${conversationId}`);
  }

  const handleSelect = (e) => {
    e.stopPropagation();

    toggleIsMailSelected(conversationId);
  }

  const handleDelete = async (e) => {
    e.stopPropagation();

    setPending(prev => ({ ...prev, delPending: true }));
    setMailListPending(true);

    if (isSearch) {
      await deleteMailsFromSearchList(getDeletionConfig());
    } else if (isPermanentlyDelete) {
      await deleteMailsPermanently(getDeletionConfig());
    } else {
      await deleteMailsToTrash(getDeletionConfig());
    }

    setPending(prev => ({ ...prev, delPending: false }));
    setMailListPending(false);
  }

  const handleStarred = async (e) => {
    e.stopPropagation();

    setPending(prev => ({ ...prev, starredPending: true }));
    setMailListPending(true);

    await setIsMailStarred({
      folder: type,
      conversationId,
      isStarred: !item.is_starred,
      activeMailTab: activeFolderTab,
    });

    setPending(prev => ({ ...prev, starredPending: false }));
    setMailListPending(false);
  }

  const lastMsgDate = useMemo(() => {
    const msgDate = getDateByTimezoneOffset(userTimezone, item.created_at);
    const now = getDateByTimezoneOffset(userTimezone);

    if (
      msgDate.getFullYear() === now.getFullYear() &&
      msgDate.getMonth() === now.getMonth() &&
      msgDate.getDate() === now.getDate()
    ) {
      return msgDate.toLocaleString('en-GB', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: userHour12,
        hourCycle: 'h12',
      });
    }

    return msgDate.toLocaleString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  }, [userTimezone, userHour12, item]);

  // It is necessary for the css animation to work correctly in actions menu
  const getActionsOffset = () => {
    const marginLeft = 20;
    const btnWidth = 40;

    if (isShowActions) {
      return 0;
    }

    // Two static buttons: mark and delete
    const staticWidth = marginLeft + btnWidth * 2;
    // Dynamic buttons example
    // const dynamicWidth = (Number(isSpam) + Number(isTrash)) * btnWidth;

    return staticWidth * (-1);
  }

  return (
    <div
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classModifier('mail-list-item', [
        !item.viewed && 'unread',
        somePending && 'pending',
        item.is_starred && 'starred',
      ])}
    >
      {item.is_draft &&
        <ICONS.pencil className="mail-list-item__draft-label" />
      }

      <button
        className="mail-list-item__checkbox-btn"
        onClick={handleSelect}
        disabled={somePending}
      >
        <div
          className={classModifier('mail-list-item__checkbox',
            isMailSelected && 'active'
          )}
        >
          {isMailSelected &&
            <ICONS.check className="mail-list-item__checkbox-check-icon" />
          }
        </div>
      </button>

      <div className="mail-list-item__avatar">
        <LazyLoadImage src={getContactAvatar(isOutgoingLastMsg ? item.user : item.caller)} />
      </div>

      <div className="mail-list-item__preview">
        <div className="mail-list-item__preview-header">
          <p className="mail-list-item__name">
            {getHighlightedText(
              (item.caller?.fn || (isOutgoingLastMsg ? item.to : item.from) || 'NO RECEPIENT'),
              search,
              'mail-list-item__highlight',
            )}
          </p>
          
          {isOutgoingLastMsg && !isDrafts &&
            <p className="mail-list-item__username">
              {getHighlightedText(
                (item.user?.username || 'Fastmail'),
                search,
                'mail-list-item__highlight'
              )}
            </p>
          }

          {item.count > 1 &&
            <p className="mail-list-item__count">{item.count}</p>
          }

          {item.is_attachments &&
            <ICONS.clip className="mail-list-item__attachments-icon" />
          }

          <p className="mail-list-item__subject">
            {getHighlightedText((item.subject || 'NO TITLE'), search, 'mail-list-item__highlight')}
          </p>

          {!isShowActions &&
            <p className="mail-list-item__date">{lastMsgDate}</p>
          }
        </div>

        <p className="mail-list-item__message">
          {getHighlightedText(item.body, search, 'mail-list-item__highlight')}
        </p>
      </div>

      <div className="mail-list-item__actions-wrapper">
        <div className="mail-list-item__actions"
          style={{ marginRight: getActionsOffset() }}
        >
          <button
            className="mail-list-item__action-btn"
            disabled={somePending}
            onClick={handleStarred}
          >
            <span
              className="mail-list-item__mark-sign"
              title={item.is_starred ? 'Unmark' : 'Mark'}
            />
          </button>

          <button
            className="mail-list-item__action-btn"
            title={isPermanentlyDelete ? "Delete Permanently" : "Delete (to Trash)"}
            disabled={somePending}
            onClick={handleDelete}
          >
            {pending.delPending
              ? <Spinner spinnerSize={24} />
              : <ICONS.trash />
            }
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  offset: state.mail.offset,
  search: state.mail.mailList.search,
  userHour12: state.user.hour12,
  sortBy: state.mail.mailList.activeSortFilter,
  activeFolderTab: selectActiveFolderTab(state),
  userTimezone: selectUserTimezone(state),
  selectedConversationsIds: state.mail.selectedConversationsIds,
});

const mapDispatchToProps = {
  deleteMailsPermanently,
  deleteMailsToTrash,
  toggleIsMailSelected,
  setIsMailStarred,
  setMailListPending,
  deleteMailsFromSearchList,
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(MailListItem));
