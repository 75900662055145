import ReactCalendar from 'react-calendar';

import './Calendar.scss';
import ICONS from 'assets/icons';

const Calendar = props => {
  const doubleQuoteElement = (
    <><ICONS.angleQuote /><ICONS.angleQuote /></>
  );

  return (
    <ReactCalendar
      className='default-calendar'
      minDetail='month'
      locale="en-En"
      prevLabel={<ICONS.angleQuote />}
      nextLabel={<ICONS.angleQuote />}
      prev2Label={doubleQuoteElement}
      next2Label={doubleQuoteElement}
      {...props}
    />
  )
}

export default Calendar;
