import { combineReducers } from 'redux';

import API from "api/api";

// 📌️ Types

export const GET_ALL_REQUIREMENTS = 'BOOKINGS/GET_ALL_REQUIREMENTS';

// 📌️ Operations

export const getAllRequirements = () => dispatch => {
  return API.getAllRequirements()
    .then(({ data }) => {
      dispatch({
        type: GET_ALL_REQUIREMENTS,
        payload: data
      })
    })
};

// 📌️ Reducers

const requirements = (state = [], { type, payload }) => {
  switch (type) {
    case GET_ALL_REQUIREMENTS:
      return payload;
    default:
      return state;
  }
};

export default combineReducers({
  requirements
});
