import { memo, useState, useRef, useLayoutEffect, useEffect, useMemo } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import { classModifier, throttle } from 'utils';

import './ContactTags.scss';
import Portal from 'components/Portal';

const gap = 5;
const maxExpandBtnWidth = 35;
const classPrefix = 'contact-tags';

const getSortedTags = (tagList) => {
  return tagList.sort((item1, item2) => {
    return item1.title.length - item2.title.length;
  })
}

const getRows = (parentElem, items, rowsLimit) => {
  const parentStyles = getComputedStyle(parentElem);
  const parentWidth = parentElem.clientWidth -
    parseInt(parentStyles.paddingRight) - 
    parseInt(parentStyles.paddingLeft);

  const tagsWidth = items.map(tag => {
    const span = document.createElement('span');
    span.setAttribute('class', `${classPrefix}__tag`);
    span.setAttribute('style', 'position: absolute; left: -999px; top: -999px');
    span.innerHTML = tag.title;

    document.body.appendChild(span);

    const style = getComputedStyle(span);
    const width = +parseFloat(style.width);

    span.parentNode.removeChild(span);

    return width;
  })

  const rows = {};
  let currentRow = 1;
  let currentRowWidth = 0;

  // Calculating tags per row
  for (let i = 0; i < tagsWidth.length; i++) {
    const tagWidth = tagsWidth[i];
    const isLastIndex = i === tagsWidth.length - 1;

    const addToArrayIfExist = () => {
      rows[currentRow] = rows[currentRow]
          ? [...rows[currentRow], items[i]]
          : [items[i]]
    }

    if (currentRow === rowsLimit) {
      if (currentRowWidth + tagWidth + gap + maxExpandBtnWidth <= parentWidth) {
        currentRowWidth += tagWidth + gap;
        addToArrayIfExist();
      }
      else {
        if (isLastIndex && currentRowWidth + tagWidth <= parentWidth) {
          addToArrayIfExist();
        }
        break;
      }
    }
    else {
      if (currentRowWidth + tagWidth <= parentWidth) {
        currentRowWidth += tagWidth + gap;
        addToArrayIfExist();
      }
      else {
        currentRow++;
        currentRowWidth = tagWidth + gap;
        rows[currentRow] = [items[i]]
      }
    }
  }

  return Object.values(rows);
}

const ContactTags = (props) => {
  const {
    tags,
    rowsLimit = 2,
  } = props;

  if (!tags) return null;

  const [rows, setRows] = useState([]);
  const [sortedTags, setSortedTags] = useState([]);
  const [hiddenTagsCount, setHiddenTagsCount] = useState(0);

  const headerTagsRef = useRef();

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible
  } = usePopperTooltip({ 
    trigger: 'hover',
    placement: 'top',
    offset: [0, 10],
  });

  useLayoutEffect(() => {
    handleTags();
  }, [tags]);

  const handleTags = () => {
    const sortedTags = getSortedTags(tags);
    const rows = getRows(headerTagsRef.current, sortedTags, rowsLimit);
    const hiddenTagsCount = sortedTags.length - rows.flat().length;
    
    setRows(rows);
    setSortedTags(sortedTags);
    setHiddenTagsCount(hiddenTagsCount);
  }

  const throttledHandleTags = useMemo(() => throttle(handleTags, 400), [tags]);

  useEffect(() => {
    if (tags.length > 0) {
      window.addEventListener('resize', throttledHandleTags);
      return () => window.removeEventListener('resize', throttledHandleTags);
    }
  }, [tags]);

  return (
    <div className={classPrefix} ref={headerTagsRef}>
      {rows.map((row, idx) => (
        <div key={idx} className={`${classPrefix}__row`} style={{ gap: `${gap}px` }}>
          {row.map(tag => (
            <span
              key={tag.id}
              className={`${classPrefix}__tag`}
              title={tag.title}
            >
              {tag.title}
            </span>
          ))}

          {(idx === rows.length - 1) && hiddenTagsCount > 0 && <>
            <span
              ref={setTriggerRef}
              className={classModifier(`${classPrefix}__tag`, 'hidden-count')}
            >
              {hiddenTagsCount > 99 ? '+99' : `+${hiddenTagsCount}`}
            </span>

            {visible &&
              <Portal>
                <div
                  className={`${classPrefix}__hidden-tags-wrap`}
                  ref={setTooltipRef}
                  {...getTooltipProps()}
                >
                  <div className={`${classPrefix}__hidden-tags`}>
                    {sortedTags
                      .slice(sortedTags.length - hiddenTagsCount)
                      .map(tag => (
                        <span
                          key={tag.id}
                          className={classModifier(`${classPrefix}__tag`, 'hidden')}
                          title={tag.title}
                        >
                          {tag.title}
                        </span>
                      ))
                    }
                  </div>

                  <div 
                    className={`${classPrefix}__arrow`} 
                    {...getArrowProps()}
                  />
                </div>
              </Portal>
            }
          </>}
        </div>
      ))}
    </div>
  )
}

export default memo(ContactTags);
