import React from 'react';
import { connect } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';

import {
  LIST_TYPES,
  COMPOSE_PENDING_TYPES,
  setComposeDraftId,
  setComposePending,
  deleteMessageDraftRequest,
} from 'redux/ducks/mail';

import './MailHeader.scss';
import MailHeaderComposeActions from './components/MailHeaderComposeActions';
import MailHeaderListActions from './components/MailHeaderListActions';

const mailListHeaderRoutes = Object.values(LIST_TYPES).map((type) => `/${type}`);

const MailHeader = (props) => {
  const {
    draftId,
    draftStatus,
    composePending,
    setComposePending,
    setComposeDraftId,
    deleteMessageDraftRequest,
  } = props;

  const navigate = useNavigate();

  const handleDeleteDraft = async () => {
    setComposePending({ [COMPOSE_PENDING_TYPES.delDraft]: true });

    try {
      if (draftId) {
        await deleteMessageDraftRequest(draftId);
      } else {
        // At first: disable draft saving in MailFormAutosave by setPending({ delDraft: true })
        // And on next render: unmount MailFormAutosave by navigate.push('inbox')
        await Promise.resolve();
      }

      navigate('/mail/inbox');
      setComposePending({ [COMPOSE_PENDING_TYPES.delDraft]: false });
      setComposeDraftId(null);
    } catch {
      setComposePending({ [COMPOSE_PENDING_TYPES.delDraft]: false });
    }
  };

  return (
    <div className="mail-header">
      <div className="mail-header__email"/>
      <div className="mail-header__actions">
        <Routes>
          <Route
            path="/compose"
            element={
              <MailHeaderComposeActions
                handleDeleteDraft={handleDeleteDraft}
                pending={composePending}
                draftStatus={draftStatus}
              />
            }
          />

          {mailListHeaderRoutes.map((path) => (
            <Route
              path={path}
              key={path}
              element={ <MailHeaderListActions /> }
            />
          ))}
        </Routes>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  draftId: state.mail.compose.draftId,
  composePending: state.mail.compose.pending,
  draftStatus: state.mail.compose.draftStatus,
});

const mapDispatchToProps = {
  setComposePending,
  setComposeDraftId,
  deleteMessageDraftRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(MailHeader);
