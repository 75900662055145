import React from 'react';
import { useDispatch } from 'react-redux';
import { FieldArray } from 'react-final-form-arrays';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';

import NotForFieldItem from './NotForFieldItem';

const NotForField = ({ callerId, values, saveContact, classPrefix, isNewContactCreation }) => {

  const dispatch = useDispatch();
  const ignoreIds = values.not_for_clients?.map(item => item.caller_ignore_id);

  const handleAddItem = (profileItem) => {
    if(!isNewContactCreation) {
      saveContact({
        ...values,
        not_for_clients: values.not_for_clients.concat(profileItem)
      });
    }
  };

  const handleOpenModal = (addContact) => {
    dispatch(openModal(MODAL_TYPES.notForContact, {
      ignoreIds,
      callerId,
      addContact,
      saveContact: handleAddItem
    }));
  }

  const handleRemoveItem = (removeItem, id) => {
    removeItem();

    if(!isNewContactCreation) {
      saveContact({
        ...values,
        not_for_clients: values.not_for_clients.filter(item => item.caller_ignore_id !== id)
      });
    }
  };

  return (
    <div className={classModifier(`${classPrefix}__field`, "not-for")}>
      <h4 className={`${classPrefix}__field-title`}>Not for:</h4>

      <FieldArray name="not_for_clients">
        {({ fields }) => (
          <>
            {!!fields.value?.length
              ? fields.value.map((item, index) => { 
                return (
                  <NotForFieldItem
                    key={item.id || index}
                    ignoreName={item.ignoreName}
                    removeItem={() => handleRemoveItem(() => fields.remove(index), item.caller_ignore_id)}
                  />
                )})
              : <div className={`${classPrefix}__field-content`}>
                  Empty field...
                </div>
            }

            <div className={`${classPrefix}__btn-wrapper`}>
              <button
                type="button"
                className={classModifier(`${classPrefix}__add-field-btn`, "contact-add")}
                onClick={() => handleOpenModal(fields.push)}
              >
                <ICONS.plusCircle className="adr-book-contact-form__btn-add-icon" />
              </button>
            </div>
          </>
        )}
      </FieldArray>
    </div>
  )
}

export default NotForField;
