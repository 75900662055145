import API from 'api/api';

import { FINISH_CALL } from './calls';

import { getDefaultField } from 'utils';
import { openModal, MODAL_TYPES } from './activeWindows';


export const SET_CONNECTION = 'SET_CONNECTION';
export const LOAD_TWILIO_DEVICE = 'LOAD_TWILIO_DEVICE';


export const loadTwilioDevice = (device) => dispatch => {
  dispatch({
    type: LOAD_TWILIO_DEVICE,
    payload: device
  });
};

export const getToken = (idToken) => {
  return API.getTwilioToken(idToken)
    .then(result => result.data.token);
};

export const setConnection = connection => dispatch => {
  dispatch({
    type: SET_CONNECTION,
    payload: connection
  });
};

export const callTo = (contact, tel) => (dispatch, getState) => {
  const device = getState().twilio.device;

  const To = tel || contact.username || getDefaultField(contact.tels, 'tel');

  device.connect({ To });

  // dispatch(openModal(MODAL_TYPES.outgoingCall, { contact }));
  // dispatch(showVoicemails(false));
};

export const callToConference = (conferenceId) => (dispatch, getState) => {
  const device = getState().twilio.device;

  device.connect({
    To: conferenceId || 'start-conference',
    action: 'create_new_conference'
  });

};

export const callToWithParams = (action, interactionId) => ({
  interactionId,
  action,
});

export const killConnection = (connection) => {
  if (connection) {
    connection.reject();
    connection.disconnect();
  }
};

export const acceptConnection = (connection) => {
  if (connection) {
    connection.accept();
  }
};


const initialState = {
  connection: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TWILIO_DEVICE: {
      return {
        ...state,
        device: action.payload
      };
    }

    case SET_CONNECTION: {
      return {
        ...state,
        connection: action.payload
      };
    }

    case FINISH_CALL: {
      return {
        ...state,
        connection: null
      };
    }

    default:
      return state;
  }
};