import {
  UPDATE_CLIENT,
  UPDATE_GIRL,
  GET_GIRLS_FROM_TABS,
  REMOVE_GIRL
} from "./contacts";

import API from 'api/api';

import { NEW_OUTGOING_CALL, NEW_INCOMING_CALL, NEW_INCOMING_QUEUE_CALL } from "./calls";
import {
  onUpdateTimeline,
  onInteraction,
  onStatusChange,
  updateUnreadMsgsInTimeline,
  NEW_INCOMING_MESSAGE,
  NEW_OUTGOING_MESSAGE,
  CHANGE_CALL_STATUS,
  CHANGE_MESSAGE_STATUS,
  onAttachmentsAdding,
  NEW_MESSAGE_ATTACHMENTS,
  onSelectTimelineMedia,
  onRemoveTab,
  onRemoveTabUpdateActiveContact,
  onRemoveTabs,
  PIN_GIRL_MSG,
  DELETE_PINNED_GIRL_MSG,
  CHANGE_ACTIVE_PINNED_GIRL_MSG,
  UPDATE_GIRL_CONVERSATION_MEDIA,
  UPDATE_GIRL_MSG_CONTEXT,
  CLEAN_GIRL_MSG_CONTEXT,
  GET_GIRL_DATE_MSG_CONTEXT,
  UPDATE_GIRL_DATE_MSG_CONTEXT,
  CLEAN_GIRL_DATE_MSG_CONTEXT,
  GET_GIRL_SCHEDULED_MSGS,
  UPDATE_GIRL_SCHEDULED_MSGS,
  CLEAN_CLIENT_SCHEDULED_MSGS,
  CLEAN_GIRL_SCHEDULED_MSGS,
  NEW_SCHEDULED_MESSAGE,
  GET_GIRL_SCHEDULED_MSGS_COUNT,
  START_SEARCH_GIRLS_MSGS,
  groupInteractionsByTimezone,
  DELETE_TELEGRAM_MSG,
  removeFromTimeline,
} from "./clientChats";

import { NEW_MISSED_CALL, UPDATE_VOICEMAIL_STATUS } from "./missedCalls";
import { CHAT_SOURCES, INTERACTION_TYPES } from "config/constants";

export const TEXT_TO_GIRL = "TEXT_TO_GIRL";

export const SET_GIRL_UPDATE_PENDING = 'SET_GIRL_UPDATE_PENDING';

export const UPDATE_SEARCHED_GIRL_MESSAGES = "UPDATE_SEARCHED_GIRL_MESSAGES";
export const GET_SEARCHED_GIRL_MESSAGES = "GET_SEARCHED_GIRL_MESSAGES";
export const GLOBAL_GIRL_MESSAGE_SEARCH = "GLOBAL_GIRL_MESSAGE_SEARCH";
export const UPDATE_GLOBAL_GIRL_MESSAGE_SEARCH = "UPDATE_GLOBAL_GIRL_MESSAGE_SEARCH";
export const STOP_SEARCH_GIRL_MESSAGES = "STOP_SEARCH_GIRL_MESSAGES";

export const UPDATE_ACTIVE_GIRL = "UPDATE_ACTIVE_GIRL";

export const GET_GIRL_TIMELINE = "GET_GIRL_TIMELINE";
export const GET_GIRL_TIMELINE_PENDING = "GET_GIRL_TIMELINE_PENDING";
export const GET_GIRL_TIMELINE_ERROR = "GET_GIRL_TIMELINE_ERROR";

export const REMOVE_GIRL_TAB = 'REMOVE_GIRL_TAB';
export const REMOVE_GIRL_TABS = 'REMOVE_GIRL_TABS';
export const SET_GIRLS_TABS = 'SET_GIRLS_TABS';

export const UPDATE_GIRL_TIMELINE = "UPDATE_GIRL_TIMELINE";
export const REMOVE_GIRL_REMIND_MSG = 'REMOVE_GIRL_REMIND_MSG;'
export const REMOVE_GIRL_REMINDER_FROM_MAIN_TIMELINE = "REMOVE_GIRL_REMINDER_FROM_MAIN_TIMELINE";

export const GET_GIRL_MSG_REMINDERS = 'GET_GIRL_MSG_REMINDERS';
export const CLEAN_GIRL_MSG_REMINDERS = 'CLEAN_GIRL_MSG_REMINDERS';
export const UPDATE_GIRL_MSG_REMINDERS = 'UPDATE_GIRL_MSG_REMINDERS'

export const UNREAD_GIRL_MESSAGES = "UNREAD_GIRL_MESSAGES";

export const GET_GIRL_CONVERSATION_MEDIA = "GET_GIRL_CONVERSATION_MEDIA";
export const CLEAN_GIRL_CONVERSATION_MEDIA = "CLEAN_GIRL_CONVERSATION_MEDIA";

export const GET_GIRL_MSG_CONTEXT = "GET_GIRL_MSG_CONTEXT";
export const FIX_GIRL_TAB = 'FIX_GIRL_TAB';
export const INIT_UNFIXED_GIRL_TAB = 'INIT_UNFIXED_GIRL_TAB';

export const GET_GIRL_SERVICE_MSGS = 'GET_GIRL_SERVICE_MSGS';
export const UPDATE_GIRL_SERVICE_MSGS = 'UPDATE_GIRL_SERVICE_MSGS';
export const CLEAN_GIRL_SERVICE_MSGS = 'CLEAN_GIRL_SERVICE_MSGS';
export const GET_GIRL_SERVICE_MSGS_COUNT = 'GET_GIRL_SERVICE_MSGS_COUNT';
export const NEW_SERVICE_MESSAGE = 'NEW_SERVICE_MESSAGE';

export const UPDATE_GIRL_MSG = 'UPDATE_GIRL_MSG';
export const DELETE_GIRL_MSG = 'DELETE_GIRL_MSG';
export const SET_GIRLS_RECENT_TABS = 'SET_GIRLS_RECENT_TABS';


export const setGirlsTabs = (newTabs) => dispatch => {
  dispatch({
    type: SET_GIRLS_TABS,
    payload: newTabs,
  });
};

export const fixGirlTab = (tabId) => dispatch => dispatch({ type: FIX_GIRL_TAB, payload: tabId });

export const initUnfixedGirlTab = (tabId) => dispatch => dispatch({ type: INIT_UNFIXED_GIRL_TAB, payload: tabId });

export const getServiceMsgs = (contact, userTimezone, withCancel = true) => dispatch => {
  dispatch({ type: GET_GIRL_TIMELINE_PENDING });

  API.getContactServiceMsgs(contact.id, null, withCancel)
    .then(res => {
      dispatch({
        type: GET_GIRL_SERVICE_MSGS,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          chatSource: CHAT_SOURCES.SYSTEM_MSGS,
        }
      });
    })
    .catch(console.error);
};

export const updateServiceMsgs = (contact, page, loadDirection, userTimezone) => dispatch => {
  API.getContactServiceMsgs(contact.id, page, true)
    .then(res => {
      dispatch({
        type: UPDATE_GIRL_SERVICE_MSGS,
        payload: {
          ...res.data,
          messages: groupInteractionsByTimezone(res.data.messages, userTimezone),
          userTimezone,
          loadDirection,
        }
      })
    })
    .catch(err => console.log(err));
};

export const cleanServiceMsgs = () => dispatch => dispatch({ type: CLEAN_GIRL_SERVICE_MSGS });

export const getServiceMsgsCount = contactId => dispatch => {
  API.getScheduledMsgsCount(contactId)
    .then(res => {
      dispatch({
        type: GET_GIRL_SERVICE_MSGS_COUNT,
        payload: res.data,
      })
    });
};

export const newServiceMsg = (msg, userTimezone) => dispatch => {
  dispatch({
    type: NEW_SERVICE_MESSAGE,
    payload: {
      interaction: msg,
      userTimezone,
    }
  });
};


const initialState = {
  active: null,

  tabs: [],
  unfixedTab: null,
  recentTabs: {
    all: [], // all recent tabs we worked with
    visible: [], // tabs that can be returned by undo button.
  },

  pinnedMsgs: [],
  activePinnedMsgs: null,

  timelinePending: false,
  updatePending: false,

  timeline: [],
  timelinePageCount: null,
  timelineLowerLoadedPage: null,
  timelineCurrentPage: null,
  newInteractionType: null,


  search: '',
  isGlobalMsgSearch: false,
  showSearchQuery: false,

  contextMsgId: null,
  contextDate: null,

  chatSource: CHAT_SOURCES.MSGS,

  scheduledMsgsCount: 0,
  serviceMsgCount: 0,

  auxiliaryTimeline: [],
  auxiliaryPageCount: null,
  auxiliaryLowerLoadedPage: null,
  auxiliaryHigherLoadedPage: null,
  auxiliaryCurrentPage: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_GIRL_UPDATE_PENDING:
      return {
        ...state,
        updatePending: action.payload,
      };

    case GET_GIRLS_FROM_TABS: {
      const getUpdatedActive = () => {
        if (action.payload.ids.includes(action.payload.active)) {
          // if we already have our active contact
          return action.payload.active;
        } else if (
          !!action.payload.ids.length &&
          !action.payload.ids.includes(action.payload.active)
        ) {
          // if our active has been changed => chose new active from tabs
          return action.payload.ids[0];
        } else return null;
      };

      const getUpdatedUnfixedTab = () => {
        if (state.unfixedTab && action.payload.ids.includes(state.unfixedTab)) {
          return state.unfixedTab
        }
        // if we have unfixedTab that was removed from server
        else {
          return null
        }
      }

      return {
        ...state,
        tabs: action.payload.ids,
        active: getUpdatedActive(),
        unfixedTab: getUpdatedUnfixedTab()
      };
    }
    case INIT_UNFIXED_GIRL_TAB: {
      return {
        ...state,
        unfixedTab: action.payload
      }
    }
    case FIX_GIRL_TAB: {
      if (action.payload !== state.unfixedTab) return state;

      return {
        ...state,
        unfixedTab: null,
      }
    }
    case UPDATE_ACTIVE_GIRL: {
      // if we mark msgs as read
      const girlId = action.payload.contact.id;

      if (girlId === state.active && !action.payload.showSearchQuery && !action.payload.fromMoreTabs) {
        return state;
      }
      if (girlId === state.active && action.payload.showSearchQuery) {
        return {
          ...state,
          isGlobalMsgSearch: action.payload.isGlobalMsgSearch ? true : false,
          showSearchQuery: action.payload.showSearchQuery ? true : false,
          search: action.payload.isGlobalMsgSearch ? state.search : '',
        };
      }

      const isInTabs = state.tabs.indexOf(girlId) !== -1;

      let updatedTabs = [...state.tabs];

      let updatedUnfixedTab = state.unfixedTab;

      if (action.payload.fromMoreTabs) {
        updatedTabs = [girlId, ...state.tabs.filter(id => id !== girlId)];

        if (girlId === state.active) {
          return {
            ...state,
            tabs: updatedTabs,
          }
        }
      }
      else if (!isInTabs) {
        if (state.unfixedTab) { //if tab not fixed replace her by action.payload.chatId
          if (updatedTabs.length === 20) { // limit - 20 msgs;
            updatedTabs[19] = girlId;
          }
          else {
            updatedTabs = updatedTabs.map(id => {
              if (id === state.unfixedTab) {
                return girlId;
              }
              return id;
            });
          }
        }
        else {
          if (updatedTabs.length === 20) { // limit - 20 msgs;
            updatedTabs[19] = girlId;
          }
          else {
            updatedTabs.push(girlId);
          }
        }

        updatedUnfixedTab = girlId;
      }


      return {
        ...state,
        tabs: updatedTabs,
        unfixedTab: updatedUnfixedTab,
        active: girlId,

        pinnedMsgs: [],
        activePinnedMsgs: null,

        timeline: [],
        timelinePageCount: null,
        timelineLowerLoadedPage: null,
        timelineCurrentPage: null,

        auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,
        auxiliaryCurrentPage: null,
        auxiliaryLowerLoadedPage: null,
        auxiliaryHigherLoadedPage: null,
        auxiliaryPageCount: null,

        contextMsgId: null,
        contextDate: null,
        chatSource: CHAT_SOURCES.MSGS,
        scheduledMsgsCount: 0,
        serviceMsgCount: 0,

        isGlobalMsgSearch: action.payload.isGlobalMsgSearch ? true : false,
        showSearchQuery: action.payload.showSearchQuery ? true : false,
        search: action.payload.isGlobalMsgSearch ? state.search : '',
      };
    }
    case START_SEARCH_GIRLS_MSGS: {
      return {
        ...state,
        timelinePending: true,
        showSearchQuery: true,
        search: action.payload
      }
    }
    case GET_GIRL_TIMELINE_PENDING: {
      return {
        ...state,
        timelinePending: true
      };
    }
    case GET_GIRL_TIMELINE: {
      if (action.payload.contactId !== state.active) {
        return state;
      }

      if (!action.payload.messages.length) {
        return {
          ...state,
          timelinePending: false,
          timelinePageCount: action.payload.pageCount,
          timelineLowerLoadedPage: action.payload.currentPage,
          timelineCurrentPage: action.payload.currentPage,
        };
      }


      return {
        ...state,
        timelinePending: false,
        timeline: action.payload.messages,
        timelinePageCount: action.payload.pageCount,
        timelineLowerLoadedPage: action.payload.currentPage,
        timelineCurrentPage: action.payload.currentPage,
        newInteractionType: null,
        pinnedMsgs: action.payload.pinnedMessage,
        activePinnedMsgs: action.payload.pinnedMessage[action.payload.pinnedMessage.length-1],

        search: '',
        isGlobalMsgSearch: false,
        showSearchQuery: false,

        // contextMsgId: null,
        contextDate: null,
        chatSource: CHAT_SOURCES.MSGS,
      };
    }
    case UPDATE_GIRL_TIMELINE: {
      return {
        ...state,
        timeline: onUpdateTimeline(state.timeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
        timelineCurrentPage: action.payload.currentPage,
        // do not update page count if we scroll to top because new msgs can update it dynamically
        timelinePageCount: action.payload.loadDirection === 'up'
          ? state.timelinePageCount
          : action.payload.pageCount,
        newInteractionType: null
      };
    }

    case REMOVE_GIRL_TAB: {
      return {
        ...state,
        timeline: action.payload === state.active ? [] : state.timeline,
        unfixedTab: action.payload === state.unfixedTab ? null : state.unfixedTab,
        tabs: onRemoveTab(state.tabs, action.payload),
        active: onRemoveTabUpdateActiveContact(
          state.tabs,
          state.active,
          action.payload
        )
      };
    }
    case REMOVE_GIRL_TABS: {
      let updatedTimeline = [];
      let updatedActive = null;

      if (
        state.active === action.payload.activeTab &&
        action.payload.query !== "all"
      ) {
        updatedTimeline = state.timeline;
        updatedActive = state.active;
      } else if (
        state.active !== action.payload.activeTab &&
        action.payload.query !== "all"
      ) {
        updatedActive = action.payload.activeTab;
      }

      return {
        ...state,
        active: updatedActive,
        timeline: updatedTimeline,
        tabs: onRemoveTabs(state.tabs, action.payload)
      };
    }
    case SET_GIRLS_TABS: {
      if (!state.unfixedTab) {
        return {
          ...state,
          tabs: action.payload,
        }
      }

      const prevIndex = state.tabs.indexOf(state.unfixedTab);
      const newIndex = action.payload.indexOf(state.unfixedTab);

      if (newIndex === -1 || newIndex !== prevIndex) {
        return {
          ...state,
          tabs: action.payload,
          unfixedTab: null,
        }
      }
    }
    case NEW_OUTGOING_CALL:
    case NEW_INCOMING_CALL:
    case NEW_INCOMING_QUEUE_CALL:
    case NEW_INCOMING_MESSAGE:
    case NEW_OUTGOING_MESSAGE: {
      const contactId = action.payload.interaction.caller_id;
      const newInteraction = { ...action.payload.interaction, isAnimated: true };

      if (state.active === contactId) {
        return {
          ...state,
          timeline: onInteraction(state.timeline, newInteraction, action.payload.userTimezone),
          newInteractionType: newInteraction.type
        };
      }
      if (action.type === NEW_INCOMING_MESSAGE && state.tabs.includes(contactId)) {
        const updatedTabs = state.tabs.filter(id => id !== contactId);

        updatedTabs.unshift(contactId);

        return {
          ...state,
          tabs: updatedTabs,
        }
      }
      return state;
    }
    case NEW_SCHEDULED_MESSAGE: {
      const contactId = action.payload.interaction.caller_id;

      if (state.active === contactId && state.chatSource === CHAT_SOURCES.SCHEDULED_MSGS) {
        return {
          ...state,
          auxiliaryTimeline: onInteraction(state.auxiliaryTimeline, action.payload.interaction, action.payload.userTimezone),
          newInteractionType: action.payload.interaction.type,
          scheduledMsgsCount: state.scheduledMsgsCount + 1
        };
      }
      return state;
    }
    case NEW_SERVICE_MESSAGE: {
      const contactId = action.payload.interaction.caller_id;

      if (state.active === contactId && state.chatSource === CHAT_SOURCES.SYSTEM_MSGS) {
        return {
          ...state,
          auxiliaryTimeline: onInteraction(state.auxiliaryTimeline, action.payload.interaction, action.payload.userTimezone),
          newInteractionType: action.payload.interaction.type,
          serviceMsgCount: state.serviceMsgCount + 1,
        }
      }
    }
    case UPDATE_GIRL: {
      if (action.payload.isTypeChanged && action.payload.wasContactInTabs) {
        const updatedContactId = action.payload.ids
          ? action.payload.ids[0]
          : action.payload.pinIds[0];

        const updatedTabs = onRemoveTab(state.tabs, updatedContactId);

        let updatedActive = state.active;
        let updatedTimeline = state.timeline;

        if (action.payload.isActive) {
          updatedActive = onRemoveTabUpdateActiveContact(
            state.tabs,
            state.active,
            updatedContactId
          );
          updatedTimeline = [];
        }

        const updatedUnfixedTab = state.unfixedTab === updatedContactId
          ? null
          : state.unfixedTab;

        return {
          ...state,
          active: updatedActive,
          tabs: updatedTabs,
          timeline: updatedTimeline,
          unfixedTab: updatedUnfixedTab
        };
      }
      return state;
    }
    case UPDATE_CLIENT: {
      if (action.payload.isTypeChanged && action.payload.wasContactInTabs) {
        const updatedContactId = action.payload.ids
          ? action.payload.ids[0]
          : action.payload.pinIds[0];

        const updatedTabs = [...state.tabs, updatedContactId];

        let updatedActive = state.active ? state.active : updatedContactId;

        let updatedTimeline = state.active ? state.timeline : [];

        if (action.payload.isActive) {
          updatedTimeline = [];
          updatedActive = updatedContactId;
        }

        return {
          ...state,
          active: updatedActive,
          tabs: updatedTabs,
          timeline: updatedTimeline
        };
      }
      return state;
    }
    case REMOVE_GIRL: {
      const isContactInTabs =
        state.tabs.indexOf(action.payload) === -1 ? false : true;

      if (!isContactInTabs) {
        // if contact not in tabs return
        return state;
      }

      const updatedTabs = onRemoveTab(state.tabs, action.payload);
      let updatedActive = state.active;
      let updatedTimeline = state.timeline;

      if (state.active === action.payload) {
        updatedActive = onRemoveTabUpdateActiveContact(
          state.tabs,
          state.active,
          action.payload
        );
        updatedTimeline = [];
      }

      const updatedUnfixedTab = state.unfixedTab === action.payload
        ? null
        : state.unfixedTab;

      return {
        ...state,
        active: updatedActive,
        tabs: updatedTabs,
        timeline: updatedTimeline,
        unfixedTab: updatedUnfixedTab
      };
    }
    case CHANGE_MESSAGE_STATUS:
    case CHANGE_CALL_STATUS: {
      if (
        state.active &&
        (state.active === action.payload.caller_id || // if smsStatus
          state.active === action.payload.callerId)
      ) {
        // if callStatus
        return {
          ...state,
          timeline: onStatusChange(state.timeline, action.payload)
        };
      }
      return state;
    }
    case NEW_MESSAGE_ATTACHMENTS: {
      if (state.active && state.active === action.payload.caller_id) {
        return {
          ...state,
          timeline: onAttachmentsAdding(state.timeline, action.payload),
          newInteractionType: INTERACTION_TYPES.MSG_ATTACHMENT
        };
      }
      return state;
    }
    case UNREAD_GIRL_MESSAGES: {
      return {
        ...state,
        timeline: updateUnreadMsgsInTimeline(state.timeline, action.payload.interaction)
      };
    }

    case CLEAN_GIRL_CONVERSATION_MEDIA: {
      if (state.search) {
        return state;
      }

      return {
        ...state,
        // timelinePending: false,

        auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline
      };
    }
    case GET_GIRL_MSG_CONTEXT: {
      return {
        ...state,
        timelinePending: false,

        isGlobalMsgSearch: false,
        showSearchQuery: false,
        contextDate: null,
        chatSource: CHAT_SOURCES.MSGS,

        contextMsgId: action.payload.contextMsgId,
        auxiliaryCurrentPage: action.payload.currentPage,
        auxiliaryLowerLoadedPage: action.payload.currentPage,
        auxiliaryHigherLoadedPage: action.payload.currentPage,
        auxiliaryPageCount: action.payload.pageCount,
        auxiliaryTimeline: action.payload.messages
      };
    }
    case UPDATE_GIRL_MSG_CONTEXT: {
      //exit from context
      // if (action.payload.currentPage === action.payload.pageCount && action.payload.loadDirection === 'down') {
      //   return {
      //     ...state,
      //     timeline: onUpdateTimeline(state.auxiliaryTimeline, action.payload.messages, action.payload.loadDirection),
      //     timelinePageCount: action.payload.pageCount,
      //     timelineLowerLoadedPage: action.payload.currentPage,
      //     timelineCurrentPage: state.auxiliaryHigherLoadedPage,

      //     auxiliaryCurrentPage: null,
      //     auxiliaryLowerLoadedPage: null,
      //     auxiliaryHigherLoadedPage: null,
      //     auxiliaryPageCount: null,
      //     auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,

      //     contextMsgId: null,
      //     search: '',
      //   }
      // }

      return {
        ...state,
        auxiliaryTimeline: onUpdateTimeline(state.auxiliaryTimeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
        auxiliaryCurrentPage: action.payload.currentPage,

        auxiliaryHigherLoadedPage: state.auxiliaryHigherLoadedPage > action.payload.currentPage
          ? action.payload.currentPage
          : state.auxiliaryHigherLoadedPage,
        auxiliaryLowerLoadedPage: state.auxiliaryLowerLoadedPage > action.payload.currentPage
          ? state.auxiliaryLowerLoadedPage
          : action.payload.currentPage,
        auxiliaryPageCount: action.payload.loadDirection === 'up'
          ? state.auxiliaryPageCount
          : action.payload.pageCount,
      }
    }
    case CLEAN_GIRL_MSG_CONTEXT: {
      return {
        ...state,
        contextMsgId: null,
        auxiliaryCurrentPage: null,
        auxiliaryLowerLoadedPage: null,
        auxiliaryHigherLoadedPage: null,
        auxiliaryPageCount: null,
        auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,

      }
    }
    case GET_GIRL_DATE_MSG_CONTEXT: {
      return {
        ...state,
        timelinePending: false,

        search: '',
        contextMsgId: null,
        chatSource: CHAT_SOURCES.MSGS,

        contextDate: action.payload.contextDate,
        auxiliaryCurrentPage: action.payload.currentPage,
        auxiliaryLowerLoadedPage: action.payload.currentPage,
        auxiliaryHigherLoadedPage: action.payload.currentPage,
        auxiliaryPageCount: action.payload.pageCount,
        auxiliaryTimeline: action.payload.messages
      }
    }
    case UPDATE_GIRL_DATE_MSG_CONTEXT: {
      //exit from context
      // if (action.payload.currentPage === action.payload.pageCount && action.payload.loadDirection === 'down') {
      //   return {
      //     ...state,
      //     timeline: onUpdateTimeline(state.auxiliaryTimeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
      //     timelinePageCount: action.payload.pageCount,
      //     timelineLowerLoadedPage: action.payload.currentPage,
      //     timelineCurrentPage: state.auxiliaryHigherLoadedPage,

      //     auxiliaryCurrentPage: null,
      //     auxiliaryLowerLoadedPage: null,
      //     auxiliaryHigherLoadedPage: null,
      //     auxiliaryPageCount: null,
      //     auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,

      //     contextDate: null,
      //     search: '',
      //   }
      // }
      return {
        ...state,

        auxiliaryTimeline: onUpdateTimeline(state.auxiliaryTimeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
        auxiliaryCurrentPage: action.payload.currentPage,

        auxiliaryHigherLoadedPage: state.auxiliaryHigherLoadedPage > action.payload.currentPage
          ? action.payload.currentPage
          : state.auxiliaryHigherLoadedPage,
        auxiliaryLowerLoadedPage: state.auxiliaryLowerLoadedPage > action.payload.currentPage
          ? state.auxiliaryLowerLoadedPage
          : action.payload.currentPage,
        auxiliaryPageCount: action.payload.loadDirection === 'up'
          ? state.auxiliaryPageCount
          : action.payload.pageCount,
      }
    }
    case CLEAN_GIRL_DATE_MSG_CONTEXT: {
      return {
        ...state,
        contextDate: null,
        auxiliaryCurrentPage: null,
        auxiliaryLowerLoadedPage: null,
        auxiliaryHigherLoadedPage: null,
        auxiliaryPageCount: null,
        auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,
      }
    }
    case GET_SEARCHED_GIRL_MESSAGES: {
      return {
        ...state,
        search: action.payload.query,
        timelinePending: false,

        auxiliaryCurrentPage: action.payload.currentPage,
        auxiliaryLowerLoadedPage: action.payload.currentPage,
        auxiliaryHigherLoadedPage: action.payload.currentPage,
        auxiliaryPageCount: action.payload.pageCount,
        auxiliaryTimeline: action.payload.messages,

        contextMsgId: null,
        contextDate: null,
        chatSource: CHAT_SOURCES.MSGS,
        isGlobalMsgSearch: state.isGlobalMsgSearch
          ? false
          : state.isGlobalMsgSearch
      };
    }
    case UPDATE_GLOBAL_GIRL_MESSAGE_SEARCH:
    case UPDATE_SEARCHED_GIRL_MESSAGES: {
      return {
        ...state,
        auxiliaryCurrentPage: action.payload.currentPage,
        auxiliaryHigherLoadedPage: state.auxiliaryHigherLoadedPage > action.payload.currentPage
          ? action.payload.currentPage
          : state.auxiliaryHigherLoadedPage,
        auxiliaryLowerLoadedPage: state.auxiliaryLowerLoadedPage > action.payload.currentPage
          ? state.auxiliaryLowerLoadedPage
          : action.payload.currentPage,
        auxiliaryTimeline: onUpdateTimeline(state.auxiliaryTimeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
        auxiliaryPageCount: action.payload.loadDirection === 'up'
          ? state.auxiliaryPageCount
          : action.payload.pageCount,
        updatePending: false,
      }
    }
    case STOP_SEARCH_GIRL_MESSAGES: {
      return {
        ...state,
        search: '',

        auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,
        auxiliaryPageCount: null,
        auxiliaryLowerLoadedPage: null,
        auxiliaryHigherLoadedPage: null,
        auxiliaryCurrentPage: null,

        contextMsgId: null,
        isGlobalMsgSearch: false,
      }
    }
    case GLOBAL_GIRL_MESSAGE_SEARCH: {
      return {
        ...state,
        isGlobalMsgSearch: true,
        search: action.payload.query,
        chatSource: CHAT_SOURCES.MSGS,

        auxiliaryCurrentPage: action.payload.currentPage,
        auxiliaryLowerLoadedPage: action.payload.currentPage,
        auxiliaryHigherLoadedPage: action.payload.currentPage,
        auxiliaryPageCount: action.payload.pageCount,
        auxiliaryTimeline: action.payload.messages,
        timelinePending: false,
      };
    }

    case PIN_GIRL_MSG: {
      return {
        ...state,
        pinnedMsgs: [action.payload, ...state.pinnedMsgs],
        activePinnedMsgs: action.payload
      };
    }

    case DELETE_PINNED_GIRL_MSG: {
      const filteredPinnedMsgs = state.pinnedMsgs.filter((el) => el.id !== action.payload);

      return {
        ...state,
        pinnedMsgs: filteredPinnedMsgs,
        activePinnedMsgs: filteredPinnedMsgs[filteredPinnedMsgs.length - 1]
      };
    }

    case CHANGE_ACTIVE_PINNED_GIRL_MSG: {
      return {
        ...state,
        activePinnedMsgs: action.payload || state.pinnedMsgs[state.pinnedMsgs.length - 1]
      };
    }

    case NEW_MISSED_CALL: {
      const isActive = state.active === +action.payload.id.split('_')[0];

      if (!isActive) return state;

      const newTimeline = isActive && state.timeline.map(group => {
        return group.map(msg => {
          if (msg.id === action.payload.entity.id) {
            return action.payload.entity;
          }
          return msg;
        });
      });

      return {
        ...state,
        timeline: newTimeline
      }
    }

    case UPDATE_VOICEMAIL_STATUS: {
      const isActive = state.active === action.payload.callerId

      const newTimeline = isActive && state.timeline.map(group => {
        return group.map(msg => {
          if (msg.id === action.payload.callId) {
            return { ...msg, voicemail: action.payload.voicemail };
          }
          return msg;
        });
      });

      return {
        ...state,
        timeline: isActive
          ? newTimeline
          : state.timeline
      }
    }

    case GET_GIRL_CONVERSATION_MEDIA:
    case GET_GIRL_SCHEDULED_MSGS:
    case GET_GIRL_MSG_REMINDERS:
    case GET_GIRL_SERVICE_MSGS: {
      return {
        ...state,
        timelinePending: false,
        contextDate: null,
        chatSource: action.payload.chatSource,

        auxiliaryTimeline: action.payload.messages,
        auxiliaryPageCount: action.payload.pageCount,
        auxiliaryLowerLoadedPage: action.payload.currentPage,
        auxiliaryHigherLoadedPage: action.payload.currentPage,
        auxiliaryCurrentPage: action.payload.currentPage,
      };
    }

    case UPDATE_GIRL_CONVERSATION_MEDIA:
    case UPDATE_GIRL_SCHEDULED_MSGS:
    case UPDATE_GIRL_MSG_REMINDERS:
    case UPDATE_GIRL_SERVICE_MSGS: {
      return {
        ...state,
        auxiliaryTimeline: onUpdateTimeline(state.auxiliaryTimeline, action.payload.messages, action.payload.loadDirection, action.payload.userTimezone),
        auxiliaryPageCount: action.payload.loadDirection === 'up'
          ? state.auxiliaryPageCount
          : action.payload.pageCount,
        auxiliaryHigherLoadedPage: state.auxiliaryHigherLoadedPage > action.payload.currentPage
          ? action.payload.currentPage
          : state.auxiliaryHigherLoadedPage,
        auxiliaryLowerLoadedPage: state.auxiliaryLowerLoadedPage > action.payload.currentPage
          ? state.auxiliaryLowerLoadedPage
          : action.payload.currentPage,
        auxiliaryCurrentPage: action.payload.currentPage,
      };
    }

    case CLEAN_GIRL_CONVERSATION_MEDIA:
    case CLEAN_GIRL_SCHEDULED_MSGS:
    case CLEAN_GIRL_MSG_REMINDERS:
    case CLEAN_GIRL_SERVICE_MSGS: {
      if (state.search) {
        return state;
      }

      return {
        ...state,

        chatSource: CHAT_SOURCES.MSGS,

        auxiliaryCurrentPage: null,
        auxiliaryLowerLoadedPage: null,
        auxiliaryHigherLoadedPage: null,
        auxiliaryPageCount: null,
        auxiliaryTimeline: state.auxiliaryTimeline.length ? [] : state.auxiliaryTimeline,
        serviceMsgCount: state.chatSource === CHAT_SOURCES.SYSTEM_MSGS
          ? 0
          : state.serviceMsgCount
      }
    }

    case REMOVE_GIRL_REMIND_MSG: {
      const updatedReminders = [];

      state.auxiliaryTimeline.forEach(remindersByDate => {
        const updatedRemindersByDate = remindersByDate.filter(remind => remind.id !== action.payload);

        if (updatedRemindersByDate.length) {
          updatedReminders.push(updatedRemindersByDate);
        }
      })

      return {
        ...state,
        auxiliaryTimeline: updatedReminders
      }
    }

    case REMOVE_GIRL_REMINDER_FROM_MAIN_TIMELINE: {
      const updatedTimeline = [...state.timeline];
      const updatedLastGroup = updatedTimeline[updatedTimeline.length - 1]
        .filter(interaction => interaction.type !== 7 && interaction.id !== action.payload);

      updatedTimeline[updatedTimeline.length - 1] = updatedLastGroup;

      return {
        ...state,
        timeline: updatedTimeline,
      }
    }

    case UPDATE_GIRL_MSG: {
      let updatedTimeline = 'timeline';

      if (action.payload.type === 11 && state.chatSource === CHAT_SOURCES.SCHEDULED_MSGS) {
        updatedTimeline = 'auxiliaryTimeline'
      }

      return {
        ...state,
        [updatedTimeline]: state[updatedTimeline].map(group => {
          return group.map(msg => {
            if (msg.id === action.payload.id) {
              return {
                ...msg,
                body: action.payload.body,
                edited: action.payload.edited || true,
                date: action.payload.date ? action.payload.date : null,
                hasUrls: action.payload.hasUrls || msg.hasUrls,
                attachments: action.payload.attachments,
              };
            }
            return msg;
          });
        }),
      };
    }

    case DELETE_GIRL_MSG: {
      let updatedTimelineName = 'timeline';

      if (action.payload.type === 11 && state.chatSource === CHAT_SOURCES.SCHEDULED_MSGS ||
        action.payload.type === 4 && state.chatSource === CHAT_SOURCES.SYSTEM_MSGS
      ) {
        updatedTimelineName = 'auxiliaryTimeline'
      }

      const updatedTimeline = [];

      state[updatedTimelineName].forEach(group => {
        const updatedGroup = group.filter(msg => msg.id !== action.payload.id);

        if (updatedGroup.length) {
          updatedTimeline.push(updatedGroup);
        }
      })

      return {
        ...state,
        [updatedTimelineName]: updatedTimeline,
        scheduledMsgsCount: action.payload.type === 11 && state.scheduledMsgsCount >= 1
          ? state.scheduledMsgsCount - 1
          : state.scheduledMsgsCount,
        serviceMsgCount: action.payload.type === 4 && state.serviceMsgCount >= 1
          ? state.serviceMsgCount - 1
          : state.serviceMsgCount,
      };
    }

    case SET_GIRLS_RECENT_TABS: {
      if (!action.payload) return state;

      return {
        ...state,
        recentTabs: action.payload,
      }
    }

    case GET_GIRL_SCHEDULED_MSGS_COUNT: {
      if (action.payload === state.scheduledMsgsCount) return state;

      return {
        ...state,
        scheduledMsgsCount: action.payload,
      }
    }

    case GET_GIRL_SERVICE_MSGS_COUNT: {
      if (action.payload === state.serviceMsgCount) return state;

      return {
        ...state,
        serviceMsgCount: action.payload,
      }
    }

    case DELETE_TELEGRAM_MSG: {
      const { timeline, auxiliaryTimeline } = state;
      const { id } = action.payload;

      return {
        ...state,
        timeline: removeFromTimeline(timeline, id),
        auxiliaryTimeline: removeFromTimeline(auxiliaryTimeline, id),
      };
    }

    default:
      return state;
  }
};
