import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';

import { useDidMount } from 'hooks';
import { readNotification, addNotifications } from 'redux/ducks/notifications';

import './PublicChatNotificationsList.scss';
import List from 'components/List/List';
import NotificationComponent from 'containers/NotificationComponent/NotificationComponent';
import CustomScrollbarTrack from 'components/UI/CustomScrollbarTrack/CustomScrollbarTrack';


const PublicChatNotificationsList = (props) => {
  const {
    notifications,
    addNotifications,
    readNotification,
    savedNotificationsIds,
  } = props;

  const [userNotifications, setUserNotifications] = useState([]);
  const [isPending, setPending] = useState(true);

  useDidMount(() => {
    setPending(true)

    addNotifications()
      .then(() => {
        setPending(false);
      });
  });

  useEffect(() => {
    setUserNotifications(savedNotificationsIds.map(id => notifications[id]))
  }, [notifications]);

  const listItem = useCallback((props) => {
    return (
      <NotificationComponent
        className="user-notifications__item"
        {...props}
      />
    );
  }, []);

  return (
    <div className="user-notifications">
      <div className="user-notifications__header">Important Notifications</div>

      <List
        list={userNotifications}
        limit={20}
        classPrefix='user-notifications'
        scrollInitialPosition='top'
        listItem={listItem}
        loadMore={addNotifications}
        listItemProps={(notification) => ({
          notification,
          readNotification,
        })}
        listLoadPending={isPending}
        scrollbarProps={{
          renderTrackVertical: CustomScrollbarTrack,
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  unreadCount: state.notifications.unreadCount,
  notifications: state.notifications.entities,
  savedNotificationsIds: state.notifications.savedIds,
});

const mapDispatchToProps = {
  readNotification,
  addNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicChatNotificationsList);
