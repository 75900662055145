import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { connect } from 'react-redux';

import { selectAddrBookActiveContact } from 'redux/selectors/selectors';

import ContactLogsTab from './components/ContactLogsTab/ContactLogsTab';
import ContactAddressTab from './components/ContactAddressTab/ContactAddressTab';
import ContactRequestsTab from './components/ContactRequestsTab/ContactRequestsTab';
import FeedbackList from 'components/FeedbackList/FeedbackList';
import FeedbackAddBtn from 'components/FeedbackAddBtn/FeedbackAddBtn';

const AdrBookContactFormTabs = (props) => {
  const {
    values,
    saveContact,
    editedContact = {},
    serverError,
    setServerError,
    feedbackList,
    setFeedbackList,
    setNewContactAdr,
    isNewContactCreation,
    linksLabels,
    isFieldEdited,
    activeContact,         // contains actual contact (unlike editedContact)
  } = props;

  const [tabIndex, setTabIndex] = useState(0);

  const activeContactInForm = isNewContactCreation ? null : activeContact;

  useEffect(() => {
    setTimeout(() => setTabIndex(0), 0);
  }, [editedContact.id, isNewContactCreation]);

  return (
    <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
      <TabList>
        <Tab>Notes</Tab>
        <Tab>Address</Tab>
        <Tab>Log</Tab>
        <Tab>Bookings</Tab>
        <Tab>Requests</Tab>
        <Tab>Feedbacks</Tab>
      </TabList>

      <TabPanel>
        Notes
      </TabPanel>

      <TabPanel>
        <ContactAddressTab 
          values={values}
          serverError={serverError}
          saveContact={saveContact}
          editedContact={editedContact} 
          setServerError={setServerError}
        />
      </TabPanel>

      <TabPanel>
        <ContactLogsTab 
          id={editedContact.id}
          name={editedContact.fn}
        />
      </TabPanel>

      <TabPanel>
        Bookings
      </TabPanel>

      <TabPanel>
        <ContactRequestsTab
          id={editedContact.id}
          isSortByNewest
        />
      </TabPanel>

      <TabPanel>
        <div className='adr-book-contact-form__feedbacks-tab'>
          <div>
            <FeedbackAddBtn
              activeContact={activeContactInForm}
              setFeedbackList={setFeedbackList}
            />
          </div>

          <FeedbackList
            contactId={isNewContactCreation ? null : editedContact.id}
            feedbackList={feedbackList}
            setFeedbackList={setFeedbackList}
            activeContact={activeContactInForm}
          />
        </div>
      </TabPanel>
    </Tabs>
  )
}

const mSTP = state => ({
  activeContact: selectAddrBookActiveContact(state),
});

export default connect(mSTP)(AdrBookContactFormTabs);
