import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { classModifier } from 'utils';
import { addProfilesToBufferAndGallery } from 'redux/ducks/sessions';
import {
  selectActiveSessionId,
  selectCanIEditSession,
  selectDefaultSession,
  selectDivaGirlsProfilesPending,
  selectGalleryProfilesCount,
  selectIsAllGalleryProfilesInBuffer,
  selectSessionIdsForGallery,
} from 'redux/selectors/selectors';

import './BufferAllButton.scss';

const BufferAllButton = () => {
  const dispatch = useDispatch();

  const activeSessionId = useSelector(selectActiveSessionId);
  const defaultSession = useSelector(selectDefaultSession);
  const canIEditSession = useSelector(selectCanIEditSession);

  const galleryProfilesIds = useSelector(selectSessionIdsForGallery);
  const galleryProfilesCount = useSelector(selectGalleryProfilesCount);
  const isAllProfilesInBuffer = useSelector(selectIsAllGalleryProfilesInBuffer);
  const galleryPending = useSelector(selectDivaGirlsProfilesPending);

  const [bufferAllPending, setBufferAllPending] = useState(false);
  const [isShowWarning, setIsShowWarning] = useState(false);

  const isBufferAllDisable =
    !canIEditSession || isAllProfilesInBuffer ||
    galleryPending || bufferAllPending;
  const isBufferAllDisabledStyle = galleryProfilesCount > 20 || !canIEditSession;

  const onVisible = (visible) => {
    if (visible) {
      if (galleryProfilesCount > 20) {
        setIsShowWarning(true);
      }
    } else {
      setIsShowWarning(false);
    }
  };

  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible
  } = usePopperTooltip({
    trigger: 'hover',
    placement: 'top',
    offset: [0, 10],
    visible: isShowWarning,
    onVisibleChange: onVisible,
  });

  const handleBufferAll = () => {
    if (galleryProfilesCount > 20) {
      return;
    }

    const sessionId = activeSessionId === defaultSession.id
      ? null
      : activeSessionId;

    setBufferAllPending(true);

    dispatch(addProfilesToBufferAndGallery({
      sessionId,
      profileIds: galleryProfilesIds,
    }))
      .finally(() => setBufferAllPending(false));
  }

  return (
    <>
      <button
        // onClick={handleBufferAll} //TODO uncomment after adding buffer
        disabled={isBufferAllDisable}
        ref={setTriggerRef}
        className={`buffer-all-btn ${classModifier('gallery-sidebar-filters__action-btn', [
          isBufferAllDisabledStyle && 'disabled',
        ])}`}
      >
        Buffer All
      </button>

      {visible && (
        <div
          className="buffer-all-btn__warning"
          ref={setTooltipRef}
          {...getTooltipProps()}
        >
          <p className="buffer-all-btn__warning-text">
            You can work with up to 20 escorts at a time. Please narrow your
            gallery results.
          </p>

          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="buffer-all-btn__warning-icon"
          />
        </div>
      )}
    </>
  );
}

export default BufferAllButton;
