import { useState, useCallback } from 'react';

import API from 'api/api';
import { useDidMount } from 'hooks';

import './FeedbackList.scss';
import List from "components/List/List";
import FeedbackItem from './components/FeedbackItem/FeedbackItem';


const FeedbackList = ({
  contactId,
  feedbackList,
  setFeedbackList,
}) => {
  const [pending, setPending] = useState(contactId);

  useDidMount(async () => {
    if (!contactId) {
      return;
    }

    try {
      const { data } = await API.getContactNotes(contactId);
      setFeedbackList(data);
    } catch {
      setFeedbackList([]);
    }
    setPending(false);
  });

  const loadMore = useCallback(async offset => {
    const { data } = await API.getContactNotes(contactId, offset);
    setFeedbackList(prev => [...prev, ...data]);
  }, [contactId]);
  
  // const handleEdit = async editId => {
  //   // to add implementation
  // }

  const handleDelete = async delId => {
    if (!window.confirm('Delete feedback?')) {
      return;
    }

    if (!contactId) {
      setFeedbackList(prev => prev.filter(({ tempId }) => tempId !== delId));
      return;
    }

    await API.deleteContactNote(delId);
    setFeedbackList(prev => prev.filter(({ id }) => id !== delId));
  }

  return (
    <div className='feedback-list'>
      <List
        list={pending ? [] : feedbackList}
        listItem={FeedbackItem}
        listItemKey={contactId ? "id" : "tempId"}
        limit={20}
        loadMore={loadMore}
        hasMore={contactId}
        classPrefix='feedback-list'
        listItemProps={item => ({
          onDelete: () => handleDelete(item.id || item.tempId),
          // onEdit: handleEdit,
          setFeedbackList,
        })}
        listLoadPending={pending}
        scrollbarProps={{
          renderTrackVertical: () =>
            <div className="track-vertical" />,
          renderThumbVertical: props =>
            <div {...props} className="thumb-vertical"/>,
        }}
      />
    </div>
  );
}

export default FeedbackList;
