import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';

import API from 'api/api';
import ICONS from 'assets/icons';
import { getContactAvatar } from 'utils';
import { useDidUpdate } from 'hooks';
import { CONTACT_TYPES } from 'config/constants';
import { MODAL_TYPES, closeModal, openModal } from 'redux/ducks/activeWindows';

import './FeedbackContactField.scss';
import SearchListContactItem from 'components/SearchList/components/SearchListContactItem/SearchListContactItem';
import SearchListEscortItem from 'components/SearchList/components/SearchListEscortItem/SearchListEscortItem';
import DropdownMenu from 'components/DropdownMenu/DropdownMenu';

const PAGE_SIZE = 20;
const SOURCE_TYPES = {
  operator: 'operator',
  client: 'client',
  escort: 'escort',
};

const FeedbackContactField = ({
  input: { value: contact, onChange, onBlur },
  meta: { touched, error },
  fieldType = 'client',      // 'client', 'escort', 'source'
}) => {
  const [isSourceDropdownOpen, setIsSourceDropdownOpen] = useState(false);

  const isSource = fieldType === 'source';
  const isOperatorContact = contact.type === CONTACT_TYPES.OPERATOR;

  // === Hooks

  const dispatch = useDispatch();

  const {
    setTriggerRef,
    setTooltipRef,
    getTooltipProps,
  } = usePopperTooltip({
    trigger: 'click',
    offset: [0, 0],
    visible: isSourceDropdownOpen,
    onVisibleChange: setIsSourceDropdownOpen,
  });

  useDidUpdate(() => {
    if (!isSourceDropdownOpen) {
      onBlur();
    }
  }, [isSourceDropdownOpen]);

  // === Markup functions

  const labelText = () => {
    switch (fieldType) {
      case 'client':
        return 'Client';
      case 'escort':
        return 'Escort';
      case 'source':
        return 'Source';
    }
  };

  const fetchDataRequests = {
    operator: (params) => API.getOperatorsForFeedback({ ...params, limit: PAGE_SIZE }),
    client: ({ offset, query, cancelToken }) => API.searchContacts(1, query, offset, PAGE_SIZE, cancelToken),
    escort: (params) => API.getEscortsForFeedback({ ...params, limit: PAGE_SIZE }),
  };

  const fetchData = (type) => {
    const requestFunction = fetchDataRequests[type];

    // params contain offset, query, cancelToken
    return (params) => requestFunction(params)
      .then(({ data }) => ({
        newItems: data,
        newHasMore: data.length === PAGE_SIZE,
      }));
  };

  const handleChoose = contact => {
    onChange(contact);
    dispatch(closeModal(MODAL_TYPES.SearchList));
  };

  const subMenuItemsActions = {
    operator: () => dispatch(openModal(MODAL_TYPES.SearchList, {
      fetchData: fetchData(SOURCE_TYPES.operator),
      itemComponent: SearchListContactItem,
      onChoose: handleChoose,
    })),
    client: () => dispatch(openModal(MODAL_TYPES.SearchList, {
      fetchData: fetchData(SOURCE_TYPES.client),
      itemComponent: SearchListContactItem,
      onChoose: handleChoose,
    })),
    escort: () => dispatch(openModal(MODAL_TYPES.SearchList, {
      fetchData: fetchData(SOURCE_TYPES.escort),
      itemComponent: SearchListEscortItem,
      onChoose: handleChoose,
    })),
  };

  const generateSubMenuItems = () => Object.keys(SOURCE_TYPES).map((key) => ({
    content: key,
    action: subMenuItemsActions[key],
  }));

  const handleClick = () => {
    if (!isSource) {
      subMenuItemsActions[fieldType]();
    }
  };

  // === Calculated props

  const tooltipProps = getTooltipProps({
    className: 'feedback-contact-field__tooltip',
  });

  return (
    <div className='feedback-contact-field'>
      <p className='feedback-contact-field__label'>
        {labelText()}
      </p>

      <div
        className='feedback-contact-field__button'
        ref={setTriggerRef}
        onClick={handleClick}
      >
        {!contact &&
          <div className='feedback-contact-field__no-contact'>
            <p className='feedback-contact-field__placeholder'>Name</p>

            {!isSource &&
              <ICONS.adrBook className='feedback-contact-field__adr-book-icon' />
            }

            {isSource &&
              <ICONS.chevron className='feedback-contact-field__drop-menu-icon' />
            }
          </div>
        }

        {contact &&
          <div className='feedback-contact-field__contact'>
            <div className='feedback-contact-field__avatar-thumb'>
              <img src={getContactAvatar(contact)} alt="avatar" />
            </div>

            <div className='feedback-contact-field__text-info'>
              <p className='feedback-contact-field__name'>{!isOperatorContact ? contact.fn : contact.username}</p>

              {!isOperatorContact && contact.tels.length > 0 &&
                <p className='feedback-contact-field__phone'>
                  {contact.tels[0].tel}
                </p>
              }
            </div>
          </div>
        }
      </div>

      {touched && error &&
        <p className='feedback-form__field-error'>{error}</p>
      }
  
      {isSourceDropdownOpen && isSource &&
        <div ref={setTooltipRef} {...tooltipProps}>
          <DropdownMenu
            items={generateSubMenuItems()}
            closeDropdownMenu={() =>  setIsSourceDropdownOpen(false)}
          />
        </div>
      }
    </div>
  );
};

export default FeedbackContactField;
