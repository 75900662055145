import { combineReducers } from 'redux';

import activeWindowsReducer from './ducks/activeWindows';
import addressBookReducer from "./ducks/addressBook";
import bookingsReducer from './ducks/bookings'
import callsReducer from './ducks/calls';
import clientChatsReducer from './ducks/clientChats';
import conferencesReducer from "./ducks/conferences";
import contactsReducer from './ducks/contacts';
import countersReducer from './ducks/counters'
import divaGirlsReducer from "./ducks/divaGirls";
import girlChatsReducer from './ducks/girlChats';
import mailReducer from "./ducks/mail";
import missedCallsReducer from './ducks/missedCalls';
import msgTemplatesReducer from './ducks/msgTemplates';
import notificationsReducer from "./ducks/notifications";
import operatorsReducer from './ducks/operators';
import roomChatsReducer from './ducks/roomChats';
import roomsReducer from './ducks/rooms';
import saleSessionChatsReducer from "./ducks/saleSessionChats";
import searchReducer from "./ducks/search";
import sessionsReducer from "./ducks/sessions";
import settingsReducer from "./ducks/settings";
import timersReducer from './ducks/timers';
import twilioReducer from './ducks/twilio';
import typingOperatorsReducer from "./ducks/typingOperators";
import userReducer from './ducks/user';
import webrtcReducer from "./ducks/webrtc";

export default combineReducers({
  activeWindows: activeWindowsReducer,
  addressBook: addressBookReducer,
  bookings: bookingsReducer,
  calls: callsReducer,
  clientChats: clientChatsReducer,
  conferences: conferencesReducer,
  contacts: contactsReducer,
  counters: countersReducer,
  divaGirls: divaGirlsReducer,
  girlChats: girlChatsReducer,
  mail: mailReducer,
  missedCalls: missedCallsReducer,
  msgTemplates: msgTemplatesReducer,
  notifications: notificationsReducer,
  operators: operatorsReducer,
  roomChats: roomChatsReducer,
  rooms: roomsReducer,
  saleSessionChats: saleSessionChatsReducer,
  search: searchReducer,
  sessions: sessionsReducer,
  settings: settingsReducer,
  timers: timersReducer,
  twilio: twilioReducer,
  typingOperators: typingOperatorsReducer,
  user: userReducer,
  webrtc: webrtcReducer,
});
