import React, { useState } from 'react';

import './TriStateSwitch.scss';
import { useDidUpdate } from 'hooks';


const TriStateSwitch = (props) => {
  const {
    classNamePrefix = 'tri-state-switch',
    name,
    value = 'waiting',
    disabled = false,
  } = props;

  const [switchState, setSwitchState] = useState(value);
  const [toggleId, setToggleId] = useState(props.id || Math.round(Math.random() * 1000));

  useDidUpdate(() => {
    setSwitchState(value);
  }, [value])

  const handleChange = (e) => {
    console.log('Handle Switch Change', e.target.value);
    setSwitchState(e.target.value);

    if (props.onChange && typeof props.onChange === "function") {
      props.onChange(e.target.value);
    }
  }

  return (
    <div className={`${classNamePrefix} 
      ${classNamePrefix}--${switchState}`}>

      <input
        type='radio'
        id={`radio-switch-off--${toggleId}`}
        name={name}
        value='off'
        onChange={handleChange}
        disabled={disabled}
        checked={switchState === 'off'}
      />
      <label
        htmlFor={`radio-switch-off--${toggleId}`}
        // className={`${classNamePrefix}${
        //   switchState === 'off' ? '--active' : ""
        // }`}  
      />

      <input
        type='radio'
        id={`radio-switch-waiting--${toggleId}`}
        name={name}
        value='waiting'
        onChange={handleChange}
        disabled={disabled}
        checked={switchState === 'waiting'}
      />
      <label
        htmlFor={`radio-switch-waiting--${toggleId}`}
        // className={`${classNamePrefix}${
        //   switchState === 'na' ? '--active' : ""
        // }`} 
      />

      <input
        type='radio'
        id={`radio-switch-available--${toggleId}`}
        name={name}
        value='available'
        onChange={handleChange}
        disabled={disabled}
        checked={switchState === 'available'}
      />
      <label
        htmlFor={`radio-switch-available--${toggleId}`}
        // className={`${classNamePrefix}${
        //   switchState === 'available' ? '--active' : ""
        // }`} 
      />
    </div>
  )
};

export default TriStateSwitch;