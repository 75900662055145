import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './AvailabilityList.scss';

import API from 'api/api';
import { useDidMount } from 'hooks';
import { addContactsToEntities } from 'redux/ducks/contacts';
import { setRoomPending, addAvailableGirls, addOffTodayGirls } from 'redux/ducks/rooms';
import { CONTACT_TYPES } from 'config/constants';

import Spinner from 'components/UI/Spinner/Spinner';
import Scrollbars from 'react-custom-scrollbars';
import PublicChatGirlsItem from '../components/PublicChatGirlsItem';
import CustomScrollbarThumb from 'components/UI/CustomScrollbarThumb/CustomScrollbarThumb';
import CustomScrollbarTrack from 'components/UI/CustomScrollbarTrack/CustomScrollbarTrack';
import { PUBLIC_CHAT_TABS } from 'redux/ducks/roomChats';

const AvailabilityList = (props) => {
  const {availability} = props;

  const dispatch = useDispatch();

  const pending = useSelector(state => state.rooms.pending);
  const availabilityIds = useSelector((state) =>
    availability === PUBLIC_CHAT_TABS.available
      ? state.rooms.availableIds
      : state.rooms.offTodayIds
  );

  const request = async () => {
    try {
      const isOffToday = availability === PUBLIC_CHAT_TABS.offToday;

      const response = await API.getGirlsWithStatus(
        isOffToday ? "off" : PUBLIC_CHAT_TABS.available
      );

      dispatch(addContactsToEntities(response.data.map((item) => item.caller)));
      
      isOffToday
        ? dispatch(addOffTodayGirls(response.data))
        : dispatch(addAvailableGirls(response.data));
    } catch(err) {
      console.log(err)
    }
  }

  useDidMount(() => {
    dispatch(setRoomPending());

    request()
  });

  if (pending) {
    return (
      <div className="availability-list__pending">
        <Spinner spinnerSize="30px"/>
      </div>
    )
  };

  if (!availabilityIds.length) {
    return (
      <span className="availability-list__no-items">
        no items
      </span>
    )
  };

  const titleAvailabilityList = {available: 'Available', off_today: "Off/Busy"}

  return (
    <div className="availability-list">
      <div className="availability-list__header">
        <h3 className="public-chat__title">
          {titleAvailabilityList[availability]} ({availabilityIds.length})
        </h3>
      </div>

      <ul className="availability-list__list">
        <Scrollbars 
          autoHide 
          renderThumbVertical={CustomScrollbarThumb}
          renderTrackVertical={CustomScrollbarTrack}
        >
          {availabilityIds.map(id => (
            <li
              className="availability-list__item"
              key={id}
            >
              <PublicChatGirlsItem 
                id={id}
                isFromListOfGirlsWithStatus
                isSimpleView
                type={CONTACT_TYPES.GIRL}
              />
            </li>
          ))}
        </Scrollbars>
      </ul>
    </div>
  );
};

export default AvailabilityList;