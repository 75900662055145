import React from 'react';

import "./AdrBookListSort.scss";
import Select from 'components/Select/Select';


const options = [
  { label: 'A-Z', value: "" },
  // { label: 'Z-A', value: 'fn=descending' },
  // { label: 'Creation Date ASC', value: 'date_created=ascending' },
  // { label: 'Creation Date DESC', value: 'date_created=descending' },
];


const AdrBookListSort = (props) => {

  const onSortingChange = (option) => {
    props.changeContactSorting(option.value);
  }

  return (
    <div className="adr-book-list-sort">
      <Select
        classPrefix={"adr-book-list-sort"}
        options={options}
        getOptionValue={option => option.value}
        getOptionLabel={option => option.label}
        isDisabled={props.contactsPending}
        defaultValue={props.sortBy}
        defaultLabel={options.find(opt => opt.value === props.sortBy).label}
        onInputChange={onSortingChange}
      />
    </div>
  );
}

export default AdrBookListSort;