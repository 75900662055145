import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';

import API from 'api/api';
import { CARD_TABS } from 'config/constants';
import { openModal, MODAL_TYPES } from 'redux/ducks/activeWindows';
import ICONS from 'assets/icons';

const newEmailBtnOption = { value: '+ Add New Email' };

const ContactEmails = ({ activeRecipient, ...props }) => {
  const { emails = [] } = activeRecipient;
  const defaultEmail = emails[0];

  const [selectedEmail, setSelectedEmail] = useState({});

  // const dispatch = useDispatch();

  useEffect(() => {
    if (emails.length > 0) {
      setSelectedEmail({ id: defaultEmail.id, value: defaultEmail.email });
    }
  }, [activeRecipient]);

  const openEditContactForm = () => {
    // return dispatch(openModal(MODAL_TYPES.contactCard, {
    //     contact: props.activeRecipient.id,
    //     defaultTab: CARD_TABS.EDIT,
    //     isAddEmailFieldOnMount: true,
    // }));
  };

  const onChangeHandler = (option) => {
    if (option.value === selectedEmail.value) return;

    if (option.value === newEmailBtnOption.value) {
      openEditContactForm();
      return;
    }

    API.changeDefaultContactEmail(option.id)
      .then(() => setSelectedEmail(option))
      .catch(console.error);
  }

  const filterOption = (option) => {
    return emails.length > 1
      ? true
      : option.value !== selectedEmail.value;
  }

  const options = useMemo(() => [
    ...emails.map(email => ({ id: email.id, value: email.email, label: email.labelName })),
    newEmailBtnOption
  ], [emails]);

  if (emails.length === 0) {
    return (
      <div className="chat-header__select-wrap">
        <button
          className="chat-header__add-new-btn"
          onClick={openEditContactForm}
        >
          + Add New Email
        </button>
      </div>
    )
  }

  return (
    <div className="chat-header__select-wrap">
      <button className="chat-header__select-btn chat-header__select-btn--email">
        <ICONS.mail className="chat-header__select-icon"/>
      </button>
  
      <Select
        className="react-select"
        classNamePrefix="react-select"
        options={options}
        value={selectedEmail}
        isSearchable={false}
        getOptionLabel={option => option.value}
        getOptionValue={option => option.value}
        onChange={onChangeHandler}
        filterOption={filterOption}
        components={{
          IndicatorSeparator: null,
        }}
      />
    </div>
  )
}

export default ContactEmails;