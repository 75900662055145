import React from 'react';
import { useSelector } from 'react-redux';

import { selectOnlineOperators } from 'redux/selectors/selectors';

import './SidebarOperatorsList.scss';
import ICONS from 'assets/icons';
import SidebarOperatorItem from './SidebarOperatorItem';
import List from 'components/List/List';
import CustomScrollbarTrack from 'components/UI/CustomScrollbarTrack/CustomScrollbarTrack';
import CustomScrollbarThumb from 'components/UI/CustomScrollbarThumb/CustomScrollbarThumb';

const SidebarOperatorsList = () => {
  const operators = useSelector((state) => selectOnlineOperators(state));
  const onlineOperatorsCount = operators.length;

  return (
    <div className="sidebar-sections">
      <div className="sidebar-sections__header">
        <div className="sidebar-header">
          <h2 className="sidebar-header-title">
            <div className="sidebar-header-title__icon-wrap">
              <ICONS.peopleConference className="sidebar-header-title__icon"/>
            </div>

            <span className="sidebar-header-title__text">
              {`Operators ( ${onlineOperatorsCount} )`}
            </span>
          </h2>
        </div>
      </div>

      <List 
        list={operators}
        listItem={SidebarOperatorItem}
        classPrefix='sidebar-operators-list'
        scrollbarProps = {{ 
          renderTrackVertical: CustomScrollbarTrack, 
          renderThumbVertical: CustomScrollbarThumb 
        }}
      />
    </div>
  );
};

export default SidebarOperatorsList;