import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";

import {
  selectSessionIdsForGallery,
  selectPropertyOfActiveSession,
} from "redux/selectors/selectors";
import { getDivaGirls, DIVA_GIRLS_LIMIT } from "redux/ducks/divaGirls";

import './GalleryList.scss';
import Spinner from "components/UI/Spinner/Spinner";
import GalleryProfileCard from "../GalleryProfileCard/GalleryProfileCard";
import CustomScrollbarThumb from "components/UI/CustomScrollbarThumb/CustomScrollbarThumb";
import CustomScrollbarTrack from "components/UI/CustomScrollbarTrack/CustomScrollbarTrack";

const GalleryList = ({
  ids,
  apiParams,
  loadProfilesPending,
  additionalFilters,
  newCancelToken,
  cancelPrevRequest,
  activeFiltersLength,
  getDivaGirls,
}) => {
  const [part, setPart] = useState(2);

  const galleryListRef = useRef(null);
  const profileListRef = useRef(null);

  useEffect(() => {
    if (apiParams) {
      const filterBy = apiParams.filterby;
      const isActiveFilters = !!activeFiltersLength;
      const sameParams = apiParams['same-params']?.split('bestmatch')[0]; // bestmatch - Is Used For Receiving Host Profile Similarity Percentage
      const isAllProfiles = !sameParams && !filterBy && !isActiveFilters;

      if (isAllProfiles && loadProfilesPending && !Array.isArray(apiParams)) {
        cancelPrevRequest();

        getDivaGirls(
          { ...apiParams, cancelToken: newCancelToken() },
          additionalFilters,
          true,
        );
      }
    }
  }, [apiParams, loadProfilesPending]);

  useEffect(() => {
    if (apiParams) {
      setPart(2);
    };
  }, [apiParams]);

  useEffect(() => {
    const loadMoreProfilesIfItNeed = () => {
      if (ids.length >= DIVA_GIRLS_LIMIT && !loadProfilesPending) {
        const profilesWrapHeight = galleryListRef.current.scrollHeight;
        const profilesHeight = profileListRef.current.scrollHeight;

        if (profilesWrapHeight > profilesHeight) {
          const params = {
            ...apiParams,
            part: Math.ceil(ids.length / DIVA_GIRLS_LIMIT + 1),
          }

          getDivaGirls(params, additionalFilters)
            .then(res => {
              setPart(prev => res === 'max-part' ? res : prev + 1);
            });
        }
      }
    }

    loadMoreProfilesIfItNeed();
    window.addEventListener('resize', loadMoreProfilesIfItNeed);

    return () => window.removeEventListener('resize', loadMoreProfilesIfItNeed);
  }, [ids.length, apiParams, loadProfilesPending]);

  const onScroll = e => {
    if (part === 'max-part' || apiParams === null) return;

    const scrollHeightErrorOnPageScaling = 0.5;
    const list = e.target;
    const scrollBottom = list.scrollHeight - list.clientHeight - list.scrollTop;

    if (
      scrollBottom <= scrollHeightErrorOnPageScaling && 
      ids.length >= DIVA_GIRLS_LIMIT
    ) {
      getDivaGirls({ ...apiParams, part }, additionalFilters)
        .then(res => {
          setPart(res === 'max-part' ? res : part + 1);
        });
    }
  }

  if (loadProfilesPending) {
    return (
      <div className="gallery-list gallery-list--centered">
        <Spinner spinnerSize={75} />
      </div>
    );
  }

  if (!ids.length) {
    return (
      <div className="gallery-list gallery-list--centered">
        <p className="gallery-list__no-profiles">No profiles</p>
      </div>
    );
  }

  return (
    <div className="gallery-list" ref={galleryListRef}>
      <Scrollbars 
        onScroll={onScroll}
        renderThumbVertical={CustomScrollbarThumb}
        renderTrackVertical={CustomScrollbarTrack}
      >
        <ul className="gallery-list__profile-list" ref={profileListRef}>
          {ids?.map(id => (
            <GalleryProfileCard key={id} id={id} />
          ))}
        </ul>
      </Scrollbars>
    </div>
  );
};

const mSTP = state => ({
  ids: selectSessionIdsForGallery(state),
  apiParams: state.divaGirls.filterDivaGirlsApiParams,
  loadProfilesPending: state.divaGirls.loadProfilesPending,
  additionalFilters: (!!state.sessions.entities[state.sessions.activeSession]
    && selectPropertyOfActiveSession(state, 'additionalFilters')),
  activeFiltersLength: (!!state.sessions.entities[state.sessions.activeSession]
    && selectPropertyOfActiveSession(state, 'activeFilters'))?.length,
});

const mDTP = {
  getDivaGirls,
}

export default connect(mSTP, mDTP)(GalleryList);
