export default (text, highlight, className) => {
  if (!highlight) return text;

  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));

  return (
    <>
      {parts.map((part, index) => {
        if (part.toLowerCase() !== highlight.toLowerCase()) return part;

        return (
          <span className={className} key={index}>
            {part}
          </span>
        );
      })}
    </>
  );
};
