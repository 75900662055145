import classNames from 'classnames';

const DropdownMenu = (props) => {
  const {
    items = [],
    className,
    classPrefix,
  } = props;

  const handleItemClick = (e, item) => {
    e.preventDefault();

    item.action && item.action();

    if (item.notCloseOnClick) {
      return;
    }

    props.closeDropdownMenu && props.closeDropdownMenu();
  }

  return (
    <div className={classNames('popup-menu', className)}>
      {items.map((menuItem, idx) => (
        <div
          key={idx}
          className={classNames(
            'popup-menu__item', 
            { [`${classPrefix}__menu-item`]: classPrefix }
          )}
          onMouseOver={menuItem.onMouseOver || null}
          onClick={(e) => handleItemClick(e, menuItem)}
        >
          {menuItem.content}
        </div>
      ))}
    </div>
  );
}

export default DropdownMenu;