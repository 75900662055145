import React, { useState, useEffect, useRef, memo } from "react";
import { connect } from "react-redux";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import API from "api/api";
import { useToggle } from "hooks";
import { getContactAvatar, classModifier } from "utils";
import { CHAT_TYPES, CONTACT_TYPES } from "config/constants";
import { openModal, MODAL_TYPES } from "redux/ducks/activeWindows";
import { toggleContactPin, toggleContactMark, addNewArrayGirlsToState } from 'redux/ducks/contacts';
import { selectUserTimezone } from "redux/selectors/selectors";
import { addDivaGirlsToEntities } from "redux/ducks/divaGirls";

import "./ChatHeader.scss";
import LazyLoadImage from "components/LazyLoadImage/LazyLoadImage";
import ContactTels from "./ContactTels";
import ContactEmails from "./ContactEmails";
// import ContactDetails from "./ContactDetails/ContactDetails";
// import ContactBlockInfo from "./ContactBlockInfo";
// import ContactAgent from "./ContactAgent";
// import ChatCreatorForm from "../ChatCreatorForm/ChatCreatorForm";
// import AsyncButton from "components/AsyncButton/AsyncButton";
import TriStateSwitch from "components/TriStateSwitch/TriStateSwitch";
// import CopyToClipboardButton from "../../../../components/CopyToClipboardButton/CopyToClipboardButton";
// import SvgIcon from "../../../../components/SvgIcon/SvgIcon";
// import DropWrapper from 'components/DropWrapper';
// import StartSessionBtn from "../ChatSources/StartSessionBtn";
import BookingIcon from "../../../../components/BookingIcon";
import CopyToClipboardButton from "components/CopyToClipboardButton/CopyToClipboardButton";
import ICONS from "assets/icons";
import Icon from "components/Icon/Icon";
import Spinner from "components/UI/Spinner/Spinner";
import ContactTags from "components/ContactTags/ContactTags";
import ChatHeaderActionMenu from "./ChatHeaderActionMenu/ChatHeaderActionMenu";
import ProfileRates from "components/ProfileRates /ProfileRates";
// import BookedDropdown from "../../../../components/BookedDropdown/BookedDropdown";
// import ParsedGirlList from "../ParsedGirlList/ParsedGirlList";

const getRealAge = (birthday) => {
  if (birthday) {
    const b = birthday.split('-');
    const birthdayDate = new Date(b[0], b[1] - 1, b[2]);
    return `${Math.floor((Date.now() - birthdayDate.getTime()) / 1000 / 60 / 60 / 24 / 365)}`;
  } 
}

const ChatHeader = ({ activeRecipient, divaProfile, ...props }) => {
  // const [resultTags, setResultTags] = useState(getSortedTags(activeRecipient.callerTags || []));
 
  const [tags, setTags] = useState(activeRecipient.callerTags);
  const [parsedGirlsList, setParsedGirlsList] = useState({
    query: '',
    list: [],
    isOpen: false,
  });

  const [blockInfo, toggleContactInfo] = useToggle(false);
  const [isSessionMenuOpen, toggleSessionMenu] = useToggle(false);
  const [isRatesOpen, toggleRatesOpen] = useToggle(false);

  const contactInfoRef = useRef();
  const sessionMenuRef = useRef(null);
  const msgRef = useRef();

  useEffect(() => {
    contactInfoRef.current && contactInfoRef.current.scrollToTop();

    if (activeRecipient.type === CONTACT_TYPES.GIRL) {
      API.getDivaGirlsByContactsIds(activeRecipient.id)
        .then(res => props.addDivaGirlsToEntities([res.data]))
        .catch(console.error);
    }
  }, [activeRecipient.id, activeRecipient.type]);

  useEffect(() => {
    setTags(activeRecipient.callerTags);
  }, [activeRecipient.callerTags]);

  const isBlocked = !!activeRecipient.blocked;

  const onNameClick = () => {
    if (!props.fullScreenMode) {
      props.openModal(MODAL_TYPES.contactCard, { contact: activeRecipient.id });
    }
  }

  const getContactName = () => {
    const isEx = activeRecipient.fn.startsWith('EX ');
    const splittedName = activeRecipient.fn.split(' ');
    let name = activeRecipient.fn;

    if (isGirlChat) {
      name = isEx ? splittedName[1] : splittedName[0];
    } else if (isEx) {
      name = splittedName.slice(1).join(' ');
    }
    
    if (isEx) {
      return <><span className="chat-header__ex">EX</span>{name}</>
    }

    return name;
  }

  const getContactDetails = () => {
    const getId = () => {
      if (activeRecipient.diva_id) {
        return `ID ${activeRecipient.diva_id}`
      }
    }
  
    if (divaProfile) {
      const { prices, location_main, birthday } = divaProfile;

      const pricesStr = `${prices[0].incall || '-'}/${prices[2].incall || '-'}/${prices[2].outcall || '-'}`;

      const getLocation = () => {
        if (location_main) {
          return ` - ${location_main}`;
        }
      }

      const getId = () => {
        if (activeRecipient.diva_id) {
          return ` - ID ${activeRecipient.diva_id}`
        }
      }

      const getAge = () => {
        const realAge = getRealAge(birthday);

        if (activeRecipient.age || realAge) {
          return ` - ${activeRecipient.age || '-'}/${realAge || '-'} y.o`;
        }
      }

      return pricesStr + getLocation() + ` - ${getId()}` + getAge();
    };

    return getId();
  }

  // const bookingBtnClick = () => {
  //   setParsedGirlsList({
  //     ...parsedGirlsList,
  //     isOpen: true, 
  //   });
  // }
  
  const openProfileReviews = () => {
    if (!activeRecipient.reviewsCount || !activeRecipient.diva_default_id) return;

    props.openModal(MODAL_TYPES.profileReviews, {id: activeRecipient.diva_default_id});
  }

  const isGirlChat = activeRecipient.type === CONTACT_TYPES.GIRL;
  const contactDetails = getContactDetails();
  const isInfoBottom = isGirlChat && (!!activeRecipient.rating || !!activeRecipient.reviewsCount || contactDetails);
 
  return (
    <div
      className={classModifier("chat-header", [
        !activeRecipient.id && "hidden",
        activeRecipient.agentId && "with-agent",
        activeRecipient.availability && activeRecipient.availability
      ])}
    >
      {activeRecipient.id === "new_chat" ? (<>
        {/*  <ChatCreatorForm //TODO v2
           updateActiveContact={props.updateActiveContact}
           removeContactTab={props.removeContactTab}
         /> */}
      </>) : (
          <>
            <div className='chat-header__header'>
              <div
                className={`chat-header__ava-wrap ${isBlocked ? "blocked" : ""}`}
                onMouseEnter={isBlocked ? () => toggleContactInfo() : null}
                onMouseLeave={isBlocked ? () => toggleContactInfo() : null}
              >
                <LazyLoadImage
                  src={getContactAvatar(activeRecipient)}
                  className='round-img'
                  alt='contact_avatar'
                />

                {/* {blockInfo && ( 
                  <ContactBlockInfo
                    activeRecipient={activeRecipient}
                    userTimezone={props.userTimezone}
                  />
                )} */}
              </div>

              <div className="chat-header__info">
                <div className="chat-header__info-top">
                  <div className='chat-header__contact-details'>
                    <CopyToClipboardButton
                      className='item__copy'
                      value={activeRecipient.fn}
                      disabled={!activeRecipient.fn}
                      title='Copy name'
                    />

                    <p
                      className='chat-header__name'
                      title={activeRecipient.fn}
                      onClick={onNameClick}
                    >
                      {getContactName()}
                    </p>
                  </div>

                  <div className='chat-header__action-buttons'>
                    {props.type === CHAT_TYPES.CLIENT &&
                      <button
                        className={classModifier('chat-header__btn', 'session')}
                        // onMouseDown={() => toggleSessionMenu()} //TODO v2
                        title='start session'
                        disabled={!props.showSalesButton}
                      >
                        <ICONS.puzzle className={classModifier('chat-header__icon', 'session')} />
                      </button>
                    }

                    {activeRecipient.type === 1 && <>
                      {!activeRecipient.closestBooking && (
                        <button className={classModifier('chat-header__btn', 'booking')}>
                          <ICONS.booking
                            className="chat-header__icon"
                            // onClick={bookingBtnClick}
                          />
                        </button>
                      )}
                      
                      {activeRecipient.uncompletedBooking && (
                        <BookingIcon
                          className={classModifier('chat-header__btn', 'booking')}
                          booking={activeRecipient.closestBooking}
                          userTimezone={props.userTimezone}
                          // onClick={() => props.openModal(MODAL_TYPES.bookingsList, { contactId: activeRecipient.id })}
                        />
                      )}
                    </>}

                    {activeRecipient.type === CONTACT_TYPES.GIRL && <> 
                      <div className='chat-header__available'>
                        <TriStateSwitch
                          value={activeRecipient.availability ? activeRecipient.availability : "waiting"}
                          onChange={(value) => API.changeGirlsStatus(value, activeRecipient.id)}
                        />
                      </div>

                      {divaProfile &&
                        <button 
                          className="chat-header__btn"
                          onMouseEnter={() => toggleRatesOpen(true)}
                          onMouseLeave={() => toggleRatesOpen(false)}
                        >
                          <ICONS.poundSign className="chat-header__icon"/>

                          {isRatesOpen &&
                            <div className="chat-header__rates">
                              <ProfileRates
                                isEditor
                                profileId={divaProfile?.id}
                                isMember={divaProfile?.is_for_member || false}
                                defaultPrices={divaProfile?.prices || []}
                                memberPrices={divaProfile?.prices_member || []}
                                secretPrices={divaProfile?.prices_secret || []}
                              />
                            </div>
                          }
                        </button>
                      }
                    </>}                  
                  </div>
                </div>
                  
                {isInfoBottom &&
                  <div className="chat-header__info-bottom">
                    {contactDetails &&
                      <p className="chat-header__details">{getContactDetails()}</p>
                    }
 
                    {!!activeRecipient.rating &&
                      <span className="chat-header__rating">
                        {activeRecipient.rating}&nbsp;
                      </span>
                    }

                    {!!activeRecipient.reviewsCount && 
                      <button
                        className="chat-header__reviews"
                        onClick={openProfileReviews}
                      >
                        ({activeRecipient.reviewsCount})
                      </button>
                    }
                  </div>
                }
              </div>
{/* 
              <ContactDetails
                divaProfile={divaProfile}
                activeRecipient={activeRecipient} 
              /> */}

              <div className="chat-header__action-menu">
                <ChatHeaderActionMenu
                  type={props.type}
                  userId={props.userId}
                  girlDivaId={divaProfile?.id}
                  activeRecipient={activeRecipient}
                  toggleContactPin={props.toggleContactPin}
                  toggleContactMark={props.toggleContactMark}
                  openModal={props.openModal}
                />
              </div>
            </div>

            <div className='chat-header__tags'>
              <ContactTags tags={tags} />
            </div>

            <div className='chat-header__footer' ref={msgRef}>
              <div className='chat-header__contact-tels'>
                <ContactTels
                  callFromChat={props.callFromChat}
                  activeRecipient={activeRecipient}
                />

              </div>
              
              <div className='chat-header__contact-emails'>
                <ContactEmails activeRecipient={activeRecipient} />
              </div>

              {/* {activeRecipient.agentId && <ContactAgent agentId={activeRecipient.agentId} />} */}

              {/* {parsedGirlsList.isOpen && //TODO v2
                <ParsedGirlList 
                  msgRef={msgRef}
                  activeRecipient={activeRecipient}
                  addNewArrayGirlsToState={props.addNewArrayGirlsToState}
                  parsedGirlsList={parsedGirlsList}
                  updateActiveContact={props.updateActiveContact}
                  setParsedGirlsList={setParsedGirlsList}
                />
              } */}

              {/* {activeRecipient.type === 2 && props.canBookFromBuffer && divaProfile && // TODO v2 girl chat
              // we should check if profile exist, because in some moments when we add to buffer girl, we cat not diva_id
              // but id_new, this is problem (of bug) FIXME
                <span className="chat-header__booked-dropdown" >
                  <BookedDropdown
                    profileId={activeRecipient.diva_id}
                    isFromSessionChat
                  />
                </span>
              } */}
            </div>

            {/* {isSessionMenuOpen && // TODO v2
              <div ref={sessionMenuRef}>
                <DropWrapper 
                  dropWrapperRef={sessionMenuRef}
                  closeDropWrapper={toggleSessionMenu}
                >
                  <StartSessionBtn
                    activeRecipientId={activeRecipient.id}
                    toggleSalesBtn={toggleSessionMenu}
                  />
                </DropWrapper>
              </div>
            } */}
          </>
        )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  divaProfile: state.divaGirls.entities[ownProps.activeRecipient.diva_default_id],
  userTimezone: selectUserTimezone(state),
});

const mapDispatchToProps = {
  openModal,
  toggleContactPin,
  toggleContactMark,
  addDivaGirlsToEntities,
  addNewArrayGirlsToState,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(ChatHeader));
