import {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { usePopperTooltip } from 'react-popper-tooltip';

import {
  LIST_TYPES,
  MAIL_LIST_LIMIT,
  moveMailsToFolder,
  deleteMailsToTrash,
  deleteMailsPermanently,
  deleteMailsFromSearchList,
  setMailListPending,
  archiveMails,
  updateSearch,
} from 'redux/ducks/mail';
import { classModifier } from 'utils';
import { selectActiveFolderTab } from 'redux/selectors/selectors';

import SearchInput from 'components/SearchInput/SearchInput';
import MailHeaderActionButton from './MailHeaderActionButton';
import DropdownMenu from 'components/DropdownMenu/DropdownMenu';

const MIN_SEARCH_LENGTH = 3;

const MailHeaderListActions = (props) => {
  const {
    offset,
    search,
    updateSearch,
    activeFolder,
    archiveMails,
    mailListPending,
    activeFolderTab,
    activeSortFilter,
    moveMailsToFolder,
    setMailListPending,
    deleteMailsToTrash,
    deleteMailsPermanently,
    selectedConversationsIds,
    deleteMailsFromSearchList,
  } = props;

  const [query, setQuery] = useState(search || '');
  const [isMoveToMenuActive, setIsMoveToMenuActive] = useState(false);
  const [pending, setPending] = useState({ deletePending: false, archivePending: false, movePending: false });

  const navigate = useNavigate();

  const somePending = [mailListPending, ...Object.values(pending)].includes(true);

  const isInbox = activeFolder === LIST_TYPES.inbox;
  const isSent = activeFolder === LIST_TYPES.sent;
  const isSpam = activeFolder === LIST_TYPES.spam;
  const isTrash = activeFolder === LIST_TYPES.trash;
  const isDrafts = activeFolder === LIST_TYPES.drafts;
  const isSearch = activeFolder === LIST_TYPES.search;
  const isPermanentlyDelete = isSpam || isTrash || isDrafts;
  const isDisplayArchiveBtn = isInbox || isSent;
  const isDisplayMoveToBtn = !isDrafts;

  const {
    visible,
    setTooltipRef,
    setTriggerRef,
    getTooltipProps,
  } = usePopperTooltip(
    {
      offset: [0, 0],
      trigger: 'click',
      visible: isMoveToMenuActive,
      onVisibleChange: setIsMoveToMenuActive,
    }
  );

  const getConfigForOperationsWithEmails = (additionalParams) => {
    const config = {};

    if (isSearch) {
      config.search = search;
    } else {
      config.folder = activeFolder;
      config.mode = activeFolderTab;
      config.sortBy = activeSortFilter;
    }

    config.offset = offset;
    config.limit = MAIL_LIST_LIMIT;
    config.conversationsIds = selectedConversationsIds;

    return { ...config, ...additionalParams };
  };

  useEffect(() => {
    setQuery(search);
  }, [search]);

  const handleDeleteMails = async () => {
    setMailListPending(true);
    setPending(prev => ({ ...prev, deletePending: true }));

    if (isSearch) {
      await deleteMailsFromSearchList(getConfigForOperationsWithEmails());
    } else if (isPermanentlyDelete) {
      await deleteMailsPermanently(getConfigForOperationsWithEmails());
    } else {
      await deleteMailsToTrash(getConfigForOperationsWithEmails());
    }

    setMailListPending(false);
    setPending(prev => ({ ...prev, deletePending: false }));
  };

  const handleArchiveMails = async () => {
    setMailListPending(true);
    setPending(prev => ({ ...prev, archivePending: true }));

    await archiveMails(getConfigForOperationsWithEmails());

    setMailListPending(false);
    setPending(prev => ({ ...prev, archivePending: false }));
  };

  const handleMoveMails = async (moveTo) => {
    setMailListPending(true);
    setPending(prev => ({ ...prev, movePending: true }));

    await moveMailsToFolder(getConfigForOperationsWithEmails({ to: moveTo }));

    setMailListPending(false);
    setPending(prev => ({ ...prev, movePending: false }));
  };

  const handleUpdateSearch = (query) => {
    updateSearch(query);

    if (query.length >= MIN_SEARCH_LENGTH) {
      navigate('/mail/search');
    }
  };

  const generateMoveToMenuItems = () => ([
    {
      content: 'Inbox',
      action: () => handleMoveMails('inbox'),
    },
    {
      content: 'Archive',
      action: () => handleMoveMails('archive'),
    },
    {
      content: 'Sent',
      action: () => handleMoveMails('sent'),
    },
    {
      content: 'Junk',
      action: () => handleMoveMails('spam'),
    },
    {
      content: 'Trash',
      action: () => handleMoveMails('trash'),
    },
  ].filter(item => item.content.toLowerCase() !== activeFolder));

  return (
    <>
      <SearchInput
        id="search-mails"
        placeholder="Search mails"
        inputWrapClassname="mail-header__search-input-wrap"
        inputClassName="mail-header__search-input"
        query={query}
        showClearBtn
        showSearchBtn
        maxLength='100'
        minSearchLength={MIN_SEARCH_LENGTH - 1}
        setQuery={setQuery}
        stopSearch={handleUpdateSearch}
        startSearch={handleUpdateSearch}
      />

      <MailHeaderActionButton
        title='Archive'
        content='Archive'
        iconName='archive'
        classNameModifier='main'
        pending={pending.archivePending}
        disabled={somePending || !selectedConversationsIds.length}
        isDisplay={isDisplayArchiveBtn}
        handleClick={handleArchiveMails}
      />

      <MailHeaderActionButton
        title='Delete'
        content='Delete'
        iconName='trash'
        classNameModifier='main'
        pending={pending.deletePending}
        disabled={somePending || !selectedConversationsIds.length}
        handleClick={handleDeleteMails}
      />

      <div
        className={classModifier('mail-header__move-to-wrap', [
          isMoveToMenuActive && 'move-menu-active'
        ])}
        ref={setTriggerRef}
      >
        <MailHeaderActionButton
          title='Move to'
          content='Move to'
          iconName='folderOpen'
          classNameModifier='move'
          iconClassNameModifier='move'
          pending={pending.movePending}
          isDisplay={isDisplayMoveToBtn}
          disabled={somePending || !selectedConversationsIds.length}
        />

        {visible &&
          <div
            ref={setTooltipRef}
            {...getTooltipProps({ className: 'mail-header__move-to-popup-wrap' })}
          >
            <DropdownMenu
              items={generateMoveToMenuItems()}
              closeDropdownMenu={() => setIsMoveToMenuActive(false)}
            />
          </div>
        }
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  offset: state.mail.offset,
  search: state.mail.mailList.search,
  mailListPending: state.mail.mailList.mailListPending,
  activeFolder: state.mail.mailList.activeFolder,
  activeFolderTab: selectActiveFolderTab(state),
  activeSortFilter: state.mail.mailList.activeSortFilter,
  selectedConversationsIds: state.mail.selectedConversationsIds,
});

const mapDispatchToProps = {
  archiveMails,
  updateSearch,
  moveMailsToFolder,
  deleteMailsToTrash,
  setMailListPending,
  deleteMailsPermanently,
  deleteMailsFromSearchList,
};

export default connect(mapStateToProps, mapDispatchToProps)(MailHeaderListActions);
