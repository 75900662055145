import React from 'react';
import { connect } from 'react-redux';

import { classModifier } from 'utils';


const AdrBookTag = ({ tag, ...props }) => {

  return (
    <li className="adr-book-tags__list-item">
      <button
        className={classModifier("adr-book-tags__tag", [
          props.isActive && "active"
        ])}
        onClick={() => props.updateTagsFilter(tag.id)}
        disabled={props.contactsPending}
      >
        <span className="adr-book-tags__tag-title">
          {tag.title}
        </span>

        <span className="adr-book-tags__tag-count">
          &nbsp;({tag.callersCount})
        </span>
      </button>
    </li>
  );
}

const mapStateToProps = (state, { tag }) => ({
  isActive: !!state.addressBook.tags.active[tag.id],
  contactsPending: state.addressBook.contactsPending,
});

export default connect(mapStateToProps)(AdrBookTag);