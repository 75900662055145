import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import { classModifier } from '../../../../utils';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { openModal, MODAL_TYPES } from 'redux/ducks/activeWindows';

import VideoThumbnail from 'components/VideoThumbnail/VideoThumbnail';


const AttachmentVideos = ({ videos, openModal }) => {
  const handleOnClick = (src) => {
    openModal(MODAL_TYPES.video, { src });
  };

  // const download = (e, urlsList) => {
  //   e.preventDefault();

  //   urlsList.forEach(url => {
  //     let a = document.createElement('a');
  //     a.href = url;
  //     a.download = url;
  //     a.click();
  //   });
  // };

  // const currVideos = useMemo(() => videos.map(item => item.url), []);

  return (
    <div className="attachments__images">
      <h4 className="attachments__title">Attached videos</h4>

      {/* {currVideos.length > 1 && 
        <button className={classModifier("attachment-img__btn", "download-all")} title="download all">
          <FontAwesomeIcon size="lg" icon={faArrowDown} onClick={(e) => download(e, currVideos)}/>
        </button>
      } */}

      <div className="attachment-img">
        {videos.map((video, idx) => (
          <div key={idx} className="attachment-img__img-wrap">
            <VideoThumbnail
              spinnerSize={40}
              src={video.url}
              onClick={() => handleOnClick(video.url)}
            />
            {/* <button 
              className={classModifier("attachment-img__btn", "download")} 
              onClick={(e) => download(e, [video.url])} 
              title="download"
            >
              <FontAwesomeIcon size="lg" icon={faArrowDown} />
            </button> */}
          </div>
        ))}
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  openModal,
};

export default connect(null, mapDispatchToProps)(AttachmentVideos);