import React from 'react';

import { classModifier } from 'utils';

import './ChatAttachments.scss';
import AttachmentMessage from './AttachmentMessage';
import AttachmentContact from './AttachmentContact';
import AttachmentImages from './AttachmentImages';
import AttachmentLinks from './AttachmentLinks';
import AttachmentVoiceMessage from './AttachmentVoiceMessage';
import AttachmentSession from './AttachmentSession';
import AttachmentVideos from './AttachmentVideos';

const InteractionAttachments = ({ attachments, profileId, ...props }) => {
  return (
    <div className={`interaction__attachments ${classModifier('attachments', [
      attachments.sharedMsg && 'shared-msg'
    ])}`}>
      {attachments.voiceMsg &&
        <AttachmentVoiceMessage
          showTitle
          className="attachments__voice-message"
          voiceMsg={attachments.voiceMsg} />
      }

      {attachments.sharedMsg &&
        <AttachmentMessage
          className="attachments__msg"
          showTitle
          interaction={attachments.sharedMsg}
          profileId={profileId}
          userTimezone={props.userTimezone}
          userHour12={props.userHour12}
        />
      }

      {attachments.sharedCaller &&
        <AttachmentContact
          className="attachments__contact"
          contactId={attachments.sharedCaller.id}
          contactFromMsg={attachments.sharedCaller}
          showTitle
          showActions />
      }

      {attachments.images &&
        <AttachmentImages
          images={attachments.images} />
      }

      {attachments.videos &&
        <AttachmentVideos
          videos={attachments.videos} /> 
      }

      {(attachments.links && attachments.links.length === 1) &&
        <AttachmentLinks
          links={attachments.links} />
      }

      {attachments.sharedSalesSession &&
        <AttachmentSession
          showTitle
          id={attachments.sharedSalesSession.id}
        />
      }

    </div>
  );
}


export default InteractionAttachments;